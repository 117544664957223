import React, { Component } from 'react' 
import { Redirect } from 'react-router-dom';
import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import FormCheckBox from '../../atom/FormCheckBox/FormCheckBox';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import Line from '../../atom/Line/Line';
import MessageBox from '../../atom/MessageBox/MessageBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import BackBar from '../../molecules/BackBar/BackBar'; 
import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox';import TabButtonBox from '../../molecules/TabButtonBox/TabButtonBox'; 
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import Accordion from '../../organism/Accordion/Accordion'; 
import DataTableDocuments from '../../organism/DataTableDocuments/DataTableDocuments';
import CommunicationContent from '../../organism/CommunicationContent/CommunicationContent';
import CommunicationForm from '../../organism/CommunicationForm/CommunicationForm';
import LoanDetails from '../../organism/LoanDetails/LoanDetails';
import ProgressStep from '../../organism/ProgressStep/ProgressStep';

import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/InstructMSAPage.css';   
import { AuthService } from '../../../services/auth-service';
import { InstructionService } from '../../../services/instruction-service';
import RadioButton from '../../atom/RadioButon/RadioButon';
import RadioButtonGroup from '../../molecule/RadioButtonGroup/RadioButtonGroup';
import {saveAs}         from 'file-saver';
import Button from '../../atom/Button/Button';
import ButtonGroupDischarges from '../../molecule/ButtonGroupDischarges/ButtonGroupDischarges';
import AutoLogout from '../../../auto-logout';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import { MatterService } from '../../../services/matter-service';
import IconBox from '../../atom/IconBox/IconBox';
import Dropzone   from 'react-dropzone';
import { FilePond } from 'react-filepond';
import { MatterDocumentService } from '../../../services/matter-document-service';
import SAlert from "sweetalert2";
import InsightBox from '../../molecules/InsightBox/InsightBox';
import ButtonGroup from '../../molecule/ButtonGroup/ButtonGroup';
import MortManagerControl from '../../organism/SearchLoanBox/mort-manager-control';


class InstructMSAPage extends Component { 
    
    constructor(props){
        super(props) 

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }
        let userdetails = AuthService.getUserDetails();	
        let lendername= AuthService.getLendername();
        // let token = AuthService.getToken();
        this.state={ 
            pageTitle : 'dischargeinstructions',
            leftContent : 'dischargeinstructions',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            isInstructPriv: null,
            isLoading:false,
            onboarding:true,
            fade:false,
            option:"",
            prompt:"",
            copy:"This page can update MSA's systems, eliminating the need to it for an update to be actioned. only certain lenders are permitted to instruct MSA through the portal. For documentation please contact MSA. You will receive a message asking you to confirm your changes.",
            lenderReference:'',
            dischargeType:0,
            dischargeTypeName:'',
            subDischargeType:0,
            subDischargeTypeOptions:['Sale','External Refinance','Debt Repaid','Internal Refinance'],
            //subDischargeTypeOptions:{},
            matterNumber:'',
            matterDescription:'',
            isChecking:false,
            isExistingLenderReference:false,
            isNewLoanConfirmed:false,
            hasCheckedReferenceNumber:false,
            errorMessage:'',
            LoanIds:[],
            IndicativeAmounts:[],
            LoanIdEntityCount:0,
            LoanIdEntityMinCount:1,
            LoanIdEntities:[],
            PartyRepEntityCount:1,
            PartyRepEntitySegmentMinCount:1,
            PartyRepEntitySegmentMaxCount:1,
            PartyRepNames:[],
            PartyRepTypes:[],
            PartyRepEmails:[],
            PartyRepNumbers:[],
            PartyRepEntities:[],
            IncomingMortgageeNames:[],
            IncomingMortgageeEmails:[],
            IncomingMortgageeNumbers:[],
            IncomingMortgageeType:[],
            IncomingMortgageeSegmentEntities:[],
            IncomingMortgageeSegmentCount:0,
            IncomingMortgageeSegmentMinCount:0,
            IncomingMortgageeSegmentMaxCount:1,
            PartySegmentPhoneNumbers:[],
            PartySegmentPartyTypes:[],
            PartySegmentPorCTypes:[],
            PartySegmentCompanyPPSR:[],
            PartySegmentNames:[],
            PartySegmentEmails:[],
            PartySegmentAddresses:[],
            PartySegmentMinCount:1,
            PartySegmentCount:1,
            PartySegmentEntities:[],
            SecuritySegmentAddreses:[],
            SecuritySegmentTitleReferences:[],
            SecuritySegmentTitleAddresses:[],
            SecuritySegmentEntities:[],
            SecuritySegmentMinCount:1,
            SecuritySegmentCount:1,
            SecuritySegmentMaxCount:1000,
            SimultaneousSettlementSegmentEntities:[],
            SimultaneousSettlementSegmentCount:0,
            SimultaneousSettlementSegmentMaxCount:1,
            SimultaneousSettlementSegmentLoanIds:[],
            LoanHasSimultaneous:false,
            ImportantNoteSegmentEntities:[],
            ImportantNoteSegmentCount:1,
            ImportantNoteSegmentMinCount:1,
            ImportantNoteSegmentMaxCount:1,
            HasImportantNoteSegmentGuarantorTitleDeedToAddress:false,
            ImportantNoteSegmentGuarantorTitleDeedToAddress:[],
            HasBorrowerClearTitleToAddress:false,
            BorrowerClearTitleToAddress:[],
            ImportantNoteSegmentSettlementNotToBeBookedPriorToDates:[],
            ImportantNoteSegmentCollectSurplus:[],
            ImportantNoteSegmentGuarantorNames:[],
            SpecialConditionSegmentEntities:[],
            SpecialConditionSegmentCount:0,
            SpecialConditionSegmentConditionValues:[],
            SpecialConditionSegmentConditionTypes:[],
            DischargeinstructionOtherRequests:'',
            InstructionType:null,
            InstructionLenderReference:null,
            InstructionSecondaryLenderRef:null,
            
            MatterExists:false,
            CheckedMatter:false,
            MsaReference:null,
            MatterDescription:null,
            MatterLenderReference:null,
            DateValidGood:true,
            SecurityDischargeReasons:[],
            DischargeTypeChoice:[],

            SelfActing:[],
            States:[],
            DischargeTypes:{},
            MappedStates:[],
            msg:'',

            PayoutHasSpecialConditions:false,
            PayoutSpecialConditions:'',

            HasAnticipatedDate:false,
            MatterDateValid:null,
            MatterAdditionalNotes:null,
            MatterLoanAccounts:null,
            PayoutStage:'',
            shouldRedirect:false,
            InstructionSubmit:false,
            CanInstructNewLoans: false,
            CanInstructDischarges: false,
            CanProvideDischargePayout: false,
            CanInstructDischargeUpload: false,
            IsLenderThinkTank: false,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            userdetails: userdetails,
            emailValidationError: null,
            profileDisplay: "",	
            lenderName: lendername,
            shouldverifyQLDSecurityTitle: false,
            SecurityQLDAddressesMatched: [],
            SecurityQLDAddressOverridden: [],
            SecurityQLDDetailsVerified: [],
            SecurityQLDDetailsNeedsToBeVerified:[],
            SecurityQLDVerifiedAddress:[],
            SecurityQLDLotNo:[],
            SecurityQLDPlanNo:[],
            SecurityVerifierLoading:[],
            SecurityQLDNotExist:[],

            shouldverifyNSWSecurityTitle: false,            
            SecurityNSWVerifiedAddress:[],
            SecurityNSWVerifiedLandAddress:[],
            SecurityNSWNotExist:[],
            SecurityNSWAddressesMatched:[],
            SecurityNSWDetailsNeedsToBeVerified: [],
            SecurityNSWAddressOverridden: [],
            SecurityNSWDetailsVerified: [],

            shouldverifyVICSecurityTitle: false,
            SecurityVICAddressesMatched: [],
            SecurityVICAddressOverridden: [],
            SecurityVICDetailsVerified: [],
            SecurityVICDetailsNeedsToBeVerified:[],
            SecurityVICVerifiedAddress:[],
            SecurityVICLotNo:[],
            SecurityVICPlanNo:[],
            SecurityVICNotExist:[],
            SecurityVICTitleAddress:[],            
            
            SecurityShouldBeVerifiedFirst: false,
            MatterDetails: [],

            // Upload Title Search
            CanUploadTitleSearch: false,
            SecurityTitleSearches: [],
            files:[],
            filenames: [],
            SecurityTitleFiles: [],
            SecurityTitleSearchFilenames: [],
            SecurityMortgageeExists:[],
            SecurityMortgageeTitleSearchFiles:[],
            SecurityMortgageeId:[],
            SecurityMortgageeNames:[],
            SecurityMortgageeNamesManual:[],
            SecurityMortgageeCompanyACN:[],
            SecurityMortgageNumbers:[],
            SecurityMortgageeFormattedTitleRefNo:[],
            SecurityMortgageeVolume:[],
            SecurityMortgageeFolio:[],
            SecurityMortgageeBlock:[],
            SecurityMortgageeDistrictDivision:[],
            SecurityMortgageeLandDescription:[],
            SecurityMortgageeSection:[],
            SecurityMortgageeUnit:[],
            SecurityMortgageeLot:[],
            SecurityMortgageeTown:[],
            SecurityStateReadOnly:[],
            LenderMortgagees:[],
            MappedLenderMortgagees:[],
            InsightBox:0, 
            displayPPSRDischarges: false,
            //token: token,
            sessionHasExpired: false,
            redirectToLogin: false,
            IsTitleSearchFromUpload: [],
            TitleReferenceValid: [],
            TitleRefFormat: [],
            CanUserChooseMortMgr: false,
            CanUserAddSecondaryRef: false,
            mortManagers: [],
            mortManager:[]
        }
    

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.CheckMatter=this.CheckMatter.bind(this);
        this.UpdateLenderReferenceNumber = this.UpdateLenderReferenceNumber.bind(this);
        this.LoanIdUpdated = this.LoanIdUpdated.bind(this);
        this.IndicativeAmountUpdated=this.IndicativeAmountUpdated.bind(this);
        this.UpdateLoanIdSegment=this.UpdateLoanIdSegment.bind(this);
        this.CreateDischargeInstructionEntity=this.CreateDischargeInstructionEntity.bind(this);
        this.GetDischargeTypes=this.GetDischargeTypes.bind(this);
        this.GetStates = this.GetStates.bind(this);
        this.LoanIdUpdated=this.LoanIdUpdated.bind(this);
        this.IndicativeAmountUpdated = this.IndicativeAmountUpdated.bind(this);
        this.UpdateLoanIdSegment = this.UpdateLoanIdSegment.bind(this);
        this.PartyRepNameUpdated = this.PartyRepNameUpdated.bind(this);
        this.PartyRepEmailUpdated = this.PartyRepEmailUpdated.bind(this);
        this.PartyRepNumberUpdated = this.PartyRepNumberUpdated.bind(this);
        this.UpdatePartyRep = this.UpdatePartyRep.bind(this);
        this.IncomingMortgageeNameUpdated = this.IncomingMortgageeNameUpdated.bind(this);
        this.IncomingMortgageeEmailUpdated=this.IncomingMortgageeEmailUpdated.bind(this);
        this.IncomingMortgageeNumberUpdated = this.IncomingMortgageeNumberUpdated.bind(this);
        this.UpdateIncomingMortgageeSegment = this.UpdateIncomingMortgageeSegment.bind(this);
        this.PartySegmentEmailUpdated = this.PartySegmentEmailUpdated.bind(this);
        this.PartySegmentNumberUpdated = this.PartySegmentNumberUpdated.bind(this);
        this.PartyAddressUpdated=this.PartyAddressUpdated.bind(this);
        this.PartyTypeUpdated=this.PartyTypeUpdated.bind(this);
        this.PartyNameUpdated = this.PartyNameUpdated.bind(this);
        this.UpdatePartySegment=this.UpdatePartySegment.bind(this);
        this.SecurityAddressUpdated = this.SecurityAddressUpdated.bind(this);
        this.UpdateSecuritySegment = this.UpdateSecuritySegment.bind(this);
        this.SimultaneousSettlementSegmentLoanIdUpdated = this.SimultaneousSettlementSegmentLoanIdUpdated.bind(this);
        this.UpdateSimultaneuosSettlementSegment = this.UpdateSimultaneuosSettlementSegment.bind(this);
        this.UpdateGuarantorTitleDeedToAddress = this.UpdateGuarantorTitleDeedToAddress.bind(this);
        this.UpdateBorrowerClearTitleToAddress = this.UpdateBorrowerClearTitleToAddress.bind(this);
        this.SettlementNotToBeBookedPriorToUpdated = this.SettlementNotToBeBookedPriorToUpdated.bind(this);
        this.CollectSurplusUpdated = this.CollectSurplusUpdated.bind(this);
        this.GuarantorUpdated = this.GuarantorUpdated.bind(this);
        this.UpdateImportNotesSegment = this.UpdateImportNotesSegment.bind(this);
        this.UpdateSpecialConditionValues = this.UpdateSpecialConditionValues.bind(this);
        this.UpdateSpecialConditionSegmentTypes = this.UpdateSpecialConditionSegmentTypes.bind(this);
        this.UpdateSpecialConditionSegment = this.UpdateSpecialConditionSegment.bind(this);
        //this.CreateDischargeInstructionEntity =this.CreateDischargeInstructionEntity.bind(this);
        this.SecurityTitleReferenceUpdated = this.SecurityTitleReferenceUpdated.bind(this);
        this.GetDischargeTypes = this.GetDischargeTypes.bind(this);
        //this.GetStates = this.GetStates.bind(this);
        this.UpdateLenderReferenceCombined = this.UpdateLenderReferenceCombined.bind(this);
        this.LoanIdUpdated = this.LoanIdUpdated.bind(this);
        this.IndicativeAmountUpdated=this.IndicativeAmountUpdated.bind(this);
        this.DownloadInstructionHtml=this.DownloadInstructionHtml.bind(this);
        this.DownloadInstructionXml=this.DownloadInstructionXml.bind(this);
        this.GetExternalUserPrivilege = this.GetExternalUserPrivilege.bind(this);
        this.GetLenderDetails = this.GetLenderDetails.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
        this.GetDischargeReasonTypes = this.GetDischargeReasonTypes.bind(this);
        this.ResetForm = this.ResetForm.bind(this);
        this.GetLenderMortgagees = this.GetLenderMortgagees.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
        this.mortManagerChanged = this.mortManagerChanged.bind(this);
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    ResetForm(){
        this.setState({
            PartySegmentPhoneNumbers:[],
            PartySegmentPartyTypes:[],
            PartySegmentPorCTypes:[],
            PartySegmentCompanyPPSR:[],
            PartySegmentNames:[],
            PartySegmentEmails:[],
            PartySegmentAddresses:[],
            PartySegmentMinCount:1,
            PartySegmentCount:1,
            PartySegmentEntities:[],
            SecuritySegmentAddreses:[],
            SecuritySegmentTitleReferences:[],
            SecuritySegmentEntities:[],
            SecuritySegmentMinCount:1,
            SecuritySegmentCount:1,
            LoanIds:[],
            IndicativeAmounts:[],
            LoanIdEntityCount:1,
            LoanIdEntityMinCount:1,
            LoanIdEntities:[],
            
        },()=>{
            this.UpdatePartySegment();
            this.UpdateSecuritySegment();
            this.UpdateLoanIdSegment();
        });

    }
    
    componentDidMount(){
        document.title='Loantrak - Discharges Application';
        AuthService.isNewVersionDetected();

        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("discharges instruction").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }

        MatterService.CanUserChooseMortMgr().then((resp) => {
            console.log("CanUserChooseMortMgr");
            console.log(resp);         
            this.setState({ CanUserChooseMortMgr: resp.data.Content},()=>
            {
                if(this.state.CanUserChooseMortMgr){
                    let mmList = MatterService.getLinkedMortMgrList();

                    Promise.all([mmList]).then(values => {
                      
                        let mortManagers = values[0].data;
                  
                      //  let brokers = values[6].data;
                        //brokers.splice(0,0,AllOption);
                        this.setState({
                          //set values
                         
                          mortManagers: mortManagers,
                          mortManager: mortManagers[0],
                        //  brokers:brokers,
                         // broker:brokers[0],
                        },()=>{
                            console.log(this.state.mortManagers)
                            console.log("this.state.mortManager")
                            console.log(this.state.mortManager)
                        });
                      });
                }
            }
            );           
           
        }, (err) => {
            this.setState({ CanUserChooseMortMgr: false});
        }); 

        MatterService.canUserAddSecondaryLenderRef().then((resp) => {
            console.log("CanUserAddSecondaryRef");
            console.log(resp);         
            this.setState({ CanUserAddSecondaryRef: resp.data.Content});           
           
        }, (err) => {
            this.setState({ CanUserAddSecondaryRef: false});
        }); 


        MatterService.ShouldVerifyQLDSecurityTitle().then((r) =>{
            console.log("monday")
            console.log(r)
            //alert(r.data.Content)
            this.setState({
                shouldverifyQLDSecurityTitle: r.data.Content
            })
        },(err)=>{
            this.setState({
             hasError: true,
             shouldRedirect: true
            })
        });

        MatterService.ShouldVerifyNSWSecurityTitle().then((r) =>{
            console.log("NSW")
            console.log(r)
            //alert(r.data.Content)
            this.setState({
                shouldverifyNSWSecurityTitle: r.data.Content
            })
        },(err)=>{
            this.setState({
             hasError: true,
             shouldRedirect: true
            })
        });

        MatterService.CanUserDoTitleSearchUpload().then((s) =>{	
            console.log("CanUploadTitleSearch")	
            console.log(s)	
            //alert(r.data.Content)	
            this.setState({	
                CanUploadTitleSearch: s.data.Content	
            })	
        },(err)=>{	
            this.setState({	
             hasError: true,	
             shouldRedirect: true	
            })	
        });

        MatterService.DisplayPPSRDischarges().then((s) =>{	
            console.log("DisplayPPSRDischarges")	
            console.log(s)	
            //alert(r.data.Content)	
            this.setState({	
                displayPPSRDischarges: s.data.Content	
            })	
        },(err)=>{	
            this.setState({	
             //hasError: true,	
             //shouldRedirect: true	
            })	
        });
        
        
        this.setState({LoanIdEntityCount:this.state.LoanIdEntityCount+1},()=>{this.UpdateLoanIdSegment();});
        //this.GetDischargeReasonTypes();
        this.GetStates();
        this.GetExternalUserPrivilege();
        this.GetLenderMortgagees();
        //this.GetLenderDetails();

        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        if(usertypeid == 2)	
        {	
            this.setState({	
                IsLenderThinkTank: this.state.lenderName !=null ? this.state.lenderName.toLowerCase().includes('think tank')?true:false : false	
            });	
            // AuthService.GetLenderDetails().then((resp)=>{	
            //     this.setState({	
            //         IsLenderThinkTank:resp.data.Content	
            //     },()=>{console.log(this.state.IsLenderThinkTank);	
            //         //alert(this.state.IsLenderThinkTank)	
            //     });	
            // },(err)=>{	
            //     console.log("there is an error")	
            //     //this.setState({isInstructPriv:false});	
            // })	
        }	


        AuthService.isUserAuthorisedToInstruct().then((resp)=>{
            this.setState({isInstructPriv:true,shouldRedirect:false},
                ()=>{
                    console.log("caninstruct" + "shouldred")
                    console.log(this.state.isInstructPriv )
                    console.log( this.state.shouldRedirect)
                });
        },(err)=>{
            this.setState({
                isInstructPriv:false,
                shouldRedirect:true
            });
        });
        AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({
                CanInstructDischarges:resp.data.Content.CanInstructDischarges,
                CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
                CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
                CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
                shouldRedirect: !resp.data.Content.CanInstructDischarges,
            },()=>{
                console.log(this.state.CanInstructDischarges)
            });
        },(err)=>{
            console.log("there is an error")
            //this.setState({isInstructPriv:false});
        })

        window.addEventListener("resize", this.updateWindowDimension)

        

 
    }

    componentWillUpdate(np,ns){
        if(ns!==this.state){
            
        }
    }
    componentDidUpdate(){
        let displayNoneElems = document.querySelectorAll('div[style*="display:none"]');
        for(let y=0; y<displayNoneElems.length;y++){
            let noneInputElems = displayNoneElems[y].getElementsByTagName('input');
            for(let i =0; i<noneInputElems.length;++i){
                if(noneInputElems[i].required){
                    noneInputElems[i].required=false;
                }
            }
        } 
      
    
        
    }

    checkIfSessionHasExpired()
    {
        let strToken = AuthService.getToken();
        //AuthService.checkIfSessionHasExpired(this.state.token).then((resp)=>{
        AuthService.checkIfSessionHasExpired(strToken).then((resp)=>{
            this.setState({sessionHasExpired:resp.data},
                ()=>{
                    console.log("sessionHasExpired")
                    console.log(this.state.sessionHasExpired )
                });
        },(err)=>{
            this.setState({
                // isInstructPriv:false,
                // shouldRedirect:true
                sessionHasExpired: true
            });
        });
    }

    verifyQLDSecurityTitle(i,titleRef, street, suburb, state, postcode){
        // alert(titleRef)
        // alert(street)
        // alert(suburb)
        // alert(postcode)
        let verifierLoading = this.state.SecurityVerifierLoading;
        verifierLoading[i] = true;
        let stateReadOnly = this.state.SecurityStateReadOnly;
        stateReadOnly[i] = true;
        this.setState({SecurityVerifierLoading: verifierLoading},
            ()=>{
                this.UpdateSecuritySegment();
            });

        MatterService.VerifyQLDSecurityTitle(titleRef).then((r) =>{
            console.log("monday")
            console.log(r)
            //alert(r.data.Content)           

            let securityQLDNotExist = this.state.SecurityQLDNotExist;
            let verified = this.state.SecurityQLDDetailsVerified; 
            let needsToBeVerified = this.state.SecurityQLDDetailsNeedsToBeVerified;
            let addressesMatched = this.state.SecurityQLDAddressesMatched; 
            let securityQLDVerifiedAddress = this.state.SecurityQLDVerifiedAddress;
            let securityQLDLotNo = this.state.SecurityQLDLotNo;
            let securityQLDPlanNo = this.state.SecurityQLDPlanNo;
          
            // securityQLDVerifiedAddress[i] = (r.data.Content[0].Address != "" && r.data.Content[0].Address != null) ? r.data.Content[0].Address.trim().replace('"','').replace('"','') : '';
            //securityQLDVerifiedAddress[i] = (r.data.Content.results[0].Address != "" && r.data.Content.results[0].Address != null) ? r.data.Content.results[0].Address.trim().replace('"','').replace('"','') : '';
            if(r.data.Content != null)
            {
                if(r.data.Content.hasError == false)
                {
                    // securityQLDVerifiedAddress[i] = r.data.Content.results != null? ((r.data.Content.results[0].Address != "" && r.data.Content.results[0].Address != null) ? r.data.Content.results[0].Address.trim().replace('"','').replace('"','') : '') : '';
                    // securityQLDLotNo[i] = r.data.Content.results != null? ((r.data.Content.results[0].LotNo != "" && r.data.Content.results[0].LotNo != null) ? r.data.Content.results[0].LotNo.trim().replace('"','').replace('"','') : '') : '';
                    // securityQLDPlanNo[i] = r.data.Content.results != null? ((r.data.Content.results[0].PlanNo != "" && r.data.Content.results[0].PlanNo != null) ? r.data.Content.results[0].PlanNo.trim().replace('"','').replace('"','') : '') : '';
                    securityQLDVerifiedAddress[i] = r.data.Content != null? ((r.data.Content.Address != "" && r.data.Content.Address != null) ? r.data.Content.Address.trim().replace('"','').replace('"','') : '') : '';
                    securityQLDLotNo[i] = r.data.Content != null? ((r.data.Content.LotNo != "" && r.data.Content.LotNo != null) ? r.data.Content.LotNo.trim().replace('"','').replace('"','') : '') : '';
                    securityQLDPlanNo[i] = r.data.Content != null? ((r.data.Content.PlanNo != "" && r.data.Content.PlanNo != null) ? r.data.Content.PlanNo.trim().replace('"','').replace('"','') : '') : '';

                }
                else{
                    //securityQLDVerifiedAddress[i] = (r.data.Content[0].Address != "" && r.data.Content[0].Address != null) ? r.data.Content[0].Address.trim().replace('"','').replace('"','') : '';
                    securityQLDVerifiedAddress[i] = r.data.Content != null? ((r.data.Content.Address != "" && r.data.Content.Address != null) ? r.data.Content.Address.trim().replace('"','').replace('"','') : '') : '';
                    securityQLDLotNo[i] = r.data.Content != null? ((r.data.Content.LotNo != "" && r.data.Content.LotNo != null) ? r.data.Content.LotNo.trim().replace('"','').replace('"','') : '') : '';
                    securityQLDPlanNo[i] = r.data.Content != null? ((r.data.Content.PlanNo != "" && r.data.Content.PlanNo != null) ? r.data.Content.PlanNo.trim().replace('"','').replace('"','') : '') : '';

                }
                
            }
            let address = this.state.SecuritySegmentAddreses; 
            address[i]['lotNo'] = securityQLDLotNo;
            address[i]['planNo'] = securityQLDPlanNo;
            
            verifierLoading[i] = false;
            
            this.setState({
                SecurityQLDVerifiedAddress: securityQLDVerifiedAddress,
                SecurityVerifierLoading: verifierLoading,
                SecurityQLDLotNo: securityQLDLotNo,
                SecurityQLDPlanNo: securityQLDPlanNo
            },()=>{
                // alert(this.state.SecurityQLDVerifiedAddress)
                this.UpdateSecuritySegment();
            });

            
            var formAddress = street.trim() + " " + suburb.trim() + " " + state + " " + postcode;
            // alert(this.state.SecurityQLDVerifiedAddress)
            // alert(formAddress)
            //check if security details exists first
            //var address = (r.data.Content[0].Address != null && r.data.Content[0].Address != "") ? r.data.Content[0].Address.trim().replace('"','').replace('"','').replace("\\",'') : '';
            //if(r.data.Content[0].Address == "" || r.data.Content[0].Address == null || address==""){
            if(securityQLDVerifiedAddress[i]==""){
                verified[i] = true;
                needsToBeVerified[i] = false;
                securityQLDNotExist[i] = true;
                this.setState({
                    //SecurityQLDAddressesMatched: addressesMatched,
                    SecurityQLDDetailsVerified: verified,
                    SecurityQLDNotExist: securityQLDNotExist,
                    SecurityQLDDetailsNeedsToBeVerified: needsToBeVerified
                },()=>{
                    this.UpdateSecuritySegment();
                });
            }
            else{
                //check if details match
                securityQLDNotExist[i] = false;
                if(formAddress == this.state.SecurityQLDVerifiedAddress[i])
                {
                    verified[i] = true;
                    needsToBeVerified[i] = false;
                    addressesMatched[i] = true;    
                    this.setState({
                        SecurityQLDAddressesMatched: addressesMatched,
                        SecurityQLDDetailsVerified: verified,
                        SecurityQLDDetailsNeedsToBeVerified: needsToBeVerified,
                        SecurityQLDNotExist: securityQLDNotExist,
                    },()=>{
                        this.UpdateSecuritySegment();
                    });
                }
                else
                {
                    addressesMatched[i] = false;   
                    verified[i] = false;
                    needsToBeVerified[i] = true;
                                    
                    this.setState({
                        SecurityQLDAddressesMatched: addressesMatched,
                        SecurityQLDDetailsVerified: verified,
                        SecurityQLDDetailsNeedsToBeVerified: needsToBeVerified,
                        SecurityQLDNotExist: securityQLDNotExist,                        
                        },()=>{
                            this.UpdateSecuritySegment();
                            console.log('securityNeedsToBeVerified');
                            console.log(this.state.SecurityQLDDetailsNeedsToBeVerified[i])
                            console.log('matched');
                            console.log(this.state.SecurityQLDAddressesMatched[i])
                        }                
                    );
                }
            }            
            
        },(err)=>{
            this.setState({
             hasError: true,
             shouldRedirect: true
            })
        });
    }

    verifyNSWSecurityTitle(i,titleRef, street, suburb, state, postcode){
        // alert(titleRef)
        // alert(street)
        // alert(suburb)
        // alert(postcode)
        let verifierLoading = this.state.SecurityVerifierLoading;
        verifierLoading[i] = true;
        let stateReadOnly = this.state.SecurityStateReadOnly;
        stateReadOnly[i] = true;
        this.setState({SecurityVerifierLoading: verifierLoading},
            ()=>{
                this.UpdateSecuritySegment();
            });

        MatterService.VerifyNSWSecurityTitle(titleRef).then((r) =>{
            console.log("nsw")
            console.log(r)
            //alert(r.data.Content)           

            let securityNSWNotExist = this.state.SecurityNSWNotExist;
            let verified = this.state.SecurityNSWDetailsVerified; 
            let needsToBeVerified = this.state.SecurityNSWDetailsNeedsToBeVerified;
            let addressesMatched = this.state.SecurityNSWAddressesMatched; 
            let securityNSWVerifiedAddress = this.state.SecurityNSWVerifiedAddress;
            let securityNSWVerifiedLandAddress = this.state.SecurityNSWVerifiedLandAddress;
            securityNSWVerifiedAddress[i] = (r.data.Content[0].Address != "" && r.data.Content[0].Address != null) ? r.data.Content[0].Address.trim().replace('"','').replace('"','') : '';
            securityNSWVerifiedLandAddress[i] = (r.data.Content[0].LandAddress != "" && r.data.Content[0].LandAddress != null) ? r.data.Content[0].LandAddress : '';
            verifierLoading[i] = false;
            this.setState({
                SecurityNSWVerifiedAddress: securityNSWVerifiedAddress,
                SecurityNSWVerifiedLandAddress: securityNSWVerifiedLandAddress,
                SecurityVerifierLoading: verifierLoading
            },()=>{
                // alert(this.state.SecurityQLDVerifiedAddress)
                this.UpdateSecuritySegment();
            });

            
            var formAddress = "";
            if( securityNSWVerifiedAddress[i]!="" &&  securityNSWVerifiedAddress[i].includes("NSW"))
            {formAddress = street.trim() + " " + suburb.trim() + " " + state + " " + postcode;}
            else{
                formAddress = street.trim() + " " + suburb.trim() + " " + postcode;
            }
            //alert(this.state.SecurityNSWVerifiedAddress)
            //alert(formAddress)
            //check if security details exists first
            //var address = (r.data.Content[0].Address != null && r.data.Content[0].Address != "") ? r.data.Content[0].Address.trim().replace('"','').replace('"','').replace("\\",'') : '';
            //if(r.data.Content[0].Address == "" || r.data.Content[0].Address == null || address==""){
            if(securityNSWVerifiedAddress[i]==""){
                verified[i] = true;
                needsToBeVerified[i] = false;
                securityNSWNotExist[i] = true;
                this.setState({
                    //SecurityQLDAddressesMatched: addressesMatched,
                    SecurityNSWDetailsVerified: verified,
                    SecurityNSWNotExist: securityNSWNotExist,
                    SecurityNSWDetailsNeedsToBeVerified: needsToBeVerified
                },()=>{
                    this.UpdateSecuritySegment();
                });
            }
            else{
                //check if details match
                securityNSWNotExist[i] = false;
                if(formAddress.toLowerCase() == this.state.SecurityNSWVerifiedAddress[i].toLowerCase())
                {
                    verified[i] = true;
                    needsToBeVerified[i] = false;
                    addressesMatched[i] = true;    
                    this.setState({
                        SecurityNSWAddressesMatched: addressesMatched,
                        SecurityNSWDetailsVerified: verified,
                        SecurityNSWDetailsNeedsToBeVerified: needsToBeVerified,
                        SecurityNSWNotExist: securityNSWNotExist,
                    },()=>{
                        this.UpdateSecuritySegment();
                    });
                }
                else
                {
                    addressesMatched[i] = false;   
                    verified[i] = false;
                    needsToBeVerified[i] = true;
                                    
                    this.setState({
                        SecurityNSWAddressesMatched: addressesMatched,
                        SecurityNSWDetailsVerified: verified,
                        SecurityNSWDetailsNeedsToBeVerified: needsToBeVerified,
                        SecurityNSWNotExist: securityNSWNotExist,                        
                        },()=>{
                            this.UpdateSecuritySegment();
                            console.log('securityNeedsToBeVerified');
                            console.log(this.state.SecurityNSWDetailsNeedsToBeVerified[i])
                            console.log('matched');
                            console.log(this.state.SecurityNSWAddressesMatched[i])
                        }                
                    );
                }
            }            
            
        },(err)=>{
            this.setState({
             hasError: true,
             shouldRedirect: true
            })
        });
    }

    verifyVICSecurityTitle(i,titleAdd, street, suburb, state, postcode){
        //alert(i)
        //this.verifyVICSecurityTitle((i,"this.state.SecuritySegmentTitleAddresses[i]", "this.state.SecuritySegmentAddreses[i]['street']", "this.state.SecuritySegmentAddreses[i]['city']", "this.state.SecuritySegmentAddreses[i]['state']", "this.state.SecuritySegmentAddreses[i]['postcode']"));
        console.log("titleAdd")
        console.log(this.state.SecuritySegmentTitleAddresses[i])
        // console.log("titleAdd2")
        // console.log(titleAdd)
        // console.log("StreetName")
//        console.log(this.state.SecuritySegmentTitleAddresses[i].StreetName)
        if(this.state.SecuritySegmentAddreses[i]!="" && this.state.SecuritySegmentAddreses[i]!=null && this.state.SecuritySegmentAddreses[i]!=undefined)
        {
            // console.log("street")
            // console.log(this.state.SecuritySegmentAddreses[i]['street'])
            // console.log("suburb")
            // console.log(this.state.SecuritySegmentAddreses[i]['suburb'])
            // console.log("city")
            // console.log(this.state.SecuritySegmentAddreses[i]['city'])
            // console.log("postcode")
            // this.state.SecuritySegmentAddreses[i]['postcode']
        }
       
        //check if input matches with the one from file
        let verifiedStreetNumber = "";
        let verifiedStreetName = "";
        let verifiedStreetType = "";
        let verifiedSuburb = "";
        let verifiedState = "";
        let verifiedPostcode= "";

        let securityVICNotExist = this.state.SecurityVICNotExist;
        let verified = this.state.SecurityVICDetailsVerified; 
        let needsToBeVerified = this.state.SecurityVICDetailsNeedsToBeVerified;
        let addressesMatched = this.state.SecurityVICAddressesMatched; 
        let securityVICVerifiedAddress = this.state.SecurityVICVerifiedAddress;

        if(this.state.SecuritySegmentTitleAddresses[i]!="" && this.state.SecuritySegmentTitleAddresses[i]!=null && this.state.SecuritySegmentTitleAddresses!=undefined)
        {
            verifiedStreetNumber = this.state.SecuritySegmentTitleAddresses[i].StreetNumber!=null && this.state.SecuritySegmentTitleAddresses[i].StreetNumber!=undefined ? this.state.SecuritySegmentTitleAddresses[i].StreetNumber.trim():"";
            verifiedStreetName = this.state.SecuritySegmentTitleAddresses[i].StreetName!=null && this.state.SecuritySegmentTitleAddresses[i].StreetName!=undefined ? this.state.SecuritySegmentTitleAddresses[i].StreetName.trim():"";
            verifiedStreetType = this.state.SecuritySegmentTitleAddresses[i].StreetType!=null && this.state.SecuritySegmentTitleAddresses[i].StreetType!=undefined ? this.state.SecuritySegmentTitleAddresses[i].StreetType.trim():"";
            verifiedSuburb = this.state.SecuritySegmentTitleAddresses[i].Suburb!=null && this.state.SecuritySegmentTitleAddresses[i].Suburb!=undefined ? this.state.SecuritySegmentTitleAddresses[i].Suburb.trim():"";            
            verifiedState = "VIC";//this.state.SecuritySegmentTitleAddresses[i].State;
            verifiedPostcode = this.state.SecuritySegmentTitleAddresses[i].Postcode;
        }
        var formAddress = street.trim() + " " + suburb.trim() + " " + state + " " + postcode;
        // var verifiedAddress = verifiedStreetNumber!=null?verifiedStreetNumber.trim():"" + " " + verifiedStreetName!=null?verifiedStreetName.trim():"" + " " + verifiedStreetType!=null?verifiedStreetType.trim():"" + " " + verifiedSuburb!=null?verifiedSuburb.trim():"" + " " + verifiedState + " " + verifiedPostcode;
        var verifiedAddress = verifiedStreetNumber.trim() + " " + verifiedStreetName.trim() + " " + verifiedStreetType.trim() + " " + verifiedSuburb.trim() + " " + verifiedState + " " + verifiedPostcode;

        console.log("formAddress")
        console.log(formAddress)
        console.log("verifiedAddress")
        console.log(verifiedAddress)

        // if(formAddress == verifiedAddress)
        // {
        //     this.setState({
        //         SecurityVICAddressesMatched: true,
        //         SecuritySegmentTitleAddresses
        //     });
        // }
        // else
        // {
        //     console.log("not matched")
        // }

        if(this.state.SecuritySegmentTitleAddresses[i]=="" || this.state.SecuritySegmentTitleAddresses[i]==null || this.state.SecuritySegmentTitleAddresses==undefined)
        {
            //alert("no title address")
            verified[i] = false;
            needsToBeVerified[i] = false;
            addressesMatched[i] = false;    
            securityVICNotExist[i] = true;
            this.setState({
                SecurityVICNotExist: securityVICNotExist,
            },()=>{
                this.UpdateSecuritySegment();
            });
        }
        else{
            if(formAddress == verifiedAddress && (this.state.SecuritySegmentTitleAddresses[i]!="" && this.state.SecuritySegmentTitleAddresses[i]!=null && this.state.SecuritySegmentTitleAddresses!=undefined))
            {
                //alert("form address = verified address")
                console.log("form address = verified address")
                verified[i] = true;
                needsToBeVerified[i] = false;
                addressesMatched[i] = true;    
                securityVICNotExist[i] = false;
                securityVICVerifiedAddress[i] = verifiedAddress;
                
    
                this.setState({
                    SecurityVICAddressesMatched: addressesMatched,
                    SecurityVICDetailsVerified: verified,
                    SecurityVICDetailsNeedsToBeVerified: needsToBeVerified,
                    SecurityVICNotExist: securityVICNotExist,
                    SecurityVICVerifiedAddress: securityVICVerifiedAddress
    
                },()=>{
                    this.UpdateSecuritySegment();
                });
            }
            else
            {
                //alert("form address <> verified address")
                console.log("form address <> verified address")
                addressesMatched[i] = false;   
                verified[i] = false;
                needsToBeVerified[i] = true;
                securityVICNotExist[i] = false;
                securityVICVerifiedAddress[i] = verifiedAddress;
    
    
                let address = this.state.SecuritySegmentAddreses; 
               
    
    
                if(this.state.SecuritySegmentTitleAddresses[i]!="" && this.state.SecuritySegmentTitleAddresses[i]!=null && this.state.SecuritySegmentTitleAddresses!=undefined)
                {               
                    address[i]['street'] = this.state.SecuritySegmentTitleAddresses[i].StreetNumber + " " + this.state.SecuritySegmentTitleAddresses[i].StreetName + " " + this.state.SecuritySegmentTitleAddresses[i].StreetType;
                    address[i]['city']= this.state.SecuritySegmentTitleAddresses[i].Suburb;
                    address[i]['state'] = 'VIC';
                    address[i]['postcode'] = this.state.SecuritySegmentTitleAddresses[i].Postcode;
                }           
               
                                
                this.setState({
                    SecurityVICAddressesMatched: addressesMatched,
                    SecurityVICDetailsVerified: verified,
                    SecurityVICDetailsNeedsToBeVerified: needsToBeVerified,
                    SecurityVICNotExist: securityVICNotExist,      
                    SecuritySegmentAddreses: address,
                    SecurityVICVerifiedAddress: securityVICVerifiedAddress        
                    },()=>{
                        this.UpdateSecuritySegment();
                        console.log('securityNeedsToBeVerified');
                        console.log(this.state.SecurityVICDetailsNeedsToBeVerified[i])
                        console.log('matched');
                        console.log(this.state.SecurityVICAddressesMatched[i])
                    }                
                );
            }
        }

    }

    OverrideQLDSecurityTitle(i, securityaddress){
        //alert(securityaddress);
        let address = this.state.SecuritySegmentAddreses; 
        const splittedAddress = securityaddress.split(" ");

        let lotNo= this.state.SecurityQLDLotNo;
        let planNo= this.state.SecurityQLDPlanNo;

        address[i]['lotNo'] = lotNo;
        address[i]['planNo'] = planNo;
        address[i]['street'] = securityaddress.substring(0, securityaddress.indexOf(","));
        //address[i]['city'] = securityaddress.substring(0, securityaddress.indexOf("QLD"));
        address[i]['city']= securityaddress.substring(
            securityaddress.indexOf(",") + 1, 
            securityaddress.lastIndexOf("QLD")
        );
        address[i]['state'] = 'QLD';
        address[i]['postcode'] = splittedAddress.pop();

        let verified = this.state.SecurityQLDDetailsVerified; 
        let needsToBeVerified = this.state.SecurityQLDDetailsNeedsToBeVerified;

        needsToBeVerified[i] = false;
        verified[i] = true;
        this.setState({
            SecuritySegmentAddreses: address,
            SecurityQLDDetailsVerified: verified,
            SecurityQLDDetailsNeedsToBeVerified: needsToBeVerified,

        },()=>{
            this.UpdateSecuritySegment();
        });
        //this.state.SecuritySegmentAddreses[i]['street'],'City':this.state.SecuritySegmentAddreses[i]['city'],'State':this.state.SecuritySegmentAddreses[i]['state']!=""?this.state.SecuritySegmentAddreses[i]['state']:'NSW','Postcode':this.state.SecuritySegmentAddreses[i]['postcode']

    }

    OverrideNSWSecurityTitle(i, securityaddress){
        //alert(securityaddress);
        let address = this.state.SecuritySegmentAddreses; 
        //const splittedAddress = securityaddress.split(" ");

        // address[i]['street'] = securityaddress.substring(0, securityaddress.indexOf(","));
        // //address[i]['city'] = securityaddress.substring(0, securityaddress.indexOf("QLD"));
        // address[i]['city']= securityaddress.substring(
        //     securityaddress.indexOf(",") + 1, 
        //     securityaddress.lastIndexOf("NSW")
        // );
        // address[i]['state'] = 'NSW';
        // address[i]['postcode'] = splittedAddress.pop();

        address[i]['street'] = (securityaddress.BuildingName!=""? securityaddress.BuildingName + " " : "") + securityaddress.StreetNumber + " " + securityaddress.StreetName + " " + securityaddress.StreetType;
        address[i]['city']= securityaddress.Suburb;
        address[i]['state'] = 'NSW';
        address[i]['postcode'] = securityaddress.PostCode;

        let verified = this.state.SecurityNSWDetailsVerified; 
        let needsToBeVerified = this.state.SecurityNSWDetailsNeedsToBeVerified;

        needsToBeVerified[i] = false;
        verified[i] = true;
        this.setState({
            SecuritySegmentAddreses: address,
            SecurityNSWDetailsVerified: verified,
            SecurityNSWDetailsNeedsToBeVerified: needsToBeVerified,

        },()=>{
            this.UpdateSecuritySegment();
        });
        //this.state.SecuritySegmentAddreses[i]['street'],'City':this.state.SecuritySegmentAddreses[i]['city'],'State':this.state.SecuritySegmentAddreses[i]['state']!=""?this.state.SecuritySegmentAddreses[i]['state']:'NSW','Postcode':this.state.SecuritySegmentAddreses[i]['postcode']

    }

    IgnoreQLDSecurityTitle(i){
        let verified = this.state.SecurityQLDDetailsVerified; 
        let needsToBeVerified = this.state.SecurityQLDDetailsNeedsToBeVerified;

        needsToBeVerified[i] = false;
        verified[i] = true;
        this.setState({
            SecurityQLDDetailsVerified: verified,
            SecurityQLDDetailsNeedsToBeVerified: needsToBeVerified,

        },()=>{
            this.UpdateSecuritySegment();
        });
    }

    IgnoreNSWSecurityTitle(i){
        let verified = this.state.SecurityNSWDetailsVerified; 
        let needsToBeVerified = this.state.SecurityNSWDetailsNeedsToBeVerified;

        needsToBeVerified[i] = false;
        verified[i] = true;
        this.setState({
            SecurityNSWDetailsVerified: verified,
            SecurityNSWDetailsNeedsToBeVerified: needsToBeVerified,

        },()=>{
            this.UpdateSecuritySegment();
        });
    }

    ResetSecurityDetails(i){
        let address = this.state.SecuritySegmentAddreses; 
        //address[i] = "";
        address[i]['lotNo'] = "";
        address[i]['planNo']= "";
        address[i]['street'] = "";
        address[i]['city']= "";
        address[i]['state'] = "";
        address[i]['postcode'] = "";

        let stateReadOnly = this.state.SecurityStateReadOnly;
        stateReadOnly[i] = false;

        let titleRef = this.state.SecuritySegmentTitleReferences;
        let QLDverified = this.state.SecurityQLDDetailsVerified; 
        let QLDneedsToBeVerified = this.state.SecurityQLDDetailsNeedsToBeVerified;
        let QLDaddressesMatched = this.state.SecurityQLDAddressesMatched; 
        let SecurityQLDNotExist = this.state.SecurityQLDNotExist;
        let NSWverified = this.state.SecurityNSWDetailsVerified; 
        let NSWneedsToBeVerified = this.state.SecurityNSWDetailsNeedsToBeVerified;
        let NSWaddressesMatched = this.state.SecurityNSWAddressesMatched; 
        let SecurityNSWNotExist = this.state.SecurityNSWNotExist;
        let VICverified = this.state.SecurityVICDetailsVerified; 
        let VICneedsToBeVerified = this.state.SecurityVICDetailsNeedsToBeVerified;
        let VICaddressesMatched = this.state.SecurityVICAddressesMatched; 
        let SecurityVICNotExist = this.state.SecurityVICNotExist;

        titleRef[i] = "";
        QLDneedsToBeVerified[i] = false;
        QLDverified[i] = false;
        QLDaddressesMatched[i] = false;    
        SecurityQLDNotExist[i] = false;
        NSWneedsToBeVerified[i] = false;
        NSWverified[i] = false;
        NSWaddressesMatched[i] = false;    
        SecurityNSWNotExist[i] = false;
        VICneedsToBeVerified[i] = false;
        VICverified[i] = false;
        VICaddressesMatched[i] = false;    
        SecurityVICNotExist[i] = false;

        //reset Mortgagee Details
        let mortgageeExists = this.state.SecurityMortgageeExists;
        let mortgageeNames = this.state.SecurityMortgageeNames;
        let mortgageeNamesManual = this.state.SecurityMortgageeNamesManual;
        let mortNumbers = this.state.SecurityMortgageNumbers;
        let mortCompanyACN = this.state.SecurityMortgageeCompanyACN;
        let mortFormattedTitleRefNo = this.state.SecurityMortgageeFormattedTitleRefNo;
        let mortLandDesc = this.state.SecurityMortgageeLandDescription;
        let mortLot = this.state.SecurityMortgageeLot;
        let mortTitleSearch = this.state.SecurityMortgageeTitleSearchFiles;
        let mortId = this.state.SecurityMortgageeId;

        let arrFiles = this.state.SecurityTitleFiles;
        //arrFiles.push(this.state.SecurityTitleFiles);
        // console.log(this.state.files);
        // console.log("filename");
        // console.log(this.state.files);
       
        let securityTitleFiles = [];

        let arrFilenames =this.state.SecurityTitleSearchFilenames;
        arrFilenames=[];

        mortgageeExists[i] = false;
        mortgageeNames[i] = "";
        mortgageeNamesManual[i] = "";
        mortNumbers[i] = "";
        mortCompanyACN[i] = "";
        mortFormattedTitleRefNo[i] = "";
        mortLandDesc[i] = "";
        mortLot[i] = "";
        mortId[i] = "";
        mortTitleSearch[i] = "";
        //titleRef[i] = "";
        
        this.setState({
            SecuritySegmentTitleReferences: titleRef,
            SecuritySegmentAddreses: address,
            SecurityQLDDetailsVerified: QLDverified,
            SecurityQLDDetailsNeedsToBeVerified: QLDneedsToBeVerified,
            SecurityQLDAddressesMatched: QLDaddressesMatched,
            SecurityQLDNotExist: SecurityQLDNotExist,
            SecurityNSWDetailsVerified: NSWverified,
            SecurityNSWDetailsNeedsToBeVerified: NSWneedsToBeVerified,
            SecurityNSWAddressesMatched: NSWaddressesMatched,
            SecurityNSWNotExist: SecurityNSWNotExist,
            SecurityVICDetailsVerified: VICverified,
            SecurityVICDetailsNeedsToBeVerified: VICneedsToBeVerified,
            SecurityVICAddressesMatched: VICaddressesMatched,
            SecurityVICNotExist: SecurityVICNotExist,
            SecurityMortgageeNames: mortgageeNames,
            SecurityMortgageeNamesManual: mortgageeNamesManual,
            SecurityMortgageNumbers: mortNumbers,
            SecurityMortgageeCompanyACN: mortCompanyACN,
            SecurityMortgageeExists: mortgageeExists,
            SecurityMortgageeFormattedTitleRefNo: mortFormattedTitleRefNo,
            SecurityMortgageeLandDescription: mortLandDesc,
            SecurityMortgageeLot: mortLot,
            SecurityTitleFiles:  securityTitleFiles,
            SecurityTitleSearchFilenames: arrFilenames,
            SecurityMortgageeId: mortId,
            SecurityMortgageeTitleSearchFiles: mortTitleSearch,
        },()=>{
            this.UpdateSecuritySegment();
        });
        //this.state.SecuritySegmentAddreses[i]['street'],'City':this.state.SecuritySegmentAddreses[i]['city'],'State':this.state.SecuritySegmentAddreses[i]['state']!=""?this.state.SecuritySegmentAddreses[i]['state']:'NSW','Postcode':this.state.SecuritySegmentAddreses[i]['postcode']

    }

    ResetSecurityOtherDetails(i){
        let address = this.state.SecuritySegmentAddreses; 
        //address[i] = "";
        address[i]['lotNo'] = "";
        address[i]['planNo']= "";
        address[i]['street'] = "";
        address[i]['city']= "";
        
        address[i]['postcode'] = "";

        let stateReadOnly = this.state.SecurityStateReadOnly;
        stateReadOnly[i] = false;

        let titleRef = this.state.SecuritySegmentTitleReferences;
        let QLDverified = this.state.SecurityQLDDetailsVerified; 
        let QLDneedsToBeVerified = this.state.SecurityQLDDetailsNeedsToBeVerified;
        let QLDaddressesMatched = this.state.SecurityQLDAddressesMatched; 
        let SecurityQLDNotExist = this.state.SecurityQLDNotExist;
        let NSWverified = this.state.SecurityNSWDetailsVerified; 
        let NSWneedsToBeVerified = this.state.SecurityNSWDetailsNeedsToBeVerified;
        let NSWaddressesMatched = this.state.SecurityNSWAddressesMatched; 
        let SecurityNSWNotExist = this.state.SecurityNSWNotExist;

        titleRef[i] = "";
        QLDneedsToBeVerified[i] = false;
        QLDverified[i] = false;
        QLDaddressesMatched[i] = false;    
        SecurityQLDNotExist[i] = false;
        NSWneedsToBeVerified[i] = false;
        NSWverified[i] = false;
        NSWaddressesMatched[i] = false;    
        SecurityNSWNotExist[i] = false;

        //reset Mortgagee Details
        let mortgageeExists = this.state.SecurityMortgageeExists;
        let mortgageeNames = this.state.SecurityMortgageeNames;
        let mortgageeNamesManual = this.state.SecurityMortgageeNamesManual;
        let mortNumbers = this.state.SecurityMortgageNumbers;
        let mortCompanyACN = this.state.SecurityMortgageeCompanyACN;
        let mortFormattedTitleRefNo = this.state.SecurityMortgageeFormattedTitleRefNo;
        let mortLandDesc = this.state.SecurityMortgageeLandDescription;
        let mortLot = this.state.SecurityMortgageeLot;
        let mortTitleSearch = this.state.SecurityMortgageeTitleSearchFiles;
        let mortId = this.state.SecurityMortgageeId;

        let arrFiles = this.state.SecurityTitleFiles;
        //arrFiles.push(this.state.SecurityTitleFiles);
        // console.log(this.state.files);
        // console.log("filename");
        // console.log(this.state.files);
       
        let securityTitleFiles = [];

        let arrFilenames =this.state.SecurityTitleSearchFilenames;
        arrFilenames=[];

        mortgageeExists[i] = false;
        mortgageeNames[i] = "";
        mortgageeNamesManual[i] = "";
        mortNumbers[i] = "";
        mortCompanyACN[i] = "";
        mortFormattedTitleRefNo[i] = "";
        mortLandDesc[i] = "";
        mortLot[i] = "";
        mortId[i] = "";
        mortTitleSearch[i] = "";
        //titleRef[i] = "";
        
        this.setState({
            SecuritySegmentTitleReferences: titleRef,
            SecuritySegmentAddreses: address,
            SecurityQLDDetailsVerified: QLDverified,
            SecurityQLDDetailsNeedsToBeVerified: QLDneedsToBeVerified,
            SecurityQLDAddressesMatched: QLDaddressesMatched,
            SecurityQLDNotExist: SecurityQLDNotExist,
            SecurityNSWDetailsVerified: NSWverified,
            SecurityNSWDetailsNeedsToBeVerified: NSWneedsToBeVerified,
            SecurityNSWAddressesMatched: NSWaddressesMatched,
            SecurityNSWNotExist: SecurityNSWNotExist,
            SecurityMortgageeNames: mortgageeNames,
            SecurityMortgageeNamesManual: mortgageeNamesManual,
            SecurityMortgageNumbers: mortNumbers,
            SecurityMortgageeCompanyACN: mortCompanyACN,
            SecurityMortgageeExists: mortgageeExists,
            SecurityMortgageeFormattedTitleRefNo: mortFormattedTitleRefNo,
            SecurityMortgageeLandDescription: mortLandDesc,
            SecurityMortgageeLot: mortLot,
            SecurityTitleFiles:  securityTitleFiles,
            SecurityTitleSearchFilenames: arrFilenames,
            SecurityMortgageeId: mortId,
            SecurityMortgageeTitleSearchFiles: mortTitleSearch,
        },()=>{
            this.UpdateSecuritySegment();
        });
        //this.state.SecuritySegmentAddreses[i]['street'],'City':this.state.SecuritySegmentAddreses[i]['city'],'State':this.state.SecuritySegmentAddreses[i]['state']!=""?this.state.SecuritySegmentAddreses[i]['state']:'NSW','Postcode':this.state.SecuritySegmentAddreses[i]['postcode']

        

    }

    handleInit() {
   
    }
  
    handleItemUpdate(fileItems) {
        console.log(fileItems);
        // Set current file objects to this.state

        // let val = this.state.SecurityTitleSearches;
        // val[i]=fileItems.map(fileItem => fileItem.filename);
        // alert(val[i])
        // this.setState({SecurityMortgageeNames:val},()=>{this.UpdateSecuritySegment()});
        this.setState({
          files: fileItems.map(fileItem => fileItem.file),
          filenames: fileItems.map(fileItem => fileItem.filename),
          //SecurityMortgageeTitleSearchFiles: fileItems.map(fileItem => fileItem.filename)
          //SecurityTitleFiles:fileItems
        },()=>{	
            // console.log("SecurityTitleFiles");
            // console.log(this.state.SecurityTitleFiles);
            console.log("SecurityTitleSearchFilenames");
            console.log(this.state.filenames);
         // this.btnUploadFiles(this.state.files)
        //  alert(this.state.SecurityTitleSearches)
        //this.UpdateSecuritySegment();
        });
        // console.log(this.state.files);
        //this.btnUploadFiles(this.state.files)
    }

    btnUploadFiles(i) {
        //alert(i)
        //alert(this.state.SecuritySegmentAddreses[i]['state'])
        // alert(fileItems);
        // return;
        if (this.state.files.length != 0) {
        // console.log("Button has been clicked!");
    
        // console.log("To be upload files");
        // console.log(
        //   this.props.matterId,
        //   this.state.files,
        //   this.props.note.OutstandingItemName
        // );
        //var safeFilename = this.props.note.OutstandingItemName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        //var safeFilename = fileItem.replace(/[^a-z0-9]/gi, '_').toLowerCase();

        //alert("sfsafasdf")

        var fileName = this.state.files[0].name;
          var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
          

          if(!regex.test(fileName) || fileName.length > 200)
          {
            alert ("Invalid filename. Please check.");
            return;
          }
        
        
    
        this.setState({ showModal: true });
        MatterDocumentService.uploadTitleSearch(
            //this.props.matterId,
            this.state.files,
            // safeFilename,
            //this.props.note.OutstandingItemName
            //fileItem
            this.state.SecuritySegmentAddreses[i]['state']
        ).then(
            response => {
            console.log('titlesearchdetails');
            console.log(response.data.Content);
            console.log('SecurityMortgageeNames');
            console.log(response.data.Content.MortgageeName);
            let mortgageeExists = this.state.SecurityMortgageeExists;
            let mortgageeNames = this.state.SecurityMortgageeNames;
            let mortNumbers = this.state.SecurityMortgageNumbers;
            let mortCompanyACN = this.state.SecurityMortgageeCompanyACN;
            let mortFormattedTitleRefNo = this.state.SecurityMortgageeFormattedTitleRefNo;
            let mortLandDesc = this.state.SecurityMortgageeLandDescription;
            let mortLot = this.state.SecurityMortgageeLot;
            let mortTitleSearch = this.state.SecurityMortgageeTitleSearchFiles;
            let mortId = this.state.SecurityMortgageeId;
            let titleRef = this.state.SecuritySegmentTitleReferences;
            let titleAdd = this.state.SecuritySegmentTitleAddresses;
            // let address = this.state.SecuritySegmentAddreses;

            let arrFiles = this.state.SecurityTitleFiles;
        //arrFiles.push(this.state.SecurityTitleFiles);
        // console.log(this.state.files);
        // console.log("filename");
        // console.log(this.state.files);
        arrFiles.push(this.state.files);
        console.log("arrFiles")
        console.log(arrFiles)
        let securityTitleFiles = arrFiles;

        let arrFilenames =this.state.SecurityTitleSearchFilenames;
        arrFilenames.push(this.state.filenames);
        console.log("SecurityTitleSearchFilenames")
        console.log(arrFilenames)


        let filename = this.state.filenames;//this.state.SecurityMortgageeTitleSearchFiles;//this.state.filenames;


            // let arrFiles = this.state.SecurityTitleFiles;
            // //arrFiles.push(this.state.SecurityTitleFiles);
            // console.log(this.state.files);
            // console.log("filename");
            // console.log(this.state.files);
            // arrFiles.push(this.state.files);
            // console.log("arrFiles")
            // console.log(arrFiles)
            // let securityTitleFiles = arrFiles;

            // let arrFilenames =this.state.SecurityTitleSearchFilenames;
            // arrFilenames.push(this.state.SecurityTitleSearchFilenames);

                mortgageeExists[i] = response.data.Content.MotgageeExists;
                mortgageeNames[i] = response.data.Content.MortgageeName;
                mortNumbers[i] = response.data.Content.MortgageNumber;
                mortCompanyACN[i] = response.data.Content.MortgageeCompanyACN;
                mortFormattedTitleRefNo[i] = response.data.Content.TitleRef;
                mortLandDesc[i] = response.data.Content.LandDescription;
                mortLot[i] = response.data.Content.Lot;
                mortId[i] = response.data.Content.MortgageeId;
                mortTitleSearch[i] = filename;
                titleRef[i] = response.data.Content.TitleRef;
                titleAdd[i] = response.data.Content.TitleAdd;
                // address[i] = response.data.Content.TitleAdd;
                
            this.setState({
            SecurityMortgageeNames: mortgageeNames,
            SecurityMortgageNumbers: mortNumbers,
            SecurityMortgageeCompanyACN: mortCompanyACN,
            SecurityMortgageeExists: mortgageeExists,
            SecurityMortgageeFormattedTitleRefNo: mortFormattedTitleRefNo,
            SecurityMortgageeLandDescription: mortLandDesc,
            SecurityMortgageeLot: mortLot,
            SecurityTitleFiles:  securityTitleFiles,
            SecurityTitleSearchFilenames: arrFilenames,
            SecurityMortgageeId: mortId,
            SecurityMortgageeTitleSearchFiles: mortTitleSearch,
            SecuritySegmentTitleReferences: titleRef,
            SecuritySegmentTitleAddresses: titleAdd,

            },()=>{
                if(this.state.SecuritySegmentAddreses[i]['state']=="VIC"){
                    console.log("SecuritySegmentTitleAddresses");
                    console.log(this.state.SecuritySegmentTitleAddresses[i]);
                    //this.verifyVICSecurityTitle((i,this.state.SecuritySegmentTitleAddresses[i], this.state.SecuritySegmentAddreses[i]['street'], this.state.SecuritySegmentAddreses[i]['city'], this.state.SecuritySegmentAddreses[i]['state'], this.state.SecuritySegmentAddreses[i]['postcode']));
                    
                    if(this.state.SecuritySegmentAddreses[i]!="" && this.state.SecuritySegmentAddreses[i]!=null && this.state.SecuritySegmentAddreses[i]!=undefined)
                    {   
                        if(this.state.SecuritySegmentTitleAddresses[i]!="" && this.state.SecuritySegmentTitleAddresses[i]!=null && this.state.SecuritySegmentTitleAddresses[i]!=undefined)
                        {
                            this.verifyVICSecurityTitle(i,this.state.SecuritySegmentTitleAddresses[i], this.state.SecuritySegmentAddreses[i]['street'], this.state.SecuritySegmentAddreses[i]['city'], this.state.SecuritySegmentAddreses[i]['state'], this.state.SecuritySegmentAddreses[i]['postcode']);                            
                        }                     
                        else {
                            this.verifyVICSecurityTitle(i,"", this.state.SecuritySegmentAddreses[i]['street'], this.state.SecuritySegmentAddreses[i]['city'], this.state.SecuritySegmentAddreses[i]['state'], this.state.SecuritySegmentAddreses[i]['postcode']);
                        }
                    }

                    else{
                        if(this.state.SecuritySegmentTitleAddresses[i]!="" && this.state.SecuritySegmentTitleAddresses[i]!=null && this.state.SecuritySegmentTitleAddresses[i]!=undefined)
                        {
                            this.verifyVICSecurityTitle(i,this.state.SecuritySegmentTitleAddresses[i], "", "", "", "");
                        }
                        else {
                            this.verifyVICSecurityTitle(i,"", "", "", "", "");
                        }
                    }
                    this.UpdateSecuritySegment();
                    console.log("SecurityMortgageeNames2");
                    console.log(this.state.SecurityMortgageeNames[i]);
                }
                else{
                    this.UpdateSecuritySegment();
                    console.log("SecurityMortgageeNames2");
                    console.log(this.state.SecurityMortgageeNames[i]);
                }
                
                
                //console.log("SecurityTitleSearchFilenames");
                //console.log(this.state.SecurityTitleSearchFilenames);
            });
        //     if( new SAlert({
        //       //title: "File Upload",
        //       //html: "Document transferred: <br />" + response.data.Message,
        //       // "Document transferred: " + files[0].name + "<br />" + result.Message
        //       //html: "<h4>A document has been successfully uploaded.</h4> ",//<br /> Document Name: " + response.data.Message,	
        //       html: "<h5>The document has been uploaded and will be sent to the system shortly.</h5> ",
        //       //onClose:()=>{this.reloadNotes()}
        //       didClose:()=>{this.reloadNotes()}
        //       //didClose:this.reloadNotes()
        //     })
        //   //   .then(function(){
                
        //   //   }

        //   //   )
        //     ){
        //       this.setState({
        //         files: [],
        //         showModal:false,	
        //         uploadSuccess: true
        //       });
        //       //this.props.Reload();
        //     }else{
        //       alert("Document has ben transferred.");
        //       this.setState({
        //         files: [],
        //         showModal:false
        //       });
        //       this.props.Reload();
        //     }
            
            
            
            },
            error => {
            new SAlert({
                title: "File Upload",
                html: "Document transfer FAILED.",//<br /><br />" + error.message,
                type: "error"
            });
            this.setState({ showModal: false });
    
            }
        );
        } else {
        this.pond.browse();
        this.setState({ showModal: false });
        // SAlert({
        //   title: "File Upload",
        //   html: "Please select file(s)",
        //   type: "error"
        // });
        }
    }

    isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isValidNumber(number){
        ///^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        const re =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        return re.test(String(number).toLowerCase());
    }

    stringToBool(value){
        if(value && typeof value === "string"){
            if(value === "Yes") return true;
            if(value === "No") return false;
        }
        //return value;
    }
    

    CreateDischargeInstructionEntity(e){
        // alert(this.state.CanUserAddSecondaryRef)
        // alert(this.state.InstructionSecondaryLenderRef)
        window.scrollTo(0,0);
        e.preventDefault();        

        let elem = document.getElementById('instruction-form');
        if(!elem.checkValidity()){
            elem.reportValidity();
            var result = elem.checkValidity();
            //alert('there is error');
            console.log('there is an error');
            console.log(elem.reportValidity());
            console.log(result);
            return;
        }

        //var PPSRRequired = false;

        
        var SecurityShouldBeVerifiedFirst = false;

        console.log("this.state.mortManager")
        console.log(this.state.mortManager)

        if(this.state.mortManagers.length>1 && (this.state.mortManager!= null && this.state.mortManager.id == 0)){
            alert("Please select Mortgage Manager")
            return;
        }

        if(this.state.CanUserAddSecondaryRef && (this.state.InstructionSecondaryLenderRef=="" || this.state.InstructionSecondaryLenderRef==null)){
            alert("Secondary Lender Reference is required")
            return;
        }


        this.state.PartySegmentPartyTypes.forEach((p,i)=>{
            //alert(this.state.SecurityDischargeReasons[i]);
            // alert(this.state.PartySegmentCompanyPPSR[i])
            // alert(this.state.displayPPSRDischarges)
            // alert(this.state.PartySegmentPorCTypes[i])
            if(this.state.PartySegmentCompanyPPSR[i] === "" && this.state.displayPPSRDischarges && this.state.PartySegmentPorCTypes[i]==='Company'){
                alert("Please answer PPSR field")
                SecurityShouldBeVerifiedFirst = true;
                return;
            }
            
        })  


        this.state.SecurityDischargeReasons.forEach((p,i)=>{
            //alert(this.state.SecurityDischargeReasons[i]);
            if(this.state.SecurityDischargeReasons[i] == ""){
                alert("One ore more security has no reason for discharge.")
                SecurityShouldBeVerifiedFirst = true;
                return;
            }            
            
        })        


        this.state.SecuritySegmentAddreses.forEach((p,i)=>{
            if(this.state.SecuritySegmentAddreses[i]['state'] == 'QLD' && this.state.SecurityQLDDetailsVerified[i] == false && this.state.shouldverifyQLDSecurityTitle ){
                alert("QLD Securities must be be verified first.")
                // this.setState({
                //     SecurityShouldBeVerifiedFirst: true
                // });
                SecurityShouldBeVerifiedFirst = true;
                return;
            }
            if(this.state.SecuritySegmentAddreses[i]['state'] == 'NSW' && this.state.SecurityNSWDetailsVerified[i] == false && this.state.shouldverifyNSWSecurityTitle ){
                alert("NSW Securities must be be verified first.")
                // this.setState({
                //     SecurityShouldBeVerifiedFirst: true
                // });
                SecurityShouldBeVerifiedFirst = true;
                return;
            }

            //alert(this.state.SecurityMortgageeNames[i])
            //alert(this.state.SecurityMortgageeNamesManual[i])
            if(this.state.LenderMortgagees.length > 1){
                if(this.state.SecurityMortgageeNames[i] == "" && (this.state.SecurityMortgageeNamesManual[i] == "Select Mortgagee" || this.state.SecurityMortgageeNamesManual[i] == ""))
                {
                    alert("Mortgagee is required.")
                    SecurityShouldBeVerifiedFirst = true;
                    return;
                }
            }

            if(this.state.SecurityTitleSearches[i]=="" && !this.state.TitleReferenceValid[i])
            {
                // alert("Invalid title reference. Please check.")
                // SecurityShouldBeVerifiedFirst = true;
                // check input
                SecurityShouldBeVerifiedFirst = true;
                this.CheckTitleReferenceFormat(e,i,this.state.SecuritySegmentTitleReferences[i])
                return;
            }

            return;
        })        

        if(!SecurityShouldBeVerifiedFirst){
            let applicationBatch={};
        
            let instruction={};
            
            
            instruction['LenderRef']=this.state.InstructionLenderReference.trim();
            instruction['SecondaryLenderRef']=(this.state.InstructionSecondaryLenderRef!=null && this.state.InstructionSecondaryLenderRef!="") ? this.state.InstructionSecondaryLenderRef.trim() : null;
            instruction['DischargeType']=this.state.dischargeTypeName?this.state.dischargeTypeName:'Full';
            //instruction['MortMgrId']=this.state.CanUserChooseMortMgr?this.state.mortManager : null;
            instruction['MortMgrId']=this.state.mortManager!=null? this.state.mortManager.id: null;
            //instruction['SubType']=this.state.subDischargeType;
            //instruction['SelfActing']=this.state.SelfActing;
            instruction['LoanDetails'] =[];
            this.state.LoanIds.forEach((e,i)=>instruction['LoanDetails'].push({'LoanId':e.trim(),'IndicativeAmount':this.state.IndicativeAmounts[i]}));
            // instruction['PartyRep']={'UniqueID':'PR1','Type':'Solicitor','FullName':this.state.PartyRepNames[0],'Email':this.state.PartyRepEmails[0],'PhoneNumber':this.state.PartyRepNumbers[0]};
            //this.state.PartyRepNames.forEach((e,i)=>instruction['PartyRep'].push({'FullName':e,'Email':this.state.PartyRepEmails[i],'PhoneNumber':this.state.PartyRepNumbers[i]}));

            // instruction['IncomingMortgagee']=this.state.IncomingMortgageeSegmentCount===0?{}:{'UniqueID':'IM1','Type':'IncomingMortgagee','FullName':this.state.IncomingMortgageeNames[0],'Email':this.state.IncomingMortgageeEmails[0],'PhoneNumber':this.state.IncomingMortgageeNumbers[0]};
            instruction['Party']=[];
            if(this.state.displayPPSRDischarges){
                this.state.PartySegmentPartyTypes.forEach((e,i)=>
                instruction['Party'].push({'UniqueID':'P'+i.toString(),'Name':{'GivenName':this.state.PartySegmentNames[i]['GivenName'].trim(),'Surname':this.state.PartySegmentNames[i]['Surname'].trim(),'CompanyName':this.state.PartySegmentNames[i]['CompanyName'].trim(),'ACN':this.state.PartySegmentNames[i]['Acn'].trim(),'ABN':this.state.PartySegmentNames[i]['Abn'].trim()},
                'Address':{'Street':this.state.PartySegmentAddresses[i]['street'].trim(),'City':this.state.PartySegmentAddresses[i]['city'].trim(),'State':this.state.PartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state'],'Postcode':this.state.PartySegmentAddresses[i]['postcode']},
                'PhoneNumber':this.state.PartySegmentPhoneNumbers[i].trim(),'Email':this.state.PartySegmentEmails[i].trim(),'PartyType':e,'PersonCompany':this.state.PartySegmentPorCTypes[i],'CompanyPPSR':this.state.PartySegmentCompanyPPSR[i]}));
            }
            else{
                this.state.PartySegmentPartyTypes.forEach((e,i)=>
                instruction['Party'].push({'UniqueID':'P'+i.toString(),'Name':{'GivenName':this.state.PartySegmentNames[i]['GivenName'].trim(),'Surname':this.state.PartySegmentNames[i]['Surname'].trim(),'CompanyName':this.state.PartySegmentNames[i]['CompanyName'].trim(),'ACN':this.state.PartySegmentNames[i]['Acn'].trim(),'ABN':this.state.PartySegmentNames[i]['Abn'].trim()},
                'Address':{'Street':this.state.PartySegmentAddresses[i]['street'].trim(),'City':this.state.PartySegmentAddresses[i]['city'].trim(),'State':this.state.PartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state'],'Postcode':this.state.PartySegmentAddresses[i]['postcode']},
                'PhoneNumber':this.state.PartySegmentPhoneNumbers[i].trim(),'Email':this.state.PartySegmentEmails[i].trim(),'PartyType':e,'PersonCompany':this.state.PartySegmentPorCTypes[i]}));
            }        
            
            
            instruction['Security']=[];
            this.state.SecuritySegmentAddreses.forEach((p,i)=>
            
            instruction['Security'].push(
                {
                    'UniqueID':'S'+i.toString(),'TitleRef':this.state.SecuritySegmentTitleReferences[i].trim(),
                    'SelfActing':this.state.SelfActing[i],
                    //'SubType':this.state.SecurityDischargeReasons[i]==='Sale'?0:this.state.SecurityDischargeReasons[i]==='External Refinance'?1:this.state.SecurityDischargeReasons[i]==='Internal Refinance'?2:this.state.SecurityDischargeReasons[i]==='Debt Repaid'?3:0,
                    //'SubType':this.state.SecurityDischargeReasons[i]==='Sale'?0:this.state.SecurityDischargeReasons[i]==='External Refinance'?1:this.state.SecurityDischargeReasons[i]==='Debt Repaid'?2:this.state.SecurityDischargeReasons[i]==='Internal Refinance'?3:0,
                    'SubType':this.state.SecurityDischargeReasons[i]==='Sale'?0:this.state.SecurityDischargeReasons[i]==='External Refinance'?1:this.state.SecurityDischargeReasons[i]==='Debt Repaid'?2:this.state.SecurityDischargeReasons[i]==='Internal Refinance'?3:0,
                    //'SubType':this.state.SecurityDischargeReasons[i]==='Sale'?1:this.state.SecurityDischargeReasons[i]==='External Refinance'?2:this.state.SecurityDischargeReasons[i]==='Internal Refinance'?3:this.state.SecurityDischargeReasons[i]==='Debt Repaid'?4:1,
                    //'SubType':this.state.SecurityDischargeReasons[i]?this.state.SecurityDischargeReasons[i]:1,
                    'IncomingMortgagee':this.state.SecurityDischargeReasons[i]==='External Refinance'?{'UniqueID':'IM'+i,'Type':'IncomingMortgagee','FullName':this.state.IncomingMortgageeNames[i],'Email':this.state.IncomingMortgageeEmails[i],'FaxNumber':this.state.IncomingMortgageeNumbers[i]}:null,
                    'PartyRep':!this.state.SelfActing[i]?{'UniqueID':'PR'+i,'Type':'Solicitor','FullName':this.state.PartyRepNames[i].trim(),'Email':this.state.PartyRepEmails[i],'PhoneNumber':this.state.PartyRepNumbers[i]}:null,
                    //'Address':{'Street':this.state.SecuritySegmentAddreses[i]['street'],'City':this.state.SecuritySegmentAddreses[i]['city'],'State':this.state.SecuritySegmentAddreses[i]['state'],'Postcode':this.state.SecuritySegmentAddreses[i]['postcode']}}));
                    'Address':{'Street':this.state.SecuritySegmentAddreses[i]['street'].trim(),'City':this.state.SecuritySegmentAddreses[i]['city'].trim(),'State':this.state.SecuritySegmentAddreses[i]['state']!=""?this.state.SecuritySegmentAddreses[i]['state']:'NSW','Postcode':this.state.SecuritySegmentAddreses[i]['postcode']},
                    'MortgageeDetails':{
                        'UniqueID':'M'+i,
                        'MortgageeId': this.state.SecurityMortgageeId[i],
                        //'MortgageeName':this.state.SecurityMortgageeNames[i].trim(),
                        'MortgageeName':(this.state.SecurityMortgageeNames[i] != null && this.state.SecurityMortgageeNames[i] != "")?this.state.SecurityMortgageeNames[i].trim():this.state.SecurityMortgageeNamesManual[i],
                        'MortgageeNumber':this.state.SecurityMortgageNumbers[i].trim(),
                        'FormattedTitleRefNo':this.state.SecurityMortgageeFormattedTitleRefNo[i].trim(),
                        'LandDescription':this.state.SecurityMortgageeLandDescription[i].trim(),
                        'LotNumber':this.state.SecurityMortgageeLot[i].trim(),
                        'TitleSearchFile': this.state.SecurityMortgageeTitleSearchFiles[i].toString()
                    }
                }));
            instruction['SettlementSimultaneousWith']=[];//SettlementSimultaneousWith
            this.state.SimultaneousSettlementSegmentLoanIds.forEach((v,i)=>{instruction['SettlementSimultaneousWith'].push(v)});//(p,c,i)=>[{'LoanId':this.state.SimultaneousSettlementSegmentLoanIds[i-1]},{'LoanId':this.state.SimultaneousSettlementSegmentLoanIds[i]}]):
        
            instruction['ImportantNotes'] = {'SettlementNotToBeBookedPriorTo':this.state.ImportantNoteSegmentSettlementNotToBeBookedPriorToDates[0],'CollectSurplus':this.state.ImportantNoteSegmentCollectSurplus[0]==='on'?true:false};
            if(this.state.HasBorrowerClearTitleToAddress){
                instruction['ImportantNotes']['BorrowerClearTitleTo']={'Address':{'Street':this.state.BorrowerClearTitleToAddress[0]['street'].trim(),'City':this.state.BorrowerClearTitleToAddress[0]['city'].trim(),'State':this.state.BorrowerClearTitleToAddress[0]['state'],'Postcode':this.state.BorrowerClearTitleToAddress[0]['postcode']}};
            }
            if(this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress){
                instruction['ImportantNotes']['GuarantorTitleDeedTo']={'Address':{'Street':this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[0]['street'].trim(),'City':this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[0]['city'].trim(),'State':this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[0]['state'],'Postcode':this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[0]['postcode']}};
            }
            instruction['SpecialConditions']=[];
            this.state.SpecialConditionSegmentConditionValues.forEach((e,i)=>

            instruction['SpecialConditions'].push({'Type':this.state.SpecialConditionSegmentConditionTypes[i],'SpecialCondition1':this.state.SpecialConditionSegmentConditionValues[i].trim()}));

            instruction['OtherRequests']=this.state.DischargeinstructionOtherRequests;
            
            applicationBatch['Application']=instruction;
            console.log(applicationBatch);
            this.setState({isLoading:true});
            InstructionService.SendDischargeInstruction(applicationBatch).then((resp)=>{
                this.setState({msg:resp.data.Content,
                    InstructionSubmit:true,
                    isLoading:false});
                
            
            },(err)=>{
                if (err.response && err.response.status === 401) {
                    this.setState({
                        isLoading:false,
                        shouldRedirect: true,
                        errorMessage: 'there is an error with the request'
                    });

                } else {
                    if(err.response.status== 400){
                        alert('A matter with this lender reference already exists.');
                    }
                    else{
                        alert("There is an error wth the request");
                    }
                    //alert('A matter with this lender reference already exists.');
                    this.setState({isLoading:false,});
                }
            });
        }
    }   
    

    GetDischargeTypes(){
        //this.setState({DischargeTypes:['Full','Partial']});
        InstructionService.GetDischargeTypes().then((resp)=>{
            this.setState({DischargeTypes:resp.data.Content});
            
        },
        (err)=>{
            //alert(err);
            alert("There is an error with the request");
        });
    }

    GetDischargeReasonTypes(){
        //this.setState({subDischargeTypeOptions:['Sale','External Refinance','Internal Refinance','Debt Repaid']});
        InstructionService.GetDischargeReasonTypes().then((resp)=>{
            //alert(resp.data.Content)
            this.setState({subDischargeTypeOptions:resp.data.Content},()=>{
                
                //console.log("irene again")
                console.log(this.state.subDischargeTypeOptions)
            });
            //this.UpdateSecuritySegment();
        },
        (err)=>{
            //alert(err);
            alert("There is an error with the request");
        });
    }

    GetStates(){
        let respVal = [];
        
        InstructionService.GetInstructionStates().then((resp)=>{
            this.setState({States:resp.data.Content},()=>{
                let mapd=[];
                mapd.push(<option label="State"></option>);
                for(let f=0;f<this.state.States.length;f++){
                    mapd.push(<option>{this.state.States[f]}</option>);
                };
                
                this.setState({MappedStates:mapd},()=>{
                    //this.GetDischargeReasonTypes();
                    this.UpdateIncomingMortgageeSegment();
                    this.UpdateSecuritySegment();
                    this.UpdatePartySegment();
                    this.UpdatePartyRep();
                    this.GetDischargeTypes();
                    this.UpdateImportNotesSegment();
                    //this.GetTrustAccountOptions();
                    //this.UpdatePayoutSegment();
                });
            });
        },
        (err)=>{
            this.setState({

                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
            });
            
        });

    }

    GetLenderMortgagees(){
        let respVal = [];
        
        InstructionService.GetLenderMortgagees().then((resp)=>{
            this.setState({LenderMortgagees:resp.data.Content},()=>{
                let mapd=[];
                //mapd.push(<option label="Select Mortgagee"></option>);
                for(let f=0;f<this.state.LenderMortgagees.length;f++){
                    // mapd.push(<option key={this.state.LenderMortgagees[f]}>{this.state.LenderMortgagees[f]}</option>);
                    mapd.push(<option>{this.state.LenderMortgagees[f]}</option>);
                };
                
                this.setState({MappedLenderMortgagees:mapd},()=>{
                    this.UpdateSecuritySegment();                   
                });
            });
        },
        (err)=>{
            this.setState({

                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
            });
            
        });

    }

    GetExternalUserPrivilege(){
        
       /*  AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({CanInstructNewLoans:resp.data.Content.CanInstructNewLoans},()=>{}
        }) */

        AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({
                CanInstructDischarges:resp.data.Content.CanInstructDischarges,
                CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
                CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
                CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
            },()=>{console.log(this.state.CanInstructNewLoans)});
        },(err)=>{
            console.log("there is an error")
            //this.setState({isInstructPriv:false});
        })
    }

    GetLenderDetails(){
        //let userdetails = AuthService.getUserDetails();
        //let usertypeid =  userdetails != null?userdetails.map(d=>d.usertypeid) : '';
        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        if(usertypeid == 2)
        {
            AuthService.GetLenderDetails().then((resp)=>{
                this.setState({
                    IsLenderThinkTank:resp.data.Content
                },()=>{console.log(this.state.IsLenderThinkTank)});
            },(err)=>{
                console.log("there is an error")
                //this.setState({isInstructPriv:false});
            })
        }
        
    }


    UpdateLenderReferenceCombined(e){
        e.preventDefault();
        let vals = this.state.LoanIds;
        var ref = '';
        for(let x=0;x<vals.length;x++){
            if(vals[x]===null){
                continue;
            }
            if(x===0){
                ref+=vals[x]
            }else{
                ref+=', '+vals[x]
            }
        }
        this.setState({InstructionLenderReference:ref});
    }

    LoanIdUpdated(e,i){
        e.preventDefault();
        let val = this.state.LoanIds;
        val[i]=e.target.value;
        
        this.setState({LoanIds:val},()=>{this.UpdateLoanIdSegment();});

    }
    IndicativeAmountUpdated(e,i){
        e.preventDefault();
        let val = this.state.IndicativeAmounts;
        val[i]=e.target.value;
        this.setState({IndicativeAmounts:val},()=>{this.UpdateLoanIdSegment();});
    }



    UpdateLoanIdSegment(){
        this.checkIfSessionHasExpired();
        var ents = this.state.LoanIdEntities;
        var loanIds = this.state.LoanIds;
        var loanAmounts = this.state.IndicativeAmounts;
        if(this.state.LoanIdEntities.length>this.state.LoanIdEntityCount){
            for(let i = this.state.LoanIdEntities.length; i>this.state.LoanIdEntityCount && i>this.state.LoanIdEntityMinCount ; i--){
                ents.pop();
                loanIds.pop();
                loanAmounts.pop();
            }
            this.setState({LoanIdEntities:ents,LoanIds:loanIds,IndicativeAmounts: loanAmounts},()=>{return;});
        }else{
        
            
            for(let i = 0; i<this.state.LoanIdEntityCount; i++){
                if(loanIds.length<this.state.LoanIdEntityCount){
                    loanIds.push(null);
                    loanAmounts.push(null);
                }
            
                ents[i]=((
                <div>
                    <h5 className="listContentTitle">Loan Account {i+1}</h5>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Loan Id
                        </div>
                        <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete="hello"  readOnly={this.state.InstructionSubmit?true:false} required />
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                        Indicative Amount
                        </div>
                        <input type="number" min="0" step="any" id={'IndicativeAmount'+i} className="dollarTextBox" value={this.state.IndicativeAmounts[i]} onChange={(e)=>{e.preventDefault();this.IndicativeAmountUpdated(e,i)}} placeholder="Amount" autoComplete="hello" required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div>
                </div>));
            }
            this.setState({LoanIdEntities:ents,LoanIds:loanIds,IndicativeAmounts: loanAmounts});
        }
    }
    

    PartyRepNameUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepNames;
        val[i]=e.target.value;
        this.setState({PartyRepNames:val},()=>{this.UpdateSecuritySegment()});
    }

    PartyRepEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepEmails;
        val[i]=e.target.value;
        this.setState({PartyRepEmails:val},()=>{this.UpdateSecuritySegment()});
    }
    PartyRepNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepNumbers;
        val[i]=e.target.value;
        this.setState({PartyRepNumbers:val},()=>{this.UpdateSecuritySegment()});
    }
    PartyRepTypeUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepType;
        val[i]=e.target.value;
        this.setState({PartyRepType:val},()=>{this.UpdateSecuritySegment()});
    }
    UpdatePartyRep(){
        
       
    }

    SecurityMortgageeNameUpdated(e,i){	
        //alert(e)	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeNames;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeNames:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeCompanyACNUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeCompanyACN;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeCompanyACN:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageNumberUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageNumbers;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageNumbers:val},()=>{this.UpdateSecuritySegment()});	
    }   	
    SecurityMortgageeFormattedTitleRefNoUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeFormattedTitleRefNo;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeFormattedTitleRefNo:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeVolumeUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeVolume;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeVolume:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeFolioUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeFolio;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeFolio:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeBlockUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeBlock;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeBlock:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeDistrictDivisionUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeDistrictDivision;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeDistrictDivision:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeLandDescriptionUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeLandDescription;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeLandDescription:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeSectionUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeSection;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeSection:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeUnitUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeUnit;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeUnit:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeLotUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeLot;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeLot:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeTownUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeTown;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeTown:val},()=>{this.UpdateSecuritySegment()});	
    }

    IncomingMortgageeNameUpdated(e,i){
        //alert(e)
        e.preventDefault();
        let val = this.state.IncomingMortgageeNames;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeNames:val},()=>{this.UpdateSecuritySegment()});
    }

    IncomingMortgageeEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeEmails;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeEmails:val},()=>{this.UpdateSecuritySegment()});
    }
    IncomingMortgageeNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeNumbers;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeNumbers:val},()=>{this.UpdateSecuritySegment()});
    }

    IncomingMortgageeTypeUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeType;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeType:val},()=>{this.UpdateSecuritySegment()});
    }

    UpdateIncomingMortgageeSegment(){
        let ents = this.state.IncomingMortgageeSegmentEntities;
    }   

    PartySegmentEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentEmails;
        val[i]=e.target.value;

        let emailValidationError = "";

        if(e.target.value.trim() == ""){
            emailValidationError = "Please fill out email field";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: false
                }
            );
        }
        else if(!this.isValidEmail(e.target.value)){
            emailValidationError = "Invalid email";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: false
                }
            );
        }
        else{
            emailValidationError = "";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: true
                }
            );
        }

        this.setState({PartySegmentEmails:val},()=>{this.UpdatePartySegment()});
    }
    PartySegmentNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentPhoneNumbers;
        val[i]=e.target.value;
        this.setState({PartySegmentPhoneNumbers:val},()=>{this.UpdatePartySegment()});
    }


    PartyAddressUpdated(e,i,key){
        e.preventDefault();
        let address = this.state.PartySegmentAddresses;
        if(address[i]===undefined){
            let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            address.push(addressEntity);
        }
        address[i][key]=e.target.value;

        this.setState({PartySegmentAddresses:address},()=>{this.UpdatePartySegment()});

    }

    PartyOrCompanyTypeUpdated(e,i){
        e.preventDefault();
        // let types = this.state.PartySegmentPartyTypes;
        // types[i]=e.target.value;
        
        //this.setState({PartySegmentPorCTypes:types},()=>{this.UpdatePartySegment()});
        let inps = document.getElementById('PartyName'+i).getElementsByTagName('input');        
        for(let x = 0;x<inps.length;x++){
            inps[x].required=false;
        }
        if(e.target.value==='Person'){
            document.getElementById('PartyNamePerson'+i).style.display='inline-block';
            document.getElementById('PartyNameCompany'+i).style.display='none';
            document.getElementById('PartyNamePerson'+i).getElementsByTagName('input');
            let personInps = document.getElementById('PartyNamePerson'+i).getElementsByTagName('input');
            for(let x = 0;x<personInps.length;x++){
                personInps[x].required=true;
            }
        }
        if(e.target.value==='Company'){
            document.getElementById('PartyNameCompany'+i).style.display='inline-block';
            document.getElementById('PartyNamePerson'+i).style.display='none';
            let companyInps = document.getElementById('PartyNameCompany'+i).getElementsByTagName('input');        
            for(let x = 0;x<companyInps.length;x++){
                companyInps[x].required=true;
            }
        }
    }

    PartyNameUpdated(e,i,key){
        e.preventDefault();
        let names = this.state.PartySegmentNames;
        // if(names[i]===undefined){
        //     let partyNameEntity = {'GivenName':'','Surname':'','CompanyName':'','Acn':'','Abn':'', 'CompanyPPSR':''};            
        //     names.push(partyNameEntity);
        // }
        names[i][key]=e.target.value;
        this.setState({PartySegmentNames:names},()=>{this.UpdatePartySegment()});
    }

    PartyTypeUpdated(e,i){
        e.preventDefault();
        let types =this.state.PartySegmentPartyTypes;
        types[i]=e.target.value;
        this.setState({PartySegmentPartyTypes:types},()=>{this.UpdatePartySegment()});
    }

    

    UpdatePartySegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.PartySegmentEntities;
        
        if(ents.length>this.state.PartySegmentCount){
            let partyAddresses = this.state.PartySegmentAddresses;
            let partyNames = this.state.PartySegmentNames;
            let partyPorCTypes = this.state.PartySegmentPorCTypes;
            let partyTypes= this.state.PartySegmentPartyTypes;
            let partyEmails = this.state.PartySegmentEmails;
            let partyMobiles = this.state.PartySegmentPhoneNumbers;
            let companyPPSR = this.state.PartySegmentCompanyPPSR;
            for(let i = this.state.PartySegmentEntities.length; i>this.state.PartySegmentCount  && i>this.state.PartySegmentMinCount; i--){
                partyAddresses.pop();
                partyNames.pop();
                partyTypes.pop();
                ents.pop();
                partyMobiles.pop();
                partyEmails.pop();
                partyPorCTypes.pop();
                companyPPSR.pop();
            }
            this.setState({PartySegmentAddresses:partyAddresses,PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,
                PartySegmentEntities:ents,
                PartySegmentPhoneNumbers:partyMobiles,
                PartySegmentEmails:partyEmails,
                PartySegmentPorCTypes:partyPorCTypes,
                PartySegmentCompanyPPSR: companyPPSR
            });
        }else{
            let partyTypes= this.state.PartySegmentPartyTypes;
            let partyAdd = this.state.PartySegmentAddresses;
            let partyNames = this.state.PartySegmentNames;
            let partyPorCTypes = this.state.PartySegmentPorCTypes;
            let partyEmails = this.state.PartySegmentEmails;
            let partyMobiles = this.state.PartySegmentPhoneNumbers;
            let addressEntity = {'state':'','city':'','number':'','postcode':'','street':''};
            //let partyNameEntity = {'GivenName':'','Surname':'','CompanyName':'','Acn':'','Abn':'', 'CompanyPPSR':''};
            let partyNameEntity = {'GivenName':'','Surname':'','CompanyName':'','Acn':'','Abn':''};
            let companyPPSR = this.state.PartySegmentCompanyPPSR;
            for(let f = 0; f<this.state.PartySegmentCount; f++){
                if(partyEmails.length<this.state.PartySegmentCount){
                 
                    partyNames.push(partyNameEntity);
                    partyAdd.push(addressEntity);
                    partyTypes.push('Borrower');
                    partyPorCTypes.push('Person');
                    partyEmails.push('');
                    partyMobiles.push('');
                    companyPPSR.push('');
                }
            }
            this.setState({PartySegmentAddresses:partyAdd,PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,
                PartySegmentPorCTypes:partyPorCTypes,
                PartySegmentEmails:partyEmails, 
                PartySegmentPhoneNumbers:partyMobiles,
                PartySegmentCompanyPPSR: companyPPSR
            },()=>{
                        for(let i =0; i < this.state.PartySegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Loan Party #{i+1}</h5>
                                    <div id={"PartyName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Party Type
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Borrower" name={"PartyType"+i} title="Borrower" checked={this.state.PartySegmentPartyTypes[i]==='Borrower'}/>                              
                                                <RadioButton value="Guarantor" name={"PartyType"+i} title="Guarantor" checked={this.state.PartySegmentPartyTypes[i]==='Guarantor'}/>
                                            </div>
                                        </div> 
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PersonOrCompanyLabel"+1}>
                                                Individual or Company?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PersonOrCompany"+i} value={this.state.PartySegmentPorCTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPorCTypes; vals[i]=e.target.value;this.setState({PartySegmentPorCTypes:vals},()=>{this.UpdatePartySegment()})}}>    
                                            {/* <div className="multiColsInputBoxContentWidth" id={"PersonOrCompany"+i} value={this.state.PartySegmentPorCTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOrCompanyTypeUpdated(e,i)}}> */}
                                                <RadioButton value="Person" name={"PersonOrCompany"+i} title="Person" checked={this.state.PartySegmentPorCTypes[i]==='Person'} />                              
                                                <RadioButton value="Company" name={"PersonOrCompany"+i} title="Company" checked={this.state.PartySegmentPorCTypes[i]==='Company'} />                              
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':this.state.PartySegmentPorCTypes[i]==='Company'?'none':'block'}}>
                                            <div className="inputLabel">
                                                Name
                                            </div>
                                            <div className="multiColsInputBox">
                                                <input type="text" id={'PartyNamePersonGivenName'+i} className={this.state.PartySegmentNames[i]['GivenName']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['GivenName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'GivenName')}} autoComplete="hello" placeholder="Given Name" />
                                                <input type="text" id={'PartyNamePersonSurname'+i} className={this.state.PartySegmentNames[i]['Surname']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['Surname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Surname')}} autoComplete="hello" placeholder="Surname" />
                                            </div>
                                        </div>
                                        <div id={"PartyNameCompany"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Company'?'block':'none'}}>
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Company Name
                                                </div>
                                                <div>
                                                <input type="text" id={'PartyNamePersonCompanyName'+i}  value={this.state.PartySegmentNames[i]['CompanyName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'CompanyName')}} autoComplete="hello" placeholder="Company Name" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ACN
                                                </div>                                                
                                                <div>
                                                    <input type="text" id={'PartyNamCompanyACN'+i} value={this.state.PartySegmentNames[i]['Acn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Acn')}} autoComplete="hello" placeholder="ACN" />
                                                    
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ABN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNameCompanyAbn'+i} value={this.state.PartySegmentNames[i]['Abn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Abn')}} autoComplete="hello" placeholder="ABN" />
                                                </div>
                                            </div>
                                            {this.state.displayPPSRDischarges? 
                                            <div className="inputBox">
                                                <div className="inputLabel" id={"PersonOrCompanyLabel"+1}>
                                                    PPSR?
                                                </div>
                                                {/* <div className="multiColsInputBoxContentWidth" id={"CompanyPPSR"+i} value={this.state.PartySegmentCompanyPPSR[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentCompanyPPSR; vals[i]=e.target.value;this.setState({PartySegmentCompanyPPSR:vals},()=>{this.UpdatePartySegment()})}}>    
                                                    <RadioButton value="Yes" name={"CompanyPPSR"+i} title="Yes" checked={this.state.PartySegmentCompanyPPSR[i]==='Yes'} />                              
                                                    <RadioButton value="No" name={"CompanyPPSR"+i} title="No" checked={this.state.PartySegmentCompanyPPSR[i]==='No'} />                              
                                                </div> */}
                                                <div className="multiColsInputBoxContentWidth" id={"CompanyPPSR"+i} value={this.state.PartySegmentCompanyPPSR[i]} readOnly={this.state.InstructionSubmit?true:false} 
                                                    onChange={(e)=>{
                                                        let vals = this.state.PartySegmentCompanyPPSR; 
                                                        //vals[i]=this.stringToBool(e.target.value);
                                                        vals[i]=e.target.value;
                                                        this.setState({PartySegmentCompanyPPSR:vals},()=>{
                                                            this.UpdatePartySegment()
                                                            console.log(this.state.PartySegmentCompanyPPSR[i])
                                                        })}
                                                        }>    
                                                    {/* <RadioButton value="Yes" name={"CompanyPPSR"+i} title="Yes" checked={this.state.PartySegmentCompanyPPSR[i]===true} />                              
                                                    <RadioButton value="No" name={"CompanyPPSR"+i} title="No" checked={this.state.PartySegmentCompanyPPSR[i]===false} />                               */}
                                                    <RadioButton value="Yes" name={"CompanyPPSR"+i} title="Yes" checked={this.state.PartySegmentCompanyPPSR[i]==='Yes'} />                              
                                                    <RadioButton value="No" name={"CompanyPPSR"+i} title="No" checked={this.state.PartySegmentCompanyPPSR[i]==='No'} />   
                                                </div>
                                            </div>
                                            : ''}
                                            {/* {this.state.displayPPSRDischarges? 
                                            <div className="inputBox">
                                                <div className="inputLabel" id={"PersonOrCompanyLabel"+1}>
                                                    PPSR?
                                                </div>
                                                <div className="multiColsInputBoxContentWidth" id={"PartyNameCompanyPPSR"+i} value={this.state.PartySegmentNames[i]['CompanyPPSR']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{alert(e.target.value); let vals = this.state.PartySegmentNames; 
                                                    vals[i]['CompanyPPSR']=e.target.value;
                                                    //vals[i]['CompanyPPSR']=this.stringToBool(e.target.value);
                                                    alert( vals[i]['CompanyPPSR'])
                                                    this.setState({PartySegmentNames:vals},()=>{this.PartyNameUpdated(e,i,['CompanyPPSR'])})}}>    
                                                   {this.state.PartySegmentNames[i]['CompanyPPSR']}
                                                    <RadioButton value="Yes" name={"PartyNameCompanyPPSR"+i} title="Yes" checked={this.state.PartySegmentNames[i]['CompanyPPSR']=='Yes'} />                              
                                                    <RadioButton value="No" name={"PartyNameCompanyPPSR"+i} title="No" checked={this.state.PartySegmentNames[i]['CompanyPPSR']=='No' } />                                           
                                                </div>
                                            </div>  
                                            // <div className="inputBox">
                                            //     <div className="inputLabel" id={"PersonOrCompanyLabel"+1}>
                                            //         PPSR?
                                            //     </div>
                                            //     <div className="multiColsInputBoxContentWidth" id={"PartyNameCompanyPPSR"+i} value={this.state.PartySegmentNames[i]['CompanyPPSR']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'CompanyPPSR')}}>                                                    
                                            //         <RadioButton value="Yes" name={"PartyNameCompanyPPSR"+i} title="Yes" checked={this.state.PartySegmentNames[i]['CompanyPPSR']==='Yes'} />                              
                                            //         <RadioButton value="No" name={"PartyNameCompanyPPSR"+i} title="No" checked={this.state.PartySegmentNames[i]['CompanyPPSR']==='No'} />                              
                                            //     </div>
                                            // </div>
                                            : ''} */}
                                            
                                        </div>
                                    </div>

                                    <div style={{'display':this.state.SelfActing[0]?'block':'none'}} className="PartyAddress" id={"PartyAddress"+i}>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Street
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyStreet'+i} className="form-control" value={this.state.PartySegmentAddresses[i]['street']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'street')}} autoComplete="hello" placeholder="Street" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                City
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartySegmentAddresses[i]['city']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'city')}} autoComplete="hello" placeholder="City" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                State
                                            </div>
                                            <div>                                                    
                                                <select className="form-control" id={"StateName"+i} value={this.state.PartySegmentAddresses[i]['state']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'state')}}>
                                                {this.state.MappedStates}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Postcode
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'Postcode'+i} className="form-control" maxLength="4" value={this.state.PartySegmentAddresses[i]['postcode']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.PartyAddressUpdated(e,i,'postcode')}}} autoComplete="hello" placeholder="Postcode" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Phone Number
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyPhoneNumber'+i} className="form-control" value={this.state.PartySegmentPhoneNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentNumberUpdated(e,i)}} autoComplete="hello" placeholder="" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Email
                                        </div>
                                        <div>
                                            {/* <input type="email" id={'PartyEmail'+i} className="form-control" value={this.state.PartySegmentEmails[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentEmailUpdated(e,i)}} autoComplete="hello" placeholder="example@email.com" required={this.state.SelfActing[0]}/> */}
                                            {/* <input type="email" id={'PartyEmail'+i} className={this.state.validEmail==true?'successTextBox':this.state.emailValidationError?"":''} value={this.state.PartySegmentEmails[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentEmailUpdated(e,i)}} autoComplete="hello" placeholder="example@email.com" required={this.state.SelfActing[0]}/>                                             */}
                                            <input type="email" id={'PartyEmail'+i} className={(this.state.PartySegmentEmails[i] && this.state.validEmail==true)?'successTextBox':this.state.emailValidationError?"":''} value={this.state.PartySegmentEmails[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentEmailUpdated(e,i)}} autoComplete="hello" placeholder="example@email.com" required={this.state.SelfActing[0]}/>                                                                                
                                            {/* <input type="email" id={'PartyEmail'+i} className={this.state.PartySegmentEmails[i]?"successTextBox":''} value={this.state.PartySegmentEmails[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentEmailUpdated(e,i)}} autoComplete="hello" placeholder="example@email.com" required={this.state.SelfActing[0]}/>                                             */}
                                        </div>
                                    </div>
                                </>));
                               
                        }
                        this.setState({PartySegmentEntities:ents});
            });
        }

    }
    
    IsValidPostcode(value){
        let re = /[^0-9]/;
        return !re.test(value);
    }

    SecurityAddressUpdated(e,i,key){
        e.preventDefault();
        let address = this.state.SecuritySegmentAddreses;
        address[i][key]=e.target.value;
        let titleRefFormat = this.state.TitleRefFormat;
        //alert(e.target.value)
        // alert(e)
        // alert(key)
        // alert(address[i])
        //if(address[i][key]=="NSW" || address[i][key]=="QLD"){
        if(key=="state" && address[i][key]!=""){
            //alert('dsfdsfdsf')
            this.ResetSecurityOtherDetails(i);
            if(e.target.value == "NSW")
            {
                titleRefFormat[i] = "1/SP12345, 7/12/6789, 7456-56"
                // this.setState({
                //     TitleRefFormat: "1/SP12345, 7/12/6789, 7456-56"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "NT")
            {
                titleRefFormat[i] = "12345/12345, CUFT12345/12345"
                // this.setState({
                //     //TitleRefFormat: "CUFT145678"
                //     TitleRefFormat: "12345/12345, CUFT12345/12345"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "QLD")
            {
                titleRefFormat[i] = "14483140"
                // this.setState({
                //     TitleRefFormat: "14483140"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "SA")
            {
                titleRefFormat[i] = "CT 6321/321, CL 5432/43"
                // this.setState({
                //     //TitleRefFormat: "1234/234"
                //     TitleRefFormat: "CT 6321/321, CL 5432/43"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "TAS")
            {
                titleRefFormat[i] = "123456/1"
                // this.setState({
                //     //TitleRefFormat: "155100/1, 155100:S1/1"
                //     TitleRefFormat: "123456/1"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "VIC")
            {
                titleRefFormat[i] = "6237/745, 18378/002, 459/789K"
                // this.setState({
                //     //TitleRefFormat: "12345/789A"
                //     TitleRefFormat: "6237/745, 18378/002, 459/789K"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "WA")
            {
                titleRefFormat[i] = "AB12345-11111A, 2767-913"
                // this.setState({
                //     //TitleRefFormat: "AB12345/11111A"
                //     TitleRefFormat: "AB12345-11111A, 2767-913"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "ACT")
            {
                //titleRefFormat[i] = "1234:123"
                titleRefFormat[i] = "1234/123"
                // this.setState({
                //     TitleRefFormat: "1234:123"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
        }
        this.setState({SecuritySegmentAddreses:address
            , TitleRefFormat:titleRefFormat
        },()=>{this.UpdateSecuritySegment()});

         
    }

    SecurityReasonUpdated(e,i){
        e.preventDefault();
        let dischargeReason = this.state.SecurityDischargeReasons;
        dischargeReason[i]=e.target.value;
        //let selfActing = this.state.SelfActing;
        
        // if(dischargeReason[i] === 'Debt Repaid')
        // {
        //     selfActing[i] = true;
        // }
        // else {selfActing[i] = false;}
        this.setState({SecurityDischargeReasons:dischargeReason, 
            //SelfActing: selfActing
        },()=>{this.UpdateSecuritySegment()});
    }

    SecurityTitleReferenceUpdated(e,i){
        e.preventDefault();
        let titleReferences = this.state.SecuritySegmentTitleReferences;
        titleReferences[i]=e.target.value;
        this.setState({SecuritySegmentTitleReferences:titleReferences},()=>{
            this.UpdateSecuritySegment()
            //check if needs to be verified
        });
    }

    // CheckTitleReferenceFormatTEST(e,i,titleRef){
    //     e.preventDefault();
    //     // alert(e)
    //     // alert(i)
    //     // alert(titleRef)
    //     let titleReferences = this.state.SecuritySegmentTitleReferences;
    //     if(titleRef!="" && titleRef!= undefined)
    //     {
    //         titleReferences[i]=titleRef;
    //     }
    //     else titleReferences[i]=e.target.value;
    //     let IsTitleSearchFromUpload = this.state.IsTitleSearchFromUpload;
    //     let titlesearches = this.state.SecurityTitleSearches;
    //     let address = this.state.SecuritySegmentAddreses;     
    //     let titleRefValid = this.state.TitleReferenceValid;

    //     //if(this.state.IsTitleSearchFromUpload[i])
    //     // if(this.state.SecurityTitleSearches[i])
    //     // {
    //     //     //valid - all title references from uploads are assumed to be valid
    //     //     //alert("valid from title search")
    //     //     titleRefValid[i] = true;

    //     //     this.setState({
    //     //         TitleReferenceValid: titleRefValid
    //     //     },()=>{
    //     //         this.UpdateSecuritySegment();
    //     //     })
    //     // }
    //     // else{

    //         if(address[i]['state']=="")
    //         {
    //             alert("Please select state first")
    //             return;
    //         }
    //         if(titleReferences[i]=="")
    //         {
    //             alert("Title reference is required")
    //             return;
    //         }

    //         //check the state
    //         if(address[i]['state'] == "NT")
    //         {

    //             var regex = /^[A-Z][/][A-Z][/][A-Z]/;
    //             if(!regex.test(titleReferences[i]))
    //             {
    //                 alert("not valid");
    //                 return;
    //             }
    //             else{
    //                 alert("valid")
    //             }
    //             //NT - no checking for now     
    //             // alert("COOL")
    //             // alert(address[i]['state'])
    //             titleRefValid[i] = true;

    //             this.setState({
    //                 TitleReferenceValid: titleRefValid
    //             },()=>{
    //                 this.UpdateSecuritySegment();
    //             })
    //             //split the title ref
    //             // if(titleReferences[i].trim().includes(" "))
    //             // {
    //             //     let words = titleReferences[i].trim().split(" ");
    //             //     alert(words.length)
    //             //     if(words.length !=3 || titleReferences[i].trim().titleReferences[i].replace(" ", "").length != 12)
    //             //     {
    //             //         alert ("Invalid title reference. Should be in the format: {Register} {Volume} {Folio}");
    //             //         return;
    //             //     }
    //             //     else{
    //             //         words.forEach((element) => {
    //             //             console.log(element[0])
    //             //             if(!(/^[a-zA-Z]/).test(element[0]) || element[0].length != 4)
    //             //             {
    //             //                 alert ("Invalid title reference. Should be in the format: {Register} {Volume} {Folio}");
    //             //                 return;
    //             //             }
    //             //         });
    
    //             //     }
    //             // }
    //             // else{
    //             //     //var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
    //             //     //var regex = /^[0-9a-zA-Z]\d{6}$/;
    //             //     var regex = /^[0-9a-zA-Z]/;              
                    

    //             //     if(!regex.test(titleReferences[i].replace(" ", "")) || titleReferences[i].length != 12)
    //             //     {
    //             //         alert ("Invalid title reference. Should be in the format: CUFT145678 {Register} {Volume} {Folio}");
    //             //         return;
    //             //     }
    //             // }                
    //         }
    //         else if(address[i]['state'] == "QLD")
    //         {
    //             //alert(address[i]['state'])
    //             var regex = /^[0-9]*$/;//"^[a-zA-Z0-9 \-_.]";
    //             if(!regex.test(titleReferences[i].replace(" ", "")) || titleReferences[i].length != 8)
    //             {
    //                 titleRefValid[i] = false;

    //                 this.setState({
    //                     TitleReferenceValid: titleRefValid
    //                 },()=>{
    //                     this.UpdateSecuritySegment();
    //                 })
    //                 // alert ("Invalid title reference. Should be in the format: {Title Reference}");
    //                 alert ("Invalid QLD Title Reference. Should be in the format: 14483140 {Title Reference}");
    //                 return;
    //             }
    //             else{
    //                 titleRefValid[i] = true;

    //                 this.setState({
    //                     TitleReferenceValid: titleRefValid
    //                 },()=>{
    //                     this.UpdateSecuritySegment();
    //                 })
    //             }
    //         }
    //         else if(address[i]['state'] == "SA")
    //         {
    //             //alert(address[i]['state'])
    //             if(titleReferences[i].trim().includes("/") && titleReferences[i].trim().replace(" ", "").length == 8)
    //             {
    //                 let words = titleReferences[i].trim().split("/");
    //                 //alert(words.length)
    //                 if(words.length == 2)
    //                 {
    //                     //alert("yey")
    //                     var regex = /^\d+$/;
    //                     //words.forEach((element) => {
    //                         console.log(words[0])
    //                         if((regex.test(words[0]) && words[0].length == 4) && (/^\d+$/.test(words[1]) && words[1].length == 3))
    //                         {
    //                             //valid
    //                             //alert("valid")
    //                             titleRefValid[i] = true;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                         }   
    //                         else{
    //                             titleRefValid[i] = false;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                             // alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}");
    //                             alert ("Invalid SA Title Reference. Should be in the format: 1234/234 {Volume}/{Folio}");
    //                             return;
    //                         }                        
    //                     //});
    
                        
    //                 }
    //                 else{
    //                     titleRefValid[i] = false;

    //                     this.setState({
    //                         TitleReferenceValid: titleRefValid
    //                     },()=>{
    //                         this.UpdateSecuritySegment();
    //                     })
    //                     // alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}");
    //                     alert ("Invalid SA Title Reference. Should be in the format: 1234/234 {Volume}/{Folio}");
    //                     return;
                        
    //                 }
    //             }
    //             else{
    //                 titleRefValid[i] = false;

    //                 this.setState({
    //                     TitleReferenceValid: titleRefValid
    //                 },()=>{
    //                     this.UpdateSecuritySegment();
    //                 })
    //                 //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}");
    //                 alert ("Invalid SA Title Reference. Should be in the format: 1234/234 {Volume}/{Folio}");
    //                 return;
    //             }                
    //         }
    //         else if(address[i]['state'] == "TAS")
    //         {
    //             //NT - no checking for now     
    //             //alert(address[i]['state'])
    //             titleRefValid[i] = true;

    //             this.setState({
    //                 TitleReferenceValid: titleRefValid
    //             },()=>{
    //                 this.UpdateSecuritySegment();
    //             })
    //             //14483140
    //             // var regex = /^[0-9]*$/;//"^[a-zA-Z0-9 \-_.]";
    //             // if(!regex.test(titleReferences[i].replace(" ", "")) || titleReferences[i].length != 8)
    //             // {
    //             //   alert ("Invalid TAS Title Reference. Should be in the format: 155100/1 OR 155100:S1/1 {Volume}/{Folio}");
    //             //   return;
    //             // }
    //         }
    //         else if(address[i]['state'] == "VIC")
    //         {
    //             //alert(address[i]['state'])

    //             if(titleReferences[i].trim().includes("/") && (titleReferences[i].trim().replace(" ", "").length == 10 || titleReferences[i].trim().replace(" ", "").length == 9))
    //             {
    //                 let words = titleReferences[i].trim().split("/");
    //                 if(words.length == 2)
    //                 {
    //                     var regex = /^\d+$/;

    //                     if(titleReferences[i].trim().replace(" ", "").length == 9)
    //                     {                        
    //                         console.log(words[0])
    //                         if((regex.test(words[0]) && words[0].length == 5) && (regex.test(words[1]) && words[1].length == 3))
    //                         {
    //                             //valid
    //                             //alert("valid")
    //                             titleRefValid[i] = true;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                         }   
    //                         else{
    //                             titleRefValid[i] = false;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                             //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
    //                             alert ("Invalid VIC Title Reference. Should be in the format: 12345/789A {Volume}/{Folio}{Suffix}");
    //                             return;
    //                         }  
    //                     }                       

    //                     else if(titleReferences[i].trim().replace(" ", "").length == 10)
    //                     {
    //                         //alert(titleReferences[i].trim().slice(-1))
    //                         if((regex.test(words[0]) && words[0].length == 5) && (/^\d+$/.test(words[1].slice(0, -1)) && words[1].length == 4) &&
    //                         titleReferences[i].trim().slice(-1) == "A" || titleReferences[i].trim().slice(-1) == "B" || titleReferences[i].trim().slice(-1) == "C" || titleReferences[i].trim().slice(-1) == "K" || titleReferences[i].trim().slice(-1) == "L")
    //                         {
    //                             //valid
    //                             //alert("valid")
    //                             titleRefValid[i] = true;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                         }   
    //                         else{
    //                             titleRefValid[i] = false;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                             //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
    //                             alert ("Invalid VIC Title Reference. Should be in the format: 12345/789A {Volume}/{Folio}{Suffix}");
    //                             return;
    //                         }  

    //                         // if(titleReferences[i].trim().slice(-1) != "A" && titleReferences[i].trim().slice(-1) != "B" && titleReferences[i].trim().slice(-1) != "C" && titleReferences[i].trim().slice(-1) != "K" && titleReferences[i].trim().slice(-1) != "L");
    //                         // {
    //                         //     alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
    //                         //     return;
    //                         // }
    //                     }
    
                        
    //                 }
    //                 else{
    //                     titleRefValid[i] = false;

    //                     this.setState({
    //                         TitleReferenceValid: titleRefValid
    //                     },()=>{
    //                         this.UpdateSecuritySegment();
    //                     })
    //                     //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
    //                     alert ("Invalid VIC Title Reference. Should be in the format: 12345/789A {Volume}/{Folio}{Suffix}");
    //                     return;
                        
    //                 }
    //             }
    //             else{
    //                 titleRefValid[i] = false;

    //                 this.setState({
    //                     TitleReferenceValid: titleRefValid
    //                 },()=>{
    //                     this.UpdateSecuritySegment();
    //                 })
    //                 //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
    //                 alert ("Invalid VIC Title Reference. Should be in the format: 12345/789A {Volume}/{Folio}{Suffix}");
    //                 return;
    //             } 
    //         }
    //         else if(address[i]['state'] == "WA")
    //         {
    //             //alert(address[i]['state'])
    //             if((titleReferences[i].trim().includes("/") || titleReferences[i].trim().includes("-")) && titleReferences[i].length == 14)
    //             {
    //                 if(titleReferences[i].trim().includes("/"))
    //                 {
    //                     let words = titleReferences[i].trim().split("/");
                        
    //                     if(words.length == 2)
    //                     {
    //                         var regex = /^\d+$/;
    
    //                         if((regex.test(words[0].slice(2,7)) && words[0].length == 7) && (regex.test(words[1].slice(0, 5)) && (words[1].length == 6 && words[1].trim().slice(-1) == "A") || words[1].length == 5))
    //                         {
    //                             //valid
    //                             titleRefValid[i] = true;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                             //alert("valid")
    //                         }   
    //                         else{
    //                             titleRefValid[i] = false;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                             //alert ("Invalid title reference. Should be in the format: {Prefix}{Volume}/{Folio}{Suffix}");
    //                             alert ("Invalid WA Title Reference. Should be in the format: AB12345/11111A {Prefix}{Volume}/{Folio}{Suffix}");
    //                             return;
    //                         }
    //                     }                   
    //                 }
    //                 else if (titleReferences[i].trim().includes("-"))
    //                 {
    //                     let words = titleReferences[i].trim().split("-");
    //                     if(words.length == 2)
    //                     {
    //                         var regex = /^\d+$/;
    
    //                         if((regex.test(words[0].slice(2,7)) && words[0].length == 7) && (regex.test(words[1].slice(0, 5)) && (words[1].length == 6 && words[1].trim().slice(-1) == "A") || words[1].length == 5))
    //                         {
    //                             //valid
    //                             titleRefValid[i] = true;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                             //alert("valid")
    //                         }   
    //                         else{
    //                             titleRefValid[i] = false;

    //                             this.setState({
    //                                 TitleReferenceValid: titleRefValid
    //                             },()=>{
    //                                 this.UpdateSecuritySegment();
    //                             })
    //                             //alert ("Invalid title reference. Should be in the format: {Prefix}{Volume}/{Folio}{Suffix}");
    //                             alert ("Invalid WA Title Reference. Should be in the format: AB12345/11111A {Prefix}{Volume}/{Folio}{Suffix}");
    //                             return;
    //                         }
    //                     }    
    //                 }
    //                 else{
    //                     titleRefValid[i] = false;

    //                     this.setState({
    //                         TitleReferenceValid: titleRefValid
    //                     },()=>{
    //                         this.UpdateSecuritySegment();
    //                     })
    //                     //alert ("Invalid title reference. Should be in the format: {Prefix}{Volume}/{Folio}{Suffix}");
    //                     alert ("Invalid WA Title Reference. Should be in the format: AB12345/11111A {Prefix}{Volume}/{Folio}{Suffix}");
    //                     return;
                        
    //                 }
    //             }
    //             else{
    //                 titleRefValid[i] = false;

    //                 this.setState({
    //                     TitleReferenceValid: titleRefValid
    //                 },()=>{
    //                     this.UpdateSecuritySegment();
    //                 })
    //                 //alert ("Invalid title reference. Should be in the format: {Prefix}{Volume}/{Folio}{Suffix}");
    //                 alert ("Invalid WA Title Reference. Should be in the format: AB12345/11111A {Prefix}{Volume}/{Folio}{Suffix}");
    //                 return;
    //             } 
    //         }
    //         else{
    //             //NSW & ACT- no checking for now     
    //             //alert("COOL NSW")
    //             titleRefValid[i] = true;

    //             this.setState({
    //                 TitleReferenceValid: titleRefValid
    //             },()=>{
    //                 this.UpdateSecuritySegment();
    //             })
    //         }         

            
    //     //}

       
    //     // this.setState({SecuritySegmentTitleReferences:titleReferences},()=>{
    //     //     this.UpdateSecuritySegment()
    //     //     //check if needs to be verified
    //     // });
    // }

    CheckTitleReferenceFormat(e,i,titleRef){
        e.preventDefault();
        // alert(e)
        // alert(i)
        // alert(titleRef)
        let titleReferences = this.state.SecuritySegmentTitleReferences;
        if(titleRef!="" && titleRef!= undefined)
        {
            titleReferences[i]=titleRef;
        }
        else titleReferences[i]=e.target.value;
        let IsTitleSearchFromUpload = this.state.IsTitleSearchFromUpload;
        let titlesearches = this.state.SecurityTitleSearches;
        let address = this.state.SecuritySegmentAddreses;     
        let titleRefValid = this.state.TitleReferenceValid;

        //if(this.state.IsTitleSearchFromUpload[i])
        // if(this.state.SecurityTitleSearches[i])
        // {
        //     //valid - all title references from uploads are assumed to be valid
        //     //alert("valid from title search")
        //     titleRefValid[i] = true;

        //     this.setState({
        //         TitleReferenceValid: titleRefValid
        //     },()=>{
        //         this.UpdateSecuritySegment();
        //     })
        // }
        // else{

            if(address[i]['state']=="")
            {
                alert("Please select state first")
                return;
            }
            if(titleReferences[i]=="")
            {
                alert("Title reference is required")
                return;
            }

            // if(titleReferences[i]!="")
            // {
            //     if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
            //         || titleReferences[i].includes(":") || titleReferences[i].includes(";")){
            //         alert("Invalid title reference")
            //         return;
            //     }
            // }
            

            //check the state
            if(address[i]['state'] == "NT")
            {
                //NT: Register: 4 Characters (optional) Volume: 5 Numeric Folio : 5 Numeric Separated by a forward slash “/”.
                var regex = /^([A-Z][A-Z][A-Z][A-Z])?\d{5}[/]\d{5}$/;
                if(regex.test(titleReferences[i]))
                {
                    titleRefValid[i] = true;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    // alert("valid");
                    // return;
                }

                else if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
                    || titleReferences[i].includes(":") || titleReferences[i].includes(";") || titleReferences[i].includes(",")){
                        titleRefValid[i] = false;

                        this.setState({
                            TitleReferenceValid: titleRefValid
                        },()=>{
                            this.UpdateSecuritySegment();
                        })
                        //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                        alert ("Invalid NT Title Reference. Should be in the format: [Register]{Volume}/{Folio} \nSome examples are: 12345/12345, CUFT12345/12345");
                        return;
                }
                

                else{
                    titleRefValid[i] = false;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                    alert ("Invalid NT Title Reference. Should be in the format: [Register]{Volume}/{Folio} \nSome examples are: 12345/12345, CUFT12345/12345");
                    return;
                }           
            }
            else if(address[i]['state'] == "QLD")
            {
                //alert(address[i]['state'])
                var regex = /^[0-9]*$/;//"^[a-zA-Z0-9 \-_.]";
                if(!regex.test(titleReferences[i].replace(" ", "")) || titleReferences[i].length != 8)
                {
                    titleRefValid[i] = false;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    // alert ("Invalid title reference. Should be in the format: {Title Reference}");
                    alert ("Invalid QLD Title Reference. Should be in the format: 14483140 {Title Reference}");
                    return;
                }
                else if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
                    || titleReferences[i].includes(":") || titleReferences[i].includes(";") || titleReferences[i].includes(",")){
                        titleRefValid[i] = false;

                        this.setState({
                            TitleReferenceValid: titleRefValid
                        },()=>{
                            this.UpdateSecuritySegment();
                        })
                        //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                        alert ("Invalid QLD Title Reference. Should be in the format: 14483140 {Title Reference}");
                        return;
                }
                else{
                    titleRefValid[i] = true;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                }
            }
            else if(address[i]['state'] == "SA")
            {
                //SA: Land Title Reference must be in the form Volume Type Volume/ Folio (i.e. separated by a forward slash) 
                //Volume Type is 2 characters Volume is maximum 4 characters Folio is maximum 4 characters Total characters allowed is 12 maximum Some examples of SA Land Title References are: CT 6321/321 CL 5432/43
                //alert(address[i]['state'])
                var regex = /^(([A-Z ]{1,4})?\d{1,4})[/]\d{1,4}$/;

                if(regex.test(titleReferences[i]))
                {
                    titleRefValid[i] = true;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    // alert("valid");
                    // return;
                }
                else if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
                    || titleReferences[i].includes(":") || titleReferences[i].includes(";") || titleReferences[i].includes(",")){
                        titleRefValid[i] = false;

                        this.setState({
                            TitleReferenceValid: titleRefValid
                        },()=>{
                            this.UpdateSecuritySegment();
                        })
                        //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                        alert ("Invalid SA Title Reference. Should be in the format: {Volume}/{Folio} \nSome examples are: CT 6321/321 CL 5432/43");
                        return;
                }
                else{
                    titleRefValid[i] = false;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                    alert ("Invalid SA Title Reference. Should be in the format: {Volume}/{Folio} \nSome examples are: CT 6321/321 CL 5432/43");
                    return;
                }                           
            }
            else if(address[i]['state'] == "TAS")
            {
                //TAS: Land Title Reference must be in the form Volume/Folio (i.e. separated by a forward slash). Volume is maximum 8 alpha-numeric characters Folio is maximum 4 characters For example 123456/1
                //TAS - no checking for now     
                //alert(address[i]['state'])
                var regex = /^[A-Za-z\d]{1,8}[/][A-Za-z\d]{1,4}$/;
                //var regex2 = /^[0-9]{1,5}[/]([0-9]{3}.*[A-Z]{1})$/;
                ///^[a-z]{0,10}$/

                if(regex.test(titleReferences[i]))
                {
                    titleRefValid[i] = true;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    // alert("valid");
                    // return;
                }
                else if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
                    || titleReferences[i].includes(":") || titleReferences[i].includes(";") || titleReferences[i].includes(",")){
                        titleRefValid[i] = false;

                        this.setState({
                            TitleReferenceValid: titleRefValid
                        },()=>{
                            this.UpdateSecuritySegment();
                        })
                        //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                        alert ("Invalid TAS Title Reference. Should be in the format: {Volume}/{Folio} \nFor example: 123456/1");
                        return;
                }
                else{
                    titleRefValid[i] = false;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                    alert ("Invalid TAS Title Reference. Should be in the format: {Volume}/{Folio} \nFor example: 123456/1");
                    return;
                }
            }
            else if(address[i]['state'] == "VIC")
            {
                //Land Title Reference must be in the form Volume/Folio (i.e. separated by a forward slash). 
                //Volume is maximum 5 numeric characters. leading zeros Folio is 3 numeric characters required and maximum of one alpha character allowed, only at the end. 
                //For folio leading zeros must be inserted to reach 3 numeric characters. Some examples of VIC Land Title References are: 6237/745 18378/002 459/789K
                
                //alert(address[i]['state'])

                var regex = /^[0-9]{1,5}[/][0-9]{3}$/;
                //var regex = /^[0-9]{1,5}[/][0-9]{3}.*([A-Z]{1}?)?$/;
                var regex2 = /^[0-9]{1,5}[/]([0-9]{3}.*[A-Z]{1})$/;

                if(regex.test(titleReferences[i]) || regex2.test(titleReferences[i]))
                //if(regex.test(titleReferences[i]))
                {
                    titleRefValid[i] = true;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    // alert("valid");
                    // return;
                }
                else if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
                    || titleReferences[i].includes(":") || titleReferences[i].includes(";") || titleReferences[i].includes(",")){
                        titleRefValid[i] = false;

                        this.setState({
                            TitleReferenceValid: titleRefValid
                        },()=>{
                            this.UpdateSecuritySegment();
                        })
                        //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                        alert ("Invalid VIC Title Reference. Should be in the format:  {Volume}/{Folio}[Suffix] \nSome examples are: 6237/745, 18378/002, 459/789K");
                        return;
                }
                else{
                    titleRefValid[i] = false;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                    alert ("Invalid VIC Title Reference. Should be in the format:  {Volume}/{Folio}[Suffix] \nSome examples are: 6237/745, 18378/002, 459/789K");
                    return;
                }                
            }
            else if(address[i]['state'] == "WA")
            {
                //alert(address[i]['state'])
                //WA: Land Title Reference must be in the form:
                //Prefix 2 characters (optional) Volume maximum of 5 numeric Separator “-“ Folio maximum of 5 numeric Suffix of 1 character (allowed values are ‘A’ or blank) 
                //For example: 2767-913
                //WA	{Prefix}{Volume}/{Folio}{Suffix}	Alphanumeric	2 characters for Prefix, 5 digits for Volume, 5 digits for Folio, 1 character for Suffix	
                //AB12345/11111A	Space, hyphen (-) and forward slash (/) are acceptable characters. ‘A’ or space are valid Suffix values.
                //var regex0 = /^([A-Z][A-Z])?\d{1,5}[-][0-9]{1,5}$/;
                //var regex = /^([A-Z][A-Z])?\d{1,5}[-][0-9]{1,5}A$/;
                var regex = /^([A-Z][A-Z])?\d{1,5}[-][0-9]{1,5}([A ])?$/;
                // var regex = /^([A-Z][A-Z])?\d{1,5}[-][0-9]{1,5}[A ]$/;
                //var regex1 = /^([A-Z][A-Z])?\d{1,5}[-][0-9]{1,5} $/;
                //if(regex0.test(titleReferences[i]) || regex.test(titleReferences[i]) || regex1.test(titleReferences[i]))
                if(regex.test(titleReferences[i]))
                {
                    titleRefValid[i] = true;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    // alert("valid");
                    // return;
                }
                else if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
                    || titleReferences[i].includes(":") || titleReferences[i].includes(";") || titleReferences[i].includes(",")){
                        titleRefValid[i] = false;

                        this.setState({
                            TitleReferenceValid: titleRefValid
                        },()=>{
                            this.UpdateSecuritySegment();
                        })
                        //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                        alert ("Invalid WA Title Reference. Should be in the format:  [Prefix]{Volume}-{Folio}[Suffix]  \nSome examples are: AB12345-11111A, 2767-913");
                        return;
                }
                else{
                    titleRefValid[i] = false;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    //alert ("Invalid WA Title Reference. Should be in the format: AB12345/11111A {Prefix}{Volume}/{Folio}{Suffix}");
                    alert ("Invalid WA Title Reference. Should be in the format:  [Prefix]{Volume}-{Folio}[Suffix]  \nSome examples are: AB12345-11111A, 2767-913");
                    return;
                }
                
            }
            else if(address[i]['state'] == "ACT")
            {
                //ACT: Land Title Reference must be in the form Volume: Folio (i.e. separated by a colon) Volume is maximum 4 numeric characters Folio is maximum 3 numeric characters
                // var regex = /^(\d{1,4})[:]\d{1,3}$/;
                var regex = /^(\d{1,4})[/]\d{1,3}$/;
                //var regex2 = /^[0-9]{1,5}[/]([0-9]{3}.*[A-Z]{1})$/;
                ///^[a-z]{0,10}$/

                if(regex.test(titleReferences[i]))
                {
                    titleRefValid[i] = true;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    // alert("valid");
                    // return;
                }
                else if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
                    || titleReferences[i].includes(":") || titleReferences[i].includes(";") || titleReferences[i].includes(",")){
                        titleRefValid[i] = false;

                        this.setState({
                            TitleReferenceValid: titleRefValid
                        },()=>{
                            this.UpdateSecuritySegment();
                        })
                        //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                        alert ("Invalid ACT Title Reference. Should be in the format: {Volume}/{Folio} \nFor example: 1234/123");
                        return;
                }
                else{
                    titleRefValid[i] = false;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                    //alert ("Invalid ACT Title Reference. Should be in the format: {Volume}:{Folio} \nFor example: 1234:123");
                    alert ("Invalid ACT Title Reference. Should be in the format: {Volume}/{Folio} \nFor example: 1234/123");
                    return;
                }
                            
            }
            else{
                //NSW no checking for now     
                //NSW: Between 3 and 21 ascii characters Only include " ", "/" or "-" other than alpha or numeric characters
                //alert("COOL NSW")
                // titleRefValid[i] = true;
                //var regex = /^[A-Z0-9 \/-,]$/;
                //var regex = /^[A-Z\d ,-]+(?:\/[A-Z\d]+)+$/;
                //var regex = /^([ A-Za-z0-9_@./#&+-])*$/;
                // var regex = /^([ A-Za-z0-9,/-])*$/;
                var regex = /^([ A-Za-z0-9/-])*$/;
                //[a-zA-Z0-9 \-_.]
                if(regex.test(titleReferences[i]))
                {
                    titleRefValid[i] = true;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    // alert("valid");
                    // return;
                }
                else if(titleReferences[i].toLowerCase().includes("volume") || titleReferences[i].toLowerCase().includes("vol") || titleReferences[i].toLowerCase().includes("folio")
                    || titleReferences[i].includes(":") || titleReferences[i].includes(";") || titleReferences[i].includes(",")){
                        titleRefValid[i] = false;

                        this.setState({
                            TitleReferenceValid: titleRefValid
                        },()=>{
                            this.UpdateSecuritySegment();
                        })
                        //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                        alert ("Invalid NSW Title Reference. Should be in the format: 1/SP12345, 7/12/6789, 7456-56");
                        return;
                }
                else{
                    titleRefValid[i] = false;

                    this.setState({
                        TitleReferenceValid: titleRefValid
                    },()=>{
                        this.UpdateSecuritySegment();
                    })
                    //alert ("Invalid title reference. Should be in the format: {Volume}/{Folio}{Suffix}");
                    alert ("Invalid NSW Title Reference. Should be in the format: 1/SP12345, 7/12/6789, 7456-56");
                    return;
                }


                // this.setState({
                //     TitleReferenceValid: titleRefValid
                // },()=>{
                //     this.UpdateSecuritySegment();
                // })
            }         

            
        //}

       
        // this.setState({SecuritySegmentTitleReferences:titleReferences},()=>{
        //     this.UpdateSecuritySegment()
        //     //check if needs to be verified
        // });
    }

    GoBackClearForm(i){
        
    }

    UpdateSecuritySegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.SecuritySegmentEntities;
        var dischargeReasonTypeOptions=[];
        // dischargeReasonTypeOptions.push(<option>Select reasoning</option>)
        for(let x=0;x<this.state.subDischargeTypeOptions.length;x++){
            //dischargeReasonTypeOptions.push(<option>{this.state.subDischargeTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
            dischargeReasonTypeOptions.push(<option>{this.state.subDischargeTypeOptions[x]}</option>)
        }
        let mappedDischargeReasonOptions = dischargeReasonTypeOptions.length?dischargeReasonTypeOptions.reduce((p,c)=>[p,'',c]):'';
        if(ents.length>this.state.SecuritySegmentCount){
            //alert('a')
            var names = this.state.PartyRepNames;
            var emails = this.state.PartyRepEmails;
            var numbers = this.state.PartyRepNumbers;
            let mortNames=this.state.IncomingMortgageeNames;
            let mortEmails = this.state.IncomingMortgageeEmails;
            let mortNumbers = this.state.IncomingMortgageeNumbers;
            let types = this.state.IncomingMortgageeType;
            let selfActingChecks = this.state.SelfActing;
            
            let partyRepNames = this.state.PartyRepNames;
            let partyRepEmails=this.state.PartyRepEmails;
            let partyRepNumbers=this.state.PartyRepNumbers;
            let partyAddresses = this.state.SecuritySegmentAddreses;
            let titleRefs = this.state.SecuritySegmentTitleReferences;
            let SecurityQLDAddressesMatched = this.state.SecurityQLDAddressesMatched;
            let SecurityQLDAddressOverridden = this.state.SecurityQLDAddressOverridden;
            let SecurityQLDDetailsVerified = this.state.SecurityQLDDetailsVerified;
            let SecurityQLDDetailsNeedsToBeVerified = this.state.SecurityQLDDetailsNeedsToBeVerified;
            let securityQLDVerifiedAddress = this.state.SecurityQLDVerifiedAddress;
            let verifierLoading = this.state.SecurityVerifierLoading;
            let SecurityQLDNotExist = this.state.SecurityQLDNotExist;
            let securityNSWVerifiedAddress = this.state.SecurityNSWVerifiedAddress;
            let SecurityNSWAddressesMatched = this.state.SecurityNSWAddressesMatched;
            let SecurityNSWAddressOverridden = this.state.SecurityNSWAddressOverridden;
            let SecurityNSWDetailsVerified = this.state.SecurityNSWDetailsVerified;
            let SecurityNSWDetailsNeedsToBeVerified = this.state.SecurityNSWDetailsNeedsToBeVerified;
            let SecurityNSWNotExist = this.state.SecurityNSWNotExist;
            let SecurityNSWVerifiedLandAddress = this.state.SecurityNSWVerifiedLandAddress;
            let titleSearches = this.state.SecurityTitleSearches;
            let securityTitleFiles = this.state.SecurityTitleFiles;
            let securityTitleSearchFilenames = this.state.SecurityTitleSearchFilenames;
            let securityMortgageeExists = this.state.SecurityMortgageeExists;
            let securityMortgageeNames = this.state.SecurityMortgageeNames;
            let securityMortgageeNamesManual=this.state.SecurityMortgageeNamesManual;
            let securityMortgageeCompanyACN = this.state.SecurityMortgageeCompanyACN;
            let securityMortgageNumbers = this.state.SecurityMortgageNumbers;
            let securityMortgageeFormattedTitleRefNo = this.state.SecurityMortgageeFormattedTitleRefNo;
            let securityMortgageeLandDescription = this.state.SecurityMortgageeLandDescription;
            let securityMortgageeLot = this.state.SecurityMortgageeLot;
            let securityMortgageeId = this.state.SecurityMortgageeId;
            let securityMortgageeTitleSearchFiles = this.state.SecurityMortgageeTitleSearchFiles;
            let securityQLDLotNo = this.state.SecurityQLDLotNo;
            let securityQLDPlanNo = this.state.SecurityQLDPlanNo;
            let dischargeReason = this.state.SecurityDischargeReasons;
            let titleRefValid = this.state.TitleReferenceValid;
            let titleRefFormat = this.state.TitleRefFormat;


            for(let i = this.state.SecuritySegmentEntities.length; i>this.state.SecuritySegmentCount && i>this.state.SecuritySegmentMinCount ; i--){
                ents.pop();
                selfActingChecks.pop();
                partyAddresses.pop();
                mortNames.pop();
                mortEmails.pop();
                mortNumbers.pop();
                types.pop();
                names.pop();
                emails.pop();
                numbers.pop();
                titleRefs.pop();
                partyRepNames.pop();
                partyRepEmails.pop();
                partyRepNumbers.pop();
                SecurityQLDAddressesMatched.pop();
                SecurityQLDAddressOverridden.pop();
                SecurityQLDDetailsVerified.pop();
                SecurityQLDDetailsNeedsToBeVerified.pop();
                securityQLDVerifiedAddress.pop();
                verifierLoading.pop();
                SecurityQLDNotExist.pop();
                securityNSWVerifiedAddress.pop();
                SecurityNSWAddressesMatched.pop();
                SecurityNSWAddressOverridden.pop();
                SecurityNSWDetailsVerified.pop();
                SecurityNSWDetailsNeedsToBeVerified.pop();
                SecurityNSWNotExist.pop();
                SecurityNSWVerifiedLandAddress.pop();
                titleSearches.pop();
                securityMortgageeExists.pop();
                securityMortgageeNames.pop();
                securityMortgageeNamesManual.pop();
                securityMortgageeCompanyACN.pop();
                securityMortgageNumbers.pop();
                securityMortgageeFormattedTitleRefNo.pop();
                securityMortgageeLandDescription.pop();
                securityMortgageeLot.pop();
                securityTitleFiles.pop();
                securityTitleSearchFilenames.pop();
                securityMortgageeId.pop();
                securityMortgageeTitleSearchFiles.pop();
                securityQLDLotNo.pop();
                securityQLDPlanNo.pop();
                dischargeReason.pop();
                titleRefValid.pop();
                titleRefFormat.pop();
            }
            this.setState({SecuritySegmentAddreses:partyAddresses,PartyRepNames:names,PartyRepEmails:emails,PartyRepNumbers:numbers,SecuritySegmentTitleReferences:titleRefs,SelfActing:selfActingChecks,
            PartyRepNames:partyRepNames, PartyRepEmails:partyRepEmails, PartyRepNumbers:partyRepNumbers, 
            SecurityQLDAddressesMatched: SecurityQLDAddressesMatched,
            SecurityQLDAddressOverridden: SecurityQLDAddressOverridden,
            SecurityQLDDetailsVerified: SecurityQLDDetailsVerified,
            SecurityQLDDetailsNeedsToBeVerified: SecurityQLDDetailsNeedsToBeVerified,
            SecurityQLDVerifiedAddress: securityQLDVerifiedAddress,
            SecurityVerifierLoading: verifierLoading,
            SecurityQLDNotExist: SecurityQLDNotExist,
            SecurityNSWVerifiedAddress: securityNSWVerifiedAddress,
            SecurityNSWAddressesMatched: SecurityNSWAddressesMatched,
            SecurityNSWAddressOverridden: SecurityNSWAddressOverridden,
            SecurityNSWDetailsVerified: SecurityNSWDetailsVerified,
            SecurityNSWDetailsNeedsToBeVerified: SecurityNSWDetailsNeedsToBeVerified,
            SecurityNSWNotExist: SecurityNSWNotExist,
            SecurityNSWVerifiedLandAddress: SecurityNSWVerifiedLandAddress,
            SecurityTitleSearches: titleSearches,
            SecurityTitleFiles: securityTitleFiles,
            SecurityMortgageeExists: securityMortgageeExists,
            SecurityMortgageeNames: securityMortgageeNames,
            SecurityMortgageeNamesManual: securityMortgageeNamesManual,
            SecurityMortgageeCompanyACN: securityMortgageeCompanyACN,
            SecurityMortgageNumbers: securityMortgageNumbers,
            SecurityMortgageeFormattedTitleRefNo: securityMortgageeFormattedTitleRefNo,
            SecurityMortgageeLandDescription: securityMortgageeLandDescription,
            SecurityMortgageeLot: securityMortgageeLot,
            SecurityTitleSearchFilenames: securityTitleSearchFilenames,
            SecurityMortgageeId: securityMortgageeId,
            SecurityMortgageeTitleSearchFiles: securityMortgageeTitleSearchFiles,
            SecurityQLDLotNo: securityQLDLotNo,
            SecurityQLDPlanNo: securityQLDPlanNo,
            SecurityDischargeReasons:dischargeReason,
            TitleReferenceValid: titleRefValid,
            TitleRefFormat: titleRefFormat
        });
        }else{
            //alert('b');
            var names = this.state.PartyRepNames;
            var emails = this.state.PartyRepEmails;
            var numbers = this.state.PartyRepNumbers;
            let mortNames=this.state.IncomingMortgageeNames;
            let mortEmails = this.state.IncomingMortgageeEmails;
            let mortNumbers = this.state.IncomingMortgageeNumbers;
            let types = this.state.IncomingMortgageeType;
            let selfActingChecks = this.state.SelfActing;
            var dischargeReasonTypeOptions=[];
            let partyRepNames = this.state.PartyRepNames;
            let partyRepEmails=this.state.PartyRepEmails;
            let partyRepNumbers=this.state.PartyRepNumbers;
            let partyAdd = this.state.SecuritySegmentAddreses;
            let tRefs = this.state.SecuritySegmentTitleReferences;
            let dischargeReason = this.state.SecurityDischargeReasons;
            let SecurityQLDAddressesMatched = this.state.SecurityQLDAddressesMatched;
            let SecurityQLDAddressOverridden = this.state.SecurityQLDAddressOverridden;
            let SecurityQLDDetailsVerified = this.state.SecurityQLDDetailsVerified;
            let SecurityQLDDetailsNeedsToBeVerified = this.state.SecurityQLDDetailsNeedsToBeVerified;
            let securityQLDVerifiedAddress = this.state.SecurityQLDVerifiedAddress;
            let verifierLoading = this.state.SecurityVerifierLoading;
            let SecurityQLDNotExist = this.state.SecurityQLDNotExist;
            let securityNSWVerifiedAddress = this.state.SecurityNSWVerifiedAddress;
            let SecurityNSWAddressesMatched = this.state.SecurityNSWAddressesMatched;
            let SecurityNSWAddressOverridden = this.state.SecurityNSWAddressOverridden;
            let SecurityNSWDetailsVerified = this.state.SecurityNSWDetailsVerified;
            let SecurityNSWDetailsNeedsToBeVerified = this.state.SecurityNSWDetailsNeedsToBeVerified;
            let SecurityNSWNotExist = this.state.SecurityNSWNotExist;
            let SecurityNSWVerifiedLandAddress = this.state.SecurityNSWVerifiedLandAddress;
            let titleSearches = this.state.SecurityTitleSearches;
            let securityTitleFiles = this.state.SecurityTitleFiles;
            let securityTitleSearchFilenames = this.state.SecurityTitleSearchFilenames;
            let securityMortgageeExists = this.state.SecurityMortgageeExists;
            let securityMortgageeNames = this.state.SecurityMortgageeNames;
            let securityMortgageeNamesManual = this.state.SecurityMortgageeNamesManual;
            let securityMortgageeCompanyACN = this.state.SecurityMortgageeCompanyACN;
            let securityMortgageNumbers = this.state.SecurityMortgageNumbers;
            let securityMortgageeFormattedTitleRefNo = this.state.SecurityMortgageeFormattedTitleRefNo;
            let securityMortgageeLandDescription = this.state.SecurityMortgageeLandDescription;
            let securityMortgageeLot = this.state.SecurityMortgageeLot;
            let securityMortgageeId = this.state.SecurityMortgageeId;
            let securityMortgageeTitleSearchFiles = this.state.SecurityMortgageeTitleSearchFiles;
            let securityQLDLotNo = this.state.SecurityQLDLotNo;
            let securityQLDPlanNo = this.state.SecurityQLDPlanNo;
            let titleRefValid = this.state.TitleReferenceValid;
            let titleRefFormat = this.state.TitleRefFormat;

            
            for(let i = 0; i<this.state.SecuritySegmentCount; i++){
                let addressEntity = {'lotNo':'','planNo':'','state':'','city':'','number':'','postcode':'','street':''};
                if(partyAdd.length<this.state.SecuritySegmentCount){
                    partyAdd.push(addressEntity);
                    tRefs.push('');
                    //selfActingChecks.push(null);
                    names.push('');
                    emails.push('');
                    numbers.push('');
                    mortNames.push('');
                    mortEmails.push('');
                    mortNumbers.push('');
                    partyRepNames.push('');
                    partyRepEmails.push('');
                    partyRepNumbers.push('');
                    dischargeReason.push('');
                    types.push(0);
                    selfActingChecks.push(false);
                    SecurityQLDAddressesMatched.push(false);
                    SecurityQLDAddressOverridden.push(false);
                    SecurityQLDDetailsVerified.push(false);
                    SecurityQLDDetailsNeedsToBeVerified.push('');
                    securityQLDVerifiedAddress.push('');
                    verifierLoading.push(false);
                    SecurityQLDNotExist.push(false);
                    securityNSWVerifiedAddress.push('');
                    SecurityNSWAddressesMatched.push(false);
                    SecurityNSWAddressOverridden.push(false);
                    SecurityNSWDetailsVerified.push(false);
                    SecurityNSWDetailsNeedsToBeVerified.push('');
                    SecurityNSWNotExist.push(false);
                    SecurityNSWVerifiedLandAddress.push('');
                    titleSearches.push('');
                    securityTitleFiles.push();
                    securityMortgageeExists.push(false);
                    securityMortgageeNames.push('');
                    securityMortgageeNamesManual.push('');
                    securityMortgageeCompanyACN.push('');
                    securityMortgageNumbers.push('');
                    securityMortgageeFormattedTitleRefNo.push('');
                    securityMortgageeLandDescription.push('');
                    securityMortgageeLot.push('');
                    securityTitleFiles.push('');
                    securityMortgageeId.push('');
                    securityMortgageeTitleSearchFiles.push('');
                    securityQLDLotNo.push('');
                    securityQLDPlanNo.push('');
                    titleRefValid.push(true);
                    titleRefFormat.push('');
                }
            }
                this.setState({SecuritySegmentAddreses:partyAdd,PartyRepNames:names,PartyRepEmails:emails,PartyRepNumbers:numbers,SecuritySegmentTitleReferences:tRefs,SelfActing:selfActingChecks,
                    PartyRepNames:partyRepNames, PartyRepEmails:partyRepEmails, PartyRepNumbers:partyRepNumbers, SecurityDischargeReasons:dischargeReason ,
                    SecurityQLDAddressesMatched: SecurityQLDAddressesMatched,
                    SecurityQLDAddressOverridden: SecurityQLDAddressOverridden,
                    SecurityQLDDetailsVerified: SecurityQLDDetailsVerified,
                    SecurityQLDDetailsNeedsToBeVerified: SecurityQLDDetailsNeedsToBeVerified,
                    SecurityQLDVerifiedAddress: securityQLDVerifiedAddress,
                    SecurityVerifierLoading: verifierLoading,
                    SecurityQLDNotExist: SecurityQLDNotExist,
                    SecurityNSWVerifiedAddress: securityNSWVerifiedAddress,
                    SecurityNSWAddressesMatched: SecurityNSWAddressesMatched,
                    SecurityNSWAddressOverridden: SecurityNSWAddressOverridden,
                    SecurityNSWDetailsVerified: SecurityNSWDetailsVerified,
                    SecurityNSWDetailsNeedsToBeVerified: SecurityNSWDetailsNeedsToBeVerified,
                    SecurityNSWNotExist: SecurityNSWNotExist,
                    SecurityNSWVerifiedLandAddress: SecurityNSWVerifiedLandAddress,
                    SecurityTitleFiles: securityTitleFiles,
                    SecurityMortgageeExists: securityMortgageeExists,
                    SecurityMortgageeNames: securityMortgageeNames,
                    SecurityMortgageeNamesManual: securityMortgageeNamesManual,
                    SecurityMortgageeCompanyACN: securityMortgageeCompanyACN,
                    SecurityMortgageNumbers: securityMortgageNumbers,
                    SecurityMortgageeFormattedTitleRefNo: securityMortgageeFormattedTitleRefNo,
                    SecurityMortgageeLandDescription: securityMortgageeLandDescription,
                    SecurityMortgageeLot: securityMortgageeLot,
                    SecurityTitleSearchFilenames: securityTitleSearchFilenames,
                    SecurityMortgageeId: securityMortgageeId,
                    SecurityMortgageeTitleSearchFiles: securityMortgageeTitleSearchFiles,
                    SecurityQLDLotNo: securityQLDLotNo,
                    SecurityQLDPlanNo: securityQLDPlanNo,
                    TitleReferenceValid: titleRefValid,
                    TitleRefFormat: titleRefFormat
                },()=>{
                    for(let i = 0; i<this.state.SecuritySegmentCount; i++){
                ents[i]=((
                <div>
                    <h5 className="listContentTitle">Security {i+1}</h5>
                    {/* <div>
                        {this.state.shouldverifyQLDSecurityTitle || this.state.shouldverifyNSWSecurityTitle ? 
                            <p>
                                Note: To verify security, please fill out Title Ref and State and click on the verify security button
                                <br />
                            </p>
                            : ""
                        }                                    
                    </div> */}
                    <div className="inputBox">
                        <div className="inputLabel">
                            Reason for discharge
                        </div>
                        <div>
                            <select className="form-control" id="DischargeTypeReason" value={this.state.SecurityDischargeReasons[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecurityDischargeReasons; vals[i]=e.target.value;this.setState({SecurityDischargeReasons:vals},()=>{this.SecurityReasonUpdated(e,i)})}}>
                                <option selected>Select reasoning</option>
                                {mappedDischargeReasonOptions}
                            </select>
                        </div>
                    </div>
                    <div className="SecurityAddress" id={"SecurityAddress"+i}>                        
                        <div className="inputBox">
                            <div className="inputLabel">
                                Security State
                            </div>
                            <select className="form-control" id={"StateName"+i} disabled={this.state.InstructionSubmit?true:this.state.SecurityStateReadOnly[i]} value={this.state.SecuritySegmentAddreses[i]['state']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'state')}}>
                                {this.state.MappedStates}
                            </select>                            
                        </div>
                        {
                            this.state.CanUploadTitleSearch && this.state.SecuritySegmentAddreses[i]['state'] !="" && !this.state.SecurityStateReadOnly[i] ? 
                            (
                                <div>
                                    <div className="inputLabel">
                                        Title Search
                                    </div>
                                    <div className="inputLabelSub">
                                        {this.state.SecuritySegmentAddreses[i]['state']=="VIC" ? "Drag and drop any Title Search PDF or TEXT file to upload" : "Drag and drop any Title Search PDF file to upload"}
                                    </div>
                                    <div>
                                        {/* {this.state.SecurityTitleSearches[i] != "" ?  <div className="inputLabel">Uploaded:</div> : ""}
                                        <div className="inputLabel">
                                            {this.state.SecurityTitleSearches[i]}
                                        </div> */}
                                        {this.state.SecurityTitleSearches[i] != "" ?  <div className="inputLabel">Uploaded:</div> : ""}
                                        <div className="inputLabel">
                                            {this.state.SecurityTitleSearches[i]}
                                        </div>
                                    </div>
                                    <div>
                                    {/* <form id="upload-form">
                                        <div className="inputUploadBox">
                                            <div className="iubContent">
                                                <IconBox
                                                    addCSS="iubIcon"
                                                    id=""
                                                    title=""
                                                    iconURL="../../../../images/icons/OutlineGrey/Upload.svg"
                                                />
                                                <Dropzone onDrop={this.handleOnDrop} style={{height:"50px", width:"100%", backgroundColor:"none"}}>                                                
                                                    <div className="titleBox">Drag & drop file here or click to select file to upload.</div>
                                                    <div className="subText">File format: PDF</div>
                                                </Dropzone> 
                                                
                                            </div>
                                           
                                        </div>
                                        </form> */}
                                        <FilePond	 
                                            labelIdle="
                                            <div class='GUContent'>
                                                <div class='GUTextBox'>
                                                    <div classname='GUText1'>Drag & drop file here</div>
                                                    <div id='format'>File format: PDF</div>
                                                </div>
                                                <div id='button'> Browse</div>
                                            </div>" 
                                            ref={ref => (this.pond = ref)}	
                                            files={this.state.SecurityTitleSearches[i]}	
                                            allowMultiple={false}	
                                            oninit={() => this.handleInit()}	
                                            onupdatefiles={fileItems => this.handleItemUpdate(fileItems)}	
                                            //onupdatefiles={fileItems => this.handleItemUpdate(i,fileItems)}	
                                            onClick={click => this.handleOnClick(click)}	
                                            onaddfile={()=>this.btnUploadFiles(i)}
                                        />
                                    </div>
                                    {/* <br />
                                    <ButtonIcon
                                        id="btn"
                                        addCSS="ButtonIconForm"
                                        display='inline-block'
                                        onClick={(e)=>{e.preventDefault();this.IgnoreQLDSecurityTitle(i)}}
                                    >Upload Title Search</ButtonIcon>
                                    <br /><br /> */}
                                    
                                </div>
                            )
                            :''
                        }
                        {this.state.SecuritySegmentAddreses[i]['state'] !="" ? 
                        (<><div className="inputBox">
                        {/* <label for={"SecurityTitleReference"+i}>Title Reference</label> */}
                        <div className="inputLabel">
                            Title Reference
                        </div>
                        <input type="text" id={'SecurityTitleReference'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitleReferences[i]} onChange={(e)=>{e.preventDefault();this.SecurityTitleReferenceUpdated(e,i)}} onBlur={(e)=>{e.preventDefault();if(this.state.SecurityTitleSearches[i]==""){this.CheckTitleReferenceFormat(e,i)}}} placeholder="Enter title reference" autoComplete="hello" required={this.state.IsLenderThinkTank == false ? true : false}/>
                        <div className="inputLabel">
                            <br/>
                            {this.state.TitleRefFormat[i] == "" ? "" : "Example: "  + this.state.TitleRefFormat[i]}
                        </div>
                    </div>
                    <div>
                    {
                        //this.state.lenderName == "RACQ Bank" ?
                        this.state.LenderMortgagees.length > 1 ?                        
                        <div>
                            {
                                //this.state.CanUploadTitleSearch && this.state.SecuritySegmentAddreses[i]['state'] !="" && this.state.SecurityTitleSearches[i]=="" && this.state.SecurityMortgageeNames[i]!=""? 
                                this.state.SecuritySegmentAddreses[i]['state'] !="" && this.state.SecurityTitleSearches[i]=="" ? 
                                this.state.SecurityMortgageeNames[i]!="" ?
                                <div>
                                <h6>Mortgagee Details</h6>
                                {!this.state.SecurityMortgageeExists[i] && this.state.SecurityMortgageeNames[i]!=""?                                
                                    <p style={{color:"red",fontSize:"12px"}}>Warning: Mortgagee does not exist</p>                                
                                :
                                ''
                                }
                                <div className="inputBox">
                                    <div className="inputLabel">
                                        Mortgagee
                                    </div>
                                    <input type="text" id={'SecurityMortgageeName'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeNames[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeNameUpdated(e,i)}} autoComplete="hello" placeholder="Mortgagee Name" readOnly/>
                                </div>
                                {/* <div className="form-group">
                                    <label for={"IncomingMortgageeEmail"+i}>Email</label>
                                    <input type="text" id={'IncomingMortgageeEmail'+i}  className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.IncomingMortgageeEmails[i]} onChange={(e)=>{e.preventDefault();this.IncomingMortgageeEmailUpdated(e,i)}} autoComplete="hello" placeholder="" />
                                    <div className='invalid-feedback'>
                                        Please provide a valid email address
                                    </div>
                                </div> */}
                                {/* <div className="inputBox">
                                    <div className="inputLabel">
                                        Company ACN
                                    </div>
                                    <input type="text" id={'SecurityMortgageeCompanyACN'+i}  className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeCompanyACN[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeCompanyACNUpdated(e,i)}} autoComplete="hello" placeholder="Company ACN" readOnly/>
                                </div> */}
                                <div className="inputBox">
                                    <div className="inputLabel">
                                        Mortgage Number
                                    </div>
                                    <input type="text" id={'SecurityMortgageeNumber'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageNumbers[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageNumberUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Number" readOnly/>
                                </div>
                                <div className="inputBox">
                                    <div className="inputLabel">
                                        Formatted Title Ref No
                                    </div>
                                    <input type="text" id={'SecurityMortgageeFormattedTitleRefNo'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeFormattedTitleRefNo[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeFormattedTitleRefNoUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Fortmatted Title Ref" readOnly/>
                                </div>                                            
                                {/* <div className="inputBox">
                                    <div className="inputLabel">
                                        Volume
                                    </div>
                                    <input type="text" id={'SecurityMortgageeVolume'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeVolume[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeVolumeUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Volumne" readOnly/>
                                </div>
                                <div className="inputBox">
                                    <div className="inputLabel">
                                        Folio
                                    </div>
                                    <input type="text" id={'SecurityMortgageeFolio'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeFolio[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeFolioUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Folio" readOnly/>
                                </div>
                                <div className="inputBox">
                                    <div className="inputLabel">
                                        Block
                                    </div>
                                    <input type="text" id={'SecurityMortgageeBlock'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeBlock[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeBlockUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Block" readOnly/>
                                </div>
                                <div className="inputBox">
                                    <div className="inputLabel">
                                        District/Division
                                    </div>
                                    <input type="text" id={'SecurityMortgageeDistrictDivision'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeDistrictDivision[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeDistrictDivisionUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage District/Division" readOnly/>
                                </div> */}
                                <div className="inputBox">
                                    <div className="inputLabel">
                                        Land Description
                                    </div>
                                    <input type="text" id={'SecurityMortgageeLandDescription'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeLandDescription[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeLandDescriptionUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Land Description" readOnly/>
                                </div>
                                {/* <div className="inputBox">
                                    <div className="inputLabel">
                                        Section
                                    </div>
                                    <input type="text" id={'SecurityMortgageeSection'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeSection[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeSectionUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Section" readOnly/>
                                </div> */}
                                {/* <div className="inputBox">
                                    <div className="inputLabel">
                                        Unit
                                    </div>
                                    <input type="text" id={'SecurityMortgageeUnit'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeUnit[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeUnitUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Unit" readOnly/>
                                </div> */}
                                <div className="inputBox">
                                    <div className="inputLabel">
                                        Lot Number
                                    </div>
                                    <input type="text" id={'SecurityMortgageeLot'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeLot[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeLotUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Lot" readOnly/>
                                </div>
                                {/* <div className="inputBox">
                                    <div className="inputLabel">
                                        Town
                                    </div>
                                    <input type="text" id={'SecurityMortgageeTown'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeTown[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeTownUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Town" readOnly/>
                                </div> */}
                                
                            
                            </div>
                            
                                : 
                                <>
                                    <h6>Mortgagee Details</h6>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Mortgagee
                                        </div>
                                        <div>
                                            {/* <input type="text" id={'SecurityMortgageeName'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeNames[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeNameUpdated(e,i)}} autoComplete="hello" placeholder="Mortgagee Name" readOnly/> */}
                                            <select className="form-control" id={'SecurityMortgageeNamesManual'+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeNamesManual[i]} onChange={(e)=>{let vals = this.state.SecurityMortgageeNamesManual; vals[i]=e.target.value;this.setState({SecurityMortgageeNamesManual:vals},()=>{this.UpdateSecuritySegment(e,i)})}}>
                                            {/* <select className="form-control" id={'SecurityMortgageeName'+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeNames[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeNameUpdated(e,i)}}> */}
                                                <option selected>Select Mortgagee</option>
                                                {this.state.MappedLenderMortgagees}
                                            </select>
                                        </div>
                                    </div>
                                </>
                                // <div style={{padding:'30px 0px', backgroundColor:'yellow'}}>
                            
                                :''
                            }
                            </div>
                        :
                        this.state.CanUploadTitleSearch && this.state.SecuritySegmentAddreses[i]['state'] !="" && this.state.SecurityTitleSearches[i]=="" && this.state.SecurityMortgageeNames[i]!=""? 
                        // <div style={{padding:'30px 0px', backgroundColor:'yellow'}}>
                        <div>
                            <h6>Mortgagee Details</h6>
                            {!this.state.SecurityMortgageeExists[i] && this.state.SecurityMortgageeNames[i]!=""?                                
                                <p style={{color:"red",fontSize:"12px"}}>Warning: Mortgagee does not exist</p>                                
                            :
                            ''
                            }
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Mortgagee
                                </div>
                                <input type="text" id={'SecurityMortgageeName'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeNames[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeNameUpdated(e,i)}} autoComplete="hello" placeholder="Mortgagee Name" readOnly/>
                            </div>
                            {/* <div className="form-group">
                                <label for={"IncomingMortgageeEmail"+i}>Email</label>
                                <input type="text" id={'IncomingMortgageeEmail'+i}  className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.IncomingMortgageeEmails[i]} onChange={(e)=>{e.preventDefault();this.IncomingMortgageeEmailUpdated(e,i)}} autoComplete="hello" placeholder="" />
                                <div className='invalid-feedback'>
                                    Please provide a valid email address
                                </div>
                            </div> */}
                            {/* <div className="inputBox">
                                <div className="inputLabel">
                                    Company ACN
                                </div>
                                <input type="text" id={'SecurityMortgageeCompanyACN'+i}  className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeCompanyACN[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeCompanyACNUpdated(e,i)}} autoComplete="hello" placeholder="Company ACN" readOnly/>
                            </div> */}
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Mortgage Number
                                </div>
                                <input type="text" id={'SecurityMortgageeNumber'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageNumbers[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageNumberUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Number" readOnly/>
                            </div>
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Formatted Title Ref No
                                </div>
                                <input type="text" id={'SecurityMortgageeFormattedTitleRefNo'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeFormattedTitleRefNo[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeFormattedTitleRefNoUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Fortmatted Title Ref" readOnly/>
                            </div>                                            
                            {/* <div className="inputBox">
                                <div className="inputLabel">
                                    Volume
                                </div>
                                <input type="text" id={'SecurityMortgageeVolume'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeVolume[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeVolumeUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Volumne" readOnly/>
                            </div>
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Folio
                                </div>
                                <input type="text" id={'SecurityMortgageeFolio'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeFolio[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeFolioUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Folio" readOnly/>
                            </div>
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Block
                                </div>
                                <input type="text" id={'SecurityMortgageeBlock'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeBlock[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeBlockUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Block" readOnly/>
                            </div>
                            <div className="inputBox">
                                <div className="inputLabel">
                                    District/Division
                                </div>
                                <input type="text" id={'SecurityMortgageeDistrictDivision'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeDistrictDivision[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeDistrictDivisionUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage District/Division" readOnly/>
                            </div> */}
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Land Description
                                </div>
                                <input type="text" id={'SecurityMortgageeLandDescription'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeLandDescription[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeLandDescriptionUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Land Description" readOnly/>
                            </div>
                            {/* <div className="inputBox">
                                <div className="inputLabel">
                                    Section
                                </div>
                                <input type="text" id={'SecurityMortgageeSection'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeSection[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeSectionUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Section" readOnly/>
                            </div> */}
                            {/* <div className="inputBox">
                                <div className="inputLabel">
                                    Unit
                                </div>
                                <input type="text" id={'SecurityMortgageeUnit'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeUnit[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeUnitUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Unit" readOnly/>
                            </div> */}
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Lot Number
                                </div>
                                <input type="text" id={'SecurityMortgageeLot'+i} className="form-control-disabled" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeLot[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeLotUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Lot" readOnly/>
                            </div>
                            {/* <div className="inputBox">
                                <div className="inputLabel">
                                    Town
                                </div>
                                <input type="text" id={'SecurityMortgageeTown'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityMortgageeTown[i]} onChange={(e)=>{e.preventDefault();this.SecurityMortgageeTownUpdated(e,i)}} autoComplete="hello" placeholder="Mortgage Town" readOnly/>
                            </div> */}
                            
                        
                        </div>
                        
                        :''
                    }
                    </div>
                    <div className="inputBox">
                        <div className="multiRowsInputBox">
                        
                            {/* {this.state.SecuritySegmentAddreses[i]['state'] == "QLD" &&  this.state.SecuritySegmentTitleReferences[i] !=""  && this.state.SecuritySegmentAddreses[i]['lotNo'] != ""? */}
                            {this.state.SecuritySegmentAddreses[i]['state'] == "QLD" &&  this.state.SecuritySegmentTitleReferences[i] !=""  && this.state.SecurityQLDLotNo[i] != ""?
                                // <input type="text" id={'LotNo'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['lotNo']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'lotNo')}} placeholder="Lot Number" autoComplete="hello" required/>
                                <>
                                    <div className="inputLabel">
                                        Verified Lot No
                                    </div>
                                    {/* <input type="text" id={'LotNo'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['lotNo']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'lotNo')}} placeholder="Lot Number" autoComplete="hello" required/> */}
                                    <input type="text" id={'LotNo'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityQLDLotNo[i]} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i)}} placeholder="Lot Number" autoComplete="hello" required/>
                                </>
                                
                            : 
                            ''
                            }
                            {/* {this.state.SecuritySegmentAddreses[i]['state'] == "QLD" &&  this.state.SecuritySegmentTitleReferences[i] !=""  && this.state.SecuritySegmentAddreses[i]['planNo'] != ""? */}
                            {this.state.SecuritySegmentAddreses[i]['state'] == "QLD" &&  this.state.SecuritySegmentTitleReferences[i] !=""  && this.state.SecurityQLDPlanNo[i] != ""?
                                // <input type="text" id={'PlanNo'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['planNo']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'planNo')}} placeholder="Plan Number" autoComplete="hello" required/>
                                <>
                                    <div className="inputLabel">
                                        Verified Plan Number
                                    </div>
                                    {/* <input type="text" id={'PlanNo'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['planNo']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'planNo')}} placeholder="Plan Number" autoComplete="hello" required/> */}
                                    <input type="text" id={'PlanNo'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecurityQLDPlanNo[i]} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i)}} placeholder="Plan Number" autoComplete="hello" required/>
                                </>
                                
                            : 
                            ''
                            }                           
                            <div className="inputLabel">
                                Address
                            </div>
                        
                            <input type="text" id={'SecurityStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['street']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'street')}} placeholder="Street" autoComplete="hello" required/>
                            
                            <input type="text" id={'SecurityCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['city']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'city')}} placeholder="Suburb" autoComplete="hello" />
                        
                            <div className="multiColsInputBox">
                                <select className="form-control" id={"StateName"+i} disabled={this.state.InstructionSubmit?true:this.state.SecurityStateReadOnly[i]} value={this.state.SecuritySegmentAddreses[i]['state']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'state')}}>
                                    {this.state.MappedStates}
                                </select>
                                <input type="text" id={'SecurityPostcode'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" maxLength="4" value={this.state.SecuritySegmentAddreses[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.SecurityAddressUpdated(e,i,'postcode')}}} placeholder="Postcode" autoComplete="hello" required/>
                            </div>
                        </div>
                    </div></>)
                        : '' }
                        

                        {/* QLD Security Titles */}
                        {/* {this.state.shouldverifyQLDSecurityTitle && this.state.SecuritySegmentAddreses[i]['state'] == "QLD" &&  this.state.SecuritySegmentTitleReferences[i] !="" && this.state.SecurityQLDDetailsNeedsToBeVerified[i] == false && this.state.SecurityQLDDetailsVerified[i] == false? */}
                        {this.state.shouldverifyQLDSecurityTitle ?
                        (
                            this.state.SecuritySegmentAddreses[i]['state'] == "QLD" &&  this.state.SecuritySegmentTitleReferences[i] !="" && this.state.SecurityQLDDetailsVerified[i] == false && this.state.SecurityQLDDetailsNeedsToBeVerified[i] != true ?
                            
                                this.state.SecurityVerifierLoading[i] ?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div>
                                    <ButtonIcon
                                        id="btn"
                                        addCSS="ButtonIconForm"
                                        display='inline-block'
                                        //display={this.state.isLoading?'none': 'inline-block'}
                                        onClick={(e)=>{e.preventDefault();this.verifyQLDSecurityTitle(i,this.state.SecuritySegmentTitleReferences[i], this.state.SecuritySegmentAddreses[i]['street'], this.state.SecuritySegmentAddreses[i]['city'], this.state.SecuritySegmentAddreses[i]['state'], this.state.SecuritySegmentAddreses[i]['postcode'])}}
                                    >Verify QLD Security</ButtonIcon>
                                    <br />
                                    <br />
                                    
                                </div>
                                // :
                                // (<div><ProgressDisplay></ProgressDisplay>
                                // {this.state.SecurityVerifierLoading[i].toString()} </div>)
                            
                            :                            
                            (
                                //!this.state.SecurityQLDDetailsVerified ?
                                // this.state.SecurityVerifierLoading[i] ?                               
                                //     <ProgressDisplay></ProgressDisplay>
                                // :
                                this.state.SecurityQLDNotExist[i] ?
                                <div className="inputLabel">
                                    <p style={{color:"red"}}>Warning: Security not found. Please double check your title reference.</p>                                
                                </div>
                                :
                                    this.state.SecurityQLDAddressesMatched[i] ? 
                                    <div className="inputLabel">
                                        {/* Security details matches */}
                                        <p style={{color:"green"}}>Security details verified.</p>     
                                    </div>
                                    :
                                    (
                                        this.state.SecurityQLDDetailsNeedsToBeVerified[i] && !this.state.SecurityQLDAddressesMatched[i] && !this.state.SecurityQLDDetailsVerified[i] ?
                                        (<div className="inputLabel">
                                            Record does not match to your input.< br/>
                                            The record shows the following:< br/>
                                            {this.state.SecurityQLDVerifiedAddress[i]} <br />
                                            Click the override button to update <br /><br />
                                            <ButtonIcon
                                                id="btn"
                                                addCSS="ButtonIconFormOrange"
                                                display='inline-block'
                                                onClick={(e)=>{e.preventDefault();this.OverrideQLDSecurityTitle(i,this.state.SecurityQLDVerifiedAddress[i])}}
                                            >Override</ButtonIcon>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <ButtonIcon
                                                id="btn"
                                                addCSS="ButtonIconForm"
                                                display='inline-block'
                                                onClick={(e)=>{e.preventDefault();this.IgnoreQLDSecurityTitle(i)}}
                                            >Ignore</ButtonIcon>
                                        </div>)
                                        :''
                                    )
                            )
                            
                        )
                        : 
                        ''
                        }

                        {/* NSW Security Titles */}
                        {this.state.shouldverifyNSWSecurityTitle ?
                        (
                            this.state.SecuritySegmentAddreses[i]['state'] == "NSW" &&  this.state.SecuritySegmentTitleReferences[i] !="" && this.state.SecurityNSWDetailsVerified[i] == false && this.state.SecurityNSWDetailsNeedsToBeVerified[i] != true ?
                            
                                this.state.SecurityVerifierLoading[i] ?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div>
                                    <ButtonIcon
                                        id="btn"
                                        addCSS="ButtonIconForm"
                                        display='inline-block'
                                        //display={this.state.isLoading?'none': 'inline-block'}
                                        onClick={(e)=>{e.preventDefault();this.verifyNSWSecurityTitle(i,this.state.SecuritySegmentTitleReferences[i], this.state.SecuritySegmentAddreses[i]['street'], this.state.SecuritySegmentAddreses[i]['city'], this.state.SecuritySegmentAddreses[i]['state'], this.state.SecuritySegmentAddreses[i]['postcode'])}}
                                    >Verify NSW Security</ButtonIcon>
                                    <br />
                                    <br />
                                    
                                </div>
                                // :
                                // (<div><ProgressDisplay></ProgressDisplay>
                                // {this.state.SecurityVerifierLoading[i].toString()} </div>)
                            
                            :                            
                            (
                                //!this.state.SecurityQLDDetailsVerified ?
                                // this.state.SecurityVerifierLoading[i] ?                               
                                //     <ProgressDisplay></ProgressDisplay>
                                // :
                                this.state.SecurityNSWNotExist[i] ?
                                <div className="inputLabel">
                                    <p style={{color:"red"}}>Warning: Security not found. Please double check your title reference.</p>                                
                                </div>
                                :
                                    this.state.SecurityNSWAddressesMatched[i] ? 
                                    <div className="inputLabel">
                                        {/* Security details matches */}
                                        <p style={{color:"green"}}>Security details verified.</p>     
                                    </div>
                                    :
                                    (
                                        this.state.SecurityNSWDetailsNeedsToBeVerified[i] && !this.state.SecurityNSWAddressesMatched[i] && !this.state.SecurityNSWDetailsVerified[i] ?
                                        (<div className="inputLabel">
                                            Record does not match to your input.< br/>
                                            The record shows the following:< br/>
                                            {this.state.SecurityNSWVerifiedAddress[i]} <br />
                                            Click the override button to update <br /><br />
                                            <ButtonIcon
                                                id="btn"
                                                addCSS="ButtonIconFormOrange"
                                                display='inline-block'
                                                //onClick={(e)=>{e.preventDefault();this.OverrideNSWSecurityTitle(i,this.state.SecurityNSWVerifiedAddress[i])}}
                                                onClick={(e)=>{e.preventDefault();this.OverrideNSWSecurityTitle(i,this.state.SecurityNSWVerifiedLandAddress[i])}}
                                            >Override</ButtonIcon>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <ButtonIcon
                                                id="btn"
                                                addCSS="ButtonIconForm"
                                                display='inline-block'
                                                onClick={(e)=>{e.preventDefault();this.IgnoreNSWSecurityTitle(i)}}
                                            >Ignore</ButtonIcon>
                                        </div>)
                                        :''
                                    )
                            )
                            
                        )
                        : 
                        ''
                        }

                        {/* VIC Security Titles */}
                        {   
                            this.state.SecurityVICNotExist[i] ?
                            <div className="inputLabel">
                                <p style={{color:"red"}}>Warning: Security not found. Please double check your data.</p>                                
                            </div>
                            :
                                this.state.SecurityVICAddressesMatched[i] ? 
                                <div className="inputLabel">
                                    {/* Security details matches */}
                                    <p style={{color:"green"}}>Security details verified.  </p>     
                                </div>
                                :
                                (
                                    this.state.SecurityVICDetailsNeedsToBeVerified[i] && !this.state.SecurityVICAddressesMatched[i] && !this.state.SecurityVICDetailsVerified[i] ?
                                    (<div className="inputLabel">
                                        Record does not match to your input.< br/>
                                        The record shows the following:< br/>
                                        {this.state.SecurityVICVerifiedAddress[i]} <br />    
                                        Your input has been overwritten.                                  
                                    </div>)
                                    :''
                                )
                        }
                        
                        <div>
                            <ButtonIcon
                                id="btn"
                                addCSS="ButtonIconForm"
                                display='inline-block'
                                onClick={(e)=>{e.preventDefault();this.ResetSecurityDetails(i)}}
                            >Reset</ButtonIcon>
                            <br />
                            <br />
                        </div>
                        
                        {/* <div className="form-check">
                            <input className="form-check-input" type="checkbox" readOnly={this.state.InstructionSubmit?true:false} name="exampleRadios" id={"securityselfacting" + i} checked={this.state.SelfActing[i]}  onChange={(e)=>{let vals = this.state.SelfActing;vals[i]=!this.state.SelfActing[i];this.setState({SelfActing:vals},()=>{this.UpdateSecuritySegment()})}}/>
                            <label className="form-check-label" for="exampleRadios1">
                                Self Acting
                            </label>
                        </div> */}                        
                        {this.state.SecuritySegmentAddreses[i]['state'] !="" ? 
                        (
                            <>
                            <div className="inputBox">
                            <div className="inputLabel">
                                Is this security self-acting?
                            </div>
                            <div className="multiColsInputBoxContentWidth" name="exampleRadios" id={"securityselfacting"+i} value={this.state.SelfActing[i]} onChange={
                                (e)=>{
                                    let vals = this.state.SelfActing; 
                                    vals[i]=this.stringToBool(e.target.value);
                                    
                                    // let dischargeReason = this.state.SecurityDischargeReasons;
                                    // if(vals[i] == true){
                                    //     //alert('hey')
                                    //     dischargeReason[i] = "Debt Repaid";
                                    // }
                                    // else{
                                    //     //alert('hi')
                                    //     dischargeReason[i] = "";
                                    // }
                                    
                                    this.setState({
                                        SelfActing:vals,
                                        //SecurityDischargeReasons:dischargeReason
                                    },()=>{
                                    this.UpdateSecuritySegment()
                                    console.log(this.state.SelfActing[i])
                                })}}>    
                                <RadioButton value="Yes" name={"exampleRadios"+i} title="Yes" checked={this.state.SelfActing[i]===true} />                              
                                <RadioButton value="No" name={"exampleRadios"+i} title="No" checked={this.state.SelfActing[i]===false} />
                            </div>

                            {/* <div className="multiColsInputBoxContentWidth" id={"securityselfacting"+i} value={this.state.SelfActing[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SelfActing; vals[i]=e.target.value;this.setState({SelfActing:vals},()=>{this.UpdateSecuritySegment()})}}>    
                                <RadioButton value="Yes" name={"securityselfacting"+i} title="Yes" checked={this.state.SelfActing[i]===true}/>                              
                                <RadioButton value="No" name={"securityselfacting"+i} title="No" checked={this.state.SelfActing[i]===false}/>
                            </div> */}
                        </div>

                        {/* <div id={"PartyName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Party Type
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Borrower" name={"PartyType"+i} title="Borrower" checked={this.state.PartySegmentPartyTypes[i]==='Borrower'}/>                              
                                                <RadioButton value="Guarantor" name={"PartyType"+i} title="Guarantor" checked={this.state.PartySegmentPartyTypes[i]==='Guarantor'}/>
                                            </div>
                                        </div> 
                        </div> */}
                        
                        {/* <div style={{display:this.state.SelfActing[i]==='No'?'block':this.state.SelfActing[i]==='Yes'?'none':'none'}}> */}
                        {this.state.SelfActing[i]?'': (<div className="inputBox">
                                            <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>
                        {/* <div style={{display:!this.state.SelfActing[i]?'block':'none'}}> */}
                            <h6>Party representative details</h6>
                            <div className="inputBox">
                                <div className="inputLabel">
                                Full Name
                                </div>
                                <input type="text" id={'PartyRepName'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartyRepNames[i]} onChange={(e)=>{e.preventDefault();this.PartyRepNameUpdated(e,i)}} placeholder="Full Name" autoComplete="hello" required />
                            </div>
                            <div className="inputBox">
                                <div className="inputLabel">
                                Email
                                </div>
                                <input type="email" id={'PartyRepEmail'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartyRepEmails[i]} onChange={(e)=>{e.preventDefault();this.PartyRepEmailUpdated(e,i)}} placeholder="example@email.com" autoComplete="hello" required={this.state.IsLenderThinkTank == true ? false : true} />
                            </div>
                            <div className="inputBox">
                                <div className="inputLabel">
                                Phone Number
                                </div>
                                <input type="text" id={'PartyRepNumber'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartyRepNumbers[i]} onChange={(e)=>{e.preventDefault();this.PartyRepNumberUpdated(e,i)}} placeholder="Phone Number" autoComplete="hello" required={this.state.IsLenderThinkTank == true ? false : true}/>
                            </div>
                        </div></div>)}
                            </>
                        )
                        
                        :''}
                        
                            
                        {this.state.SecurityDischargeReasons[i]==='External Refinance'? <div style={{padding:'30px 0px'}}>
                            <h5>Incoming Mortgagee</h5>
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Full Name
                                </div>
                                <input type="text" id={'IncomingMortgageeName'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.IncomingMortgageeNames[i]} onChange={(e)=>{e.preventDefault();this.IncomingMortgageeNameUpdated(e,i)}} autoComplete="hello" placeholder="Full name" required={this.state.SecurityDischargeReasons[i]==='External Refinance'? true : false} />
                            </div>
                            {/* <div className="form-group">
                                <label for={"IncomingMortgageeEmail"+i}>Email</label>
                                <input type="text" id={'IncomingMortgageeEmail'+i}  className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.IncomingMortgageeEmails[i]} onChange={(e)=>{e.preventDefault();this.IncomingMortgageeEmailUpdated(e,i)}} autoComplete="hello" placeholder="" />
                                <div className='invalid-feedback'>
                                    Please provide a valid email address
                                </div>
                            </div> */}
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Email
                                </div>
                                <input type="text" id={'IncomingMortgageeEmail'+i}  className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.IncomingMortgageeEmails[i]} onChange={(e)=>{e.preventDefault();this.IncomingMortgageeEmailUpdated(e,i)}} autoComplete="hello" placeholder="example@email.com" />
                            </div>
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Fax Number
                                </div>
                                <input type="text" id={'IncomingMortgageeNumber'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.IncomingMortgageeNumbers[i]} onChange={(e)=>{e.preventDefault();this.IncomingMortgageeNumberUpdated(e,i)}} autoComplete="hello" placeholder="Fax Number" />
                            </div>
                        </div>
                        :''}
                    </div>
                    
                </div>));
            
            }
            // this.setState({SecuritySegmentEntities:ents,SecuritySegmentAddreses:partyAdd,SecuritySegmentTitleReferences:titleRefs,PartyRepNames:names,PartyRepEmails:emails,PartyRepNumbers:numbers,SelfActing:selfActingChecks,PartyRepNames:partyRepNames, PartyRepEmails:partyRepEmails, PartyRepNumbers:partyRepNumbers});
            this.setState({SecuritySegmentEntities:ents});
        });
        }
        
    }


    SimultaneousSettlementSegmentLoanIdUpdated(e,i){
        e.preventDefault();
        this.checkIfSessionHasExpired();
        let loanIds = this.state.SimultaneousSettlementSegmentLoanIds;
        loanIds[i]=e.target.value;
        this.setState({SimultaneousSettlementSegmentLoanIds:loanIds},()=>{this.UpdateSimultaneuosSettlementSegment()});

    }
    UpdateSimultaneuosSettlementSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.SimultaneousSettlementSegmentEntities;
        let loans = this.state.SimultaneousSettlementSegmentLoanIds;
        if(this.state.SimultaneousSettlementSegmentEntities.length>this.state.SimultaneousSettlementSegmentCount){
            for(let i = this.state.SimultaneousSettlementSegmentEntities.length;i>this.state.SimultaneousSettlementSegmentCount && i>0;i--){
                ents.pop();
                loans.pop();
                
            }
            this.setState({SimultaneousSettlementSegmentEntities:ents,SimultaneousSettlementSegmentLoanIds:loans});
        }else{
            for(let i = 0;i<this.state.SimultaneousSettlementSegmentCount;i++){
                if(loans.length<this.state.SimultaneousSettlementSegmentCount){
                    loans.push(null);
                }
                ents[i]=((
                    <div>
                        <div className="SimultaneousSettlement" id={"SimultaneousSettlement"+i}>
                            <div className="inputBox">
                                <div className="inputLabel">
                                Loan Id {i+1}
                                </div>
                                <input type="text" id={'SimulataneousSettlementLoanId'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" value={this.state.SimultaneousSettlementSegmentLoanIds[i]} onChange={(e)=>{e.preventDefault();this.SimultaneousSettlementSegmentLoanIdUpdated(e,i)}} placeholder="" autoComplete="hello" required/>
                            </div>
                        </div>
                    </div>
                ));
                
            }
            this.setState({SimultaneousSettlementSegmentEntities:ents,SimultaneousSettlementSegmentLoanIds:loans});
        }
    }

    UpdateGuarantorTitleDeedToAddress(e,i,key){
        e.preventDefault();
        let address = this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress;
        let oPrev = this.state.BorrowerClearTitleToAddress;
        address[i][key]=e.target.value;
        this.setState({ImportantNoteSegmentGuarantorTitleDeedToAddress:address,BorrowerClearTitleToAddress:oPrev},()=>{this.UpdateImportNotesSegment()});

    }

    UpdateBorrowerClearTitleToAddress(e,i,key){
        e.preventDefault();
        let address = this.state.BorrowerClearTitleToAddress;
        let oPrev = this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress;
        
        address[i][key]=e.target.value;
        this.setState({ImportantNoteSegmentGuarantorTitleDeedToAddress:oPrev,BorrowerClearTitleToAddress:address},()=>{this.UpdateImportNotesSegment()});

    }

    SettlementNotToBeBookedPriorToUpdated(e,i){
        e.preventDefault();
        let vals = this.state.ImportantNoteSegmentSettlementNotToBeBookedPriorToDates;
        vals[i]=e.target.value;
        this.setState({ImportantNoteSegmentSettlementNotToBeBookedPriorToDates:vals},()=>{this.UpdateImportNotesSegment()});
    }

    CollectSurplusUpdated(e,i){
        e.preventDefault();
    }

    GuarantorUpdated(e,i){
        e.preventDefault();
        let vals = this.state.ImportantNoteSegmentGuarantorNames;
        vals[i]=e.target.value;
        this.setState({ImportantNoteSegmentGuarantorNames:vals},()=>{this.UpdateImportNotesSegment()});
    }

    UpdateImportNotesSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.ImportantNoteSegmentEntities;
        if(this.state.ImportantNoteSegmentEntities.length>this.state.ImportantNoteSegmentCount){
            let borrPartyAdd = this.state.BorrowerClearTitleToAddress;
            let guarPartyAdd = this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress;
            for(let i = this.state.ImportantNoteSegmentEntities.length;i>this.state.ImportantNoteSegmentCount && i>this.state.ImportantNoteSegmentMinCount; i--){
                ents.pop();
                borrPartyAdd.pop();
                guarPartyAdd.pop();
            }
            this.setState({ImportantNoteSegmentGuarantorTitleDeedToAddress:guarPartyAdd,BorrowerClearTitleToAddress:borrPartyAdd},()=>{});
            this.setState({ImportantNoteSegmentEntities:ents});
        }else{
            let borrPartyAdd = this.state.BorrowerClearTitleToAddress;
            let guarPartyAdd = this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress;
            for(let i  =0; i<this.state.ImportantNoteSegmentCount;i++){
                let addressEntity = {'state':'','city':'','number':'','postcode':''};
                let ent2 = {'state':'','city':'','number':'','postcode':'','street':''};
                if(borrPartyAdd.length<this.state.ImportantNoteSegmentCount){   
                    borrPartyAdd.push(ent2);
                    guarPartyAdd.push(addressEntity);
                }

                ents[i]=((
                <div>
                    <div className="inputBox">
                        <div className="inputLabel">
                        Do you want to mail the Guarantor Title Deed?
                        </div>
                        <div className="multiColsInputBoxContentWidth" name="provideGuarAddDetails" id="provideGuarAddDetails" value={this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{this.checkIfSessionHasExpired();this.setState({HasImportantNoteSegmentGuarantorTitleDeedToAddress:this.stringToBool(e.target.value)},
                            ()=>{
                                ;document.getElementById('GuarantorTitleDeedToSegment').style.display=(this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress?'block':'none');
                            })}}>
                            {/* <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{ alert('hi');let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdatePartySegment()})}}>     */}
                                <RadioButton value="Yes" name="provideGuarAddDetails" title="Yes"/>                              
                                <RadioButton value="No" name="provideGuarAddDetails" title="No"/>
                            {/* </div>  */}
                        </div>
                    </div>
                    <div id="GuarantorTitleDeedToSegment" style={{display:'none'}}>
                        <div className="GuarantorClearTitleToAdd" id={"GuarantorClearTitleToAdd"+i}>
                            <div className="inputBox">
                                <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Street
                                        </div>
                                        <div> 
                                        <input type="text" id={'GuarantorClearTitleToStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false}  value={this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[i]['street']} onChange={(e)=>{e.preventDefault();this.UpdateGuarantorTitleDeedToAddress(e,i,'street')}} placeholder="Street" autoComplete="hello" required={this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress?true:false}/>
                                        </div>
                                    </div>  
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            City
                                        </div>
                                        <div> 
                                        <input type="text" id={'SecurityCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[i]['city']} onChange={(e)=>{e.preventDefault();this.UpdateGuarantorTitleDeedToAddress(e,i,'city')}} autoComplete="hello" placeholder="City" />
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            State
                                        </div>
                                        <div> 
                                            <select className="form-control" id={"GuarantorClearTitleToState"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[i]['state']} onChange={(e)=>{e.preventDefault();this.UpdateGuarantorTitleDeedToAddress(e,i,'state')}}>
                                                {this.state.MappedStates}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Postcode
                                        </div>
                                        <div> 
                                        <input type="text" id={'GuarantorClearTitleToPostCode'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" value={this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.UpdateGuarantorTitleDeedToAddress(e,i,'postcode')}}} autoComplete="hello" placeholder="Postcode" required={this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress?true:false}/>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Do you want to mail the Borrower Title Deed?
                        </div>                        
                        <div className="multiColsInputBoxContentWidth" name="hasBorrowerClearTitleTo" id="hasBorrowerClearTitleTo" value={this.state.HasBorrowerClearTitleToAddress} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{this.setState({HasBorrowerClearTitleToAddress:this.stringToBool(e.target.value)},
                            ()=>{
                                ;document.getElementById('BorrowerClearTitleToSegment').style.display=(this.state.HasBorrowerClearTitleToAddress?'block':'none');
                            })}}>
                            <RadioButton value="Yes" name="hasBorrowerClearTitleTo" title="Yes"/>                              
                            <RadioButton value="No" name="hasBorrowerClearTitleTo" title="No"/>
                        </div>
                    </div>
                    <div id="BorrowerClearTitleToSegment" style={{display:'none'}}>
                        <div className="BorrowerClearTitleToAdd" id={"BorrowerClearTitleToAdd"+i}>
                            <div className="inputBox">
                                <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Street
                                        </div>
                                        <div> 
                                        <input type="text" id={'BorrowerClearTitleToStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.BorrowerClearTitleToAddress[i]['street']} onChange={(e)=>{e.preventDefault();this.UpdateBorrowerClearTitleToAddress(e,i,'street')}} placeholder="Street" autoComplete="hello" required={this.state.HasBorrowerClearTitleToAddress?true:false}/>
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            City
                                        </div>
                                        <div> 
                                        <input type="text" id={'SecurityCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false}  value={this.state.BorrowerClearTitleToAddress[i]['city']} onChange={(e)=>{e.preventDefault();this.UpdateBorrowerClearTitleToAddress(e,i,'city')}} autoComplete="hello" placeholder="City" />
                                        </div>
                                    </div>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            State
                                        </div>
                                        <div> 
                                        <select className="form-control" id={"BorrowerClearTitleToState"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.BorrowerClearTitleToAddress[i]['state']} onChange={(e)=>{e.preventDefault();this.UpdateBorrowerClearTitleToAddress(e,i,'state')}}>
                                            {this.state.MappedStates}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Postcode
                                        </div>
                                        <div> 
                                        <input type="text" id={'BorrowerClearTitleToPostCode'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} maxLength="4" value={this.state.BorrowerClearTitleToAddress[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.UpdateBorrowerClearTitleToAddress(e,i,'postcode')}}} autoComplete="hello" placeholder="Postcode" required={this.state.HasBorrowerClearTitleToAddress?true:false}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                        What is the anticipated settlement date? (Optional)
                        </div>
                        <div> 
                        <input type="date" id={'SettlementNotToBeBookedPriorTo'+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.ImportantNoteSegmentSettlementNotToBeBookedPriorToDates[i]} autoComplete="hello" onChange={(e)=>{e.preventDefault();this.SettlementNotToBeBookedPriorToUpdated(e,i)}} placeholder="DD/MM/YY" />
                            {/* <FormTextBox 
                                type="date" className="dateTextBoxRight"
                                placeholder="DD/MM/YY"
                            /> */}
                        </div>
                    </div>  
                    <div className="inputBox">
                        <div className="inputLabel">
                        Would you like MSA to collect the surplus?
                        </div>
                        {/* <div className="multiColsInputBoxContentWidth" name="collectSurplus" id="collectSurplus" value={this.state.ImportantNoteSegmentCollectSurplus} readOnly={this.state.InstructionSubmit?true:false} autoComplete="hello" onChange={(e)=>{let vals = this.state.ImportantNoteSegmentCollectSurplus; vals[0]=this.stringToBool(e.target.value);this.setState({ImportantNoteSegmentCollectSurplus:vals},()=>{console.log(this.state.ImportantNoteSegmentCollectSurplus)})}}> */}
                        {/* <div className="multiColsInputBoxContentWidth" name="collectSurplus" id="collectSurplus" readOnly={this.state.InstructionSubmit?true:false} autoComplete="hello" onChange={(e)=>{let vals = this.state.ImportantNoteSegmentCollectSurplus; vals[0]=this.stringToBool(e.target.value);this.setState({ImportantNoteSegmentCollectSurplus:vals},()=>{console.log(this.state.ImportantNoteSegmentCollectSurplus)})}}> */}
                        <div className="multiColsInputBoxContentWidth" name="collectSurplus" id="collectSurplus" readOnly={this.state.InstructionSubmit?true:false} autoComplete="hello" onChange={(e)=>{let vals = this.state.ImportantNoteSegmentCollectSurplus; vals[0]=this.stringToBool(e.target.value);this.setState({ImportantNoteSegmentCollectSurplus:vals})}}>
                            <RadioButton value="on" name="collectSurplus" title="Yes"/>                              
                            <RadioButton value="off" name="collectSurplus" title="No"/>
                        </div>                        
                    </div>
                </div>))
            }
            this.setState({ImportantNoteSegmentEntities:ents,ImportantNoteSegmentGuarantorTitleDeedToAddress:guarPartyAdd,BorrowerClearTitleToAddress:borrPartyAdd});
        }
    }

    UpdateSpecialConditionValues(e,i){
        e.preventDefault();
        let vals = this.state.SpecialConditionSegmentConditionValues;
        vals[i]=e.target.value;
        this.setState({SpecialConditionSegmentConditionValues:vals},()=>{this.UpdateSpecialConditionSegment()});

    }

    UpdateSpecialConditionSegmentTypes(e,i){
        e.preventDefault();
        let vals = this.state.SpecialConditionSegmentConditionTypes;
        vals[i]=e.target.value;
        this.setState({SpecialConditionSegmentConditionTypes:vals},()=>{this.UpdateSpecialConditionSegment()});
    }

    UpdateSpecialConditionSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.SpecialConditionSegmentEntities;
        if(ents.length>this.state.SpecialConditionSegmentCount){
            let types =this.state.SpecialConditionSegmentConditionTypes;
            let vals = this.state.SpecialConditionSegmentConditionValues;
            for(let i =ents.length ; i >this.state.SpecialConditionSegmentCount; i--){
                ents.pop();
                types.pop();
                vals.pop();
            }
            this.setState({SpecialConditionSegmentConditionTypes:types,SpecialConditionSegmentConditionValues:vals,SpecialConditionSegmentEntities:ents});

        }else{
            let types =this.state.SpecialConditionSegmentConditionTypes;
            let vals = this.state.SpecialConditionSegmentConditionValues;
            for(let i =0;i<this.state.SpecialConditionSegmentCount;i++){
                if(types.length<this.state.SpecialConditionSegmentCount){
                    types.push(null);
                    vals.push('');
                }
           
            }
            this.setState({SpecialConditionSegmentConditionTypes:types,SpecialConditionSegmentConditionValues:vals},()=>{
                for (let i=0;i<this.state.SpecialConditionSegmentCount;i++){
                    ents[i]=((
                        <div>
                             <h6>Special Condition {i+1}</h6>
                             {/* <label for={"SpecialConditionType"+i}>Condition Type</label>
                             <select className="form-control" id={"SpecialConditionType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.SpecialConditionSegmentConditionTypes[i] }onChange={(e)=>{e.preventDefault();this.UpdateSpecialConditionSegmentTypes(e,i)}}>
                                 <option value='All' selected={this.state.SpecialConditionSegmentConditionTypes[i]==='ALL'}>All</option>
                                 <option value='MSA' selected={this.state.SpecialConditionSegmentConditionTypes[i]==='MSA'}>MSA</option>
                            </select> */}
                            <div className="inputBox">
                                <div className="inputLabel">
                                Condition Type
                                </div>
                                <div> 
                                <select className="form-control" id={"SpecialConditionType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.SpecialConditionSegmentConditionTypes[i] }onChange={(e)=>{e.preventDefault();this.UpdateSpecialConditionSegmentTypes(e,i)}}>
                                    <option value='All' selected={this.state.SpecialConditionSegmentConditionTypes[i]==='ALL'}>All</option>
                                    <option value='MSA' selected={this.state.SpecialConditionSegmentConditionTypes[i]==='MSA'}>MSA</option>
                                </select>
                                </div>
                            </div>  
                            {/* <div className="form-group">
                                <label for={"SpecialConditionComment"+i}>Condition</label>
                                <textarea id={'SpecialConditionComment'+i} className="form-control"  readOnly={this.state.InstructionSubmit?true:false} value={this.state.SpecialConditionSegmentConditionValues[i]} onChange={(e)=>{e.preventDefault();this.UpdateSpecialConditionValues(e,i)}} placeholder=""/>
                            </div> */}
                            <div className="inputBox">
                                <div className="inputLabel">
                                Condition
                                </div>
                                <div> 
                                <textarea id={'SpecialConditionComment'+i} className="form-control"  readOnly={this.state.InstructionSubmit?true:false} value={this.state.SpecialConditionSegmentConditionValues[i]} onChange={(e)=>{e.preventDefault();this.UpdateSpecialConditionValues(e,i)}} placeholder=""/>
                                </div>
                            </div>
                        </div>
                     ));
                }
                this.setState({SpecialConditionSegmentEntities:ents});
            });
            
        }
        
    }


    CheckMatter(event){
        event.preventDefault();
        this.setState({
            isChecking:true,
        });
        setTimeout(()=>{
            this.setState({isChecking:false});
        },300);
        InstructionService.CheckIsExistingMatter(this.state.lenderReference).then(response=>{
            this.setState({
                isExistingLenderReference:response.data.Exists,
                matterNumber:response.data.MatterId,
                matterDescription:response.data.MatterDescription,
            });
        },error=>{
            this.setState({
                isExistingLenderReference:false,
                errorMessage:error.message,
            })
        })
        this.setState({
            hasCheckedReferenceNumber:true,
            isChecking:false,
        },()=>{
            this.setState({
                copy:this.state.isExistingLenderReference? "":"",
            })
        });
        //check matter exists
        
    }

    UpdateLenderReferenceNumber(event){
        event.preventDefault();
        this.setState({
            lenderReference:event.target.value
        },_=>{
            if(this.state.lenderReference===""){
                this.setState({
                    hasCheckedReferenceNumber:false
                })
            }
        })
    }

    DownloadInstructionHtml(event){
        event.preventDefault();
        InstructionService.GetInstructionHtml(this.state.msg).then(
            resp => {
              const file = new Blob([resp.data], {type: resp.request.response.type});
                saveAs(file,this.state.msg+"_Instruction"+".html");  
            },err=>{

            })
    }

    DownloadInstructionXml(event){
        event.preventDefault();
        InstructionService.GetInstructionXml(this.state.msg).then(
            resp => {
              const file = new Blob([resp.data], {type: resp.request.response.type});
                saveAs(file,this.state.msg+"_Instruction"+".xml");  
            },err=>{

            })
    }

    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    } 

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    GetMatterDetails(e){
        //alert('hi')
        e.preventDefault();
        
        InstructionService.GetMatterDetailsForDischarges(this.state.InstructionLenderReference).then((resp)=>{
            
            
            
            this.setState({
                MatterDetails: resp.data.Content,
                MatterExists:true,
                CheckedMatter:true,
                PartySegmentNames:resp.data.Content.Parties,
                SecuritySegmentAddreses: resp.data.Content.Securities,
                LoanIds: resp.data.Content.LoanAccounts,
                //PartySegmentEmails: resi
                //PartySegmentEntities:resp.data.Content.Parties,

        },()=>{
            console.log("MatterDetails");
            console.log(this.state.MatterDetails)
            console.log("PartySegmentNames");
            console.log(this.state.PartySegmentNames)
            console.log("SecuritySegmentAddreses");
            console.log(this.state.SecuritySegmentAddreses)
            console.log("LoanAccounts");
            console.log(this.state.LoanIds)

            let names = this.state.PartySegmentNames;        
            let partyEmails = this.state.PartySegmentEmails;
            let partyAddresses = this.state.PartySegmentAddresses;
            let partyCount = this.state.PartySegmentNames !=null? this.state.PartySegmentNames.length : 0;
            let partyTypes = this.state.PartySegmentPartyTypes;
            let porCTypes = this.state.PartySegmentPorCTypes;
            this.state.PartySegmentNames.forEach((c,i)=>{
                names[i]['GivenName']=this.state.PartySegmentNames[i].Firstname;
                names[i]['Surname']=this.state.PartySegmentNames[i].Lastname;
                partyTypes[i]= this.state.PartySegmentNames[i].PartyTypeId == 1 ? "Borrower" : "Guarantor";//this.state.PartySegmentNames[i].PartyTypeName;
                partyEmails[i]=this.state.PartySegmentNames[i].Email;
                porCTypes[i]=this.state.PartySegmentNames[i].IsIndividual==true?"Person":"Company";
                partyAddresses.push(this.state.PartySegmentNames[i].StateName,this.state.PartySegmentNames[i].StreetAddress);
               
            })

            this.setState({PartySegmentNames:names, 
                PartySegmentEmails: partyEmails,
                PartySegmentAddresses: partyAddresses, 
                PartySegmentCount: partyCount,
                PartySegmentPartyTypes: partyTypes,
                PartySegmentPorCTypes: porCTypes
            },()=>{this.UpdatePartySegment()})

            let securities = this.state.SecuritySegmentAddreses;
            let titlerefs = this.state.SecuritySegmentTitleReferences;
            this.state.SecuritySegmentAddreses.forEach((c,i)=>{
                titlerefs[i] = this.state.SecuritySegmentAddreses[i].TitleRefs[0].TitleReference;
                securities[i]['street']=this.state.SecuritySegmentAddreses[i].StreetAddress;
                securities[i]['city']=this.state.SecuritySegmentAddreses[i].Suburb;
                securities[i]['state']=this.state.SecuritySegmentAddreses[i].StateName;
                securities[i]['postcode']=this.state.SecuritySegmentAddreses[i].PostCode;
            })

            this.setState({SecuritySegmentAddreses:securities, 
                SecuritySegmentTitleReferences: titlerefs,
                // PartySegmentAddresses: partyAddresses, 
                // PartySegmentCount: partyCount,
                // PartySegmentPartyTypes: partyTypes,
                // PartySegmentPartyTypes: porCTypes
            },()=>{this.UpdateSecuritySegment()})

            let loanAccounts = this.state.LoanIds;
            let loanAmounts = this.state.IndicativeAmounts;
            this.state.LoanIds.forEach((c,i)=>{
                //loanAccounts[i] = this.state.LoanIds[i].LoanAccountNo;
                loanAmounts[i] = resp.data.Content.LoanAccounts[i].LoanAmount;//resp.data.Content.LoanAccounts[i].LoanAmount;
                loanAccounts[i] = resp.data.Content.LoanAccounts[i].LoanAccountNo;
            })

            this.setState({LoanIds:loanAccounts, 
                IndicativeAmounts: loanAmounts,
            },()=>{this.UpdateLoanIdSegment()})

                
                // let bsbs = this.state.PayoutLoanDetailsSegmentBsbs;
                        
                // let payoutFigs = this.state.PayoutLoanDetailsSegmentPayoutFigures;
                // let accNames = this.state.PayoutLoanDetailsSegmentAccountNames;
                // let accNumbs = this.state.PayoutLoanDetailsSegmentAccountNumbers;
                
                // if(this.state.PayoutStage==='QA' | this.state.PayoutStage==='finalised'){
                //     this.state.MatterLoanAccounts.forEach((c,i)=>{
                //         if(bsbs.length < i+1){
                //             bsbs.push(this.state.MatterLoanAccounts[i]['trustAccountField']['bSBField']);
                //             accNames.push(this.state.MatterLoanAccounts[i]['trustAccountField']['accountNameField']);
                //             accNumbs.push(this.state.MatterLoanAccounts[i]['trustAccountField']['accountNumberField']);
                //             payoutFigs.push(this.state.MatterLoanAccounts[i]['payoutFigureField']);        
                //         }else{
                //             bsbs[i]=this.state.MatterLoanAccounts[i]['trustAccountField']['bSBField'];
                //             accNames[i]=(this.state.MatterLoanAccounts[i]['trustAccountField']['accountNameField']);
                //             accNumbs[i]=(this.state.MatterLoanAccounts[i]['trustAccountField']['accountNumberField']);
                //             payoutFigs[i]=(this.state.MatterLoanAccounts[i]['payoutFigureField']);
                //         }
                //     });
                    
                //}
                // var loans = this.state.MatterLenderReference; 
                // let splitLoans = loans.split(', ');
                // let vals = this.state.PayoutLoanDetailsSegmentLoanIds; 
                // splitLoans.forEach((c,i)=>{
                //     if(vals.length < i+1){
                //         vals.push(splitLoans[i]);
                //     }else{
                //         vals[i]=splitLoans[i];
                //     }
                // });
                
                // this.setState({PayoutLoanDetailsDateValid:this.state.MatterDateValid,DateValidGood:this.state.MatterDateValid? true:false, PayoutLoanDetailsSegmentCount:splitLoans.length,PayoutLoanDetailsSegmentLoanIds:vals,PayoutLoanDetailsSegmentPayoutFigures:payoutFigs,PayoutLoanDetailsSegmentBsbs:bsbs,PayoutLoanDetailsSegmentAccountNumbers:accNumbs,PayoutLoanDetailsSegmentAccountNames:accNames},()=>{this.UpdatePayoutSegment()})
            });
        },(err)=>{
            if (err.response && err.response.status === 401) {
                this.setState({
                    shouldRedirect: true,
                    errorMessage: 'there is an error with the request'
                    //,MatterDescription:null,MatterLenderReference:'',PayoutLoanDetailsSegmentLoanIds:[],PayoutLoanDetailsSegmentEntities:[]
                },()=>{
                    //this.UpdatePayoutSegment()
                });

            } else {
                // this.setState({MatterExists:false,CheckedMatter:true,MatterDescription:null,MatterLenderReference:'',PayoutLoanDetailsSegmentLoanIds:[],PayoutLoanDetailsSegmentEntities:[],PayoutLoanDetailsSegmentPayoutFigures:[],PayoutLoanDetailsDateValid:"",MatterDateValid:"",DateValidGood: false
                // ,PayoutLoanDetailsSegmentAccountNamesOptions:[],PayoutLoanDetailsSegmentAccountNames:[],PayoutLoanDetailsSegmentBsbs:[],PayoutLoanDetailsSegmentAccountNumbers:[]
                // },()=>{
                //     //this.UpdatePayoutSegment()
                // });
            }  
      
         
        })
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }

    gotoLogin(){
        this.setState({redirectToLogin: true});
    }

    mortManagerChanged(event) {
        //setter
    
        const target = event.target;
    
        this.setState({
          mortManager: {id:target.value}
        },()=>{
            //alert(this.state.mortManager.id)
        });
      }


    render() {   
        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }
        // if(this.state.isLoading){
        //     return(<ProgressDisplay></ProgressDisplay>)
        // }
        
        if(this.state.leftContent != this.state.pageTitle){
            // alert(this.state.lmwInstructMSASub)
             return( 
                 <DisplayLeftMenu 
                     page={this.state.leftContent} 
                     desktopMenu={this.state.desktopMenu} 
                     lmwInstructMSASub={this.state.lmwInstructMSASub}
                 />
             )
        } 

        if(this.state.redirectToLogin)
        {
            return <Redirect to="/" />;
        }

        if((this.state.sessionHasExpired)){
            return(
              <div className="popup-box" style={{display: 'block',height:"60%", textAlign:"center"}}>
                        <div className="box card">
                            <div className="tal">                       
                                Your session has expired.  Click the button to login.                            
                            </div>  
                            <div>&nbsp;</div>                       
                            <ButtonGroup>
                                <Button
                                    clickFunction={(e) => this.gotoLogin(e)}
                                >
                                OK
                                </Button>
                            
                                {/* <Button
                                    addCSS="tertiaryBTN" 
                                    clickFunction={(e) => this.cancel(e)}
                                >
                                Cancel
                                </Button> */}
                            </ButtonGroup>
      
                                                      
                        </div>                       
                    </div>
            );
          }

        if(this.state.msg!=='' && !this.state.InstructionSubmit){
            return( 
            <div className="instruction page">
                {/* <Header/> */}
                <div className="instruction container">
                    <div className="instruction inner">
                        <p>{this.state.PayoutStage==='QA'?(<span>This request has been uploaded and locked for MSA. Our reference number for this loan is <b>{this.state.msg}</b>.</span>):'Payout has now been saved. Please lock the payout to submit to MSA.'}</p>
                        <div className="flex">
                        <a type="link" className="btn btn-secondary" href="/Instruction">Go Back</a>
                        </div>
                    </div>
                </div>
            </div>
                );
        }

        let loanIdRenderable = this.state.LoanIdEntities.length? this.state.LoanIdEntities.reduce((p,c)=>[p,'',c]):'';
        let partyRepRenderable = this.state.PartyRepEntities.length? this.state.PartyRepEntities.reduce((p,c)=>[p,'',c]):'';
        let incomMortRenderable = this.state.IncomingMortgageeSegmentEntities.length? this.state.IncomingMortgageeSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let partySegRenderable = this.state.PartySegmentEntities.length? this.state.PartySegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let securitySegRenderable = this.state.SecuritySegmentEntities.length? this.state.SecuritySegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let simultaneousSegRenderable = this.state.SimultaneousSettlementSegmentEntities.length? this.state.SimultaneousSettlementSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let importantNotesSegRenderable = this.state.ImportantNoteSegmentEntities.length? this.state.ImportantNoteSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let specialCondSegRenderable = this.state.SpecialConditionSegmentEntities.length? this.state.SpecialConditionSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let dischargeOptions=[];
        for(let i=0; i<this.state.DischargeTypes.length;i++){
            dischargeOptions.push(<option>{this.state.DischargeTypes[i]}</option>);
        }
        
        let mappedDischargeOptions = dischargeOptions.length? dischargeOptions.reduce((p,c)=>[p,'',c]):'';

        
        

        return ( 
             

            
            <LeftMenuContent 
                id="InstructMSAPage"  
                page="dischargeinstructions" 
                desktopMenu={this.state.desktopMenu} 
                callBack = {this.handleLeftMenuCallback} 
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 

                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />
                    

                    <div className="content"> 
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                    
                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />

                            <TitleSegment>Loan Insights</TitleSegment>

                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
                            </div>

                            <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div> 

                            </div> */}
                    
                        <div className="FormsBox">
                        <form id="instruction-form">
                            <div className="spacingBottomLarge">
                                <TitleSegment>Discharge Application</TitleSegment> 
                                <Card>
                                {this.state.isLoading?<ProgressDisplay></ProgressDisplay> : ""}
                                    {this.state.InstructionSubmit?(
                                        <div className="spacingBottomLarge">
                                        <div>
                                            <p>This request has been uploaded to MSA. Our reference number for this loan is <b>{this.state.msg}</b>.</p>
                                            <div>
                                                <ButtonGroupDischarges className="DischargesGroup" addCSS="dischargesButton">
                                                    <Button addCSS="btnRegular btnSmall" clickFunction={ (e)=>this.DownloadInstructionHtml(e)}>Download HTML</Button>
                                                    <Button 
                                                        addCSS="btnRegular btnSmall"
                                                        clickFunction={ (e)=>this.DownloadInstructionXml(e)}>Download XML</Button> 
                                                    </ButtonGroupDischarges>
                                                {/* <ButtonIcon
                                                    id=""
                                                    addCSS="ButtonIconSelected biLeft mainBtn"
                                                    onClick={this.DownloadInstructionHtml}
                                                >Download HTML</ButtonIcon>
                                                <ButtonIcon
                                                    id=""
                                                    addCSS="ButtonIconSelected biRight mainBtn"
                                                    onClick={this.DownloadInstructionXml}
                                                >Download XML</ButtonIcon>
                                                <br/> */}
                                            </div>                                        
                                        </div></div>):''}
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Lender Reference
                                        </div>
                                        <div>
                                            <input type="text" className={this.state.InstructionLenderReference?"successTextBox":''} id='LenderRef' placeholder={this.state.lenderName == "Australian Mortgage"?"Input the Lender Ref to automatically populate fields when matched":"This field will be filled automatically to match Loan Account Id"}  value={this.state.InstructionLenderReference} readOnly={this.state.lenderName == "Australian Mortgage"?false:true} onChange={(e)=>{let vals = this.state.InstructionLenderReference; vals=e.target.value;this.setState({InstructionLenderReference:vals, CheckedMatter: false},()=>{this.ResetForm()})}} onBlur={(e)=>{e.preventDefault();if(this.state.InstructionLenderReference!==null && this.state.CheckedMatter == false){this.GetMatterDetails(e)}}}/>
                                        </div>
                                    </div> 
                                    {this.state.CanUserAddSecondaryRef? 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Secondary Lender Reference
                                        </div>
                                        <div>
                                            <input type="text" className="inputLabel" id='LenderRef' placeholder="Secondary Lender Reference"  value={this.state.InstructionSecondaryLenderRef} onChange={(e)=>{let vals = this.state.InstructionSecondaryLenderRef; vals=e.target.value;this.setState({InstructionSecondaryLenderRef:vals})}} required/>
                                        </div>
                                    </div> 
                                    :''}
                                    
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Discharge Type
                                        </div>
                                        {/* <div className="multiColsInputBoxContentWidth" id="DischargeType" name="DischargeType" value={this.state.dischargeTypeName} onChange={(e)=>{let vals = this.state.dischargeTypeName; vals=e.target.value;this.setState({dischargeTypeName:vals},()=>{console.log("dischargetype" + this.state.dischargeTypeName)})}}>
                                            <RadioButton value="Full" name="DischargeType" title="Full" checked={this.state.dischargeTypeName==='Full'?true:false}/>                              
                                            <RadioButton value="Partial" name="DischargeType" title="Partial" checked={this.state.dischargeTypeName==='Partial'?true:false}/>
                                        </div> */}
                                        <div className="form-group">
                                        {/* <label for="DischargeType">Discharge Type</label> */}
                                        <select className="form-control" id="DischargeType" value={this.state.dischargeTypeName} onChange={(e)=>{let vals = this.state.dischargeTypeName; vals=e.target.value;this.setState({dischargeTypeName:vals}); this.checkIfSessionHasExpired()}}>
                                        
                                        
                                            {mappedDischargeOptions}
                                        </select>
                                        </div>
                                    </div>   

                                    {this.state.CanUserChooseMortMgr? 
                                    <div>
                                        <div className="inputLabel">
                                            Mortgage Manager
                                        </div>
                                        <div>
                                            <MortManagerControl
                                                data={this.state.mortManagers}
                                                value={this.state.mortManager}
                                                onChange={this.mortManagerChanged}
                                            />
                                            {/* <DropDownList options={this.state.mortManagers} value={this.state.mortManager} onChange={this.mortManagerChanged}>
                                                
                                            </DropDownList> */}
                                        </div>
                                    </div>
                                    : ''}
                                    
                                </Card>
                            </div>


                            <div className="spacingBottomLarge">
                                <h5>Loan Party Details</h5> 
                                
                                <Card>
                                    {partySegRenderable}
                                    
                                    <div className="multiColsInputBox">
                                        <div>
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                onClick={(e)=>{e.preventDefault();this.setState({PartySegmentCount:this.state.PartySegmentCount+1},()=>{this.PartyTypeUpdated(e);this.UpdatePartySegment()})}}
                                            >Add loan party</ButtonIcon>
                                        </div>
                                        <div className="tar">
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                display={this.state.PartySegmentCount===this.state.PartySegmentMinCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();if(this.state.PartySegmentCount>=0){this.setState({PartySegmentCount:this.state.PartySegmentCount-1},()=>{this.UpdatePartySegment()})}}}
                                            >Remove loan party</ButtonIcon>         </div>                              
                                    </div>    
                                </Card>
                            </div>


                            <div className="spacingBottomLarge">
                                <h5>Security To Be Discharged</h5> 
                                <Card>
                                    {securitySegRenderable}                                    
                                    <div className="multiColsInputBox">
                                        <div>
                                        <ButtonIcon
                                            id="btnCFPost"
                                            addCSS="ButtonIconForm"
                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                            display={this.state.SecuritySegmentCount===this.state.SecuritySegmentMaxCount?'none':'inline-block'}
                                            onClick={(e)=>{e.preventDefault();this.setState({SecuritySegmentCount:this.state.SecuritySegmentCount+1},()=>{this.SecurityTitleReferenceUpdated(e);this.UpdateSecuritySegment()})}}
                                        >Add security</ButtonIcon>
                                        </div>
                                        <div className="tar">
                                        <ButtonIcon
                                            id="btnCFPost"
                                            addCSS="ButtonIconForm"
                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                            display={this.state.SecuritySegmentCount===this.state.SecuritySegmentMinCount?'none':'inline-block'}
                                            onClick={(e)=>{e.preventDefault();if(this.state.SecuritySegmentCount>=0){this.setState({SecuritySegmentCount:this.state.SecuritySegmentCount-1},()=>{this.UpdateSecuritySegment()})}}}
                                        >Remove security</ButtonIcon>
                                        </div>
                                    </div> 
                                       
                                </Card>
                            </div>


                            <div className="spacingBottomLarge">
                                <h5>Loan Account</h5> 
                                <Card>
                                    {loanIdRenderable}
                                    <div className="multiColsInputBox">
                                        <div> 
                                        <ButtonIcon
                                            id="btnCFPost"
                                            addCSS="ButtonIconForm"
                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                            onClick={(e)=>{e.preventDefault();let incVal = this.state.LoanIdEntityCount+1;this.setState({LoanIdEntityCount:incVal},()=>{this.UpdateLoanIdSegment()})}}
                                        >Add Loan Id</ButtonIcon>
                                        </div>
                                        <div className="tar">
                                        <ButtonIcon
                                            id="btnCFPost"
                                            addCSS="ButtonIconForm"
                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                            display={this.state.LoanIdEntityCount===this.state.LoanIdEntityMinCount?'none':'inline-block'}
                                            onClick={(e)=>{e.preventDefault();if(this.state.LoanIdEntityCount>=0){let incVal=this.state.LoanIdEntityCount-1;this.setState({LoanIdEntityCount:incVal},()=>{this.UpdateLoanIdSegment()})}}}
                                        >Remove Loan Id</ButtonIcon>
                                        </div>
                                    </div> 
                                </Card>
                            </div>


                            <div className="spacingBottomLarge">
                                <h5>Simultaneous Segments</h5> 
                                <Card>
                                    <div>
                                        <div className="inputLabel">
                                            Are there simultaneous settlements?
                                        </div>                                        
                                        {/* <div className="multiColsInputBoxContentWidth" name="hasSimultaneous" id="HasSimultaneousCheck" value={this.state.LoanHasSimultaneous} onChange={(e)=>{let vals = e.target.value; this.setState({LoanHasSimultaneous:vals,SimultaneousSettlementSegmentCount:this.state.LoanHasSimultaneous==='Yes'?0:1},()=>{ */}
                                        <div className="multiColsInputBoxContentWidth" name="hasSimultaneous" id="HasSimultaneousCheck" value={this.state.LoanHasSimultaneous} onChange={(e)=>{let vals = this.state.LoanHasSimultaneous; vals=this.stringToBool(e.target.value);this.setState({LoanHasSimultaneous:vals,SimultaneousSettlementSegmentCount:!vals?0:1},()=>{
                                            this.UpdateSimultaneuosSettlementSegment();  
                                            console.log("LoanHasSimultaneous")                                          
                                            console.log(this.state.LoanHasSimultaneous)
                                            })}}>
                                        {/* <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{ alert('hi');let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdatePartySegment()})}}>     */}
                                            <RadioButton value="Yes" name="hasSimultaneous" title="Yes"/>                              
                                            <RadioButton value="No" name="hasSimultaneous" title="No"/>
                                        </div> 
                                    </div>
                                    {/* <div className="SimultaneuousSettlementSeg" style={{display:this.state.LoanHasSimultaneous==='Yes'?'block':'none'}}> */}
                                    <div className="SimultaneuousSettlementSeg" style={{display:this.state.LoanHasSimultaneous?'block':'none'}}>
                                        <div className="inputBox">
                                            <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>
                                                <h6>Simultaneous Segment</h6>
                                                {simultaneousSegRenderable}
                                            </div>
                                        </div>
                                        <div> 
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                onClick={(e)=>{e.preventDefault();this.setState({SimultaneousSettlementSegmentCount:this.state.SimultaneousSettlementSegmentCount+1},()=>{this.UpdateSimultaneuosSettlementSegment()})}}
                                            >Add Loan Id</ButtonIcon>
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                display={this.state.SimultaneousSettlementSegmentCount>1?'inline-block':'none'}
                                                onClick={(e)=>{e.preventDefault();if(this.state.SimultaneousSettlementSegmentCount>=1){this.setState({SimultaneousSettlementSegmentCount:this.state.SimultaneousSettlementSegmentCount-1},()=>{this.UpdateSimultaneuosSettlementSegment()})}}}
                                            >Remove Loan Id</ButtonIcon>
                                        </div> 
                                       
                                    </div>                                  
                                    
                                </Card>
                            </div>


                            <div className="spacingBottomLarge">
                                <h5>Others</h5> 
                                <Card> 
                                    {importantNotesSegRenderable}
                                    {specialCondSegRenderable}
                                    <div className="multiColsInputBox">
                                        <div>
                                             <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                onClick={(e)=>{e.preventDefault();this.setState({SpecialConditionSegmentCount:this.state.SpecialConditionSegmentCount+1},()=>{this.UpdateSpecialConditionSegment()})}}
                                            >Add Condition</ButtonIcon>
                                        </div>
                                        <div className="tar">
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                display={this.state.SpecialConditionSegmentCount===0?'none':'inline-block'} 
                                                onClick={(e)=>{e.preventDefault();if(this.state.LoanIdEntityCount>=0){this.setState({SpecialConditionSegmentCount:this.state.SpecialConditionSegmentCount-1},()=>{this.UpdateSpecialConditionSegment()})}}}
                                            >Remove Condition</ButtonIcon>
                                        </div>
                                    </div>                         <br />          
                                    <br />
                                    <div>
                                        <div className="inputLabel">
                                            Other requests or comments
                                        </div>
                                        <div>
                                            {/* <FormTextArea placeholder="Write message" rows="7" />     */}
                                            <textarea id={'DischargeinstructionOtherRequests'} row="7" className="form-control" value={this.state.DischargeinstructionOtherRequests} onChange={(e)=>{e.preventDefault();this.setState({DischargeinstructionOtherRequests:e.target.value},()=>{this.UpdateSpecialConditionSegment()})}} placeholder="Write message"/>
                                        </div>
                                    </div>
                                </Card>
                            </div>

                            
                                
                                {this.state.InstructionSubmit?<div><a type="link" className="btn btn-secondary" href="/dischargeinstructions">                            
                                    <ButtonIcon
                                        id=""
                                        addCSS="ButtonIconSelected biRight mainBtn"
                                        >
                                    Done</ButtonIcon> 
                                </a></div>
                                :this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id=""
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={this.CreateDischargeInstructionEntity}
                                >Submit Discharge Application</ButtonIcon> </div>
                                }
                           

                        </form>    
                        </div> 
                        
                        
                     

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
InstructMSAPage=AutoLogout(InstructMSAPage);
export default InstructMSAPage
