import React, { Component } from 'react' 
import { Redirect } from 'react-router-dom';
import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/EditInstructionPage.css';   
import { AuthService } from '../../../services/auth-service';
import { InstructionService } from '../../../services/instruction-service';
import RadioButton from '../../atom/RadioButon/RadioButon';
import RadioButtonGroup from '../../molecule/RadioButtonGroup/RadioButtonGroup';
import {saveAs}         from 'file-saver';
import Button from '../../atom/Button/Button';
import ButtonGroupDischarges from '../../molecule/ButtonGroupDischarges/ButtonGroupDischarges';
import AutoLogout from '../../../auto-logout';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import { MatterService } from '../../../services/matter-service';
import IconBox from '../../atom/IconBox/IconBox';
import Dropzone   from 'react-dropzone';
import { FilePond } from 'react-filepond';
import SAlert from "sweetalert2";
import InsightBox from '../../molecules/InsightBox/InsightBox';
import ButtonGroup from '../../molecule/ButtonGroup/ButtonGroup';
import { MatterOutstandingService } from '../../../services/matter-outstanding-service';


class EditInstructionPage extends Component { 
    
    constructor(props){
        super(props) 

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }
        let userdetails = AuthService.getUserDetails();	
        let lendername= AuthService.getLendername();
        // let token = AuthService.getToken();
        this.state={ 
            matter: this.props.match.params.myKey.substring(1,8),
            MatterIsPOH: false,
            pageTitle : 'editinstructions',
            leftContent : 'editinstructions',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            // isInstructPriv: null,
            isGeneralExpanded: true,
            isUnallocatedExpanded: true,
            expanded: true,
            isLoading:false,
            onboarding:true,
            fade:false,
            option:"",
            prompt:"",
            isMiniHoldReverseMortgage: false,
            isMiniHoldOverseaAddress: false,
            isMiniHoldLimitedGuarantee: false,
            isMiniHoldChangeofName: false,
            isMiniHoldLendersDisclosureDocuments: false,
            isMiniHoldIndependentLegalAdviceWaived: false,
            isMiniHoldIndependentFinancialAdviceRequired: false,
            isMiniHoldIntrepreterRequired: false,
            isMiniHoldACTSecurity: false,
            isMiniHoldNTSecurity: false,
            SettlementInformation: [],
            copy:"This page can update MSA's systems, eliminating the need to it for an update to be actioned. only certain lenders are permitted to instruct MSA through the portal. For documentation please contact MSA. You will receive a message asking you to confirm your changes.",
            lenderReference:'',
            dischargeType:0,
            dischargeTypeName:'',
            subDischargeType:0,
            subDischargeTypeOptions:['Sale','External Refinance','Debt Repaid','Internal Refinance'],
            //subDischargeTypeOptions:{},
            matterNumber:'',
            matterDescription:'',
            isChecking:false,
            isExistingLenderReference:false,
            isNewLoanConfirmed:false,
            hasCheckedReferenceNumber:false,
            errorMessage:'',
            LoanIds:[],
            IndicativeAmounts:[],
            LoanIdEntityCount:0,
            LoanIdEntityMinCount:1,
            LoanIdEntities:[],
            PartyRepEntityCount:1,
            PartyRepEntitySegmentMinCount:1,
            PartyRepEntitySegmentMaxCount:1,
            PartyRepNames:[],
            PartyRepTypes:[],
            PartyRepEmails:[],
            PartyRepNumbers:[],
            PartyRepEntities:[],
            IncomingMortgageeNames:[],
            IncomingMortgageeEmails:[],
            IncomingMortgageeNumbers:[],
            IncomingMortgageeType:[],
            IncomingMortgageeSegmentEntities:[],
            IncomingMortgageeSegmentCount:0,
            IncomingMortgageeSegmentMinCount:0,
            IncomingMortgageeSegmentMaxCount:1,
            PartySegmentHomePhoneNumbers:[],
            PartySegmentHomeFaxNumbers:[],
            PartySegmentMobileNumbers:[],
            PartySegmentWorkPhoneNumbers:[],
            PartySegmentWorkFaxNumbers:[],
            PartySegmentPartyTypes:[],
            PartySegmentPorCTypes:[],
            PartySegmentNames:[],
            PartySegmentEmails:[],
            PartySegmentAddresses:[],
            PartySegmentApplicantTypes:[],
            PartySegmentUniqueIds:[],
            PartySegmentIndependentLegalAdvice:[],
            PartySegmentIndependentFinancialAdvice:[],
            PartySegmentInterpreterRequired:[],
            PartySegmentChangeOfName:[],
            PartySegmentChangeOfNameReason:[],
            PartySegmentSignUnderPoA:[],
            PartySegmentGrantGSA:[],
            PartySegmentGrantSSA:[],
            PartySegmentGuarantorLimitedLiability:[],
            PartySegmentGuarantorLiabilityAmount:[],
            PartySegmentGuarantorLiabilityOther:[],
            PartySegmentMinCount:1,
            PartySegmentCount:1,
            PartySegmentEntities:[],
            PartySegmentPrimaryApplicant:[],
            PartySegmentAddreses:[],
            PartySegmentAddressUsedforMailing:[],
            PartySegmentAddressPostSettlement:[],
            PartySegmentAddressPreSettlement:[],
            PartySegmentAddressResidential:[],
            PartySegmentPhoneNumbers:[],
            PartySegmentPartyTypes:[],
            PartySegmentPorCTypes:[],
            PartySegmentCompanyPPSR:[],
            PartySegmentNames:[],
            PartySegmentEmails:[],
            PartySegmentAddresses:[],
            PartyOverseasAddressUniqueId:[],
            PartyOverseasResidentialAddress:[],
            PartyOverseasMailingAddress:[],
            PartyHasOverseasAddress:false,
            AddressSectionMinCount:1,
            AddressSectionCount:1,
            AddressSectionEntities: [],
            // StreetTypeList:[
            //     'Access','Alley','Alleyway','Amble','Anchorage','Approach','Arcade','Arterial','Artery','Avenue','Banan','Bank','Basin','Bay','Beach','Bend','Block','Boardwalk','Boulevard','Boulevarde','Bowl','Brace','Brae','Break','Bridge','Broadway','Brow','Bypass','Byway',
            //     'Causeway','Centre','Centreway','Chase','Circle','Circlet','Circuit','Circus','Close','Cluster','Colonnade','Common','Concourse','Connection','Copse','Corner','Corso','Course','Court','Courtyard','Cove','Crescent',
            //     'Crest','Cross','Crossing','Crossroad','Crossway','Cruiseway','Cul-de-Sac','Cutting','Dale','Dell','Dene','Deviation','Dip','Distributor','Divide','Dock','Domain','Drive','Driveway','Edge','Elbow','End','Entrance',
            //     'Esplanade','Estate','Expressway','Extension','Fairway','Firebreak','Fireline','Firetrack','Firetrail','Flat','Flats','Follow','Footway','Ford','Foreshore','Formation','Freeway','Front','Frontage','Gap','Garden',
            //     'Gardens','Gate','Gateway','Glade','Glen','Grange','Green','Ground','Grove','Gully','Harbour','Heath','Heights','Highroad','Highway','Hill','Hollow','Hub','Interchange','Intersection','Island','Junction','Key','Keys',
            //     'Landing','Lane','Laneway','Lees','Line','Link','Little','Lookout','Loop','Lower','Mall','Manor','Meander','Mew','Mews','Motorway','Mount','Nook','Outlet','Outlook','Parade','Park','Parklands','Parkway','Part','Pass',
            //     'Passage','Path','Pathway','Piazza','Place','Plateau','Plaza','Pocket','Point','Port','Promenade','Pursuit','Quad','Quadrangle','Quadrant','Quay','Quays','Ramble','Ramp','Range','Reach','Reserve','Rest','Retreat','Return',
            //     'Ride','Ridge','Ridgeway','Right of Way','Ring','Rise','Rising','River','Riverway','Riviera','Road','Roads','Roadside','Roadway','Ronde','Rosebowl','Rotary','Round','Route','Row','Rue','Run','Service Way','Shunt',
            //     'Siding','Slope','Sound','Spur','Square','Stairs','State Highway','Steps','Strand','Street','Strip','Subway','Tarn','Terrace','Thoroughfare','Throughway','Tollway','Top','Tor','Towers','Track','Trail','Trailer','Triangle',
            //     'Trunkway','Turn','Twist','Underpass','Upper','Vale','Valley','Viaduct','View','Views','Villas','Vista','Wade','Walk','Walkway','Waters','Waterway','Way','Wharf','Woods','Wynd','Yard'
            // ],
            StreetTypeList:[
                {key:0, value: 'Access'},
                {key:1, value: 'Alley'},
                {key:2, value: 'Alleyway'},
                {key:3, value: 'Amble'},
                {key:4, value: 'Anchorage'},
                {key:5, value: 'Approach'},
                {key:6, value: 'Arcade'},
                {key:7, value: 'Arterial'},
                {key:8, value: 'Artery'},
                {key:9, value: 'Avenue'},
                {key:10, value: 'Banan'},
                {key:11, value: 'Bank'},
                {key:12, value: 'Basin'},
                {key:13, value: 'Bay'},
                {key:14, value: 'Beach'},
                {key:15, value: 'Bend'},
                {key:16, value: 'Block'},
                {key:17, value: 'Boardwalk'},
                {key:18, value: 'Boulevard'},
                {key:19, value: 'Boulevarde'},
                {key:20, value: 'Bowl'},
                {key:21, value: 'Brace'},
                {key:22, value: 'Brae'},
                {key:23, value: 'Break'},
                {key:24, value: 'Bridge'},
                {key:25, value: 'Broadway'},
                {key:26, value: 'Brow'},
                {key:27, value: 'Bypass'},
                {key:28, value: 'Byway'},
                {key:29, value: 'Canal'},
                {key:30, value: 'Causeway'},
                {key:31, value: 'Centre'},
                {key:32, value: 'Centreway'},
                {key:33, value: 'Chase'},
                {key:34, value: 'Circle'},
                {key:35, value: 'Circlet'},
                {key:36, value: 'Circuit'},
                {key:37, value: 'Circus'},
                {key:38, value: 'Close'},
                {key:39, value: 'Cluster'},
                {key:40, value: 'Colonnade'},
                {key:41, value: 'Common'},
                {key:42, value: 'Concourse'},
                {key:43, value: 'Connection'},
                {key:44, value: 'Copse'},
                {key:45, value: 'Corner'},
                {key:46, value: 'Corso'},
                {key:47, value: 'Course'},
                {key:48, value: 'Court'},
                {key:49, value: 'Courtyard'},
                {key:50, value: 'Cove'},
                {key:51, value: 'Crescent'},
                {key:52, value: 'Crest'},
                {key:53, value: 'Cross'},
                {key:54, value: 'Crossing'},
                {key:55, value: 'Crossroad'},
                {key:56, value: 'Crossway'},
                {key:57, value: 'Cruiseway'},
                {key:58, value: 'CulDeSac'},
                {key:59, value: 'Cutting'},
                {key:60, value: 'Dale'},
                {key:61, value: 'Dell'},
                {key:62, value: 'Dene'},
                {key:63, value: 'Deviation'},
                {key:64, value: 'Dip'},
                {key:65, value: 'Distributor'},
                {key:66, value: 'Divide'},
                {key:67, value: 'Dock'},
                {key:68, value: 'Domain'},
                {key:69, value: 'Downs'},
                {key:70, value: 'Drive'},
                {key:71, value: 'Driveway'},
                {key:72, value: 'Edge'},
                {key:73, value: 'Elbow'},
                {key:74, value: 'End'},
                {key:75, value: 'Entrance'},
                {key:76, value: 'Esplanade'},
                {key:77, value: 'Estate'},
                {key:78, value: 'Estuary'},
                {key:79, value: 'Expressway'},
                {key:80, value: 'Extension'},
                {key:81, value: 'Fairway'},
                {key:82, value: 'FireTrack'},
                {key:83, value: 'Firebreak'},
                {key:84, value: 'Fireline'},
                {key:85, value: 'Firetrack'},
                {key:86, value: 'Firetrail'},
                {key:87, value: 'Flat'},
                {key:88, value: 'Follow'},
                {key:89, value: 'Footway'},
                {key:90, value: 'Ford'},
                {key:91, value: 'Foreshore'},
                {key:92, value: 'Formation'},
                {key:93, value: 'Freeway'},
                {key:94, value: 'Front'},
                {key:95, value: 'Frontage'},
                {key:96, value: 'Gap'},
                {key:97, value: 'Garden'},
                {key:98, value: 'Gardens'},
                {key:99, value: 'Gate'},
                {key:100, value: 'Gates'},
                {key:101, value: 'Gateway'},
                {key:102, value: 'Glade'},
                {key:103, value: 'Glen'},
                {key:104, value: 'Grange'},
                {key:105, value: 'Green'},
                {key:106, value: 'Ground'},
                {key:107, value: 'Grove'},
                {key:108, value: 'Gully'},
                {key:109, value: 'Harbour'},
                {key:110, value: 'Heath'},
                {key:111, value: 'Heights'},
                {key:112, value: 'Highroad'},
                {key:113, value: 'Highway'},
                {key:114, value: 'Hill'},
                {key:115, value: 'Hollow'},
                {key:116, value: 'Hub'},
                {key:117, value: 'Interchange'},
                {key:118, value: 'Intersection'},
                {key:119, value: 'Junction'},
                {key:120, value: 'Key'},
                {key:121, value: 'Keys'},
                {key:122, value: 'Landing'},
                {key:123, value: 'Lane'},
                {key:124, value: 'Laneway'},
                {key:125, value: 'Lees'},
                {key:126, value: 'Line'},
                {key:127, value: 'Link'},
                {key:128, value: 'Little'},
                {key:129, value: 'Lookout'},
                {key:130, value: 'Loop'},
                {key:131, value: 'Lower'},
                {key:132, value: 'Mall'},
                {key:133, value: 'Manor'},
                {key:134, value: 'Mead'},
                {key:135, value: 'Meander'},
                {key:136, value: 'Mears'},
                {key:137, value: 'Mew'},
                {key:138, value: 'Mews'},
                {key:139, value: 'Mile'},
                {key:140, value: 'Motorway'},
                {key:141, value: 'Mount'},
                {key:142, value: 'Nook'},
                {key:143, value: 'Outlet'},
                {key:144, value: 'Outlook'},
                {key:145, value: 'Parade'},
                {key:146, value: 'Park'},
                {key:147, value: 'Parklands'},
                {key:148, value: 'Parkway'},
                {key:149, value: 'Part'},
                {key:150, value: 'Pass'},
                {key:151, value: 'Passage'},
                {key:152, value: 'Path'},
                {key:153, value: 'Pathway'},
                {key:154, value: 'Piazza'},
                {key:155, value: 'Pier'},
                {key:156, value: 'Place'},
                {key:157, value: 'Plateau'},
                {key:158, value: 'Plaza'},
                {key:159, value: 'Pocket'},
                {key:160, value: 'Point'},
                {key:161, value: 'Port'},
                {key:162, value: 'Portal'},
                {key:163, value: 'Promenade'},
                {key:164, value: 'Pursuit'},
                {key:165, value: 'Quad'},
                {key:166, value: 'Quadrangle'},
                {key:167, value: 'Quadrant'},
                {key:168, value: 'Quay'},
                {key:169, value: 'Quays'},
                {key:170, value: 'Ramble'},
                {key:171, value: 'Ramp'},
                {key:172, value: 'Range'},
                {key:173, value: 'Reach'},
                {key:174, value: 'Reserve'},
                {key:175, value: 'Rest'},
                {key:176, value: 'Retreat'},
                {key:177, value: 'Return'},
                {key:178, value: 'Ride'},
                {key:179, value: 'Ridge'},
                {key:180, value: 'Ridgeway'},
                {key:181, value: 'RightOfWay'},
                {key:182, value: 'Ring'},
                {key:183, value: 'Rise'},
                {key:184, value: 'Rising'},
                {key:185, value: 'River'},
                {key:186, value: 'Riverway'},
                {key:187, value: 'Riviera'},
                {key:188, value: 'Road'},
                {key:189, value: 'Roads'},
                {key:190, value: 'Roadside'},
                {key:191, value: 'Roadway'},
                {key:192, value: 'Ronde'},
                {key:193, value: 'Rosebowl'},
                {key:194, value: 'Rotary'},
                {key:195, value: 'Round'},
                {key:196, value: 'Route'},
                {key:197, value: 'Row'},
                {key:198, value: 'Rue'},
                {key:199, value: 'Run'},
                {key:200, value: 'ServiceWay'},
                {key:201, value: 'Shunt'},
                {key:202, value: 'Siding'},
                {key:203, value: 'Slope'},
                {key:204, value: 'Sound'},
                {key:205, value: 'Spur'},
                {key:206, value: 'Square'},
                {key:207, value: 'Stairs'},
                {key:208, value: 'StateHighway'},
                {key:209, value: 'Steps'},
                {key:210, value: 'Strand'},
                {key:211, value: 'Street'},
                {key:212, value: 'Streets'},
                {key:213, value: 'Strip'},
                {key:214, value: 'Subway'},
                {key:215, value: 'Tarn'},
                {key:216, value: 'TarniceWay'},
                {key:217, value: 'Terrace'},
                {key:218, value: 'Thoroughfare'},
                {key:219, value: 'Throughway'},
                {key:220, value: 'Tollway'},
                {key:221, value: 'Top'},
                {key:222, value: 'Tor'},
                {key:223, value: 'Towers'},
                {key:224, value: 'Track'},
                {key:225, value: 'Trail'},
                {key:226, value: 'Trailer'},
                {key:227, value: 'Tramway'},
                {key:228, value: 'Triangle'},
                {key:229, value: 'Trunkway'},
                {key:230, value: 'Turn'},
                {key:231, value: 'Twist'},
                {key:232, value: 'Underpass'},
                {key:233, value: 'Upper'},
                {key:234, value: 'Vale'},
                {key:235, value: 'Valley'},
                {key:236, value: 'Viaduct'},
                {key:237, value: 'View'},
                {key:238, value: 'Village'},
                {key:239, value: 'Villas'},
                {key:240, value: 'Vista'},
                {key:241, value: 'Wade'},
                {key:242, value: 'Walk'},
                {key:243, value: 'Walkway'},
                {key:244, value: 'Waterway'},
                {key:245, value: 'Way'},
                {key:246, value: 'Wharf'},
                {key:247, value: 'Woods'},
                {key:248, value: 'Wynd'},
                {key:249, value: 'Yard'}

            ],
            GuarantorSegmentMinCount:1,
            GuarantorSegmentCount:1,
            GuarantorSegmentEntities:[],
            GuaranteeUniqueId:[],
            GuaranteeLimit:[],
            ProprietorSegmentMinCount:1,
            ProprietorSegmentCount:1,
            ProprietorSegmentEntities:[],
            ProprietorSegmentNames:[],
            ProprietorSegmentChangeNameFormRequired:[],
            ProprietorSegmentReasonForDifferentName:[],
            // ChangeNameYNList:[
            //     'No',
            //     'Yes'
            // ],
            // ChangeNameYNList:[
            //     {key: 'No', value: 'No'},
            //     {key: 'YesRegForm', value: 'Yes Reg Form'},
            //     {key: 'YesStatDec', value: 'Yes Stat Dec'}
            // ],

            ChangeNameYNList:[
                {key: 'No', value: 'No'},
                {key: 'Yes', value: 'Yes '}
            ],

            // ReasonForDifferentNameList:[
            //     'Abbreviation',
            //     'Amalgamation',
            //     'Amalgamation Evidence',
            //     'Change Incorporated Name',
            //     'Deed Poll',
            //     'Error in Register',
            //     'Legislative Change Company',
            //     'Legislative Change Company Evidence',
            //     'Amalgamation Evidence',
            //     'Legislative Change Non Company Evidence',
            //     'Marriage',
            //     'Merger or Takeover',
            //     'Merger or Takeover Evidence',
            //     'New Name',
            //     'Resumption of Maiden Name',
            //     'Sale or Transfer',
            //     'Sale or Transfer Evidence'
            // ],
            ReasonForDifferentNameList:[
                {key: 'Abbreviation', value: 'Abbreviation'},
                {key: 'Amalgamation', value: 'Amalgamation'},
                {key: 'AmalgamationEvidence', value: 'Amalgamation Evidence'},
                {key: 'ChangeIncorporatedName', value: 'Change Incorporated Name'},
                {key: 'DeedPoll', value: 'Deed Poll'},
                {key: 'ErrorinRegister', value: 'Error in Register'},
                {key: 'LegislativeChangeCompany', value: 'Legislative Change Company'},
                {key: 'LegislativeChangeCompanyEvidence', value: 'Legislative Change Company Evidence'},
                {key: 'LegislativeChangeNonCompany', value: 'Legislative Change Non Company'},
                {key: 'LegislativeChangeNonCompanyEvidence', value: 'Legislative Change Non Company Evidence'},
                {key: 'Marriage', value: 'Marriage'},
                {key: 'MergerorTakeover', value: 'Merger or Takeover'},
                {key: 'MergerorTakeoverEvidence', value: 'Merger or Takeover Evidence'},
                {key: 'NewName', value: 'New Name'},
                {key: 'ResumptionofMaidenName', value: 'Resumption of Maiden Name'},
                {key: 'SaleorTransfer', value: 'Sale or Transfer'},
                {key: 'SaleorTransferEvidence', value: 'Sale or Transfer Evidence'}
            ],

            NonRealEstateSegmentMinCount:1,
            NonRealEstateSegmentCount:1,
            NonRealEstateSegmentEntities:[],
            NonRealEstateSegmentAccountNumbers:[],
            NonRealEstateSegmentAccountAmounts:[],
            NonRealEstateSegmentAccountOwners:[],


            RealEstateSegmentMinCount:1,
            RealEstateSegmentCount:1,
            RealEstateSegmentEntities:[],

            RealEstateAssetSegmentMinCount:1,
            RealEstateAssetSegmentCount:1,
            RealEstateAssetSegmentEntities:[],
            RealEstateAssetProprietorSegmentParties: [],
            RealEstateAssetProprietorSegmentFirstNames:[],
            RealEstateAssetProprietorSegmentLastNames:[],
            RealEstateAssetProprietorSegmentChangeNameFormRequired:[],
            RealEstateAssetProprietorSegmentReasonForDifferentName:[],
            RealEstateAssetProprietorSegmentUniqueIds:[],
            RealEstateAssetProprietorTitleUniqueIds: [],
            RealEstateAssetTitleSegmentTitles:[],
            RealEstateAssetTitleSegmentTitleReferences: [],
            RealEstateAssetTitleSegmentTitleUniqueIds: [],
            RealEstateAssetTitleSegmentTitleAddresses: [], 


            IsThereProtectedTenant:[],
            ProtectedTenantXTenant:[],
            RelationshipOfTenantToBorrower:[],
            ProtectedTenantFirstName:[],
            ProtectedTenantMiddleName:[],
            ProtectedTenantLastName:[],
            KinRelationshipList:[
                {key: 'Child', value: 'Child'},
                {key: 'Friend', value: 'Friend'},
                {key: 'Grandparent', value: 'Grandparent'},
                {key: 'OtherRelative', value: 'Other Relative'},
                {key: 'Parent', value: 'Parent'},
                {key: 'Sibling', value: 'Sibling'},
                {key: 'Spouse', value: 'Spouse'}
            ],

            ProprietorSegmentMinCount:1,
            ProprietorSegmentCount:1,
            ProprietorSegmentEntities:[],
            ProprietorSegmentNames:[],
            ProprietorSegmentChangeNameFormRequired:[],
            ProprietorSegmentReasonForDifferentName:[],
            ProprietorSegmentUniqueIds:[],
            ProprietorTitleUniqueIds: [],

            RealEstateAssetTitles: [],
            TitleSegmentMinCount:1,
            TitleSegmentCount:1,
            TitleSegmentEntities: [],            
            TitleSegmentTitleReferences: [],
            TitleSegmentTitleUniqueIds: [],
            TitleSegmentTitleAddresses: [], 

            TrustSegmentMinCount:1,
            TrustSegmentCount:1,
            TrustSegmentEntities:[],
            ApplicantTypeList:[
                {key: 'Borrower', value: 'Borrower'},
                {key: 'BorrowerandGuarantor', value: 'Borrower and Guarantor'},
                {key: 'Guarantor', value: 'Guarantor'},
            ],
            TrustSegmentApplicantType:[],
            TrustSegmentTrustNames:[],
            TrustSegmentTrustABNs:[],

            SecuritySegmentAddreses:[],
            SecuritySegmentTitleReferences:[],
            SecuritySegmentTitleAddresses:[],
            SecuritySegmentEntities:[],
            SecuritySegmentMinCount:1,
            SecuritySegmentCount:1,
            SecuritySegmentMaxCount:1000,
            SimultaneousSettlementSegmentEntities:[],
            SimultaneousSettlementSegmentCount:0,
            SimultaneousSettlementSegmentMaxCount:1,
            SimultaneousSettlementSegmentLoanIds:[],
            LoanHasSimultaneous:false,
            ImportantNoteSegmentEntities:[],
            ImportantNoteSegmentCount:1,
            ImportantNoteSegmentMinCount:1,
            ImportantNoteSegmentMaxCount:1,
            HasImportantNoteSegmentGuarantorTitleDeedToAddress:false,
            ImportantNoteSegmentGuarantorTitleDeedToAddress:[],
            HasBorrowerClearTitleToAddress:false,
            BorrowerClearTitleToAddress:[],
            ImportantNoteSegmentSettlementNotToBeBookedPriorToDates:[],
            ImportantNoteSegmentCollectSurplus:[],
            ImportantNoteSegmentGuarantorNames:[],
            SpecialConditionSegmentEntities:[],
            SpecialConditionSegmentCount:0,
            SpecialConditionSegmentConditionValues:[],
            SpecialConditionSegmentConditionTypes:[],
            DischargeinstructionOtherRequests:'',
            InstructionType:null,
            InstructionLenderReference:null,
            
            MatterExists:false,
            CheckedMatter:false,
            MsaReference:null,
            MatterDescription:null,
            MatterLenderReference:null,
            DateValidGood:true,
            SecurityDischargeReasons:[],
            DischargeTypeChoice:[],

            SelfActing:[],
            States:[],
            DischargeTypes:{},
            MappedStates:[],
            MappedStreetTypes:[],
            MappedCountries:[],
            MappedChangeNameReason:[],
            MappedYN:[],
            msg:'',

            PayoutHasSpecialConditions:false,
            PayoutSpecialConditions:'',

            HasAnticipatedDate:false,
            MatterDateValid:null,
            MatterAdditionalNotes:null,
            MatterLoanAccounts:null,
            PayoutStage:'',
            shouldRedirect:false,
            InstructionSubmit:false,
            CanInstructNewLoans: false,
            CanInstructDischarges: false,
            CanProvideDischargePayout: false,
            CanInstructDischargeUpload: false,
            IsLenderThinkTank: false,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            userdetails: userdetails,
            emailValidationError: null,
            profileDisplay: "",	
            lenderName: lendername,
            shouldverifyQLDSecurityTitle: false,
            SecurityQLDAddressesMatched: [],
            SecurityQLDAddressOverridden: [],
            SecurityQLDDetailsVerified: [],
            SecurityQLDDetailsNeedsToBeVerified:[],
            SecurityQLDVerifiedAddress:[],
            SecurityQLDLotNo:[],
            SecurityQLDPlanNo:[],
            SecurityVerifierLoading:[],
            SecurityQLDNotExist:[],

            shouldverifyNSWSecurityTitle: false,            
            SecurityNSWVerifiedAddress:[],
            SecurityNSWVerifiedLandAddress:[],
            SecurityNSWNotExist:[],
            SecurityNSWAddressesMatched:[],
            SecurityNSWDetailsNeedsToBeVerified: [],
            SecurityNSWAddressOverridden: [],
            SecurityNSWDetailsVerified: [],

            shouldverifyVICSecurityTitle: false,
            SecurityVICAddressesMatched: [],
            SecurityVICAddressOverridden: [],
            SecurityVICDetailsVerified: [],
            SecurityVICDetailsNeedsToBeVerified:[],
            SecurityVICVerifiedAddress:[],
            SecurityVICLotNo:[],
            SecurityVICPlanNo:[],
            SecurityVICNotExist:[],
            SecurityVICTitleAddress:[],            
            
            SecurityShouldBeVerifiedFirst: false,
            MatterDetails: [],

            // Upload Title Search
            CanUploadTitleSearch: false,
            SecurityTitleSearches: [],
            files:[],
            filenames: [],
            SecurityTitleFiles: [],
            SecurityTitleSearchFilenames: [],
            SecurityMortgageeExists:[],
            SecurityMortgageeTitleSearchFiles:[],
            SecurityMortgageeId:[],
            SecurityMortgageeNames:[],
            SecurityMortgageeNamesManual:[],
            SecurityMortgageeCompanyACN:[],
            SecurityMortgageNumbers:[],
            SecurityMortgageeFormattedTitleRefNo:[],
            SecurityMortgageeVolume:[],
            SecurityMortgageeFolio:[],
            SecurityMortgageeBlock:[],
            SecurityMortgageeDistrictDivision:[],
            SecurityMortgageeLandDescription:[],
            SecurityMortgageeSection:[],
            SecurityMortgageeUnit:[],
            SecurityMortgageeLot:[],
            SecurityMortgageeTown:[],
            SecurityStateReadOnly:[],
            LenderMortgagees:[],
            MappedLenderMortgagees:[],
            InsightBox:0, 
            displayPPSRDischarges: false,
            //token: token,
            sessionHasExpired: false,
            redirectToLogin: false,
            IsTitleSearchFromUpload: [],
            TitleReferenceValid: [],
            TitleRefFormat: [],
            // StreetTypeList:[
            //     'Access','Alley','Alleyway','Amble','Anchorage','Approach','Arcade','Arterial','Artery','Avenue','Banan','Bank','Basin','Bay','Beach','Bend','Block','Boardwalk','Boulevard','Boulevarde','Bowl','Brace','Brae','Break','Bridge','Broadway','Brow','Bypass','Byway',
            //     'Causeway','Centre','Centreway','Chase','Circle','Circlet','Circuit','Circus','Close','Cluster','Colonnade','Common','Concourse','Connection','Copse','Corner','Corso','Course','Court','Courtyard','Cove','Crescent',
            //     'Crest','Cross','Crossing','Crossroad','Crossway','Cruiseway','Cul-de-Sac','Cutting','Dale','Dell','Dene','Deviation','Dip','Distributor','Divide','Dock','Domain','Drive','Driveway','Edge','Elbow','End','Entrance',
            //     'Esplanade','Estate','Expressway','Extension','Fairway','Firebreak','Fireline','Firetrack','Firetrail','Flat','Flats','Follow','Footway','Ford','Foreshore','Formation','Freeway','Front','Frontage','Gap','Garden',
            //     'Gardens','Gate','Gateway','Glade','Glen','Grange','Green','Ground','Grove','Gully','Harbour','Heath','Heights','Highroad','Highway','Hill','Hollow','Hub','Interchange','Intersection','Island','Junction','Key','Keys',
            //     'Landing','Lane','Laneway','Lees','Line','Link','Little','Lookout','Loop','Lower','Mall','Manor','Meander','Mew','Mews','Motorway','Mount','Nook','Outlet','Outlook','Parade','Park','Parklands','Parkway','Part','Pass',
            //     'Passage','Path','Pathway','Piazza','Place','Plateau','Plaza','Pocket','Point','Port','Promenade','Pursuit','Quad','Quadrangle','Quadrant','Quay','Quays','Ramble','Ramp','Range','Reach','Reserve','Rest','Retreat','Return',
            //     'Ride','Ridge','Ridgeway','Right of Way','Ring','Rise','Rising','River','Riverway','Riviera','Road','Roads','Roadside','Roadway','Ronde','Rosebowl','Rotary','Round','Route','Row','Rue','Run','Service Way','Shunt',
            //     'Siding','Slope','Sound','Spur','Square','Stairs','State Highway','Steps','Strand','Street','Strip','Subway','Tarn','Terrace','Thoroughfare','Throughway','Tollway','Top','Tor','Towers','Track','Trail','Trailer','Triangle',
            //     'Trunkway','Turn','Twist','Underpass','Upper','Vale','Valley','Viaduct','View','Views','Villas','Vista','Wade','Walk','Walkway','Waters','Waterway','Way','Wharf','Woods','Wynd','Yard'
            // ],
            // CountryCodeList:[
            //     'AD','AE','AF','AG','AI','AL','AM','AN','AO','AQ','AR','AS','AT','AU','AW','AX','AZ','BA','BB','BD','BE','BF','BG','BH','BI','BJ','BL','BM','BN','BO','BR','BS','BT','BV','BW','BY','BZ','CA','CC','CD','CF','CG',
            //     'CH','CI','CK','CL','CM','CN','CO','CR','CU','CV','CX','CY','CZ','DE','DJ','DK','DM','DO','DZ','EC','EE','EG','EH','ER','ES','ET','FI','FJ','FK','FM','FO','FR','GA','GB','GD','GE','GF','GG','GH','GI','GL',
            //     'GM','GN','GP','GQ','GR','GS','GT','GU','GW','GY','HK','HM','HN','HR','HT','HU','ID','IE','IL','IM','IN','IO','IQ','IR','IS','IT','JE','JM','JO','JP','KE','KG','KH','KI','KM','KN','KP','KR','KW','KY','KZ',
            //     'LA','LB','LC','LI','LK','LR','LS','LT','LU','LV','LY','MA','MC','MD','ME','MF','MG','MH','MK','ML','MM','MN','MO','MP','MQ','MR','MS','MT','MU','MV','MW','MX','MY','MZ','NA','NC','NE','NF','NG','NI','NL',
            //     'NO','NP','NR','NU','NZ','OM','PA','PE','PF','PG','PH','PK','PL','PM','PN','PR','PS','PT','PW','PY','QA','RE','RO','RS','RU','RW','SA','SB','SC','SD','SE','SG','SH','SI','SJ','SK','SL','SM','SN','SO','SR',
            //     'SS','ST','SV','SY','SZ','TC','TD','TF','TG','TH','TJ','TK','TL','TM','TN','TO','TR','TT','TV','TW','TZ','UA','UG','UM','US','UY','UZ','VA','VC','VE','VG','VI','VN','VU','WF','WS','YE','YT','ZA','ZM','ZW'
            // ],

            CountryCodeList:[
                {key:0, value: 'AD'},
                {key:1, value: 'AE'},
                {key:2, value: 'AF'},
                {key:3, value: 'AG'},
                {key:4, value: 'AI'},
                {key:5, value: 'AL'},
                {key:6, value: 'AM'},
                {key:7, value: 'AO'},
                {key:8, value: 'AQ'},
                {key:9, value: 'AR'},
                {key:10, value: 'AS'},
                {key:11, value: 'AT'},
                {key:12, value: 'AU'},
                {key:13, value: 'AW'},
                {key:14, value: 'AX'},
                {key:15, value: 'AZ'},
                {key:16, value: 'BA'},
                {key:17, value: 'BB'},
                {key:18, value: 'BD'},
                {key:19, value: 'BE'},
                {key:20, value: 'BF'},
                {key:21, value: 'BG'},
                {key:22, value: 'BH'},
                {key:23, value: 'BI'},
                {key:24, value: 'BJ'},
                {key:25, value: 'BL'},
                {key:26, value: 'BM'},
                {key:27, value: 'BN'},
                {key:28, value: 'BO'},
                {key:29, value: 'BQ'},
                {key:30, value: 'BR'},
                {key:31, value: 'BS'},
                {key:32, value: 'BT'},
                {key:33, value: 'BV'},
                {key:34, value: 'BW'},
                {key:35, value: 'BY'},
                {key:36, value: 'BZ'},
                {key:37, value: 'CA'},
                {key:38, value: 'CC'},
                {key:39, value: 'CD'},
                {key:40, value: 'CF'},
                {key:41, value: 'CG'},
                {key:42, value: 'CH'},
                {key:43, value: 'CI'},
                {key:44, value: 'CK'},
                {key:45, value: 'CL'},
                {key:46, value: 'CM'},
                {key:47, value: 'CN'},
                {key:48, value: 'CO'},
                {key:49, value: 'CR'},
                {key:50, value: 'CU'},
                {key:51, value: 'CV'},
                {key:52, value: 'CW'},
                {key:53, value: 'CX'},
                {key:54, value: 'CY'},
                {key:55, value: 'CZ'},
                {key:56, value: 'DE'},
                {key:57, value: 'DJ'},
                {key:58, value: 'DK'},
                {key:59, value: 'DM'},
                {key:60, value: 'DO'},
                {key:61, value: 'DZ'},
                {key:62, value: 'EC'},
                {key:63, value: 'EE'},
                {key:64, value: 'EG'},
                {key:65, value: 'EH'},
                {key:66, value: 'ER'},
                {key:67, value: 'ES'},
                {key:68, value: 'ET'},
                {key:69, value: 'FI'},
                {key:70, value: 'FJ'},
                {key:71, value: 'FK'},
                {key:72, value: 'FM'},
                {key:73, value: 'FO'},
                {key:74, value: 'FR'},
                {key:75, value: 'GA'},
                {key:76, value: 'GB'},
                {key:77, value: 'GD'},
                {key:78, value: 'GE'},
                {key:79, value: 'GF'},
                {key:80, value: 'GG'},
                {key:81, value: 'GH'},
                {key:82, value: 'GI'},
                {key:83, value: 'GL'},
                {key:84, value: 'GM'},
                {key:85, value: 'GN'},
                {key:86, value: 'GP'},
                {key:87, value: 'GQ'},
                {key:88, value: 'GR'},
                {key:89, value: 'GS'},
                {key:90, value: 'GT'},
                {key:91, value: 'GU'},
                {key:92, value: 'GW'},
                {key:93, value: 'GY'},
                {key:94, value: 'HK'},
                {key:95, value: 'HM'},
                {key:96, value: 'HN'},
                {key:97, value: 'HR'},
                {key:98, value: 'HT'},
                {key:99, value: 'HU'},
                {key:100, value: 'ID'},
                {key:101, value: 'IE'},
                {key:102, value: 'IL'},
                {key:103, value: 'IM'},
                {key:104, value: 'IN'},
                {key:105, value: 'IO'},
                {key:106, value: 'IQ'},
                {key:107, value: 'IR'},
                {key:108, value: 'IS'},
                {key:109, value: 'IT'},
                {key:110, value: 'JE'},
                {key:111, value: 'JM'},
                {key:112, value: 'JO'},
                {key:113, value: 'JP'},
                {key:114, value: 'KE'},
                {key:115, value: 'KG'},
                {key:116, value: 'KH'},
                {key:117, value: 'KI'},
                {key:118, value: 'KM'},
                {key:119, value: 'KN'},
                {key:120, value: 'KP'},
                {key:121, value: 'KR'},
                {key:122, value: 'KW'},
                {key:123, value: 'KY'},
                {key:124, value: 'KZ'},
                {key:125, value: 'LA'},
                {key:126, value: 'LB'},
                {key:127, value: 'LC'},
                {key:128, value: 'LI'},
                {key:129, value: 'LK'},
                {key:130, value: 'LR'},
                {key:131, value: 'LS'},
                {key:132, value: 'LT'},
                {key:133, value: 'LU'},
                {key:134, value: 'LV'},
                {key:135, value: 'LY'},
                {key:136, value: 'MA'},
                {key:137, value: 'MC'},
                {key:138, value: 'MD'},
                {key:139, value: 'ME'},
                {key:140, value: 'MF'},
                {key:141, value: 'MG'},
                {key:142, value: 'MH'},
                {key:143, value: 'MK'},
                {key:144, value: 'ML'},
                {key:145, value: 'MM'},
                {key:146, value: 'MN'},
                {key:147, value: 'MO'},
                {key:148, value: 'MP'},
                {key:149, value: 'MQ'},
                {key:150, value: 'MR'},
                {key:151, value: 'MS'},
                {key:152, value: 'MT'},
                {key:153, value: 'MU'},
                {key:154, value: 'MV'},
                {key:155, value: 'MW'},
                {key:156, value: 'MX'},
                {key:157, value: 'MY'},
                {key:158, value: 'MZ'},
                {key:159, value: 'NA'},
                {key:160, value: 'NC'},
                {key:161, value: 'NE'},
                {key:162, value: 'NF'},
                {key:163, value: 'NG'},
                {key:164, value: 'NI'},
                {key:165, value: 'NL'},
                {key:166, value: 'NO'},
                {key:167, value: 'NP'},
                {key:168, value: 'NR'},
                {key:169, value: 'NU'},
                {key:170, value: 'NZ'},
                {key:171, value: 'OM'},
                {key:172, value: 'PA'},
                {key:173, value: 'PE'},
                {key:174, value: 'PF'},
                {key:175, value: 'PG'},
                {key:176, value: 'PH'},
                {key:177, value: 'PK'},
                {key:178, value: 'PL'},
                {key:179, value: 'PM'},
                {key:180, value: 'PN'},
                {key:181, value: 'PR'},
                {key:182, value: 'PS'},
                {key:183, value: 'PT'},
                {key:184, value: 'PW'},
                {key:185, value: 'PY'},
                {key:186, value: 'QA'},
                {key:187, value: 'RE'},
                {key:188, value: 'RO'},
                {key:189, value: 'RS'},
                {key:190, value: 'RU'},
                {key:191, value: 'RW'},
                {key:192, value: 'SA'},
                {key:193, value: 'SB'},
                {key:194, value: 'SC'},
                {key:195, value: 'SD'},
                {key:196, value: 'SE'},
                {key:197, value: 'SG'},
                {key:198, value: 'SH'},
                {key:199, value: 'SI'},
                {key:200, value: 'SJ'},
                {key:201, value: 'SK'},
                {key:202, value: 'SL'},
                {key:203, value: 'SM'},
                {key:204, value: 'SN'},
                {key:205, value: 'SO'},
                {key:206, value: 'SR'},
                {key:207, value: 'SS'},
                {key:208, value: 'ST'},
                {key:209, value: 'SV'},
                {key:210, value: 'SX'},
                {key:211, value: 'SY'},
                {key:212, value: 'SZ'},
                {key:213, value: 'TC'},
                {key:214, value: 'TD'},
                {key:215, value: 'TF'},
                {key:216, value: 'TG'},
                {key:217, value: 'TH'},
                {key:218, value: 'TJ'},
                {key:219, value: 'TK'},
                {key:220, value: 'TL'},
                {key:221, value: 'TM'},
                {key:222, value: 'TN'},
                {key:223, value: 'TO'},
                {key:224, value: 'TR'},
                {key:225, value: 'TT'},
                {key:226, value: 'TV'},
                {key:227, value: 'TW'},
                {key:228, value: 'TZ'},
                {key:229, value: 'UA'},
                {key:230, value: 'UG'},
                {key:231, value: 'UM'},
                {key:232, value: 'US'},
                {key:233, value: 'UY'},
                {key:234, value: 'UZ'},
                {key:235, value: 'VA'},
                {key:236, value: 'VC'},
                {key:237, value: 'VE'},
                {key:238, value: 'VG'},
                {key:239, value: 'VI'},
                {key:240, value: 'VN'},
                {key:241, value: 'VU'},
                {key:242, value: 'WF'},
                {key:243, value: 'WS'},
                {key:244, value: 'XK'},
                {key:245, value: 'YE'},
                {key:246, value: 'YT'},
                {key:247, value: 'ZA'},
                {key:248, value: 'ZM'}

            ],

            LenderFile: '',
            LenderReference:'',
            base64: '',
            matterId: null,
            LenderFiles:[],
            canUserGenralUpload: false,
            NTandActAddressSegment: []
        }
    

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.CheckMatter=this.CheckMatter.bind(this);
        this.handleAccordionCallback = this.handleAccordionCallback.bind(this);
        this.GetStates = this.GetStates.bind(this);
        this.PartyOverseasResidentialAddressUpdated = this.PartyOverseasResidentialAddressUpdated.bind(this);
        this.LendingGuaranteeLimitUpdated = this.LendingGuaranteeLimitUpdated.bind(this);
        this.NonRealEstateSegmentAccountNumbersUpdated = this.NonRealEstateSegmentAccountNumbersUpdated.bind(this);
        this.NonRealEstateSegmentAccountAmountsUpdated = this.NonRealEstateSegmentAccountAmountsUpdated.bind(this);        
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
        this.GetMatterLatestXmlFile = this.GetMatterLatestXmlFile.bind(this);
        // this.ProtectedTenantFirstNameUpdated = this.ProtectedTenantFirstNameUpdated.bind(this);
        // this.ProtectedTenantMiddleNameUpdated = this.ProtectedTenantMiddleNameUpdated.bind(this);
        // this.ProtectedTenantLastNameUpdated = this.ProtectedTenantLastNameUpdated.bind(this);
        // this.ProtectedTenantFirstNameUpdated = this.ProtectedTenantFirstNameUpdated.bind(this);
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    ResetForm(){
        this.setState({
            PartySegmentPhoneNumbers:[],
            PartySegmentPartyTypes:[],
            PartySegmentPorCTypes:[],
            PartySegmentCompanyPPSR:[],
            PartySegmentNames:[],
            PartySegmentEmails:[],
            PartySegmentAddresses:[],
            PartySegmentMinCount:1,
            PartySegmentCount:1,
            PartySegmentEntities:[],
            SecuritySegmentAddreses:[],
            SecuritySegmentTitleReferences:[],
            SecuritySegmentEntities:[],
            SecuritySegmentMinCount:1,
            SecuritySegmentCount:1,
            LoanIds:[],
            IndicativeAmounts:[],
            LoanIdEntityCount:1,
            LoanIdEntityMinCount:1,
            LoanIdEntities:[],
            
        },()=>{
            //this.UpdateAddressSection();
            // this.UpdateProprietorSegment();
            this.UpdateLendingGuaranteeSegment();
            this.UpdateNonRealEstateSegment();
            this.UpdateSecuritySegment();
            // this.UpdateLoanIdSegment();
            this.UpdateRealEstateSegment();
            this.UpdateTitleSegment();
            // this.UpdatePartySegment();
            this.UpdateTrustSegment();
        });

    }
    
    componentDidMount(){
        document.title='Loantrak - Edit Instruction';
        AuthService.isNewVersionDetected();

        this.setState({
            isLoading: true,
            //InstructionSubmit:true,
        });     

        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("edit instruction").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }

        AuthService.isUserCanGenralUpload().then((resp)=>{
            this.setState({canUserGenralUpload:true});
        },(err)=>{
            this.setState({canUserGenralUpload:false});
        });

        this.GetStates();


        //check if matter is provisionally on hold       
        MatterService.CheckMatterIsProvisionalOnHold(this.state.matter).then(
            (resp)=>{
              this.setState({
                MatterIsPOH: resp.data.Content,
                shouldRedirect: resp.data.Content == true ? false : true 
            },()=>
              {
                if(this.state.MatterIsPOH)
                {
                    // this.GetStates();
                    this.GetMatterLatestXmlFile();
                    // this.UpdateAddressSection();
                    // this.UpdateProprietorSegment();
                    // this.UpdateLendingGuaranteeSegment();
                }    
        
              })
            },
            (err)=>{
              this.setState({MatterIsPOH:false})
            }
        );     

        
 
    }

    GetMatterLatestXmlFile()
    {
        // e.preventDefault();
        MatterService.getMatterLatestXml(this.state.matter).then((resp)=>{
            console.log("getMatterLatestXml")
            console.log(resp)
            var ntAndActAddress = [];
            this.setState({msg:'',
                // InstructionSubmit:true,
                isLoading:false,
                matterId: 1, 
                //successfulSubmit: true,
                Application: resp.data.Content.application,
                Instructions: resp.data.Content.instructions,
                Publisher: resp.data.Content.publisher,
                Recipient: resp.data.Content.recipient,
                SchemaVersion: resp.data.Content.schemaVersion,
                PackageContentNeedsAnalysis: resp.data.Content.packageContentNeedsAnalysis,
                AccountVariation: resp.data.Content.accountVariation,
                Address: resp.data.Content.address,
                BusinessChannel: resp.data.Content.businessChannel,
                CompanyApplicant: resp.data.Content.companyApplicant,
                CompanyFinancials: resp.data.Content.companyFinancials,
                ContributionFunds: resp.data.Content.contributionFunds,
                Declarations: resp.data.Content.declarations,
                DepositAccountDetails: resp.data.Content.depositAccountDetails,
                DetailedComment: resp.data.Content.detailedComment,
                Funder: resp.data.Content.funder,
                Household: resp.data.Content.household,
                Insurance: resp.data.Content.insurance,
                LendingGuarantee: resp.data.Content.lendingGuarantee,
                Liability: resp.data.Content.liability,
                LoanDetails: resp.data.Content.loanDetails,
                MasterAgreement: resp.data.Content.masterAgreement,
                NonRealEstateAsset: resp.data.Content.nonRealEstateAsset,
                OtherExpense: resp.data.Content.otherExpense,
                OtherIncome: resp.data.Content.otherIncome,
                Overview: resp.data.Content.overview,
                PersonApplicant: resp.data.Content.personApplicant,
                ProductSet: resp.data.Content.productSet,
                RealEstateAsset: resp.data.Content.realEstateAsset,
                RelatedCompany: resp.data.Content.relatedCompany,
                RelatedPerson: resp.data.Content.relatedPerson,
                SalesChannel: resp.data.Content.salesChannel,
                Settlement: resp.data.Content.settlement,
                SplitLoan: resp.data.Content.splitLoan,
                Summary: resp.data.Content.summary,
                TrustApplicant: resp.data.Content.trustApplicant,
                VendorTaxInvoice: resp.data.Content.vendorTaxInvoice,
                ProductionData: resp.data.Content.productionData,
                ProductionDataSpecified: resp.data.Content.productionDataSpecified,
                UniqueId: resp.data.Content.uniqueId,
                editMatter: true,
                // matterId: resp.data.Content.MatterId,
                // rework: resp.data.Content.Rework
            },()=>{
                console.log("detailed comment")
                console.log(this.state.DetailedComment)
                //SettlementInformation
                //if(this.state.DetailedComment.length > 0)
                if(this.state.DetailedComment!=null)
                {
                    this.state.DetailedComment.forEach((i, e)=>{
                        if(this.state.DetailedComment[e].contextDescriptionField == "Settlement Information")
                        {
                            if(this.state.DetailedComment[e].commentField == "Limited Guarantee - Limit to be checked")
                            {
                                this.setState({
                                    isMiniHoldLimitedGuarantee: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "Details of Protected Tenant to be provided for Reverse Mortgage")
                            {
                                this.setState({
                                    isMiniHoldReverseMortgage: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "Overseas address to be checked")
                            {
                                this.setState({
                                    isMiniHoldOverseaAddress: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "Change of name required for one or more security owners")
                            {
                                this.setState({
                                    isMiniHoldChangeofName: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "Lenders Disclosure Documents to be uploaded for guarantor loan")
                            {
                                this.setState({
                                    isMiniHoldLendersDisclosureDocuments: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "Independent Legal Advice waived for one or more loan parties - set to Recommended for each affected loan party")
                            {
                                this.setState({
                                    isMiniHoldIndependentLegalAdviceWaived: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "Independent Financial Advice required for one or more loan parties - set to Required for each affected loan party")
                            {
                                this.setState({
                                    isMiniHoldIndependentFinancialAdviceRequired: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "Interpreter Required for one or more loan parties - set Understands Application to No for each affected loan party")
                            {
                                this.setState({
                                    isMiniHoldIntrepreterRequired: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "ACT Security")
                            {
                                this.setState({
                                    isMiniHoldACTSecurity: true
                                })   
                            }
                            if(this.state.DetailedComment[e].commentField == "NT Security")
                            {
                                this.setState({
                                    isMiniHoldNTSecurity: true
                                })   
                            }
                    }
                    })
                }

                console.log("ireneaddress")
                console.log(this.state.Address)
                // alert(this.state.Address[0].uniqueIDField)
                // alert(this.state.PersonApplicant[0].contactField.currentAddressField.x_MailingAddressField)
                if(this.state.RealEstateAsset.length > 0)
                {
                    var realEstateTitles = [];
                    this.state.RealEstateAsset.forEach((i, e)=>{
                        realEstateTitles.push(this.state.RealEstateAsset[e].titleField)
                    })

                    this.setState({
                        // RealEstateAssetTitles: realEstateTitles,
                        // RealEstateAssetTitleSegmentTitles: realEstateTitles
                        RealEstateSegmentCount: this.state.RealEstateAsset.length
                    },()=>{
                        this.PopulateRealEstateAsset()
                        //this.UpdateRealEstateAssetSegment();
                        console.log("RealEstateAssetTitles");
                        console.log(this.state.RealEstateAssetTitles);

                    });
                    
                }
                if(this.state.Address.length > 0){
                    this.state.Address.forEach((i, e)=>{
                        //if(this.state.Address[e].countryField!=12)//OverseasAddress
                        if(this.state.Address[e].overseasCountryField!=null || this.state.Address[e].countryField != 12)//OverseasAddress
                        {
                            // alert(e)
                            // alert(this.state.PersonApplicant[0])
                            // if((this.state.Address[e].uniqueIDField == this.state.PersonApplicant[e].contactField?.currentAddressField.x_MailingAddressField || this.state.Address[e].uniqueIDField == this.state.PersonApplicant[e].contactField?.currentAddressField.x_ResidentialAddressField) )
                            // {
                                // let ctr = 0;
                                // ctr=ctr+1;
                                this.setState({
                                    PartyHasOverseasAddress: true,
                                    //AddressSectionEntities: this.state.Address,
                                    // PartyOverseasResidentialAddress:  this.state.Address[0],
                                    // AddressSectionCount: ctr//this.state.Address.length
                                },()=>
                                {
                                    // console.log("PartyOverseasResidentialAddress");
                                    // console.log(this.state.PartyOverseasResidentialAddress);
                                    // if(this.state.PartyOverseasResidentialAddress!=null){
                                    //     alert('hi')
                                        // this.PopulateOverseasAddress(this.state.PartyOverseasResidentialAddress);
                                        this.PopulateOverseasAddress(this.state.Address[e]);
                                    // }
                                    //this.UpdateAddressSection();
                                });
                            // }
                            
                        }
                        //check if address is in ACT or NT
                        if(this.state.Address[e].australianStateField == 0 || this.state.Address[e].australianStateField == 2)
                        {
                            //alert('sdfsdf')
                            ntAndActAddress.push(this.state.Address[e])
                        }
                        

                    });

                    this.PopulateTitleRefAddress(this.state.Address)

                    
                    //this.PopulateTitleRefAddress2(ntAndActAddress)
                }


                if(this.state.LendingGuarantee!=null){
                    console.log(this.state.LendingGuarantee)
                    console.log("this.state.LendingGuarantee")
                    // alert("irene")
                    this.PopulateLendingGuarantee(this.state.LendingGuarantee);
                }

                if(this.state.PersonApplicant!=null){
                    this.PopulateParty(this.state.PersonApplicant);
                    this.PopulatePartyForPartySegment(this.state.PersonApplicant)
                    console.log("PersonApplicants");
                    console.log(this.state.PersonApplicant);
                    // this.setState({
                    //     // RealEstateAssetTitles: realEstateTitles,
                    //     // RealEstateAssetTitleSegmentTitles: realEstateTitles
                    //     PartySegmentEntities: this.state.PersonApplicant.length
                    // },()=>{
                    //     this.PopulatePartyForPartySegment()
                    //     //this.UpdateRealEstateAssetSegment();
                    //     console.log("PersonApplicants");
                    //     console.log(this.state.PersonApplicant);

                    // });
                }              
                
                
                // if(this.state.Address!=null){
                //     this.PopulateOverseasAddress(this.state.Address);
                // }

               
                
                console.log("SubmissionDate")
                console.log(this.state.SubmissionDate)
                console.log(this.state.PartySegment)
            });
        },
            err => {
                this.setState({
                    matterDetails: [],
                    loading: false,
                    shouldRedirect: true,
                    errorMessage: 'there is an error with the request'
                });
            }
        );

    }

    PopulateLendingGuarantee(lending)
    {
        this.setState({
            LendingGuaranteeSegment: lending
        },()=>{
            // alert(this.state.LendingGuaranteeSegment.length)
            

            let uniqueId = this.state.GuaranteeUniqueId;
            let limit = this.state.GuaranteeLimit;

           

           
                if(this.state.LendingGuaranteeSegment.length ==  1){

                    console.log("lending")
                    console.log(lending[0])

                    console.log("this.state.LendingGuaranteeSegment[0].limitField")
                    console.log(this.state.LendingGuaranteeSegment[0].limitField)
        
                    console.log("this.state.LendingGuaranteeSegment[0].uniqueIDField")
                    console.log(this.state.LendingGuaranteeSegment[0].uniqueIDField)
               

                    let uniqueId1 = this.state.LendingGuaranteeSegment[0].uniqueIDField;
                    uniqueId.push(uniqueId1);
                    let limit1 = this.state.LendingGuaranteeSegment[0].limitField;
                    limit.push(limit1);

                    // guaranteeUniqueId = this.state.LendingGuaranteeSegment[0].guaranteeUniqueId;
                    // guaranteeLimit = this.state.LendingGuaranteeSegment[0].limitField;
                    this.setState({
                        GuarantorSegmentCount: this.state.LendingGuaranteeSegment.length,
                        GuaranteeUniqueId: uniqueId,
                        GuaranteeLimit: limit,
                    },()=>{
                        console.log("this.state.GuaranteeUniqueId");
                        console.log(this.state.GuaranteeUniqueId);
                        console.log("this.state.GuaranteeLimit");
                        console.log(this.state.GuaranteeLimit);
                        this.UpdateLendingGuaranteeSegment();
                    });
    
                  
    
                }
                else if(this.state.LendingGuaranteeSegment.length > 1){
                    this.state.LendingGuaranteeSegment.forEach((i, e)=>{
                        let uniqueId1 = this.state.LendingGuaranteeSegment[e].uniqueIDField;
                        uniqueId.push(uniqueId1);
                        let limit1 = this.state.LendingGuaranteeSegment[e].limitField;
                        limit.push(limit1);

                        this.setState({
                            GuarantorSegmentCount: this.state.LendingGuaranteeSegment.length,
                            GuaranteeUniqueId: uniqueId,
                            GuaranteeLimit: limit,
                        },()=>{
                            console.log("this.state.GuaranteeUniqueId");
                            console.log(this.state.GuaranteeUniqueId);
                            console.log("this.state.GuaranteeLimit");
                            console.log(this.state.GuaranteeLimit);
                            this.UpdateLendingGuaranteeSegment();
                        });

                    });
                }

                
            })

           
       
    }

    PopulateOverseasAddress(address)
    {
        this.setState({
            OverseasAddressSegment: address
        },()=>{
            // alert(this.state.LendingGuaranteeSegment.length)
            

            // let uniqueId = this.state.GuaranteeUniqueId;
            // let limit = this.state.GuaranteeLimit;         
            console.log("OverseasAddressSegment")
            console.log(this.state.OverseasAddressSegment)
            // alert(this.state.OverseasAddressSegment.length)

            if(this.state.Address.length > 0){
                this.state.Address.forEach((i, e)=>{
                    // if(this.state.Address[e].countryField!=12)//OverseasAddress
                    if(this.state.Address[e].overseasCountryField!=null || this.state.Address[e].countryField != 12)//OverseasAddress
                    {
                        if(this.state.PersonApplicant!=null){
                            this.state.PersonApplicant.forEach(applicant => {
                                if((this.state.Address[e].uniqueIDField == applicant.contactField.currentAddressField.x_MailingAddressField || this.state.Address[e].uniqueIDField == applicant.contactField.currentAddressField.x_ResidentialAddressField) )
                                    {
                                        let ctr = 0;
                                        ctr=ctr+1;
                                        
                                        let uniqueId = this.state.PartyOverseasAddressUniqueId;       
                                        let uniqueId1 = this.state.Address[e].uniqueIDField;
                                        uniqueId.push(uniqueId1);
    
                                        let partyAdd = this.state.PartyOverseasResidentialAddress;                                    
            
                                        let addressEntity = {
                                            'streettype': this.state.Address[e].standardField.streetTypeField,
                                            'streetname': this.state.Address[e].standardField.streetNameField,
                                            'streetnumber': this.state.Address[e].standardField.streetNumberField,
                                            'suburb': this.state.Address[e].suburbField,
                                            'state': this.state.Address[e].overseasStateField,
                                            'postcode': this.state.Address[e].overseasPostCodeField,
                                            'country': this.state.Address[e].countryField
                                            // 'country': this.state.OverseasAddressSegment[e].overseasCountryField == null? 
                                            //             // this.state.OverseasAddressSegment[e].countryField == 170 ? 'NZ' 
                                            //             // : this.state.OverseasAddressSegment[e].countryField == 176 ? 'PH'   
                                            //             this.state.OverseasAddressSegment[e].countryField == 0 ? 'AD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 1 ? 'AE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 2 ? 'AF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 3 ? 'AG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 4 ? 'AI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 5 ? 'AL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 6 ? 'AM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 7 ? 'AO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 8 ? 'AQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 9 ? 'AR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 10 ? 'AS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 11 ? 'AT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 12 ? 'AU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 13 ? 'AW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 14 ? 'AX'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 15 ? 'AZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 16 ? 'BA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 17 ? 'BB'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 18 ? 'BD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 19 ? 'BE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 20 ? 'BF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 21 ? 'BG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 22 ? 'BH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 23 ? 'BI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 24 ? 'BJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 25 ? 'BL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 26 ? 'BM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 27 ? 'BN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 28 ? 'BO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 29 ? 'BQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 30 ? 'BR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 31 ? 'BS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 32 ? 'BT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 33 ? 'BV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 34 ? 'BW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 35 ? 'BY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 36 ? 'BZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 37 ? 'CA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 38 ? 'CC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 39 ? 'CD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 40 ? 'CF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 41 ? 'CG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 42 ? 'CH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 43 ? 'CI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 44 ? 'CK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 45 ? 'CL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 46 ? 'CM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 47 ? 'CN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 48 ? 'CO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 49 ? 'CR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 50 ? 'CU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 51 ? 'CV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 52 ? 'CW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 53 ? 'CX'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 54 ? 'CY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 55 ? 'CZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 56 ? 'DE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 57 ? 'DJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 58 ? 'DK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 59 ? 'DM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 60 ? 'DO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 61 ? 'DZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 62 ? 'EC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 63 ? 'EE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 64 ? 'EG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 65 ? 'EH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 66 ? 'ER'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 67 ? 'ES'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 68 ? 'ET'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 69 ? 'FI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 70 ? 'FJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 71 ? 'FK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 72 ? 'FM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 73 ? 'FO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 74 ? 'FR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 75 ? 'GA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 76 ? 'GB'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 77 ? 'GD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 78 ? 'GE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 79 ? 'GF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 80 ? 'GG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 81 ? 'GH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 82 ? 'GI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 83 ? 'GL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 84 ? 'GM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 85 ? 'GN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 86 ? 'GP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 87 ? 'GQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 88 ? 'GR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 89 ? 'GS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 90 ? 'GT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 91 ? 'GU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 92 ? 'GW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 93 ? 'GY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 94 ? 'HK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 95 ? 'HM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 96 ? 'HN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 97 ? 'HR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 98 ? 'HT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 99 ? 'HU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 100 ? 'ID'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 101 ? 'IE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 102 ? 'IL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 103 ? 'IM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 104 ? 'IN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 105 ? 'IO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 106 ? 'IQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 107 ? 'IR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 108 ? 'IS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 109 ? 'IT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 110 ? 'JE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 111 ? 'JM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 112 ? 'JO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 113 ? 'JP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 114 ? 'KE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 115 ? 'KG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 116 ? 'KH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 117 ? 'KI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 118 ? 'KM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 119 ? 'KN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 120 ? 'KP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 121 ? 'KR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 122 ? 'KW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 123 ? 'KY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 124 ? 'KZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 125 ? 'LA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 126 ? 'LB'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 127 ? 'LC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 128 ? 'LI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 129 ? 'LK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 130 ? 'LR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 131 ? 'LS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 132 ? 'LT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 133 ? 'LU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 134 ? 'LV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 135 ? 'LY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 136 ? 'MA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 137 ? 'MC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 138 ? 'MD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 139 ? 'ME'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 140 ? 'MF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 141 ? 'MG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 142 ? 'MH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 143 ? 'MK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 144 ? 'ML'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 145 ? 'MM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 146 ? 'MN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 147 ? 'MO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 148 ? 'MP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 149 ? 'MQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 150 ? 'MR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 151 ? 'MS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 152 ? 'MT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 153 ? 'MU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 154 ? 'MV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 155 ? 'MW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 156 ? 'MX'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 157 ? 'MY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 158 ? 'MZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 159 ? 'NA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 160 ? 'NC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 161 ? 'NE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 162 ? 'NF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 163 ? 'NG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 164 ? 'NI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 165 ? 'NL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 166 ? 'NO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 167 ? 'NP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 168 ? 'NR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 169 ? 'NU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 170 ? 'NZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 171 ? 'OM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 172 ? 'PA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 173 ? 'PE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 174 ? 'PF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 175 ? 'PG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 176 ? 'PH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 177 ? 'PK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 178 ? 'PL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 179 ? 'PM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 180 ? 'PN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 181 ? 'PR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 182 ? 'PS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 183 ? 'PT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 184 ? 'PW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 185 ? 'PY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 186 ? 'QA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 187 ? 'RE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 188 ? 'RO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 189 ? 'RS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 190 ? 'RU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 191 ? 'RW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 192 ? 'SA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 193 ? 'SB'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 194 ? 'SC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 195 ? 'SD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 196 ? 'SE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 197 ? 'SG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 198 ? 'SH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 199 ? 'SI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 200 ? 'SJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 201 ? 'SK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 202 ? 'SL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 203 ? 'SM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 204 ? 'SN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 205 ? 'SO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 206 ? 'SR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 207 ? 'SS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 208 ? 'ST'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 209 ? 'SV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 210 ? 'SX'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 211 ? 'SY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 212 ? 'SZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 213 ? 'TC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 214 ? 'TD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 215 ? 'TF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 216 ? 'TG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 217 ? 'TH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 218 ? 'TJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 219 ? 'TK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 220 ? 'TL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 221 ? 'TM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 222 ? 'TN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 223 ? 'TO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 224 ? 'TR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 225 ? 'TT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 226 ? 'TV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 227 ? 'TW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 228 ? 'TZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 229 ? 'UA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 230 ? 'UG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 231 ? 'UM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 232 ? 'US'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 233 ? 'UY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 234 ? 'UZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 235 ? 'VA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 236 ? 'VC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 237 ? 'VE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 238 ? 'VG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 239 ? 'VI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 240 ? 'VN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 241 ? 'VU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 242 ? 'WF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 243 ? 'WS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 244 ? 'XK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 245 ? 'YE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 246 ? 'YT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 247 ? 'ZA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 248 ? 'ZM'
            
                                            //             : ""                              
                                            //             : this.state.OverseasAddressSegment[e].overseasCountryField 
                                        };
            
                                        partyAdd.push(addressEntity);
            
            
                                        this.setState({
                                            PartyOverseasAddressUniqueId: uniqueId,
                                            PartyOverseasResidentialAddress: partyAdd,
                                            PartyHasOverseasAddress: true,
                                            //AddressSectionEntities: this.state.Address,
                                            //PartyOverseasResidentialAddress:  this.state.Address[e],
                                            AddressSectionCount: ctr//this.state.Address.length
                                        },()=>
                                        {
                                            console.log("PartyOverseasResidentialAddress");
                                            console.log(this.state.PartyOverseasResidentialAddress);
                                        
                                            this.UpdateAddressSection();
                                        });
                                        }
                                
                            });
                        }

                        if(this.state.CompanyApplicant!=null){
                            this.state.CompanyApplicant.forEach(applicant => {
                                if((this.state.Address[e].uniqueIDField == applicant.contactField.currentAddressField.x_MailingAddressField || this.state.Address[e].uniqueIDField == applicant.contactField.currentAddressField.x_ResidentialAddressField) )
                                    {
                                        let ctr = 0;
                                        ctr=ctr+1;
                                        
                                        let uniqueId = this.state.PartyOverseasAddressUniqueId;       
                                        let uniqueId1 = this.state.Address[e].uniqueIDField;
                                        uniqueId.push(uniqueId1);
    
                                        let partyAdd = this.state.PartyOverseasResidentialAddress;                                    
            
                                        let addressEntity = {
                                            'streettype': this.state.Address[e].standardField.streetTypeField,
                                            'streetname': this.state.Address[e].standardField.streetNameField,
                                            'streetnumber': this.state.Address[e].standardField.streetNumberField,
                                            'suburb': this.state.Address[e].suburbField,
                                            'state': this.state.Address[e].overseasStateField,
                                            'postcode': this.state.Address[e].overseasPostCodeField,
                                            'country': this.state.Address[e].countryField
                                            // 'country': this.state.OverseasAddressSegment[e].overseasCountryField == null? 
                                            //             // this.state.OverseasAddressSegment[e].countryField == 170 ? 'NZ' 
                                            //             // : this.state.OverseasAddressSegment[e].countryField == 176 ? 'PH'   
                                            //             this.state.OverseasAddressSegment[e].countryField == 0 ? 'AD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 1 ? 'AE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 2 ? 'AF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 3 ? 'AG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 4 ? 'AI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 5 ? 'AL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 6 ? 'AM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 7 ? 'AO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 8 ? 'AQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 9 ? 'AR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 10 ? 'AS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 11 ? 'AT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 12 ? 'AU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 13 ? 'AW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 14 ? 'AX'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 15 ? 'AZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 16 ? 'BA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 17 ? 'BB'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 18 ? 'BD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 19 ? 'BE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 20 ? 'BF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 21 ? 'BG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 22 ? 'BH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 23 ? 'BI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 24 ? 'BJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 25 ? 'BL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 26 ? 'BM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 27 ? 'BN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 28 ? 'BO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 29 ? 'BQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 30 ? 'BR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 31 ? 'BS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 32 ? 'BT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 33 ? 'BV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 34 ? 'BW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 35 ? 'BY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 36 ? 'BZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 37 ? 'CA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 38 ? 'CC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 39 ? 'CD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 40 ? 'CF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 41 ? 'CG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 42 ? 'CH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 43 ? 'CI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 44 ? 'CK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 45 ? 'CL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 46 ? 'CM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 47 ? 'CN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 48 ? 'CO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 49 ? 'CR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 50 ? 'CU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 51 ? 'CV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 52 ? 'CW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 53 ? 'CX'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 54 ? 'CY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 55 ? 'CZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 56 ? 'DE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 57 ? 'DJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 58 ? 'DK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 59 ? 'DM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 60 ? 'DO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 61 ? 'DZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 62 ? 'EC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 63 ? 'EE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 64 ? 'EG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 65 ? 'EH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 66 ? 'ER'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 67 ? 'ES'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 68 ? 'ET'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 69 ? 'FI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 70 ? 'FJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 71 ? 'FK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 72 ? 'FM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 73 ? 'FO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 74 ? 'FR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 75 ? 'GA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 76 ? 'GB'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 77 ? 'GD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 78 ? 'GE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 79 ? 'GF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 80 ? 'GG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 81 ? 'GH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 82 ? 'GI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 83 ? 'GL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 84 ? 'GM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 85 ? 'GN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 86 ? 'GP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 87 ? 'GQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 88 ? 'GR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 89 ? 'GS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 90 ? 'GT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 91 ? 'GU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 92 ? 'GW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 93 ? 'GY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 94 ? 'HK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 95 ? 'HM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 96 ? 'HN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 97 ? 'HR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 98 ? 'HT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 99 ? 'HU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 100 ? 'ID'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 101 ? 'IE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 102 ? 'IL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 103 ? 'IM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 104 ? 'IN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 105 ? 'IO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 106 ? 'IQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 107 ? 'IR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 108 ? 'IS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 109 ? 'IT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 110 ? 'JE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 111 ? 'JM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 112 ? 'JO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 113 ? 'JP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 114 ? 'KE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 115 ? 'KG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 116 ? 'KH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 117 ? 'KI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 118 ? 'KM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 119 ? 'KN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 120 ? 'KP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 121 ? 'KR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 122 ? 'KW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 123 ? 'KY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 124 ? 'KZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 125 ? 'LA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 126 ? 'LB'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 127 ? 'LC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 128 ? 'LI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 129 ? 'LK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 130 ? 'LR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 131 ? 'LS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 132 ? 'LT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 133 ? 'LU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 134 ? 'LV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 135 ? 'LY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 136 ? 'MA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 137 ? 'MC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 138 ? 'MD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 139 ? 'ME'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 140 ? 'MF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 141 ? 'MG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 142 ? 'MH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 143 ? 'MK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 144 ? 'ML'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 145 ? 'MM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 146 ? 'MN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 147 ? 'MO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 148 ? 'MP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 149 ? 'MQ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 150 ? 'MR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 151 ? 'MS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 152 ? 'MT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 153 ? 'MU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 154 ? 'MV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 155 ? 'MW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 156 ? 'MX'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 157 ? 'MY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 158 ? 'MZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 159 ? 'NA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 160 ? 'NC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 161 ? 'NE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 162 ? 'NF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 163 ? 'NG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 164 ? 'NI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 165 ? 'NL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 166 ? 'NO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 167 ? 'NP'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 168 ? 'NR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 169 ? 'NU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 170 ? 'NZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 171 ? 'OM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 172 ? 'PA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 173 ? 'PE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 174 ? 'PF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 175 ? 'PG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 176 ? 'PH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 177 ? 'PK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 178 ? 'PL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 179 ? 'PM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 180 ? 'PN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 181 ? 'PR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 182 ? 'PS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 183 ? 'PT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 184 ? 'PW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 185 ? 'PY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 186 ? 'QA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 187 ? 'RE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 188 ? 'RO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 189 ? 'RS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 190 ? 'RU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 191 ? 'RW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 192 ? 'SA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 193 ? 'SB'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 194 ? 'SC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 195 ? 'SD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 196 ? 'SE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 197 ? 'SG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 198 ? 'SH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 199 ? 'SI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 200 ? 'SJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 201 ? 'SK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 202 ? 'SL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 203 ? 'SM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 204 ? 'SN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 205 ? 'SO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 206 ? 'SR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 207 ? 'SS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 208 ? 'ST'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 209 ? 'SV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 210 ? 'SX'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 211 ? 'SY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 212 ? 'SZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 213 ? 'TC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 214 ? 'TD'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 215 ? 'TF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 216 ? 'TG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 217 ? 'TH'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 218 ? 'TJ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 219 ? 'TK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 220 ? 'TL'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 221 ? 'TM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 222 ? 'TN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 223 ? 'TO'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 224 ? 'TR'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 225 ? 'TT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 226 ? 'TV'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 227 ? 'TW'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 228 ? 'TZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 229 ? 'UA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 230 ? 'UG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 231 ? 'UM'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 232 ? 'US'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 233 ? 'UY'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 234 ? 'UZ'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 235 ? 'VA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 236 ? 'VC'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 237 ? 'VE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 238 ? 'VG'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 239 ? 'VI'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 240 ? 'VN'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 241 ? 'VU'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 242 ? 'WF'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 243 ? 'WS'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 244 ? 'XK'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 245 ? 'YE'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 246 ? 'YT'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 247 ? 'ZA'
                                            //             : this.state.OverseasAddressSegment[e].countryField == 248 ? 'ZM'
            
                                            //             : ""                              
                                            //             : this.state.OverseasAddressSegment[e].overseasCountryField 
                                        };
            
                                        partyAdd.push(addressEntity);
            
            
                                        this.setState({
                                            PartyOverseasAddressUniqueId: uniqueId,
                                            PartyOverseasResidentialAddress: partyAdd,
                                            PartyHasOverseasAddress: true,
                                            //AddressSectionEntities: this.state.Address,
                                            //PartyOverseasResidentialAddress:  this.state.Address[e],
                                            AddressSectionCount: ctr//this.state.Address.length
                                        },()=>
                                        {
                                            console.log("PartyOverseasResidentialAddress");
                                            console.log(this.state.PartyOverseasResidentialAddress);
                                        
                                            this.UpdateAddressSection();
                                        });
                                        }
                                
                            });
                        }

                         if(this.state.CompanyApplicant!=null){
                            this.state.CompanyApplicant.forEach(applicant => {
                                if((this.state.Address[e].uniqueIDField == applicant.contactField.x_MailingAddressField || this.state.Address[e].uniqueIDField == applicant.contactField.x_ResidentialAddressField) )
                                    {
                                        let ctr = 0;
                                        ctr=ctr+1;
                                        
                                        let uniqueId = this.state.PartyOverseasAddressUniqueId;       
                                        let uniqueId1 = this.state.Address[e].uniqueIDField;
                                        uniqueId.push(uniqueId1);
    
                                        let partyAdd = this.state.PartyOverseasResidentialAddress;                                    
            
                                        let addressEntity = {
                                            'streettype': this.state.Address[e].standardField.streetTypeField,
                                            'streetname': this.state.Address[e].standardField.streetNameField,
                                            'streetnumber': this.state.Address[e].standardField.streetNumberField,
                                            'suburb': this.state.Address[e].suburbField,
                                            'state': this.state.Address[e].overseasStateField,
                                            'postcode': this.state.Address[e].overseasPostCodeField,
                                            'country': this.state.Address[e].countryField
                                        };
            
                                        partyAdd.push(addressEntity);
            
            
                                        this.setState({
                                            PartyOverseasAddressUniqueId: uniqueId,
                                            PartyOverseasResidentialAddress: partyAdd,
                                            PartyHasOverseasAddress: true,
                                            //AddressSectionEntities: this.state.Address,
                                            //PartyOverseasResidentialAddress:  this.state.Address[e],
                                            AddressSectionCount: ctr//this.state.Address.length
                                        },()=>
                                        {
                                            console.log("PartyOverseasResidentialAddress");
                                            console.log(this.state.PartyOverseasResidentialAddress);
                                        
                                            this.UpdateAddressSection();
                                        });
                                        }
                                
                            });
                        }
                       
                        // if((this.state.Address[e].uniqueIDField == this.state.PersonApplicant[e].contactField.currentAddressField.x_MailingAddressField || this.state.Address[e].uniqueIDField == this.state.PersonApplicant[e].contactField.currentAddressField.x_ResidentialAddressField) )
                        // {
                        //     let ctr = 0;
                        //     ctr=ctr+1;

                        //     let partyAdd = this.state.PartyOverseasResidentialAddress; 

                        //     let addressEntity = {
                        //         'streettype': this.state.OverseasAddressSegment[e].standardField.streetTypeField,
                        //         'streetname': this.state.OverseasAddressSegment[e].standardField.streetNameField,
                        //         'streetnumber': this.state.OverseasAddressSegment[e].standardField.streetNumberField,
                        //         'suburb': this.state.OverseasAddressSegment[e].suburbField,
                        //         'state': this.state.OverseasAddressSegment[e].overseasStateField,
                        //         'postcode': this.state.OverseasAddressSegment[e].overseasPostCodeField,
                        //         'country': this.state.OverseasAddressSegment[e].countryField
                        //         // 'country': this.state.OverseasAddressSegment[e].overseasCountryField == null? 
                        //         //             // this.state.OverseasAddressSegment[e].countryField == 170 ? 'NZ' 
                        //         //             // : this.state.OverseasAddressSegment[e].countryField == 176 ? 'PH'   
                        //         //             this.state.OverseasAddressSegment[e].countryField == 0 ? 'AD'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 1 ? 'AE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 2 ? 'AF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 3 ? 'AG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 4 ? 'AI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 5 ? 'AL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 6 ? 'AM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 7 ? 'AO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 8 ? 'AQ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 9 ? 'AR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 10 ? 'AS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 11 ? 'AT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 12 ? 'AU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 13 ? 'AW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 14 ? 'AX'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 15 ? 'AZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 16 ? 'BA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 17 ? 'BB'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 18 ? 'BD'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 19 ? 'BE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 20 ? 'BF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 21 ? 'BG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 22 ? 'BH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 23 ? 'BI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 24 ? 'BJ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 25 ? 'BL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 26 ? 'BM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 27 ? 'BN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 28 ? 'BO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 29 ? 'BQ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 30 ? 'BR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 31 ? 'BS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 32 ? 'BT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 33 ? 'BV'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 34 ? 'BW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 35 ? 'BY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 36 ? 'BZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 37 ? 'CA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 38 ? 'CC'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 39 ? 'CD'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 40 ? 'CF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 41 ? 'CG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 42 ? 'CH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 43 ? 'CI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 44 ? 'CK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 45 ? 'CL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 46 ? 'CM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 47 ? 'CN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 48 ? 'CO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 49 ? 'CR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 50 ? 'CU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 51 ? 'CV'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 52 ? 'CW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 53 ? 'CX'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 54 ? 'CY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 55 ? 'CZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 56 ? 'DE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 57 ? 'DJ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 58 ? 'DK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 59 ? 'DM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 60 ? 'DO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 61 ? 'DZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 62 ? 'EC'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 63 ? 'EE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 64 ? 'EG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 65 ? 'EH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 66 ? 'ER'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 67 ? 'ES'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 68 ? 'ET'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 69 ? 'FI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 70 ? 'FJ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 71 ? 'FK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 72 ? 'FM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 73 ? 'FO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 74 ? 'FR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 75 ? 'GA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 76 ? 'GB'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 77 ? 'GD'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 78 ? 'GE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 79 ? 'GF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 80 ? 'GG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 81 ? 'GH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 82 ? 'GI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 83 ? 'GL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 84 ? 'GM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 85 ? 'GN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 86 ? 'GP'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 87 ? 'GQ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 88 ? 'GR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 89 ? 'GS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 90 ? 'GT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 91 ? 'GU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 92 ? 'GW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 93 ? 'GY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 94 ? 'HK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 95 ? 'HM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 96 ? 'HN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 97 ? 'HR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 98 ? 'HT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 99 ? 'HU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 100 ? 'ID'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 101 ? 'IE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 102 ? 'IL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 103 ? 'IM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 104 ? 'IN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 105 ? 'IO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 106 ? 'IQ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 107 ? 'IR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 108 ? 'IS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 109 ? 'IT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 110 ? 'JE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 111 ? 'JM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 112 ? 'JO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 113 ? 'JP'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 114 ? 'KE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 115 ? 'KG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 116 ? 'KH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 117 ? 'KI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 118 ? 'KM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 119 ? 'KN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 120 ? 'KP'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 121 ? 'KR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 122 ? 'KW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 123 ? 'KY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 124 ? 'KZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 125 ? 'LA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 126 ? 'LB'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 127 ? 'LC'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 128 ? 'LI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 129 ? 'LK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 130 ? 'LR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 131 ? 'LS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 132 ? 'LT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 133 ? 'LU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 134 ? 'LV'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 135 ? 'LY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 136 ? 'MA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 137 ? 'MC'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 138 ? 'MD'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 139 ? 'ME'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 140 ? 'MF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 141 ? 'MG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 142 ? 'MH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 143 ? 'MK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 144 ? 'ML'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 145 ? 'MM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 146 ? 'MN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 147 ? 'MO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 148 ? 'MP'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 149 ? 'MQ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 150 ? 'MR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 151 ? 'MS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 152 ? 'MT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 153 ? 'MU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 154 ? 'MV'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 155 ? 'MW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 156 ? 'MX'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 157 ? 'MY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 158 ? 'MZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 159 ? 'NA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 160 ? 'NC'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 161 ? 'NE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 162 ? 'NF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 163 ? 'NG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 164 ? 'NI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 165 ? 'NL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 166 ? 'NO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 167 ? 'NP'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 168 ? 'NR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 169 ? 'NU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 170 ? 'NZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 171 ? 'OM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 172 ? 'PA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 173 ? 'PE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 174 ? 'PF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 175 ? 'PG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 176 ? 'PH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 177 ? 'PK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 178 ? 'PL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 179 ? 'PM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 180 ? 'PN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 181 ? 'PR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 182 ? 'PS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 183 ? 'PT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 184 ? 'PW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 185 ? 'PY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 186 ? 'QA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 187 ? 'RE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 188 ? 'RO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 189 ? 'RS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 190 ? 'RU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 191 ? 'RW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 192 ? 'SA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 193 ? 'SB'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 194 ? 'SC'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 195 ? 'SD'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 196 ? 'SE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 197 ? 'SG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 198 ? 'SH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 199 ? 'SI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 200 ? 'SJ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 201 ? 'SK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 202 ? 'SL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 203 ? 'SM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 204 ? 'SN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 205 ? 'SO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 206 ? 'SR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 207 ? 'SS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 208 ? 'ST'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 209 ? 'SV'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 210 ? 'SX'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 211 ? 'SY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 212 ? 'SZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 213 ? 'TC'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 214 ? 'TD'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 215 ? 'TF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 216 ? 'TG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 217 ? 'TH'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 218 ? 'TJ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 219 ? 'TK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 220 ? 'TL'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 221 ? 'TM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 222 ? 'TN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 223 ? 'TO'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 224 ? 'TR'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 225 ? 'TT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 226 ? 'TV'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 227 ? 'TW'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 228 ? 'TZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 229 ? 'UA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 230 ? 'UG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 231 ? 'UM'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 232 ? 'US'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 233 ? 'UY'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 234 ? 'UZ'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 235 ? 'VA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 236 ? 'VC'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 237 ? 'VE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 238 ? 'VG'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 239 ? 'VI'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 240 ? 'VN'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 241 ? 'VU'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 242 ? 'WF'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 243 ? 'WS'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 244 ? 'XK'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 245 ? 'YE'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 246 ? 'YT'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 247 ? 'ZA'
                        //         //             : this.state.OverseasAddressSegment[e].countryField == 248 ? 'ZM'

                        //         //             : ""                              
                        //         //             : this.state.OverseasAddressSegment[e].overseasCountryField 
                        //     };

                        //     partyAdd.push(addressEntity);


                        //     this.setState({
                        //         PartyOverseasResidentialAddress: partyAdd,
                        //         PartyHasOverseasAddress: true,
                        //         //AddressSectionEntities: this.state.Address,
                        //         //PartyOverseasResidentialAddress:  this.state.Address[e],
                        //         AddressSectionCount: ctr//this.state.Address.length
                        //     },()=>
                        //     {
                        //         console.log("PartyOverseasResidentialAddress");
                        //         console.log(this.state.PartyOverseasResidentialAddress);
                            
                        //         this.UpdateAddressSection();
                        //     });
                        //     }
                        
                    }
                    

                });
            }

           
               
                
        })

    }

    PopulateTitleRefAddress2(address)
    {
        this.setState({
            NTandActAddressSegment: address
        },()=>{           
            console.log("NTandActAddressSegment")
            console.log(this.state.NTandActAddressSegment)
            // alert(this.state.OverseasAddressSegment.length)
            console.log("this.state.RealEstateAsset")
            console.log(this.state.RealEstateAsset)

            let titleRefs = this.state.TitleSegmentTitleReferences; 
            let uniqueIds = this.state.TitleSegmentTitleUniqueIds; 



            this.state.RealEstateAsset.forEach((i, e)=>{
               
                let uniqueIds1 = this.state.RealEstateAsset[e].x_AddressField;
                

                // let filtered = realEstateParties.filter((d) => {
                //     //return d.age > 40 && user.occupation === 'programmer';
                //     return d.registeredProprietorField == uniqueIds1;
                // });

                let filteredAddress = [];
                for (let i= 0; i<address.length; i++) {
                    if (address[i].uniqueIDField === uniqueIds1) {
                        filteredAddress = [...filteredAddress, address[i]];
                    }
                }
                console.log("filteredAddress");

                console.log(filteredAddress);

                if(filteredAddress.length > 0){
                    //alert (filteredParty.length)
                    uniqueIds.push(uniqueIds1);
                    let titleref1 = this.state.RealEstateAsset[0].titleField[0].titleReferenceField;
                    titleRefs.push(titleref1);
                   
                 
                }
                else{
                    // changeReasons1 = '';
                    // changeRequired1 = changeReasons1 !="" ?'Yes': 'No';
                }

               
               
                this.setState({
                    TitleSegmentTitleReferences: titleRefs,
                    TitleSegmentTitleUniqueIds: uniqueIds,
                    TitleSegmentCount: address.length
                    //AddressSectionEntities: this.state.Address,
                    //PartyOverseasResidentialAddress:  this.state.Address[e],
                    //AddressSectionCount: ctr//this.state.Address.length
                },()=>
                {
                    console.log("TitleSegmentTitleReferences");
                    console.log(this.state.TitleSegmentTitleReferences);
                
                    // }
                    this.UpdateTitleSegment();
                });
                

               


               

            });
            

        
            
        }
        

   // }
    );
    }

    PopulateTitleRefAddress(address)
    {
        this.setState({
            NTandActAddressSegment: address
        },()=>{   
            console.log("NTandActAddressSegment");
            console.log(this.state.NTandActAddressSegment)

            var ctr=0;
        var titleRefs = [];
        var uniqueIds = [];
        if(this.state.RealEstateAssetSegment!=null){
            //check if party id is linked to real estate title
            console.log("RealEstateAssetSegment")
            console.log(this.state.RealEstateAssetSegment)

            var realEstateTitles= [];          
            

            if(this.state.RealEstateAssetSegment.length > 1){
                //alert(this.state.RealEstateAssetSegment.length+"a")
                this.state.RealEstateAssetSegment.forEach((i, e)=>{

                    if(address.length > 0){
                        address.forEach((x, y)=>{
                            if(address[y].uniqueIDField == this.state.RealEstateAsset[e].x_AddressField){
                                //check if ACT or NT
                                if(address[y].australianStateField == 0 || address[y].australianStateField == 2)
                                {
                                    //alert("sdfasfs")
                                    console.log("this.state.RealEstateAssetSegment[e].titleField")
                            console.log(this.state.RealEstateAssetSegment[e].titleField)
                            if(this.state.RealEstateAssetSegment[e].titleField!=null)
                            {                   
                                //check how many titles
                                //alert(this.state.RealEstateAssetSegment[e].titleField.length)
                                if(this.state.RealEstateAssetSegment[e].titleField.length == 1){
                                    let uniqueIds1 = this.state.RealEstateAsset[e].titleField[0].uniqueIDField;
                                    uniqueIds.push(uniqueIds1);
                                    let titleref1 = this.state.RealEstateAsset[e].titleField[0].titleReferenceField;
                                    titleRefs.push(titleref1);
                                   
                                    
                                }
                                else if(this.state.RealEstateAssetSegment[e].titleField.length > 1){
                                    var titles = this.state.RealEstateAssetSegment[e].titleField;
                                    titles.forEach((i, b)=>{
                                        let uniqueIds1 = titles[b].uniqueIDField;
                                        uniqueIds.push(uniqueIds1);
                                        let titleref1 = titles[b].titleReferenceField;
                                        titleRefs.push(titleref1);
                                    })

                                
                                    
                                }

                                
                                    
                            }

                                }
                                else{
                                    // let titleref1 = '';
                                    // titleRefs.push(titleref1);
                                    // let uniqueId1 = '';
                                    // uniqueIds.push(uniqueId1);
                                }

                                // let filteredRefs = [];
                                // for (let i= 0; i<titleRefs.length; i++) {
                                //     if (titleRefs[i] != "") {
                                //         filteredRefs = [...filteredRefs, filteredRefs[i]];
                                //     }
                                // }
                                // console.log("filteredRefs");
                
                                // console.log(filteredRefs);

                                this.setState({
                                    TitleSegmentTitleReferences: titleRefs,
                                    TitleSegmentTitleUniqueIds: uniqueIds,
                                    TitleSegmentCount: titleRefs.length
                                    //AddressSectionEntities: this.state.Address,
                                    //PartyOverseasResidentialAddress:  this.state.Address[e],
                                    //AddressSectionCount: ctr//this.state.Address.length
                                },()=>
                                {
                                    console.log("TitleSegmentTitleReferences");
                                    console.log(this.state.TitleSegmentTitleReferences);
                                    console.log("TitleSegmentCount");
                                    console.log(this.state.TitleSegmentCount);
                                
                                    // }
                                    this.UpdateTitleSegment();
                                });
                            }
                        })
                    }

         

            // if()

            

        });
            }
            //
            else if(this.state.RealEstateAssetSegment.length == 1){

                if(address.length > 0){
                    address.forEach((x, y)=>{
                        if(address[y].uniqueIDField == this.state.RealEstateAsset[0].x_AddressField){
                            //check if ACT or NT
                            if(address[y].australianStateField == 0 || address[y].australianStateField == 2)
                            {
                                //alert("sdfasfs")
                                console.log("this.state.RealEstateAssetSegment[e].titleField")
                        console.log(this.state.RealEstateAssetSegment[0].titleField)
                        if(this.state.RealEstateAssetSegment[0].titleField!=null)
                        {                   
                            //check how many titles
                            //alert(this.state.RealEstateAssetSegment[e].titleField.length)
                            if(this.state.RealEstateAssetSegment[0].titleField.length == 1){
                                let uniqueIds1 = this.state.RealEstateAsset[0].titleField[0].uniqueIDField;
                                uniqueIds.push(uniqueIds1);
                                let titleref1 = this.state.RealEstateAsset[0].titleField[0].titleReferenceField;
                                titleRefs.push(titleref1);
                               
                                
                            }
                            else if(this.state.RealEstateAssetSegment[0].titleField.length > 1){
                                var titles = this.state.RealEstateAssetSegment[0].titleField;
                                titles.forEach((i, b)=>{
                                    let uniqueIds1 = titles[b].uniqueIDField;
                                    uniqueIds.push(uniqueIds1);
                                    let titleref1 = titles[b].titleReferenceField;
                                    titleRefs.push(titleref1);
                                })

                            
                                
                            }

                            
                                
                        }

                            }
                            else{
                                // let titleref1 = '';
                                // titleRefs.push(titleref1);
                                // let uniqueId1 = '';
                                // uniqueIds.push(uniqueId1);
                            }

                            // let filteredRefs = [];
                            // for (let i= 0; i<titleRefs.length; i++) {
                            //     if (titleRefs[i] != "") {
                            //         filteredRefs = [...filteredRefs, filteredRefs[i]];
                            //     }
                            // }
                            // console.log("filteredRefs");
            
                            // console.log(filteredRefs);

                            this.setState({
                                TitleSegmentTitleReferences: titleRefs,
                                TitleSegmentTitleUniqueIds: uniqueIds,
                                TitleSegmentCount: titleRefs.length
                                //AddressSectionEntities: this.state.Address,
                                //PartyOverseasResidentialAddress:  this.state.Address[e],
                                //AddressSectionCount: ctr//this.state.Address.length
                            },()=>
                            {
                                console.log("TitleSegmentTitleReferences");
                                console.log(this.state.TitleSegmentTitleReferences);
                                console.log("TitleSegmentCount");
                                console.log(this.state.TitleSegmentCount);
                            
                                // }
                                this.UpdateTitleSegment();
                            });
                        }
                    })
                }


            }

            else{}
        }


        
        });
        
    }

    PopulateRealEstateAsset()
    {
        if(this.state.RealEstateAsset.length > 0)
        {
            var realEstateTitles = [];
            this.state.RealEstateAsset.forEach((i, e)=>{
                realEstateTitles.push(this.state.RealEstateAsset[e].titleField)
                
            })

            this.setState({
                //RealEstateAssetTitleSegmentTitles: realEstateTitles,
                RealEstateAssetSegmentCount: this.state.RealEstateAsset.length,
                //RealEstateSegmentEntities: 
            },()=>{
                //this.UpdateRealEstateAssetSegment();
                this.UpdateRealEstateSegment();
                console.log("RealEstateAssetTitles");
                console.log(this.state.RealEstateAssetTitles);
                
            });
            
        }
    }

           

                
    //         })

    // }

    PopulateParty(party)
    {
        this.setState({
            LoanPartySegment: party,
            RealEstateAssetSegment: this.state.RealEstateAsset
        },()=>{
                //alert(this.state.LoanPartySegment.length)
                       
                //for each loan party we need to check if  this.state.LoanPartySegment[0].personNameField.uniqueIDField is equal to
                //realEstateAssetField.titleField[0].registeredProprietorField is not null and RealEstateAsset registeredProprietorField[0].x_RegisteredProprietorField
                //and nameField = this.state.LoanPartySegment[0].personNameField.firstNameField +  " " + this.state.LoanPartySegment[0].personNameField.surnameField
                // "registeredProprietorField": [
                //     {
                //         "changeNameFormRequiredFieldSpecified": false,
                //         "nameField": null,
                //         "reasonForDifferentNameFieldSpecified": false,
                //         "x_RegisteredProprietorField": "Party-145669"
                //     }
                // ],

                if(this.state.RealEstateAssetSegment!=null){
                    //check if party id is linked to real estate title
                    console.log("RealEstateAssetSegment")
                    console.log(this.state.RealEstateAssetSegment)

                    var realEstateParties= [];                  

                    if(this.state.RealEstateAssetSegment.length > 1){
                        //alert(this.state.RealEstateAssetSegment.length+"a")
                        this.state.RealEstateAssetSegment.forEach((i, e)=>{
                            console.log("this.state.RealEstateAssetSegment[e].titleField")
                            console.log(this.state.RealEstateAssetSegment[e].titleField)
                        if(this.state.RealEstateAssetSegment[e].titleField!=null)
                        {
                            
                            //check how many titles
                            //alert(this.state.RealEstateAssetSegment[e].titleField.length)
                            if(this.state.RealEstateAssetSegment[e].titleField.length == 1){
                                if(this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField != null){
                                    if(this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField.length == 1){
                                        if(this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField[0]!=null)
                                        {
                                            let titleEntity = {
                                                'titleUniqueIDField': this.state.RealEstateAssetSegment[e].titleField[0].uniqueIDField,
                                                'nameField':this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField[0].nameField,
                                                'registeredProprietorField':this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField[0].x_RegisteredProprietorField,
                                                'reasonForDifferentNameField': this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField[0].reasonForDifferentNameField,
                    
                    
                                            };
                                            if(realEstateParties!="")
                                            {
                                                realEstateParties.map(d=>{
                                                    //alert("reg " + d.registeredProprietorField + " ent " + this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField[0].x_RegisteredProprietorField)
                                                    if(d.registeredProprietorField != this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField[0].x_RegisteredProprietorField)
                                                    {
                                                        realEstateParties.push(titleEntity);
                                                    }
                                                })
                                            }
                                            else realEstateParties.push(titleEntity);
                                        }
                                    }

                                    else if(this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField.length > 1){
                                        var proprietors = this.state.RealEstateAssetSegment[e].titleField[0].registeredProprietorField;
                                        proprietors.forEach((i, a)=>{
                                        if(proprietors[a]!=null)
                                        {
                                            let titleEntity = {
                                                'titleUniqueIDField': this.state.RealEstateAssetSegment[e].titleField[0].uniqueIDField,
                                                'nameField':proprietors[a].nameField,
                                                'registeredProprietorField':proprietors[a].x_RegisteredProprietorField,
                                                'reasonForDifferentNameField': proprietors[a].reasonForDifferentNameField,
                    
                    
                                            };
                                            if(realEstateParties!="")
                                            {
                                                realEstateParties.map(d=>{
                                                    //alert("reg " + d.registeredProprietorField + " ent " + proprietors[a].x_RegisteredProprietorField)
                                                    if(d.registeredProprietorField != proprietors[a].x_RegisteredProprietorField)
                                                    {
                                                        realEstateParties.push(titleEntity);
                                                    }
                                                })
                                            }
                                            else realEstateParties.push(titleEntity);
                                        }
                                        });
                                        

                                    }
                                }
                                
                                
                            }
                            else if(this.state.RealEstateAssetSegment[e].titleField.length > 1){
                                var titles = this.state.RealEstateAssetSegment[e].titleField;
                                titles.forEach((i, b)=>{
                                    if(titles[b].registeredProprietorField != null){
                                        if(titles[b].registeredProprietorField.length == 1){
                                            if(titles[b].registeredProprietorField[0]!=null)
                                            {
                                                let titleEntity = {
                                                    'titleUniqueIDField': titles[b].uniqueIDField,
                                                    'nameField':titles[b].registeredProprietorField[0].nameField,
                                                    'registeredProprietorField':titles[b].registeredProprietorField[0].x_RegisteredProprietorField,
                                                    'reasonForDifferentNameField': titles[b].registeredProprietorField[0].reasonForDifferentNameField,
                        
                        
                                                };
                                                if(realEstateParties!="")
                                                {
                                                    realEstateParties.map(d=>{
                                                        //alert("reg " + d.registeredProprietorField + " ent " + titles[b].registeredProprietorField[0].x_RegisteredProprietorField)
                                                        if(d.registeredProprietorField != titles[b].registeredProprietorField[0].x_RegisteredProprietorField)
                                                        {
                                                            realEstateParties.push(titleEntity);
                                                        }
                                                    })
                                                }
                                                else realEstateParties.push(titleEntity);
                                            }
                                        }
                    
                                        else if(titles[b].registeredProprietorField.length > 1){
                                            var proprietors = titles[b].registeredProprietorField;
                                            proprietors.forEach((i, a)=>{
                                            if(titles[b].registeredProprietorField[a]!=null)
                                            {
                                                let titleEntity = {
                                                    'titleUniqueIDField': titles[b].uniqueIDField,
                                                    'nameField':proprietors[a].nameField,
                                                    'registeredProprietorField':proprietors[a].x_RegisteredProprietorField,
                                                    'reasonForDifferentNameField': proprietors[a].reasonForDifferentNameField,
                        
                        
                                                };
                                                if(realEstateParties!="")
                                                {
                                                    realEstateParties.map(d=>{
                                                        //alert("reg " + d.registeredProprietorField + " ent " + proprietors[a].x_RegisteredProprietorField)
                                                        if(d.registeredProprietorField != proprietors[a].x_RegisteredProprietorField)
                                                        {
                                                            realEstateParties.push(titleEntity);
                                                        }
                                                    })
                                                }
                                                else realEstateParties.push(titleEntity);
                                            }
                                            });
                                            
                    
                                        }
                                    }
                                })
                                
                                
                                
                            }
                        }
                        });
                    }

                    console.log("realEstateParties");
                    console.log(realEstateParties);

                    // if()

                }

                let changeRequired = this.state.ProprietorSegmentChangeNameFormRequired;
                let changeNames = this.state.ProprietorSegmentNames;
                let changeReasons = this.state.ProprietorSegmentReasonForDifferentName;
                let uniqueIds =  this.state.ProprietorSegmentUniqueIds;
                let titleUniqueIds = this.state.ProprietorTitleUniqueIds;
                        
                //alert(this.state.LoanPartySegment.length)
                
                if(this.state.LoanPartySegment.length ==  1){

                    console.log("partyparty")
                    console.log(party)

                    

                    // let changeNames1 = this.state.LoanPartySegment[0].personNameField.firstNameField + " " + this.state.LoanPartySegment[0].personNameField.surnameField;
                    // changeNames.push(changeNames1);
                    let uniqueIds1 = this.state.LoanPartySegment[0].uniqueIDField;
                    uniqueIds.push(uniqueIds1);


                    let nameEntity = {
                        'firstname': this.state.LoanPartySegment[0].personNameField.firstNameField,
                        // 'middlename': this.state.LoanPartySegment[0].personNameField.middleNamesField,
                        'lastname': this.state.LoanPartySegment[0].personNameField.surnameField,
                    };

                       
                    changeNames.push(nameEntity);

                    let changeReasons1 = "";
                    let changeRequired1= "";

                    realEstateParties.map(d=>{
                        //alert(uniqueIds1)
                        if(d.registeredProprietorField==uniqueIds1){
                            changeReasons1 = d.reasonForDifferentNameField == 0 ? 'Abbreviation' : 
                                            d.reasonForDifferentNameField == 1 ?'Amalgamation' : 
                                            d.reasonForDifferentNameField == 2 ?'AmalgamationEvidence' : 
                                            d.reasonForDifferentNameField == 3 ?'ChangeIncorporatedName' : 
                                            d.reasonForDifferentNameField == 4 ?'DeedPoll' : 
                                            d.reasonForDifferentNameField == 5 ?'ErrorinRegister' : 
                                            d.reasonForDifferentNameField == 6 ?'LegislativeChangeCompany' : 
                                            d.reasonForDifferentNameField == 7 ?'LegislativeChangeCompanyEvidence' : 
                                            d.reasonForDifferentNameField == 8 ?'LegislativeChangeNonCompany' : 
                                            d.reasonForDifferentNameField == 9 ?'LegislativeChangeNonCompanyEvidence' : 
                                            d.reasonForDifferentNameField == 10 ?'Marriage' : 
                                            d.reasonForDifferentNameField == 11 ?'MergerorTakeover' :
                                            d.reasonForDifferentNameField == 12 ?'MergerorTakeoverEvidence' : 
                                            d.reasonForDifferentNameField == 13 ?'NewName' : 
                                            d.reasonForDifferentNameField == 14 ?'ResumptionofMaidenName' : 
                                            d.reasonForDifferentNameField == 15 ?'SaleorTransfer' : 
                                            d.reasonForDifferentNameField == 16 ?'SaleorTransferEvidence' : 'Abbreviation';
                            changeRequired1 = d.reasonForDifferentNameField !="" ?'Yes': 'No';
                        }
                    })

                    
                    //alert(changeReasons)



                    // this.setState({
                    //     ProprietorSegmentCount: this.state.LoanPartySegment.length,
                    //     ProprietorSegmentNames: changeNames,
                    //     ProprietorSegmentUniqueIds: uniqueIds,
                    //     ProprietorSegmentReasonForDifferentName: changeReasons,
                    //     ProprietorSegmentChangeNameFormRequired: changeRequired
                    // },()=>{          
                    //     console.log('changeNames')       
                    //     console.log(this.state.ProprietorSegmentNames)           
                    //     this.UpdateProprietorSegment();
                    // });
    

                    if(changeRequired1 == 'Yes'){
                        changeReasons.push(changeReasons1);
                        changeRequired.push(changeRequired1);
                        this.setState({
                            ProprietorSegmentCount: this.state.LoanPartySegment.length,
                            ProprietorSegmentNames: changeNames,
                            ProprietorSegmentUniqueIds: uniqueIds,
                            ProprietorSegmentReasonForDifferentName: changeReasons,
                            ProprietorSegmentChangeNameFormRequired: changeRequired
                        },()=>{                            
                            this.UpdateProprietorSegment();
                        });
                    }
                    else{
                        this.setState({
                            ProprietorSegmentCount: this.state.LoanPartySegment.length,
                            ProprietorSegmentNames: changeNames,
                            ProprietorSegmentUniqueIds: uniqueIds,
                            // ProprietorSegmentReasonForDifferentName: changeReasons,
                            // ProprietorSegmentChangeNameFormRequired: changeRequired
                        },()=>{                            
                            this.UpdateProprietorSegment();
                        });
                    }
                  
    
                }
                else if(this.state.LoanPartySegment.length > 1){
                    //alert("hi")
                    this.state.LoanPartySegment.forEach((i, e)=>{
                        // let changeNames1 = this.state.LoanPartySegment[e].personNameField.firstNameField + " " + this.state.LoanPartySegment[e].personNameField.surnameField;
                        // changeNames.push(changeNames1);
                        let nameEntity = {
                            'firstname': this.state.LoanPartySegment[e].personNameField.firstNameField,
                            // 'middlename': this.state.LoanPartySegment[0].personNameField.middleNamesField,
                            'lastname': this.state.LoanPartySegment[e].personNameField.surnameField,
                        };
        
                        changeNames.push(nameEntity);

                        // let uniqueIds1 = this.state.LoanPartySegment[e].uniqueIDField;
                        // uniqueIds.push(uniqueIds1);

                        let changeReasons1 = "";
                        let changeRequired1= "";
                        let titleUniqueId="";

                        //alert(realEstateParties.length)

                        // let  result = realEstateParties.filter((realEstate) => realEstate.registeredProprietorField == uniqueIds1);
                        // console.log("result " + result)

                        let uniqueIds1 = this.state.LoanPartySegment[e].uniqueIDField;
                        uniqueIds.push(uniqueIds1);

                        // let filtered = realEstateParties.filter((d) => {
                        //     //return d.age > 40 && user.occupation === 'programmer';
                        //     return d.registeredProprietorField == uniqueIds1;
                        // });

                        let filteredParty = [];
                        for (let i= 0; i<realEstateParties.length; i++) {
                            if (realEstateParties[i].registeredProprietorField === uniqueIds1) {
                                filteredParty = [...filteredParty, realEstateParties[i]];
                            }
                        }
                        console.log("filteredParty");

                        console.log(filteredParty);

                        if(filteredParty.length > 0){
                            //alert (filteredParty.length)
                            changeReasons1 =  filteredParty[0].reasonForDifferentNameField == 0 ? 'Abbreviation' : 
                            filteredParty[0].reasonForDifferentNameField == 1 ?'Amalgamation' : 
                            filteredParty[0].reasonForDifferentNameField == 2 ?'AmalgamationEvidence' : 
                            filteredParty[0].reasonForDifferentNameField == 3 ?'ChangeIncorporatedName' : 
                            filteredParty[0].reasonForDifferentNameField == 4 ?'DeedPoll' : 
                            filteredParty[0].reasonForDifferentNameField == 5 ?'ErrorinRegister' : 
                            filteredParty[0].reasonForDifferentNameField == 6 ?'LegislativeChangeCompany' : 
                            filteredParty[0].reasonForDifferentNameField == 7 ?'LegislativeChangeCompanyEvidence' : 
                            filteredParty[0].reasonForDifferentNameField == 8 ?'LegislativeChangeNonCompany' : 
                            filteredParty[0].reasonForDifferentNameField == 9 ?'LegislativeChangeNonCompanyEvidence' : 
                            filteredParty[0].reasonForDifferentNameField == 10 ?'Marriage' : 
                            filteredParty[0].reasonForDifferentNameField == 11 ?'MergerorTakeover' :
                            filteredParty[0].reasonForDifferentNameField == 12 ?'MergerorTakeoverEvidence' : 
                            filteredParty[0].reasonForDifferentNameField == 13 ?'NewName' : 
                            filteredParty[0].reasonForDifferentNameField == 14 ?'ResumptionofMaidenName' : 
                            filteredParty[0].reasonForDifferentNameField == 15 ?'SaleorTransfer' : 
                            filteredParty[0].reasonForDifferentNameField == 16 ?'SaleorTransferEvidence' : '';
                                            // 'SaleorTransferEvidence';
                            changeRequired1 = changeReasons1 !="" ?'Yes': 'No';
                            //alert(changeReasons1)
                        }
                        else{
                            changeReasons1 = '';
                            changeRequired1 = changeReasons1 !="" ?'Yes': 'No';
                        }

                       
                        // let filteredProp = [];
                        // let reasonForDifferentNameField = "";
                        // realEstateParties.map(d=>{
                        //     let uniqueIds2 = this.state.LoanPartySegment[e].uniqueIDField;
                        //    // uniqueIds.push(uniqueIds1);
                        //     if (d.registeredProprietorField === uniqueIds2 ) {
                        //         //filteredProp = [...filteredProp, realEstateParties[i]];
                        //         //filteredProp.push(d.registeredProprietorField)
                        //         reasonForDifferentNameField=d.reasonForDifferentNameField;

                        //         console.log("filteredProp " + filteredProp);
                        //         console.log("reasonForDifferentNameField " + reasonForDifferentNameField);
        
                        //         changeReasons1 = reasonForDifferentNameField == 0 ? 'Abbreviation' : 
                        //         reasonForDifferentNameField == 1 ?'Amalgamation' : 
                        //         reasonForDifferentNameField == 2 ?'AmalgamationEvidence' : 
                        //         reasonForDifferentNameField == 3 ?'ChangeIncorporatedName' : 
                        //         reasonForDifferentNameField == 4 ?'DeedPoll' : 
                        //         reasonForDifferentNameField == 5 ?'ErrorinRegister' : 
                        //         reasonForDifferentNameField == 6 ?'LegislativeChangeCompany' : 
                        //         reasonForDifferentNameField == 7 ?'LegislativeChangeCompanyEvidence' : 
                        //         reasonForDifferentNameField == 8 ?'LegislativeChangeNonCompany' : 
                        //         reasonForDifferentNameField == 9 ?'LegislativeChangeNonCompanyEvidence' : 
                        //         reasonForDifferentNameField == 10 ?'Marriage' : 
                        //         reasonForDifferentNameField == 11 ?'MergerorTakeover' :
                        //         reasonForDifferentNameField == 12 ?'MergerorTakeoverEvidence' : 
                        //         reasonForDifferentNameField == 13 ?'NewName' : 
                        //         reasonForDifferentNameField == 14 ?'ResumptionofMaidenName' : 
                        //         reasonForDifferentNameField == 15 ?'SaleorTransfer' : 
                        //         reasonForDifferentNameField == 16 ?'SaleorTransferEvidence' : '';
                        //                         // 'SaleorTransferEvidence';
                        //         changeRequired1 = reasonForDifferentNameField !="" ?'Yes': 'No';
                                changeReasons.push(changeReasons1);
                                changeRequired.push(changeRequired1);
                                if(changeRequired1 == 'Yes'){
                                    
                                    this.setState({
                                        ProprietorSegmentCount: this.state.LoanPartySegment.length,
                                        ProprietorSegmentNames: changeNames,
                                        ProprietorSegmentUniqueIds: uniqueIds,
                                        ProprietorSegmentReasonForDifferentName: changeReasons,
                                        ProprietorSegmentChangeNameFormRequired: changeRequired,
                                        ProprietorRealEstates: realEstateParties
                                    },()=>{                            
                                        this.UpdateProprietorSegment();
                                    });
                                }
                                else{
                                    this.setState({
                                        ProprietorSegmentCount: this.state.LoanPartySegment.length,
                                        ProprietorSegmentNames: changeNames,
                                        ProprietorSegmentUniqueIds: uniqueIds,
                                        ProprietorRealEstates: realEstateParties
                                        // ProprietorSegmentReasonForDifferentName: changeReasons,
                                        // ProprietorSegmentChangeNameFormRequired: changeRequired
                                    },()=>{                            
                                        this.UpdateProprietorSegment();
                                    });
                                }
        
                            //}
                       // })
                       

                       
    
                        // realEstateParties.map(d=>{
                             
                        //      //uniqueIds1 = this.state.LoanPartySegment[e].uniqueIDField;
                        //      alert("uni " + uniqueIds1 + "reg "+d.registeredProprietorField)
                             
                           
                            
                            
                        //     // alert("reg"+d.registeredProprietorField)
                        //     if(d.registeredProprietorField==uniqueIds1){
                        //        //alert("ehhh")
                        //         //alert(d.reasonForDifferentNameField)
                        //         changeReasons1 = d.reasonForDifferentNameField == 0 ? 'Abbreviation' : 
                        //                         d.reasonForDifferentNameField == 1 ?'Amalgamation' : 
                        //                         d.reasonForDifferentNameField == 2 ?'AmalgamationEvidence' : 
                        //                         d.reasonForDifferentNameField == 3 ?'ChangeIncorporatedName' : 
                        //                         d.reasonForDifferentNameField == 4 ?'DeedPoll' : 
                        //                         d.reasonForDifferentNameField == 5 ?'ErrorinRegister' : 
                        //                         d.reasonForDifferentNameField == 6 ?'LegislativeChangeCompany' : 
                        //                         d.reasonForDifferentNameField == 7 ?'LegislativeChangeCompanyEvidence' : 
                        //                         d.reasonForDifferentNameField == 8 ?'LegislativeChangeNonCompany' : 
                        //                         d.reasonForDifferentNameField == 9 ?'LegislativeChangeNonCompanyEvidence' : 
                        //                         d.reasonForDifferentNameField == 10 ?'Marriage' : 
                        //                         d.reasonForDifferentNameField == 11 ?'MergerorTakeover' :
                        //                         d.reasonForDifferentNameField == 12 ?'MergerorTakeoverEvidence' : 
                        //                         d.reasonForDifferentNameField == 13 ?'NewName' : 
                        //                         d.reasonForDifferentNameField == 14 ?'ResumptionofMaidenName' : 
                        //                         d.reasonForDifferentNameField == 15 ?'SaleorTransfer' : 
                        //                         d.reasonForDifferentNameField == 16 ?'SaleorTransferEvidence' : '';
                        //                         // 'SaleorTransferEvidence';
                        //         changeRequired1 = d.reasonForDifferentNameField !="" ?'Yes': 'No';
                        //         //alert(changeRequired1)
                        //         //alert(changeRequired1)
                        //         if(changeRequired1 == 'Yes'){
                        //             changeReasons.push(changeReasons1);
                        //             changeRequired.push(changeRequired1);
                        //             this.setState({
                        //                 ProprietorSegmentCount: this.state.LoanPartySegment.length,
                        //                 ProprietorSegmentNames: changeNames,
                        //                 ProprietorSegmentUniqueIds: uniqueIds,
                        //                 ProprietorSegmentReasonForDifferentName: changeReasons,
                        //                 ProprietorSegmentChangeNameFormRequired: changeRequired
                        //             },()=>{                            
                        //                 this.UpdateProprietorSegment();
                        //             });
                        //         }
                        //         else{
                        //             this.setState({
                        //                 ProprietorSegmentCount: this.state.LoanPartySegment.length,
                        //                 ProprietorSegmentNames: changeNames,
                        //                 ProprietorSegmentUniqueIds: uniqueIds,
                        //                 // ProprietorSegmentReasonForDifferentName: changeReasons,
                        //                 // ProprietorSegmentChangeNameFormRequired: changeRequired
                        //             },()=>{                            
                        //                 this.UpdateProprietorSegment();
                        //             });
                        //         }
                        //     }
                            
                        // })
    
                       
    
                        // if(changeRequired1 == 'Yes'){
                        //     changeReasons.push(changeReasons1);
                        //     changeRequired.push(changeRequired1);
                        //     this.setState({
                        //         ProprietorSegmentCount: this.state.LoanPartySegment.length,
                        //         ProprietorSegmentNames: changeNames,
                        //         ProprietorSegmentUniqueIds: uniqueIds,
                        //         ProprietorSegmentReasonForDifferentName: changeReasons,
                        //         ProprietorSegmentChangeNameFormRequired: changeRequired
                        //     },()=>{                            
                        //         this.UpdateProprietorSegment();
                        //     });
                        // }
                        // else{
                        //     this.setState({
                        //         ProprietorSegmentCount: this.state.LoanPartySegment.length,
                        //         ProprietorSegmentNames: changeNames,
                        //         ProprietorSegmentUniqueIds: uniqueIds,
                        //         // ProprietorSegmentReasonForDifferentName: changeReasons,
                        //         // ProprietorSegmentChangeNameFormRequired: changeRequired
                        //     },()=>{                            
                        //         this.UpdateProprietorSegment();
                        //     });
                        // }

                        // this.setState({
                        //     ProprietorSegmentCount: this.state.LoanPartySegment.length,
                        //     ProprietorSegmentNames: changeNames,
                        //     ProprietorSegmentUniqueIds: uniqueIds,
                        //     ProprietorSegmentReasonForDifferentName: changeReasons,
                        //     ProprietorSegmentChangeNameFormRequired: changeRequired
                        // },()=>{                            
                        //     this.UpdateProprietorSegment();
                        // });

                       

                    });
                }

                
            })

    }

    

    componentWillUpdate(np,ns){
        if(ns!==this.state){
            
        }
    }
    componentDidUpdate(){
        let displayNoneElems = document.querySelectorAll('div[style*="display:none"]');
        for(let y=0; y<displayNoneElems.length;y++){
            let noneInputElems = displayNoneElems[y].getElementsByTagName('input');
            for(let i =0; i<noneInputElems.length;++i){
                if(noneInputElems[i].required){
                    noneInputElems[i].required=false;
                }
            }
        } 
      
    
        
    }

    checkIfSessionHasExpired()
    {
        let strToken = AuthService.getToken();
        //AuthService.checkIfSessionHasExpired(this.state.token).then((resp)=>{
        AuthService.checkIfSessionHasExpired(strToken).then((resp)=>{
            this.setState({sessionHasExpired:resp.data},
                ()=>{
                    console.log("sessionHasExpired")
                    console.log(this.state.sessionHasExpired )
                });
        },(err)=>{
            this.setState({
                // isInstructPriv:false,
                // shouldRedirect:true
                sessionHasExpired: true
            });
        });
    }

  
    isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isValidNumber(number){
        ///^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        const re =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        return re.test(String(number).toLowerCase());
    }

    stringToBool(value){
        if(value && typeof value === "string"){
            if(value === "Yes") return true;
            if(value === "No") return false;
        }
        //return value;
    }

    convertToBase64(file){
        console.log(file);
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            if(file.name.toLowerCase().includes('.xml')){
                reader.readAsText(file);
            }else{
                reader.readAsDataURL(file);
            }
            
            reader.onload = () => resolve(file.name.toLowerCase().includes('.xml')?btoa(reader.result):reader.result);
            reader.onerror = error => reject(error);
          });
        
    }    


    handleOnDrop = (files, rejectedfiles) =>{    
        let instruction={};
        let instrFiles = this.state.LenderFiles;
  
        if(files && files.length > 0)
        {         
            let fileData = "";   
            files.forEach((e,i)=>{         
                //const f = new Blob([files[i].name], {type: files[i].type});
                //const url = URL.createObjectURL(f);       
                
                var fileName = files[i].name;
                var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
                

                if(!regex.test(fileName) || fileName.length > 200)
                {
                    alert ("Invalid filename. Please check.");
                    return;
                }
                
                var base64 ="";
                
                this.convertToBase64(files[i]).then((resp)=>{;base64=resp;console.log(base64);
                    // if(files[i].name.toLowerCase().includes('.xml'))
                    // {
                    //     instrFiles.push({
                    //         'FileName':"Instruction_"+i+".xml",
                    //         'FileData':base64,
                    //         'FileUrl':"",
                    //     });   
                        
                    //     this.setState({LenderFiles:instrFiles});
                    // }
                    // else{

                        // alert("Hello");
                        // console.log(files[i]);
                        // console.log(files[i].name);
                        instrFiles.push({
                            'FileName':files[i].name,
                            'FileData':base64.split(',')[1],
                            'FileUrl':"",
                        });   
                        console.log(instrFiles[i]);
                        this.setState({LenderFiles:instrFiles});

                        console.log(instrFiles[i]);
                        this.setState({files:instrFiles});

                        // let uploadFiles = this.state.files;

                        // uploadFiles.push({
                        //     files
                        //     //'FileData':base64.split(',')[1],
                        //     //'FileUrl':"",
                        // });  

                        // console.log(this.state.files);

                        // this.setState({
                        //     files: files//files.map(fileItem => fileItem.file)
                        //   },()=>{	
                        //     console.log(this.state.files);
                        //    // this.btnUploadFiles(this.state.files)
                        //   });
                    //}
              
                });    
                    
              
            }
                    
                
            );
          
        }

    } 
    
    UpdateInstruction(e)
    {
        e.preventDefault();
        //alert('hello')
        // this.setState({
        //     isLoading: true
        // });

        // //upload files if there are any
        // if (this.state.files.length != 0) {
        //     // console.log("Button has been clicked!");
      
        //     // console.log("To be upload files");
        //     // console.log(
        //     //   this.props.matterId,
        //     //   this.state.files,
        //     //   this.props.note.OutstandingItemName
        //     // );
        //     //var safeFilename = this.props.note.OutstandingItemName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        //     var fileName = this.state.files[0].name;
        //     var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
            
  
        //     if(!regex.test(fileName) || fileName.length > 200)
        //     {
        //       alert ("Invalid filename. Please check.");
        //       return;
        //     }
        //     else{
        //       //alert (true);
        //       //return;
        //     }
        //     //var safeFilename = fileItem.replace(/[^a-z0-9]/gi, '_').toLowerCase();
            
      
        // //     // this.setState({ showModal: true });
        //     MatterOutstandingService.uploadDocument(
        //       this.state.matter,
        //       this.state.files,
        //       //safeFilename,
        //       //this.props.note.OutstandingItemName
        //       //fileItem
        //     ).then(
        //       response => {
        //     //     if( new SAlert({
        //     //       //title: "File Upload",
        //     //       //html: "Document transferred: <br />" + response.data.Message,
        //     //       // "Document transferred: " + files[0].name + "<br />" + result.Message
        //     //       //html: "<h4>A document has been successfully uploaded.</h4> ",//<br /> Document Name: " + response.data.Message,	
        //     //       html: "<h5>The document has been uploaded and will be sent to the system shortly.</h5> ",
        //     //       //onClose:()=>{this.reloadNotes()}
        //     //       didClose:()=>{this.reloadNotes()}
        //     //       //didClose:this.reloadNotes()
        //     //     })
        //     //   //   .then(function(){
                    
        //     //   //   }
  
        //     //   //   )
        //     //     ){
        //           this.setState({
        //             files: [],
        //             showModal:false,	
        //             uploadSuccess: true
        //           });
        //           //this.props.Reload();
        //         // }else{
        //         //   alert("Document has ben transferred.");
        //         //   this.setState({
        //         //     files: [],
        //         //     showModal:false
        //         //   });
        //         //   this.props.Reload();
        //         // }
        //         console.log("File upload success")
               
              
                
        //       },
        //       error => {
        //         console.log("File upload error")
        //         // new SAlert({
        //         //   title: "File Upload",
        //         //   html: "Document transfer FAILED.",//<br /><br />" + error.message,
        //         //   type: "error"
        //         // });
        //         // this.setState({ showModal: false });
      
        //       }
        //     );
        // }


        let packageBatch={};
        let packageContent={};
        let application={};

        var packageAttachmentContent = [];
        //packageAttachmentContent = this.state.Application;

        // packageBatch['PackageAttachment'] = [{
        //     'PackageAttachmentContent': packageAttachmentContent
        // }];

        let lendingGuarantees = [];
        if(this.state.LendingGuaranteeSegment!=undefined){
            this.state.LendingGuaranteeSegment.forEach((e,i)=> lendingGuarantees.push({
                'PackageContentApplicationLendingGuaranteeAssociatedLoanAccount':[],
                'PackageContentApplicationLendingGuaranteeGuarantor':[],
                'PackageContentApplicationLendingGuaranteeSecurity':[],
                'Limit': this.state.GuaranteeLimit[i],
                'LimitSpecified': this.state.GuaranteeLimit[i] != "" ? true : false,
                'SequenceNumber': '',
                'Type': this.state.LendingGuarantee[i].typeField == "" ? 0: this.state.LendingGuarantee[i].typeField,
                //'TypeSpecified': '',
                'UniqueID': this.state.GuaranteeUniqueId[i],                 
            }));
        }        

        let addressSegment = [];

        if(this.state.PartyOverseasResidentialAddress!=undefined)
        {
            this.state.PartyOverseasResidentialAddress.forEach((e,i)=> addressSegment.push({
                'Standard':{
                    'StreetName': this.state.PartyOverseasResidentialAddress[i]['streetname'],
                    'StreetNumber': this.state.PartyOverseasResidentialAddress[i]['streetnumber'],
                    'StreetType': this.state.PartyOverseasResidentialAddress[i]['streettype'],
                },
                'Suburb': this.state.PartyOverseasResidentialAddress[i]['suburb'],
                'OverseasPostCode': this.state.PartyOverseasResidentialAddress[i]['postcode'],
                'OverseasState': this.state.PartyOverseasResidentialAddress[i]['state'],
                //'OverseasCountry': this.state.PartyOverseasResidentialAddress[i]['country'],//'PH',
                'Country': this.state.PartyOverseasResidentialAddress[i]['country'],//'PH',
                'Type': "Standard",
                'UniqueID': this.state.PartyOverseasAddressUniqueId[i]
            }));
        }


        let primaryApplicantSegment = [];

        //for Proprietor Segment
        // if(this.state.ProprietorSegmentNames!=undefined)
        // {
        //     this.state.ProprietorSegmentNames.forEach((e,i)=> primaryApplicantSegment.push({
        //         'PersonName':{
        //             'FirstName': this.state.ProprietorSegmentNames[i]['firstname'],
        //             'Surname': this.state.ProprietorSegmentNames[i]['lastname']
        //         },
        //         'UniqueID': this.state.ProprietorSegmentUniqueIds[i], 
        //     }));
        // }

        //for independent advice and other items
        if(this.state.LoanPartySegment!=undefined && (this.state.isMiniHoldIndependentFinancialAdviceRequired || this.state.isMiniHoldIndependentLegalAdviceWaived || this.state.isMiniHoldIntrepreterRequired))
        {
            this.state.LoanPartySegment.forEach((e,i)=> primaryApplicantSegment.push({
                'IndependentFinancialAdviceRequirementSpecified' : true,
                'IndependentFinancialAdviceRequirement': this.state.PartySegmentIndependentFinancialAdvice[i] == 'Recommended' ? 1 : 2,
                'IndependentLegalAdviceRequirementSpecified' : true,
                'IndependentLegalAdviceRequirement': this.state.PartySegmentIndependentLegalAdvice[i] == 'Recommended' ? 1 : 2,
                'UniqueID': this.state.PartySegmentUniqueIds[i], 
                'UnderstandApplicationSpecified': true,
                'UnderstandApplication': this.state.PartySegmentInterpreterRequired[i]

            }));
        }


        // let realEstateSegment = [];

        // if(this.state.ProprietorSegmentNames!=undefined)
        // {
        //     this.state.ProprietorSegmentNames.forEach((e,i)=> realEstateSegment.push({
        //         'Title':{
        //             'RegisteredProprietor':{
        //                 'ChangeNameFormRequired': this.state.ProprietorSegmentChangeNameFormRequired[i],
        //                 'Name': this.state.ProprietorSegmentNames[i]['firstname'] + " " + this.state.ProprietorSegmentNames[i]['lastname'],
        //                 'ReasonForDifferentName': this.state.ProprietorSegmentReasonForDifferentName[i],
        //                 'x_RegisteredProprietor': this.state.ProprietorSegmentUniqueIds[i]
        //             }                   
        //         }
        //     }));
        // }



        let RegisteredProprietor = [];

        // if(this.state.ProprietorSegmentNames!=undefined)
        // {
        //     this.state.ProprietorSegmentNames.forEach((e,i)=> RegisteredProprietor.push({
        //         // 'Title':{
        //         //     'RegisteredProprietor':{
        //                 'ChangeNameFormRequired': this.state.ProprietorSegmentChangeNameFormRequired[i],
        //                 'Name': this.state.ProprietorSegmentNames[i]['firstname'] + " " + this.state.ProprietorSegmentNames[i]['lastname'],
        //                 'ReasonForDifferentName': this.state.ProprietorSegmentReasonForDifferentName[i] == "" ? 'Marriage' : this.state.ProprietorSegmentReasonForDifferentName[i],
        //                 'x_RegisteredProprietor': this.state.ProprietorSegmentUniqueIds[i]
        //             // }                   
        //     //     }
        //     }));
        // }


        let noError = true;
        let indicateTenant = false;
        let fillOutFields = false;
        //let isProtectiveTenantSegment = false;
        let Title = [];
        var uniqueIds = [];
        var titleRefs = [];
        var Tenant = [];
        var relatedPersonSegement = [];
        let realEstateSegment = [];
        // let realEstateSegment = [{
        //     // Title,
        //     //'Title':[{                                
        //         //RegisteredProprietor
        //    // }]
        //    Tenant,

        // }];



        if(this.state.RealEstateSegmentEntities!=undefined && this.state.isMiniHoldReverseMortgage)
        {
            //alert (this.state.RealEstateSegmentEntities.length);
            
            this.state.RealEstateSegmentEntities.forEach((e,i)=>{       
                //alert(this.state.RelationshipOfTenantToBorrower[i])
                //alert(this.state.IsThereProtectedTenant[i])
                   
                if(this.state.IsThereProtectedTenant[i] == undefined)
                {
                    noError = false;
                    indicateTenant = true;
                    //isProtectiveTenantSegment = true;
                    // this.setState({
                    //     isLoading: false,
                    // });
                    // alert("Please indicate if there is a protective tenant")
                    // return;                  
                   
                }

                if(this.state.IsThereProtectedTenant[i]==='Yes')
                {
                    //isProtectiveTenantSegment = true;
                    // alert("is there" + this.state.IsThereProtectedTenant[i])
                    // alert("fname" + this.state.ProtectedTenantFirstName[i])
                    // alert("lname" + this.state.ProtectedTenantLastName[i])
                    // alert("tenant" + this.state.ProtectedTenantXTenant[i])
                    if(this.state.ProtectedTenantFirstName[i] == "" || this.state.ProtectedTenantFirstName[i] == undefined || this.state.ProtectedTenantLastName[i] == "" || this.state.ProtectedTenantLastName[i] == undefined  || this.state.ProtectedTenantXTenant[i] == "" || this.state.ProtectedTenantXTenant[i] == undefined)
                    {
                        noError = false;
                        fillOutFields = true;
                        
                        // alert("Please fill out required fields")
                        // return;  
                        // this.setState({
                        //     isLoading: false,
                        // });
                        
                    }
                    else{
                        //alert("no error")
                        noError = true;
                        fillOutFields = false;
                        // Tenant.push({
                        //     //'Tenant':[]
                        //     'KinRelationship': this.state.RelationshipOfTenantToBorrower[i] == undefined? 'Child' : this.state.RelationshipOfTenantToBorrower[i],
                        //     'KinRelationshipSpecified': true,
                        //     'ProtectedTenant': 'Yes',
                        //     'ProtectedTenantSpecified': true,
                        //     'x_Tenant': this.state.ProtectedTenantXTenant[i],
                        // });
                        Tenant = [{
                            'KinRelationship': this.state.RelationshipOfTenantToBorrower[i] == undefined? 'Child' : this.state.RelationshipOfTenantToBorrower[i],
                            'KinRelationshipSpecified': true,
                            'ProtectedTenant': 'Yes',
                            'ProtectedTenantSpecified': true,
                            'x_Tenant': this.state.ProtectedTenantXTenant[i],
                        }]
                        relatedPersonSegement.push({
                            'UniqueID': this.state.ProtectedTenantXTenant[i],
                            'PersonName': {
                                'FirstName':this.state.ProtectedTenantFirstName[i],
                                'MiddleNames':this.state.ProtectedTenantMiddleName[i],
                                'Surname':this.state.ProtectedTenantLastName[i],
                            }
                        });
                    }
                   
                }
                else if(this.state.IsThereProtectedTenant[i]==='No')
                {
                    // isProtectiveTenantSegment = true;
                    //alert("no error")
                    noError = true;
                    fillOutFields = false;
                    // Tenant.push({
                    //     //'Tenant':[]
                    //     //'KinRelationship': null,
                    //     //'KinRelationshipSpecified': false,
                    //     'ProtectedTenant': 'No',
                    //     'ProtectedTenantSpecified': true,
                    //     //'x_Tenant': this.state.ProtectedTenantXTenant[i],
                    // });
                        
                    Tenant=[{
                        //'Tenant':[]
                        //'KinRelationship': null,
                        //'KinRelationshipSpecified': false,
                        'ProtectedTenant': 'No',
                        'ProtectedTenantSpecified': true,
                        //'x_Tenant': this.state.ProtectedTenantXTenant[i],
                    }];
                    
                }

                realEstateSegment.push({
                    'Tenant' : Tenant
                });  
            });

            if(!noError && indicateTenant)
            {
                // alert(noError)
                // alert(indicateTenant)
                // alert(isProtectiveTenantSegment)
                noError = false;
                // this.setState({
                //     isLoading: false,
                // });
                alert("Please indicate if there is a protective tenant")
                return;         
            }
            else if(!noError && fillOutFields)
            {
                alert("Please fill out required fields")
                return;  
            }

            
        }

        if(this.state.isMiniHoldLendersDisclosureDocuments && this.state.files.length < 1)
        {
            alert("Please upload document");
            noError = false;
            return;
        }

       

        // if(this.state.RealEstateSegmentEntities!=undefined)
        // {
        //     this.state.RealEstateAsset.forEach((i,e)=>{
        //         if(this.state.RealEstateAssetSegment[e].titleField!=null)
        //         {
        //             if(this.state.RealEstateAssetSegment[e].titleField.length == 1)
        //             {
        //                 if(this.state.ProprietorRealEstates.length > 0)
        //                 {
        //                     this.state.ProprietorRealEstates.forEach((x,y)=>
        //                     {
                                
        //                         if(this.state.ProprietorRealEstates[y].titleUniqueIDField ==this.state.RealEstateAssetSegment[e].titleField[0].uniqueIDField){

        //                             RegisteredProprietor.push({
        //                                 // 'Title':{
        //                                 //     'RegisteredProprietor':{
        //                                         'ChangeNameFormRequired': this.state.ProprietorSegmentChangeNameFormRequired[i],
        //                                         'Name': this.state.ProprietorSegmentNames[i]['firstname'] + " " + this.state.ProprietorSegmentNames[i]['lastname'],
        //                                         'ReasonForDifferentName': this.state.ProprietorSegmentReasonForDifferentName[i] == "" ? 'Marriage' : this.state.ProprietorSegmentReasonForDifferentName[i],
        //                                         'x_RegisteredProprietor': this.state.ProprietorSegmentUniqueIds[i]
        //                                     // }                   
        //                             //     }
        //                             })
        //                         }
        //                     })
        //                 }
                        
        //                 //check if title from realestate exists in title references
        //                 if(this.state.TitleSegmentTitleReferences.length == 1)
        //                 {                            
        //                     let uniqueIds1 = this.state.TitleSegmentTitleUniqueIds[0];
        //                     let titleref1 = this.state.TitleSegmentTitleReferences[0];                        
        //                     titles.push({
        //                         'UniqueID': uniqueIds1,
        //                         'TitleReference': titleref1
        //                     })
        //                 }
        //                 else if(this.state.TitleSegmentTitleReferences.length > 1)
        //                 {
        //                     this.state.TitleSegmentTitleReferences.forEach((a, b)=>{
        //                         let uniqueIds1 = this.state.TitleSegmentTitleUniqueIds[b];
        //                         let titleref1 = this.state.TitleSegmentTitleReferences[b];                        
        //                         titles.push({
        //                             'UniqueID': uniqueIds1,
        //                             'TitleReference': titleref1,
        //                             RegisteredProprietor
        //                         })
        //                     })
        //                 }
                       
        //             }
        //             else if(this.state.RealEstateAssetSegment[e].titleField.length > 1){
        //                 var titles = this.state.RealEstateAssetSegment[e].titleField;
        //                 titles.forEach((i, b)=>{
        //                     let uniqueIds1 = titles[b].uniqueIDField;
        //                     uniqueIds.push(uniqueIds1);
        //                     let titleref1 = titles[b].titleReferenceField;
        //                     titleRefs.push(titleref1);
        //                 })

        //             }
                    
        //         }                
                
        //     });
        // }

        if(this.state.ProprietorSegmentNames!=undefined)
        {
            this.state.ProprietorSegmentNames.forEach((e,i)=> RegisteredProprietor.push({
                // 'Title':{
                //     'RegisteredProprietor':{
                        'ChangeNameFormRequired': this.state.ProprietorSegmentChangeNameFormRequired[i],
                        'Name': this.state.ProprietorSegmentNames[i]['firstname'] + " " + this.state.ProprietorSegmentNames[i]['lastname'],
                        'ReasonForDifferentName': this.state.ProprietorSegmentReasonForDifferentName[i] == "" ? 'Marriage' : this.state.ProprietorSegmentReasonForDifferentName[i],
                        'x_RegisteredProprietor': this.state.ProprietorSegmentUniqueIds[i]
                    // }                   
            //     }
            }));
        }

        //alert(this.state.TitleSegmentTitleReferences.length)
        
        if(this.state.TitleSegmentTitleReferences.length>0)
        {
            this.state.TitleSegmentTitleReferences.forEach((e,i)=> Title.push({                
                'UniqueID': this.state.TitleSegmentTitleUniqueIds[i], 
                'TitleReference': this.state.TitleSegmentTitleReferences[i], 
                RegisteredProprietor
            }));
        }
        else{
            Title.push({                
                'UniqueID': '', 
                'TitleReference': '', 
                RegisteredProprietor
            });
        }


        // let realEstateSegment = [{
        //     // Title,
        //     //'Title':[{                                
        //         //RegisteredProprietor
        //    // }]
        //    Tenant,

        // }];



        

        application={
            'AccountVariation':[],
            'Address':addressSegment,
            'BusinessChannel':{},
            'PersonApplicant': primaryApplicantSegment,
            'CompanyApplicant':[],
			'CompanyFinancials':[],
			'ContributionFunds':[],
			// 'Declarations':[],
			// 'DepositAccountDetails':[],
			// 'DetailedComment':[],
			// 'Funder':[],
			// 'Household':[],
			// 'Insurance':[],
            'LendingGuarantee': lendingGuarantees,
            'Liability':[],
			'LoanDetails':[],
			'MasterAgreement':[],
			'NonRealEstateAsset':[],
			'OtherExpense':[],
			'OtherIncome':[],
			// 'Overview':[],
			// 'PersonApplicant':[],
			// 'ProductSet':[],
			'RealEstateAsset': realEstateSegment,
            // RealEstateAsset,
			// 'RelatedCompany':[],
			'RelatedPerson':relatedPersonSegement,
			// 'SalesChannel':[],
			// 'Settlement':[],
			// 'SplitLoan':[],
			// 'Summary':[],
			// 'TrustApplicant':[],
			// 'VendorTaxInvoice':[],
        }
        

        console.log(lendingGuarantees);	
        // var PackageContent={};
        // PackageContent['LendingGuarantee']=lendingGuarantees;
        // packageContent['Content'] = {
        //     'Application' : application
            
        // };
        
        // application['RevisionNumber'] = {'Version':"1.0"};
        packageBatch={            
            //'Package': {
                'Attachment': packageAttachmentContent,
                'Content':{
                    'Application' : application,
                    //'NeedsAnalysis': []
                },
                // 'Instructions':{},
                // 'Publisher': {},
                // 'Recipient': [],
                // 'SchemaVersion': {},
                // 'TransformMetadata': [],
                'ProductionData': false,
                'UniqueID': ''
                
            //}
        }
        console.log('packageBatch');
        console.log(packageBatch);

        //return;

        if(noError == true)
        {
            //alert("no error")
            this.setState({
                isLoading: true
            });


            var instruction = {};        
            instruction['PackageBatch']=packageBatch;
            instruction['Files']=this.state.LenderFiles;
            console.log(instruction);
    
            // InstructionService.UpdateInstruction(this.state.matter,packageBatch).then((resp)=>{
            InstructionService.UpdateInstruction(this.state.matter,instruction).then((resp)=>{
                this.setState({msg:resp.data.Content,
                    InstructionSubmit:true,
                    isLoading:false});
                
            
            },(err)=>{
                if (err.response && err.response.status === 401) {
                    this.setState({
                        isLoading:false,
                        shouldRedirect: true,
                        errorMessage: 'There is an error with the request'
                    });

                } else {
                    if(err.response.status== 400){
                        alert('There is an error with the request.');
                    }
                    else{
                        alert("There is an error with the request");
                    }
                    //alert('A matter with this lender reference already exists.');
                    this.setState({isLoading:false,});
                }
            });
        }

       
    }

    UpdateInstruction1(e)
    {
        e.preventDefault();
        //alert('hello')
        let packageBatch={};
        let packageContent={};
        let application={};

        var packageAttachmentContent = [];
        //packageAttachmentContent = this.state.Application;

        // packageBatch['PackageAttachment'] = [{
        //     'PackageAttachmentContent': packageAttachmentContent
        // }];

        let lendingGuarantees = [];
        this.state.LendingGuaranteeSegment.forEach((e,i)=> lendingGuarantees.push({
            'PackageContentApplicationLendingGuaranteeAssociatedLoanAccount':[],
            'PackageContentApplicationLendingGuaranteeGuarantor':[],
            'PackageContentApplicationLendingGuaranteeSecurity':[],
            'Limit': this.state.GuaranteeLimit[i],
            'LimitSpecified': this.state.GuaranteeLimit[i] != "" ? true : false,
            'SequenceNumber': '',
            'Type': '',
            'TypeSpecified': '',
            'UniqueID': this.state.GuaranteeUniqueId[i],                 
        }));

        application={
            'AccountVariation':[],
            'Address':[],
            'BusinessChannel':[],
            'CompanyApplicant':[],
			'CompanyFinancials':[],
			'ContributionFunds':[],
			'Declarations':[],
			'DepositAccountDetails':[],
			'DetailedComment':[],
			'Funder':[],
			'Household':[],
			'Insurance':[],
            'LendingGuarantee': lendingGuarantees,
            'Liability':[],
			'LoanDetails':[],
			'MasterAgreement':[],
			'NonRealEstateAsset':[],
			'OtherExpense':[],
			'OtherIncome':[],
			'Overview':[],
			'PersonApplicant':[],
			'ProductSet':[],
			'RealEstateAsset':[],
			'RelatedCompany':[],
			'RelatedPerson':[],
			'SalesChannel':[],
			'Settlement':[],
			'SplitLoan':[],
			'Summary':[],
			'TrustApplicant':[],
			'VendorTaxInvoice':[],
        }
        

        console.log(lendingGuarantees);	
        // var PackageContent={};
        // PackageContent['LendingGuarantee']=lendingGuarantees;
        // packageContent['Content'] = {
        //     'Application' : application
            
        // };
        
        // application['RevisionNumber'] = {'Version':"1.0"};
        packageBatch={            
            'Package': {
                'attachmentField': packageAttachmentContent,
                'contentField':{
                    'Application' : application,
                    'NeedsAnalysis': []
                },
                'instructionsField':{},
                'publisherField': {},
                
            }
        }
        console.log('packageBatch');
        console.log(packageBatch);

        //InstructionService.SendNewLoansInstruction(this.state.matter,packageBatch).then((resp)=>{
        InstructionService.UpdateInstruction(this.state.matter,packageBatch).then((resp)=>{
            this.setState({msg:resp.data.Content,
                InstructionSubmit:true,
                isLoading:false});
            
         
        },(err)=>{
            if (err.response && err.response.status === 401) {
                this.setState({
                    isLoading:false,
                    shouldRedirect: true,
                    errorMessage: 'there is an error with the request'
                });

            } else {
                if(err.response.status== 400){
                    alert('A matter with this lender reference already exists.');
                }
                else{
                    alert("There is an error with the request");
                }
                //alert('A matter with this lender reference already exists.');
                this.setState({isLoading:false,});
            }
        });
    }
    


    GetStates(){
        let respVal = [];
        
        InstructionService.GetInstructionStates().then((resp)=>{
            this.setState({States:resp.data.Content},()=>{
                console.log("this.state.States");
                console.log(this.state.States);

                let mapd=[];
                mapd.push(<option label="State"></option>);
                for(let f=0;f<this.state.States.length;f++){
                    mapd.push(<option>{this.state.States[f]}</option>);
                };

                // let mapStreetTypes=[];
                // // mapd.push(<option label="State"></option>);
                // for(let f=0;f<this.state.StreetTypeList.length;f++){
                //     mapStreetTypes.push(<option>{this.state.StreetTypeList[f]}</option>);
                // };

                // let mapCountries=[];
                // // mapd.push(<option label="State"></option>);
                // for(let f=0;f<this.state.CountryCodeList.length;f++){
                //     mapCountries.push(<option>{this.state.CountryCodeList[f]}</option>);
                // };

                // let mapReasonForDifferentNameList=[];
                // // mapd.push(<option label="State"></option>);
                // for(let a=0;a<this.state.ReasonForDifferentNameList.length;a++){
                //     mapReasonForDifferentNameList.push(<option>{this.state.ReasonForDifferentNameList[a]}</option>);
                // };

                let mapReasonForDifferentNameList="";
                mapReasonForDifferentNameList = (
                    <>
                        <option key="" value="">Select Reason</option>
                        {this.state.ReasonForDifferentNameList.map(function(data, key){  return (
                            <option key={key} value={data.key}>{data.value}</option> )
                        })}
                    </>                
                ) 

                // let mapChangeNameYNList=[];

                // for(let x=0;x<this.state.ChangeNameYNList.length;x++){
                //     //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                //     mapChangeNameYNList.push(<option>{this.state.ChangeNameYNList[x]}</option>)
                // }

                let mapChangeNameYNList="";
                mapChangeNameYNList = (
                    <>
                        {/* <option key="" value="">Select Applicant Trmansaction</option> */}
                        {this.state.ChangeNameYNList.map(function(data, key){  return (
                            <option key={key} value={data.key}>{data.value}</option> )
                        })}
                    </>                
                ) 

                let mapKinRelationshipList="";
                mapKinRelationshipList = (
                    <>
                        {/* <option key="" value="">Select Applicant Trmansaction</option> */}
                        {this.state.KinRelationshipList.map(function(data, key){  return (
                            <option key={key} value={data.key}>{data.value}</option> )
                        })}
                    </>                
                ) 

                let mapApplicantTypeList="";
                mapApplicantTypeList = (
                    <>
                        {/* <option key="" value="">Select Applicant Trmansaction</option> */}
                        {this.state.ApplicantTypeList.map(function(data, key){  return (
                            <option key={key} value={data.key}>{data.value}</option> )
                        })}
                    </>                
                )              

                // let mapStreetTypeList=[];

                // for(let x=0;x<this.state.StreetTypeList.length;x++){
                //     mapStreetTypeList.push(<option>{this.state.StreetTypeList[x]}</option>)
                // }

                let mapStreetTypeList="";
                mapStreetTypeList = (
                    <>
                        {/* <option key="" value="">Select Applicant Trmansaction</option> */}
                        {this.state.StreetTypeList.map(function(data, key){  return (
                            <option key={key} value={data.key}>{data.value}</option> )
                        })}
                    </>                
                )   

                let mapCountriesList="";
                mapCountriesList = (
                    <>
                        {/* <option key="" value="">Select Applicant Trmansaction</option> */}
                        {this.state.CountryCodeList.map(function(data, key){  return (
                            <option key={key} value={data.key}>{data.value}</option> )
                        })}
                    </>                
                )   
                
                this.setState({
                    MappedStates:mapd,                    
                    //MappedStreetTypes: 
                    //MappedCountries: mapCountries,
                    ChangeNameYNList: mapChangeNameYNList,
                    ReasonForDifferentNameList: mapReasonForDifferentNameList,
                    KinRelationshipList: mapKinRelationshipList,
                    ApplicantTypeList: mapApplicantTypeList,
                    StreetTypeList: mapStreetTypeList,
                    CountryCodeList: mapCountriesList
                },()=>{
                    // this.UpdateAddressSection();
                    this.UpdateLendingGuaranteeSegment();
                    // this.UpdateProprietorSegment();
                    this.UpdateNonRealEstateSegment();
                    this.UpdateRealEstateSegment();
                    this.UpdateTitleSegment();
                    //this.UpdatePartySegment();
                    this.UpdateTrustSegment();
                    ///this.UpdateRealEstateAssetSegment();
                    console.log("this.state.ReasonForDifferentNameList")
                    console.log(this.state.ReasonForDifferentNameList)
                    //this.GetDischargeReasonTypes();
                    // this.UpdateIncomingMortgageeSegment();
                    // this.UpdateSecuritySegment();
                    // this.UpdatePartySegment();
                    // this.UpdatePartyRep();
                    // this.GetDischargeTypes();
                    // this.UpdateImportNotesSegment();
                    //this.GetTrustAccountOptions();
                    //this.UpdatePayoutSegment();
                });
            });
        },
        (err)=>{
            this.setState({

                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
            });
            
        });

    }

    PartyRepNameUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepNames;
        val[i]=e.target.value;
        this.setState({PartyRepNames:val},()=>{this.UpdateSecuritySegment()});
    }

    PartyRepEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepEmails;
        val[i]=e.target.value;
        this.setState({PartyRepEmails:val},()=>{this.UpdateSecuritySegment()});
    }
    PartyRepNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepNumbers;
        val[i]=e.target.value;
        this.setState({PartyRepNumbers:val},()=>{this.UpdateSecuritySegment()});
    }
    PartyRepTypeUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepType;
        val[i]=e.target.value;
        this.setState({PartyRepType:val},()=>{this.UpdateSecuritySegment()});
    }
    UpdatePartyRep(){
        
       
    }

    SecurityMortgageeNameUpdated(e,i){	
        //alert(e)	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeNames;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeNames:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeCompanyACNUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeCompanyACN;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeCompanyACN:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageNumberUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageNumbers;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageNumbers:val},()=>{this.UpdateSecuritySegment()});	
    }   	
    SecurityMortgageeFormattedTitleRefNoUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeFormattedTitleRefNo;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeFormattedTitleRefNo:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeVolumeUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeVolume;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeVolume:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeFolioUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeFolio;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeFolio:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeBlockUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeBlock;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeBlock:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeDistrictDivisionUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeDistrictDivision;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeDistrictDivision:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeLandDescriptionUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeLandDescription;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeLandDescription:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeSectionUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeSection;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeSection:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeUnitUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeUnit;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeUnit:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeLotUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeLot;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeLot:val},()=>{this.UpdateSecuritySegment()});	
    }	
    SecurityMortgageeTownUpdated(e,i){	
        e.preventDefault();	
        let val = this.state.SecurityMortgageeTown;	
        val[i]=e.target.value;	
        this.setState({SecurityMortgageeTown:val},()=>{this.UpdateSecuritySegment()});	
    }

    IncomingMortgageeNameUpdated(e,i){
        //alert(e)
        e.preventDefault();
        let val = this.state.IncomingMortgageeNames;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeNames:val},()=>{this.UpdateSecuritySegment()});
    }

    IncomingMortgageeEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeEmails;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeEmails:val},()=>{this.UpdateSecuritySegment()});
    }
    IncomingMortgageeNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeNumbers;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeNumbers:val},()=>{this.UpdateSecuritySegment()});
    }

    IncomingMortgageeTypeUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeType;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeType:val},()=>{this.UpdateSecuritySegment()});
    }

    UpdateIncomingMortgageeSegment(){
        let ents = this.state.IncomingMortgageeSegmentEntities;
    }   

    // PartyOverseasResidentialAddressUpdated(e,i){
    //     e.preventDefault();
    //     let add =this.state.PartyOverseasResidentialAddress;
    //     add[i]=e.target.value;
    //     this.setState({PartyOverseasResidentialAddress:add},()=>{this.UpdatePartySegment()});
    // }

    PartyOverseasResidentialAddressUpdated(e,i,key){
        // alert(e.target.value)
        // alert(i)
        // alert(key)
        e.preventDefault();
        let address = this.state.PartyOverseasResidentialAddress;
        if(address[i]===undefined){
            // let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            // let addressEntity = {'line1':'','line2':'','postcode':'','country':''};
            let addressEntity = {'streettype':'','streetname':'','streetnumber':'','suburb':'','state':'','postcode':'','country':''};
            address.push(addressEntity);
        }
        address[i][key]=e.target.value;

        this.setState({PartyOverseasResidentialAddress:address},()=>{this.UpdateAddressSection()});

    }    

    TrustSegmentApplicantTypeUpdated(e,i){
        e.preventDefault();
        let types =this.state.TrustSegmentApplicantType;
        types[i]=e.target.value;
        this.setState({TrustSegmentApplicantType:types},()=>{this.UpdateTrustSegment()});
    }

    TrustSegmentTrustNamesUpdated(e,i){
        e.preventDefault();
        let types =this.state.TrustSegmentTrustNames;
        types[i]=e.target.value;
        this.setState({TrustSegmentTrustNames:types},()=>{this.UpdateTrustSegment()});
    }

    TrustSegmentTrustABNsUpdated(e,i){
        e.preventDefault();
        let types =this.state.TrustSegmentTrustABNs;
        types[i]=e.target.value;
        this.setState({TrustSegmentTrustABNs:types},()=>{this.UpdateTrustSegment()});
    }

    UpdateTrustSegment(){
        let ents = this.state.TrustSegmentEntities;

        // let titleOptions=[];
        // for(let i=0; i<this.state.MappedTitles.length;i++){
        //     titleOptions.push(<option>{this.state.MappedTitles[i]}</option>);
        // }
        
        
        //let mappedTitleOptions = titleOptions.length? titleOptions.reduce((p,c)=>[p,'',c]):'';

        // console.log("title")
        // console.log(mappedTitleOptions)
        
        if(ents.length>this.state.TrustSegmentCount){
            let trustApplicantTypes = this.state.TrustSegmentApplicantType;
            let trustNames = this.state.TrustSegmentTrustNames;
            let trustABN = this.state.TrustSegmentTrustABNs;
            
            for(let i = this.state.TrustSegmentEntities.length; i>this.state.TrustSegmentCount  && i>this.state.TrustSegmentMinCount; i--){
                trustApplicantTypes.pop();
                trustNames.pop();
                trustABN.pop();
            }
            this.setState({
                TrustSegmentApplicantType:trustApplicantTypes,
                TrustSegmentTrustNames: trustNames,
                TrustSegmentTrustABNs: trustABN,                
             });
        }else{
            let trustApplicantTypes = this.state.TrustSegmentApplicantType;
            let trustNames = this.state.TrustSegmentTrustNames;
            let trustABN = this.state.TrustSegmentTrustABNs;

            for(let f = 0; f<this.state.TrustSegmentCount; f++){
                if(trustApplicantTypes.length<this.state.TrustSegmentCount){
                 
                    trustApplicantTypes.push('Borrower');                    
                    trustNames.push('');
                    trustABN.push('');
                }
            }
            this.setState({
                TrustSegmentApplicantType:trustApplicantTypes,
                TrustSegmentTrustNames: trustNames,
                TrustSegmentTrustABNs: trustABN,
             },()=>{
                        for(let i =0; i < this.state.TrustSegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Trust Applicant #{i+1}</h5>
                                    <div id={"PartyName"+i}>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Applicant Type
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"PartyNamePersonTitle"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.TrustSegmentApplicantType[i]} onChange={(e)=>{e.preventDefault();this.TrustSegmentApplicantTypeUpdated(e,i)}}>
                                                    {this.state.ApplicantTypeList}
                                                </select>
                                            </div>
                                        </div>                                       
                                        <div id={"PartyNameCompany"+i}>
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Trust Name
                                                </div>
                                                <div>
                                                <input type="text" id={'PartyNamePersonCompanyName'+i}  value={this.state.TrustSegmentTrustNames[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentTrustNamesUpdated(e,i)}} autoComplete={false} placeholder="Trust Name" />
                                                </div>
                                            </div>
                                            {/* <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ACN
                                                </div>                                                
                                                <div>
                                                    <input type="text" id={'PartyNamCompanyACN'+i} value={this.state.PartySegmentNames[i]['Acn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Acn')}} autoComplete={false} placeholder="ACN" />
                                                    
                                                </div>
                                            </div> */}
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ABN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNameCompanyAbn'+i} value={this.state.TrustSegmentTrustABNs[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentTrustABNsUpdated(e,i)}} autoComplete={false} placeholder="ABN" />
                                                </div>
                                            </div>
                                            {/* <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ARBN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNameCompanyArbn'+i} value={this.state.PartySegmentNames[i]['Arbn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Arbn')}} autoComplete={false} placeholder="ARBN" />
                                                </div>
                                            </div> */}
                                           
                                        </div>                                        
                                             
                                        <br/>                                                                   
                                    </div>

                                   
                                </>));
                               
                        }                        
                        this.setState({TrustSegmentEntities:ents});
            });

           
        }

    }


    // PartyOverseasMailingAddressUpdated(e,i){
    //     e.preventDefault();
    //     let add =this.state.PartyOverseasMailingAddress;
    //     add[i]=e.target.value;
    //     this.setState({PartyOverseasMailingAddress:add},()=>{this.UpdatePartySegment()});
    // }

    // UpdatePartySegment(){
    //     this.checkIfSessionHasExpired();
    //     let ents = this.state.PartySegmentEntities;
        
    //     if(ents.length>this.state.PartySegmentCount){
    //         let partyAddresses = this.state.PartySegmentAddresses;
    //         let partyNames = this.state.PartySegmentNames;
    //         let partyPorCTypes = this.state.PartySegmentPorCTypes;
    //         let partyTypes= this.state.PartySegmentPartyTypes;
    //         let partyEmails = this.state.PartySegmentEmails;
    //         let partyMobiles = this.state.PartySegmentPhoneNumbers;
    //         let companyPPSR = this.state.PartySegmentCompanyPPSR;
    //         for(let i = this.state.PartySegmentEntities.length; i>this.state.PartySegmentCount  && i>this.state.PartySegmentMinCount; i--){
    //             partyAddresses.pop();
    //             partyNames.pop();
    //             partyTypes.pop();
    //             ents.pop();
    //             partyMobiles.pop();
    //             partyEmails.pop();
    //             partyPorCTypes.pop();
    //             companyPPSR.pop();
    //         }
    //         this.setState({PartySegmentAddresses:partyAddresses,PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,
    //             PartySegmentEntities:ents,
    //             PartySegmentPhoneNumbers:partyMobiles,
    //             PartySegmentEmails:partyEmails,
    //             PartySegmentPorCTypes:partyPorCTypes,
    //             PartySegmentCompanyPPSR: companyPPSR
    //         });
    //     }else{
    //         let partyTypes= this.state.PartySegmentPartyTypes;
    //         let partyAdd = this.state.PartySegmentAddresses;
    //         let partyNames = this.state.PartySegmentNames;
    //         let partyPorCTypes = this.state.PartySegmentPorCTypes;
    //         let partyEmails = this.state.PartySegmentEmails;
    //         let partyMobiles = this.state.PartySegmentPhoneNumbers;
    //         let addressEntity = {'state':'','city':'','number':'','postcode':'','street':''};
    //         //let partyNameEntity = {'GivenName':'','Surname':'','CompanyName':'','Acn':'','Abn':'', 'CompanyPPSR':''};
    //         let partyNameEntity = {'GivenName':'','Surname':'','CompanyName':'','Acn':'','Abn':''};
    //         let companyPPSR = this.state.PartySegmentCompanyPPSR;
    //         for(let f = 0; f<this.state.PartySegmentCount; f++){
    //             if(partyEmails.length<this.state.PartySegmentCount){
                 
    //                 partyNames.push(partyNameEntity);
    //                 partyAdd.push(addressEntity);
    //                 partyTypes.push('Borrower');
    //                 partyPorCTypes.push('Person');
    //                 partyEmails.push('');
    //                 partyMobiles.push('');
    //                 companyPPSR.push('');
    //             }
    //         }
    //         this.setState({PartySegmentAddresses:partyAdd,PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,
    //             PartySegmentPorCTypes:partyPorCTypes,
    //             PartySegmentEmails:partyEmails, 
    //             PartySegmentPhoneNumbers:partyMobiles,
    //             PartySegmentCompanyPPSR: companyPPSR
    //         },()=>{
    //                     for(let i =0; i < this.state.PartySegmentCount; i++){
    //                         ents[i]=((
    //                             <>
    //                                 <h5 className="listContentTitle">Loan Party #{i+1}</h5>
    //                                 <div id={"PartyName"+i}>
                                        
    //                                     <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
    //                                         <div className="inputLabel">
    //                                             Overseas Residential Address
    //                                         </div>
    //                                         <div>
    //                                             <input type="text" id={'PartyOverseasResidentialAddress'+i}  value={this.state.PartyOverseasResidentialAddress[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i)}} autoComplete="hello" placeholder="Overseas Residential Address" />
    //                                         </div>
    //                                     </div>

    //                                     <div id={"PartyName"+i}>
                                        
    //                                     <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
    //                                         <div className="inputLabel">
    //                                             Overseas Mailing Address
    //                                         </div>
    //                                         <div>
    //                                             <input type="text" id={'PartyOverseasMailingAddress'+i}  value={this.state.PartyOverseasMailingAddress[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasMailingAddressUpdated(e,i)}} autoComplete="hello" placeholder="Overseas Mailing Address" />
    //                                         </div>
    //                                     </div>
                                        
    //                                 </div>
                                        
    //                                 </div>

                                    
                                    
                                    
    //                             </>));
                               
    //                     }
    //                     this.setState({PartySegmentEntities:ents});
    //         });
    //     }

    // }


    PopulatePartyForPartySegment(party)
    {       

        this.setState({
            LoanPartySegment: party
        },()=>{
                
                
            

                let partyIndependentLegalAdvice = this.state.PartySegmentIndependentLegalAdvice;
                let partyIndependentFinancialAdvice = this.state.PartySegmentIndependentFinancialAdvice;            
                let partyNames = this.state.PartySegmentNames;
                let partyPorCTypes = this.state.PartySegmentPorCTypes;
                let partyTypes= this.state.PartySegmentPartyTypes;         
                let uniqueIds = this.state.PartySegmentUniqueIds; 
                let partyInterpreterRequired = this.state.PartySegmentInterpreterRequired;

                if(this.state.LoanPartySegment.length > 0)
                {
                      //if(this.state.LoanPartySegment.length > 1){
                    //alert(this.state.LoanPartySegment.length)
                    this.state.LoanPartySegment.forEach((i, e)=>{
                        // let changeNames1 = this.state.LoanPartySegment[e].personNameField.firstNameField + " " + this.state.LoanPartySegment[e].personNameField.surnameField;
                        // changeNames.push(changeNames1);
                        let nameEntity = {
                            'FirstName': this.state.LoanPartySegment[e].personNameField.firstNameField,
                            // 'middlename': this.state.LoanPartySegment[0].personNameField.middleNamesField,
                            'Surname': this.state.LoanPartySegment[e].personNameField.surnameField,
                        };
        
                        partyNames.push(nameEntity);

                        let uniqueIds1 = this.state.LoanPartySegment[e].uniqueIDField;
                        uniqueIds.push(uniqueIds1);

                        let partyTypes1 = this.state.LoanPartySegment[e].applicantTypeField;
                        partyTypes1 = partyTypes1 == 0 ? "Borrower" :  partyTypes1 == 1 ? "BorrowerandGuarantor" :"Guarantor";
                        //alert(partyTypes1)
                        partyTypes.push(partyTypes1);

                        let partyIndependentLegalAdvice1 = this.state.LoanPartySegment[e]?.independentLegalAdviceRequirementField;
                        partyIndependentLegalAdvice1 = partyIndependentLegalAdvice1 == 0 ? "NotRequired" :  partyIndependentLegalAdvice1 == 1 ? "Recommended" :"Required";
                        partyIndependentLegalAdvice.push(partyIndependentLegalAdvice1);


                        let partyIndependentFinancialAdvice1 = this.state.LoanPartySegment[e]?.independentFinancialAdviceRequirementField;
                        partyIndependentFinancialAdvice1 = partyIndependentFinancialAdvice1 == 0 ? "NotRequired" :  partyIndependentFinancialAdvice1 == 1 ? "Recommended" :"Required";
                        partyIndependentFinancialAdvice.push(partyIndependentFinancialAdvice1);

                        let understandApplicationField = this.state.LoanPartySegment[e].understandApplicationFieldSpecified;

                        let partyInterpreterRequired1 = understandApplicationField == false? 'Yes' : this.state.LoanPartySegment[e]?.understandApplicationField;
                        partyInterpreterRequired.push(partyInterpreterRequired1);

                        this.setState({                                        
                            PartySegmentIndependentLegalAdvice: partyIndependentLegalAdvice,
                            PartySegmentIndependentFinancialAdvice: partyIndependentFinancialAdvice,                
                            PartySegmentNames:partyNames,
                            PartySegmentPartyTypes:partyTypes,
                            PartySegmentPorCTypes:partyPorCTypes,
                            PartySegmentCount: this.state.LoanPartySegment.length,
                            PartySegmentUniqueIds: uniqueIds,
                            PartySegmentInterpreterRequired: partyInterpreterRequired
                        },()=>{                            
                            this.UpdatePartySegment();
                        });                      
        
                    });
                //}       
                }                       
            })

    }

    PartySegmentNamesUpdated(e, i, key){
        e.preventDefault();
        let names =this.state.PartySegmentNames;
        names[i][key]=e.target.value;

        if(names[i]===undefined){
            // let nameEntity = {'firstname':'','middlename':'','lastname':''};
            let nameEntity = {'FirstName':'','Surname':''};
            names.push(nameEntity);
        }
        this.setState({PartySegmentNames:names},()=>{this.UpdatePartySegment()});
    }


    UpdatePartySegment(){
        let ents = this.state.PartySegmentEntities;

        if(ents.length>this.state.PartySegmentCount){
            let partyIndependentLegalAdvice = this.state.PartySegmentIndependentLegalAdvice;
            let partyIndependentFinancialAdvice = this.state.PartySegmentIndependentFinancialAdvice;            
            let partyNames = this.state.PartySegmentNames;
            let partyPorCTypes = this.state.PartySegmentPorCTypes;
            let partyTypes = this.state.PartySegmentPartyTypes;    
            let uniqueIds =   this.state.PartySegmentUniqueIds;       
            let partyInterpreterRequired = this.state.PartySegmentInterpreterRequired;
            for(let i = this.state.PartySegmentEntities.length; i>this.state.PartySegmentCount  && i>this.state.PartySegmentMinCount; i--){
                partyIndependentLegalAdvice.pop();
                partyIndependentFinancialAdvice.pop();                
                partyNames.pop();
                partyTypes.pop();
                ents.pop();               
                partyPorCTypes.pop();
                uniqueIds.pop();   
                partyInterpreterRequired.pop();
            }
            this.setState({
                PartySegmentIndependentLegalAdvice: partyIndependentLegalAdvice,
                PartySegmentIndependentFinancialAdvice: partyIndependentFinancialAdvice,                
                PartySegmentNames:partyNames,
                PartySegmentPartyTypes:partyTypes,
                PartySegmentEntities:ents,
                PartySegmentPorCTypes:partyPorCTypes,
                ProprietorSegmentUniqueIds: uniqueIds,
                PartySegmentInterpreterRequired: partyInterpreterRequired
             });
        }else{
            // let partyApplicantTypes = this.state.PartySegmentApplicantTypes;
            let partyIndependentLegalAdvice = this.state.PartySegmentIndependentLegalAdvice;
            let partyIndependentFinancialAdvice = this.state.PartySegmentIndependentFinancialAdvice;            
            let partyNames = this.state.PartySegmentNames;
            let partyPorCTypes = this.state.PartySegmentPorCTypes;        
            let partyTypes= this.state.PartySegmentPartyTypes; 
            let uniqueIds =   this.state.PartySegmentUniqueIds;        
            let partyInterpreterRequired = this.state.PartySegmentInterpreterRequired;
            let partyNameEntity = {'FirstName':'','MiddleNames':'','Surname':''};
            for(let f = 0; f<this.state.PartySegmentCount; f++){
                if(partyNames.length<this.state.PartySegmentCount){                 
                    partyNames.push(partyNameEntity);
                    // partyApplicantTypes.push('');
                    partyTypes.push('Borrower');
                    partyIndependentLegalAdvice.push('Required');
                    partyIndependentFinancialAdvice.push('Required');                    
                    partyPorCTypes.push('Person');
                    uniqueIds.push('');
                    partyInterpreterRequired.push('Yes');
                }
            }
            this.setState({
                // PartySegmentApplicantTypes: partyApplicantTypes,
                PartySegmentIndependentLegalAdvice: partyIndependentLegalAdvice,
                PartySegmentIndependentFinancialAdvice: partyIndependentFinancialAdvice,                
                PartySegmentNames:partyNames,
                PartySegmentPartyTypes:partyTypes,
                PartySegmentPorCTypes:partyPorCTypes,
                ProprietorSegmentUniqueIds: uniqueIds,
                PartySegmentInterpreterRequired: partyInterpreterRequired
             },()=>{
                        for(let i =0; i < this.state.PartySegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Loan Party #{i+1}</h5>
                                    <div id={"PartyName"+i}>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}  style={{display: 'none'}}>
                                            <div className="inputLabel">
                                                Unique ID
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonUniqueId'+i} value={this.state.PartySegmentUniqueIds[i]} readOnly={true}  />                                            
                                            </div>
                                        </div>
                                        <div className="inputBox"  style={{display: 'none'}}>
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Party Type
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={true} onChange={(e)=>{let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdatePartySegment()})}} style={{display: 'none'}}>    
                                                <RadioButton value="Borrower" name={"PartyType"+i} title="Borrower" checked={this.state.PartySegmentPartyTypes[i]==='Borrower'}/>                              
                                                <RadioButton value="Guarantor" name={"PartyType"+i} title="Guarantor" checked={this.state.PartySegmentPartyTypes[i]==='Guarantor'}/>                                                                                              
                                            </div>
                                        </div>                                   
                                       {/* <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':'none'}}> */}
                                       <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonGivenName'+i} className={this.state.PartySegmentNames[i]['FirstName']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['FirstName']} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'FirstName')}} autoComplete={false} placeholder="Given Name" readOnly={true} />
                                            </div>
                                        </div>
                                        {/* <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Middle Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonMiddleName'+i} className={this.state.PartySegmentNames[i]['MiddleNames']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['MiddleNames']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'MiddleNames')}} autoComplete={false} placeholder="Middle Name" />
                                            </div>
                                        </div> */}
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Surname
                                            </div>
                                            <div className="multiColsIinputBoxnputBox">
                                                <input type="text" id={'PartyNamePersonSurname'+i} className={this.state.PartySegmentNames[i]['Surname']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['Surname']} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Surname')}} autoComplete={false} placeholder="Surname" readOnly={true} />
                                            </div>
                                        </div>

                                        {this.state.isMiniHoldIndependentLegalAdviceWaived && this.state.PartySegmentPartyTypes[i] == "Guarantor" ? 
                                            <div className="inputBox">
                                                <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                    Independent Legal Advice Waived?
                                                </div>
                                                <div className="multiColsInputBoxContentWidth" id={"PartyIndependentLegalAdvice"+i} value={this.state.PartySegmentIndependentLegalAdvice[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentIndependentLegalAdvice; vals[i]=e.target.value;this.setState({PartySegmentIndependentLegalAdvice:vals},()=>{this.UpdatePartySegment()})}}>    
                                                    <RadioButton value="Required" name={"PartyIndependentLegalAdvice"+i} title="Required" checked={this.state.PartySegmentIndependentLegalAdvice[i]==='Required'} />                              
                                                    <RadioButton value="Recommended" name={"PartyIndependentLegalAdvice"+i} title="Recommended" checked={this.state.PartySegmentIndependentLegalAdvice[i]==='Recommended'} />                                           
                                                </div>
                                            </div>
                                        : ''}

                                        {this.state.isMiniHoldIndependentFinancialAdviceRequired && (this.state.PartySegmentPartyTypes[i] == "Guarantor" || this.state.PartySegmentPartyTypes[i] == "Borrower") ? 
                                            <div className="inputBox">
                                                <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                    Independent Financial Advice Required?
                                                </div>
                                                <div className="multiColsInputBoxContentWidth" id={"PartyIndependentFinancialAdvice"+i} value={this.state.PartySegmentIndependentFinancialAdvice[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentIndependentFinancialAdvice; vals[i]=e.target.value;this.setState({PartySegmentIndependentFinancialAdvice:vals},()=>{this.UpdatePartySegment()})}}>    
                                                    <RadioButton value="Required" name={"PartyIndependentFinancialAdvice"+i} title="Required" checked={this.state.PartySegmentIndependentFinancialAdvice[i]==='Required'} />                              
                                                    <RadioButton value="Recommended" name={"PartyIndependentFinancialAdvice"+i} title="Recommended" checked={this.state.PartySegmentIndependentFinancialAdvice[i]==='Recommended'} />                                           
                                                </div>
                                            </div>
                                        : ''}
                                        
                                        {this.state.isMiniHoldIntrepreterRequired && (this.state.PartySegmentPartyTypes[i] == "Guarantor" || this.state.PartySegmentPartyTypes[i] == "Borrower") ? 
                                            <div className="inputBox">
                                                <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                    Applicant Understands Application?
                                                </div>
                                                <div className="multiColsInputBoxContentWidth" id={"PartyInterpreterRequired"+i} value={this.state.PartySegmentInterpreterRequired[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentInterpreterRequired; vals[i]=e.target.value;this.setState({PartySegmentInterpreterRequired:vals},()=>{this.UpdatePartySegment()})}}>    
                                                    <RadioButton value="Yes" name={"PartyInterpreterRequired"+i} title="Yes" checked={this.state.PartySegmentInterpreterRequired[i]==='Yes'} />                              
                                                    <RadioButton value="No" name={"PartyInterpreterRequired"+i} title="No" checked={this.state.PartySegmentInterpreterRequired[i]==='No'} />                                           
                                                </div>
                                            </div>      
                                        :''}
                                                                        
                                           
                                        <br/>                                                                   
                                    </div>                                 
                                </>));
                               
                        }                        
                        this.setState({PartySegmentEntities:ents});
            });

           
        }

    }


    // UpdateAddressSection(addressEntity)
    UpdateAddressSection()
    {
        // console.log("addressEntity");
        // console.log(addressEntity);
        this.checkIfSessionHasExpired();
        let ents = this.state.AddressSectionEntities;
        
        if(ents.length>this.state.AddressSectionCount){
            let partyAddresses = this.state.PartyOverseasResidentialAddress;
            let uniqueId = this.state.PartyOverseasAddressUniqueId;
           
            for(let i = this.state.AddressSectionEntities.length; i>this.state.AddressSectionCount  && i>this.state.AddressSectionMinCount; i--){
                ents.pop();
                partyAddresses.pop();
                uniqueId.pop()     
            }
            this.setState({
                PartyOverseasResidentialAddress:partyAddresses,
                AddressSectionEntities: ents,
                PartyOverseasAddressUniqueId: uniqueId
            });
        }else{     
            let partyAdd = this.state.PartyOverseasResidentialAddress; 
            let uniqueId = this.state.PartyOverseasAddressUniqueId;
            // let addressEntity = {'line1':'','line2':'','postcode':'','country':''};
            //let addressEntity = {'streetname':'','streetnumber':'','postcode':'','country':''};
            let addressEntity = {'streettype':'','streetname':'','streetnumber':'','suburb':'','postcode':'','country':''};
            // let addressEntity = {'state':'','city':'','number':'','postcode':'','street':''};
           
            for(let f = 0; f<this.state.AddressSectionCount; f++){
                if(partyAdd.length<this.state.AddressSectionCount){
                //  alert(partyAdd.length)
                //  alert(this.state.AddressSectionCount)
                    partyAdd.push(addressEntity);
                    uniqueId.push('');
                }
            }
            this.setState({
                PartyOverseasResidentialAddress:partyAdd,
                PartyOverseasAddressUniqueId: uniqueId
            },()=>{
                        for(let i =0; i < this.state.AddressSectionCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Overseas Address #{i+1}</h5>
                                    <div className="PartyAddress" id={"PartyAddress"+i}>
                                        {/* <div className="inputBox" >
                                            <div className="inputLabel">
                                                Street
                                            </div>
                                            <div>                                                    
                                                <select className="form-control" id={"StateName"+i} value={this.state.PartyOverseasResidentialAddress[i]['street']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'street')}}>
                                                    {this.state.StreetTypeList}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                City
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['city']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'city')}} autoComplete="hello" placeholder="City"/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                State
                                            </div>
                                            <div>                                                    
                                                <select className="form-control" id={"StateName"+i} value={this.state.PartyOverseasResidentialAddress[i]['state']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'state')}}>
                                                {this.state.MappedStates}
                                                </select>
                                                <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['line1']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPorCTypes; vals[i]=e.target.value;this.setState({PartySegmentPorCTypes:vals},()=>{this.PartyOverseasResidentialAddressUpdated(e,i,'line1')})}} autoComplete="hello" placeholder="Line1"/> 
                                            </div>
                                        </div> */}
                                        {/* <div className="inputBox" >
                                            <div className="inputLabel">
                                                Line 1 Address
                                            </div>
                                            <div>                                                    
                                                <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['line1']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'line1')}} autoComplete="hello" placeholder="Line1"/>
                                                
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Line 2 Address
                                            </div>
                                            <div>                                                    
                                                <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['line2']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'line2')}} autoComplete="hello" placeholder="Line2"/>
                                            </div>
                                        </div> */}
                                         <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display: 'none'}}>
                                            <div className="inputLabel">
                                                Address UniqueID
                                            </div>
                                            <div>
                                                <input type="text" id={'AddressUniqueId'+i}  value={this.state.PartyOverseasAddressUniqueId[i]} readOnly={true} placeholder="Guarantee Unique Id (auto populated)" />
                                            </div>
                                        </div>
                                        <div className="inputBox">    
                                            <div className="inputLabel">
                                                Street Type
                                            </div>
                                            <select className="form-control" id={"PartyStreetType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartyOverseasResidentialAddress[i]['streettype']} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'streettype')}}>
                                                {this.state.StreetTypeList}
                                            </select>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Street Name
                                            </div>
                                            <div>                                                    
                                                <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['streetname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'streetname')}} autoComplete="hello" placeholder="Street Name"/>
                                                
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Street Number
                                            </div>
                                            <div>                                                    
                                                <input type="text" id={'PartyStreetNumber'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['streetnumber']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'streetnumber')}} autoComplete="hello" placeholder="Street Number"/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Suburb
                                            </div>
                                            <div>                                                    
                                                <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['suburb']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'suburb')}} autoComplete="hello" placeholder="Suburb"/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Overseas State
                                            </div>
                                            <div>                                                    
                                                <input type="text" id={'PartyState'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['state']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'state')}} autoComplete="hello" placeholder="State"/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Overseas Postcode
                                            </div>
                                            <div>                                                    
                                                {/* <input type="text" id={'PartyPostcode'+i} className="form-control" maxLength="4" value={this.state.PartyOverseasResidentialAddress[i]['postcode']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.PartyOverseasResidentialAddressUpdated(e,i,'postcode')}}} autoComplete="hello" placeholder="Postcode"/> */}
                                                <input type="text" id={'PartyPostcode'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['postcode']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'postcode')}} autoComplete="hello" placeholder="Postcode"/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Overseas Country
                                            </div>
                                            <div>    
                                                {/* <input type="text" id={'PartyCountry'+i} className="form-control" value={this.state.PartyOverseasResidentialAddress[i]['country']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'country')}} autoComplete="hello" placeholder="Country"/>                                                 */}
                                                <select className="form-control" id={"Country"+i} value={this.state.PartyOverseasResidentialAddress[i]['country']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i,'country')}}>
                                                {/* {this.state.MappedCountries} */}
                                                {this.state.CountryCodeList}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id={"PartyName"+i}>
                                        
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Overseas Residential Address
                                            </div>
                                            <div>
                                                <input type="text" id={'PartyOverseasResidentialAddress'+i}  value={this.state.PartyOverseasResidentialAddress[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasResidentialAddressUpdated(e,i)}} autoComplete="hello" placeholder="Overseas Residential Address" />
                                            </div>
                                        </div>

                                        <div id={"PartyName"+i}>
                                        
                                            <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                                <div className="inputLabel">
                                                    Overseas Mailing Address
                                                </div>
                                                <div>
                                                    <input type="text" id={'PartyOverseasMailingAddress'+i}  value={this.state.PartyOverseasMailingAddress[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyOverseasMailingAddressUpdated(e,i)}} autoComplete="hello" placeholder="Overseas Mailing Address" />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div> */}

                                    {/* <div className="PartyAddress" id={"PartyAddress"+i}>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Street
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyStreet'+i} className="form-control" value={this.state.PartySegmentAddresses[i]['street']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'street')}} autoComplete="hello" placeholder="Street" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                City
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartySegmentAddresses[i]['city']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'city')}} autoComplete="hello" placeholder="City" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                State
                                            </div>
                                            <div>                                                    
                                                <select className="form-control" id={"StateName"+i} value={this.state.PartySegmentAddresses[i]['state']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'state')}}>
                                                {this.state.MappedStates}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Postcode
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'Postcode'+i} className="form-control" maxLength="4" value={this.state.PartySegmentAddresses[i]['postcode']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.PartyAddressUpdated(e,i,'postcode')}}} autoComplete="hello" placeholder="Postcode" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Phone Number
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyPhoneNumber'+i} className="form-control" value={this.state.PartySegmentPhoneNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentNumberUpdated(e,i)}} autoComplete="hello" placeholder="" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                    </div> */}
                                    
                                    
                                </>));
                               
                        }
                        this.setState({AddressSectionEntities:ents});
            });
        }

    }
    

    LendingGuaranteeLimitUpdated(e,i){
        e.preventDefault();
        let limit =this.state.GuaranteeLimit;
        limit[i]=e.target.value;
        this.setState({GuaranteeLimit:limit},()=>{this.UpdateLendingGuaranteeSegment()});
    }

    UpdateLendingGuaranteeSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.GuarantorSegmentEntities;
        
        if(ents.length>this.state.GuarantorSegmentCount){
            let limit =this.state.GuaranteeLimit;
            let uniqueId = this.state.GuaranteeUniqueId;
            for(let i = this.state.GuarantorSegmentEntities.length; i>this.state.GuarantorSegmentCount  && i>this.state.GuarantorSegmentMinCount; i--){
                ents.pop();
                limit.pop();   
                uniqueId.pop()             ;
            }
            this.setState({
                GuarantorSegmentEntities: ents,
                GuaranteeLimit:limit,
                GuaranteeUniqueId: uniqueId
            });
        }else{
            let limit =this.state.GuaranteeLimit;
            let uniqueId = this.state.GuaranteeUniqueId;
           
            // for(let f = 0; f<this.state.GuarantorSegmentCount; f++){
            //     if(limit.length<this.state.GuarantorSegmentCount){
            //         limit.push('');
            //         uniqueId.push('');
            //     }
            // }
            this.setState({
                // GuaranteeLimit:limit,
                // GuaranteeUniqueId: uniqueId
            },()=>{
                        for(let i =0; i < this.state.GuarantorSegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Lending Guarantee #{i+1}</h5>
                                    <div id={"PartyName"+i}>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Guarantee UniqueID
                                            </div>
                                            <div>
                                                <input type="text" id={'GuaranteeUniqueId'+i}  value={this.state.GuaranteeUniqueId[i]} readOnly={true} placeholder="Guarantee Unique Id (auto populated)" />
                                            </div>
                                        </div>

                                        
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Guarantee Limit
                                            </div>
                                            <div>
                                                <input type="number" id={'GuaranteeLimit'+i}  onWheel={(e)=> e.target.blur()} className="dollarTextBox" value={this.state.GuaranteeLimit[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.LendingGuaranteeLimitUpdated(e,i)}} autoComplete="hello" placeholder="Guarantee Limit" />
                                            </div>
                                        </div>

                                        
                                        
                                    </div>

                                    
                                    
                                    
                                </>));
                               
                        }
                        this.setState({GuarantorSegmentEntities:ents});
            });
        }

    }

    ProprietorSegmentNamesUpdated(e, i, key){
        e.preventDefault();
        let names =this.state.ProprietorSegmentNames;
        names[i][key]=e.target.value;

        if(names[i]===undefined){
            // let nameEntity = {'firstname':'','middlename':'','lastname':''};
            let nameEntity = {'firstname':'','lastname':''};
            names.push(nameEntity);
        }
        this.setState({ProprietorSegmentNames:names},()=>{this.UpdateProprietorSegment()});
    }

    RealEstateAssetProprietorSegmentFirstnamesUpdated(e, i){
        e.preventDefault();
        let names =this.state.RealEstateAssetProprietorSegmentFirstNames;
        names[i]=e.target.value;

        
        this.setState({RealEstateAssetProprietorSegmentFirstNames:names},()=>{this.UpdateRealEstateAssetSegment()});
    }

    RealEstateAssetProprietorSegmentLastnamesUpdated(e, i){
        e.preventDefault();
        let names =this.state.RealEstateAssetProprietorSegmentLastNames;
        names[i]=e.target.value;

        
        this.setState({RealEstateAssetProprietorSegmentLastNames:names},()=>{this.UpdateRealEstateAssetSegment()});
    }

    // ProprietorSegmentNamesUpdated(e, i){
    //     e.preventDefault();
    //     let names =this.state.ProprietorSegmentNames;
    //     names[i]=e.target.value;

    //     this.setState({ProprietorSegmentNames:names},()=>{this.UpdateProprietorSegment()});
    // }

    // ProprietorSegmentChangeNameFormRequiredUpdated(){
    //     e.preventDefault();
    //     let changeRequired =this.state.ProprietorSegmentChangeNameFormRequired;
    //     changeRequired[i]=e.target.value;
    //     this.setState({ProprietorSegmentChangeNameFormRequired:changeRequired},()=>{this.UpdateProprietorSegment()});
    // }

    // ProprietorSegmentReasonForDifferentNameUpdated(){
    //     e.preventDefault();
    //     let reason =this.state.ProprietorSegmentReasonForDifferentName;
    //     reason[i]=e.target.value;
    //     this.setState({ProprietorSegmentReasonForDifferentName:reason},()=>{this.UpdateProprietorSegment()});
    // }

    UpdateProprietorSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.ProprietorSegmentEntities;
        
        if(ents.length>this.state.ProprietorSegmentCount){
            let changeRequired = this.state.ProprietorSegmentChangeNameFormRequired;
            let changeNames = this.state.ProprietorSegmentNames;
            let changeReasons = this.state.ProprietorSegmentReasonForDifferentName;
            let uniqueIds =   this.state.ProprietorSegmentUniqueIds;
            for(let i = this.state.ProprietorSegmentEntities.length; i>this.state.ProprietorSegmentCount  && i>this.state.ProprietorSegmentMinCount; i--){
                ents.pop();
                changeRequired.pop();                
                changeNames.pop();                
                changeReasons.pop();      
                uniqueIds.pop();          
            }
            this.setState({
                ProprietorSegmentEntities: ents,
                ProprietorSegmentChangeNameFormRequired:changeRequired,
                ProprietorSegmentNames:changeNames,
                ProprietorSegmentReasonForDifferentName:changeReasons,
                ProprietorSegmentUniqueIds: uniqueIds
            });
        }else{
            let changeRequired = this.state.ProprietorSegmentChangeNameFormRequired;
            let changeNames = this.state.ProprietorSegmentNames;
            let changeReasons = this.state.ProprietorSegmentReasonForDifferentName;
            let uniqueIds =   this.state.ProprietorSegmentUniqueIds;
            let nameEntity = {'firstname':'','middlename':'','lastname':''};
           
            for(let f = 0; f<this.state.ProprietorSegmentCount; f++){
                if(changeNames.length<this.state.ProprietorSegmentCount){
                    changeRequired.push('No');
                    changeNames.push(nameEntity);
                    // changeNames.push('');
                    //changeReasons.push('Marriage');
                    changeReasons.push('');
                    uniqueIds.push('');
                }
            }
            this.setState({
                ProprietorSegmentChangeNameFormRequired:changeRequired,
                ProprietorSegmentNames:changeNames,
                ProprietorSegmentReasonForDifferentName:changeReasons,
                ProprietorSegmentUniqueIds: uniqueIds
            },()=>{
                        for(let i =0; i < this.state.ProprietorSegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Proprietor #{i+1}</h5>
                                    <div id={"PartyName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Change of Name Required?
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="No" value={this.state.ProprietorSegmentChangeNameFormRequired[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentChangeNameFormRequired; vals[i]=e.target.value;this.setState({ProprietorSegmentChangeNameFormRequired:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                {this.state.ChangeNameYNList}
                                                </select>
                                            </div>                                           
                                        </div>
                                        <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+i}>
                                            <div className="inputLabel">
                                                Reason for new name
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="" value={this.state.ProprietorSegmentReasonForDifferentName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentReasonForDifferentName; vals[i]=e.target.value;this.setState({ProprietorSegmentReasonForDifferentName:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                    {this.state.ReasonForDifferentNameList}
                                                </select>
                                            </div>
                                        </div>

                                        {/* <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Name on Title
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} value={this.state.ProprietorSegmentNames[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i)}} autoComplete={false} placeholder="Name" />
                                            </div>
                                        </div> */}
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['firstname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['firstname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'firstname')}} autoComplete={false} placeholder="First Name" />
                                            </div>
                                        </div>                                        

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Last Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['lastname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['lastname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'lastname')}} autoComplete={false} placeholder="Last Name" />
                                            </div>
                                        </div>

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Unique ID
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} value={this.state.ProprietorSegmentUniqueIds[i]} readOnly={true}  />                                            
                                            </div>
                                        </div>
                                        
                                        {/* <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['firstname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['firstname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'firstname')}} autoComplete={false} placeholder="First Name" />
                                            </div>
                                        </div>

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Middle Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['middlename']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['middlename']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'middlename')}} autoComplete={false} placeholder="Middle Name" />
                                            </div>
                                        </div>

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Last Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['lastname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['lastname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'lastname')}} autoComplete={false} placeholder="Last Name" />
                                            </div>
                                        </div> */}
                                        
                                        
                                    </div>

                                    
                                    
                                    
                                </>));
                               
                        }
                        this.setState({ProprietorSegmentEntities:ents});
            });
        }

    }
    GenerateProprietor(proprietors){
        return(<>
        sdfsfsdfsdf</>)
    }

    GenerateTitles(x, y)
    {
        //alert(y)
        console.log("xxxx")
        console.log(x)
    }

    UpdateRealEstateAssetSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.RealEstateAssetSegmentEntities;
        
        if(ents.length>this.state.RealEstateAssetSegmentCount){
            let changeRequired = this.state.ProprietorSegmentChangeNameFormRequired;
            let changeNames = this.state.ProprietorSegmentNames;
            let changeReasons = this.state.ProprietorSegmentReasonForDifferentName;
            let uniqueIds =   this.state.ProprietorSegmentUniqueIds;
            for(let i = this.state.RealEstateAssetSegmentEntities.length; i>this.state.RealEstateAssetSegmentCount  && i>this.state.RealEstateAssetSegmentMinCount; i--){
                ents.pop();
                changeRequired.pop();                
                changeNames.pop();                
                changeReasons.pop();      
                uniqueIds.pop();          
            }
            this.setState({
                RealEstateAssetSegmentEntities: ents,
                ProprietorSegmentChangeNameFormRequired:changeRequired,
                ProprietorSegmentNames:changeNames,
                ProprietorSegmentReasonForDifferentName:changeReasons,
                ProprietorSegmentUniqueIds: uniqueIds
            });
        }else{
            let changeRequired = this.state.ProprietorSegmentChangeNameFormRequired;
            let changeNames = this.state.ProprietorSegmentNames;
            let changeReasons = this.state.ProprietorSegmentReasonForDifferentName;
            let uniqueIds =   this.state.ProprietorSegmentUniqueIds;
            let nameEntity = {'firstname':'','middlename':'','lastname':''};
           
            for(let f = 0; f<this.state.RealEstateAssetSegmentCount; f++){
                if(changeNames.length<this.state.RealEstateAssetSegmentCount){
                    changeRequired.push('No');
                    changeNames.push(nameEntity);
                    // changeNames.push('');
                    //changeReasons.push('Marriage');
                    changeReasons.push('');
                    uniqueIds.push('');
                }
            }

            // var realEstateTitles = [];
            
            // this.state.RealEstateAsset.forEach((i, e)=>{
            //     realEstateTitles.push(this.state.RealEstateAsset[e].titleField)
               
                
            // })

            // this.setState({
            //     //RealEstateAssetTitleSegmentTitles: realEstateTitles
            // },()=>{
            //     console.log("RealEstateAssetTitles");
            //     console.log(this.state.RealEstateAssetTitles);

            // });

            let titleSeg = "";
            let registeredPropSeg = "";
            registeredPropSeg = this.state.RealEstateAssetProprietorSegmentParties.map((e,i) => {
                
                return(
                    <>
                    <p>Registered Proprietor #{i+1} </p>
                     <div id={"PartyName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Change of Name Required?
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="No" value={this.state.ProprietorSegmentChangeNameFormRequired[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentChangeNameFormRequired; vals[i]=e.target.value;this.setState({ProprietorSegmentChangeNameFormRequired:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                {this.state.ChangeNameYNList}
                                                </select>
                                            </div>                                           
                                        </div>
                                        <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+i}>
                                            <div className="inputLabel">
                                                Reason for new name
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="" value={this.state.ProprietorSegmentReasonForDifferentName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentReasonForDifferentName; vals[i]=e.target.value;this.setState({ProprietorSegmentReasonForDifferentName:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                    {this.state.ReasonForDifferentNameList}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RealEstateAssetProprietorSegmentFirstNamesUpdated(e,i)}} autoComplete={false} placeholder="First Name" />
                                            </div>
                                        </div>                                        

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Last Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RealEstateAssetProprietorSegmentLastNamesUpdated(e,i)}} autoComplete={false} placeholder="Last Name" />
                                            </div>
                                        </div>

                                        {/* <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['firstname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['firstname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'firstname')}} autoComplete={false} placeholder="First Name" />
                                            </div>
                                        </div>                                        

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Last Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['lastname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['lastname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'lastname')}} autoComplete={false} placeholder="Last Name" />
                                            </div>
                                        </div> */}


                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Unique ID
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} value={this.state.ProprietorSegmentUniqueIds[i]} readOnly={true}  />                                            
                                            </div>
                                        </div>
                                        </div>
                    </>
                );
            })
            


            // for(let i = 0; i<2; i++){
                
            //     registeredPropSeg=(
            //         <>
            //          <div id={"PartyName"+i}>
            //                             <div className="inputBox">
            //                                 <div className="inputLabel" id={"PartyTypeLabel"+1}>
            //                                     Change of Name Required?
            //                                 </div>
            //                                 <div className="inputBox">
            //                                     <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="No" value={this.state.ProprietorSegmentChangeNameFormRequired[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentChangeNameFormRequired; vals[i]=e.target.value;this.setState({ProprietorSegmentChangeNameFormRequired:vals},()=>{this.UpdateProprietorSegment()})}}>
            //                                     {this.state.ChangeNameYNList}
            //                                     </select>
            //                                 </div>                                           
            //                             </div>
            //                             <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+i}>
            //                                 <div className="inputLabel">
            //                                     Reason for new name
            //                                 </div>
            //                                 <div className="inputBox">
            //                                     <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="" value={this.state.ProprietorSegmentReasonForDifferentName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentReasonForDifferentName; vals[i]=e.target.value;this.setState({ProprietorSegmentReasonForDifferentName:vals},()=>{this.UpdateProprietorSegment()})}}>
            //                                         {this.state.ReasonForDifferentNameList}
            //                                     </select>
            //                                 </div>
            //                             </div>
            //         <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
            //                                 <div className="inputLabel">
            //                                     First Name
            //                                 </div>
            //                                 <div className="inputBox">
            //                                     {/* <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['firstname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['firstname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'firstname')}} autoComplete={false} placeholder="First Name" /> */}
            //                                 </div>
            //                             </div>                                        

            //                             <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
            //                                 <div className="inputLabel">
            //                                     Last Name
            //                                 </div>
            //                                 <div className="inputBox">
            //                                     {/* <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['lastname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['lastname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'lastname')}} autoComplete={false} placeholder="Last Name" /> */}
            //                                 </div>
            //                             </div>

            //                             <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
            //                                 <div className="inputLabel">
            //                                     Unique ID
            //                                 </div>
            //                                 <div className="inputBox">
            //                                     {/* <input type="text" id={'PartyNamePersonPreviousName'+i} value={this.state.ProprietorSegmentUniqueIds[i]} readOnly={true}  />                                             */}
            //                                 </div>
            //                             </div>
            //                             </div>
            //         </>
            //     );
            // }


           
            
                var proprietors = [];
                var realEstateTitles = [];
            this.state.RealEstateAsset.forEach((i, e)=>{
                
                realEstateTitles.push(this.state.RealEstateAsset[e].titleField)
                this.setState({
                   
                })
                titleSeg = realEstateTitles.map((x,y) => {   
                    
                    console.log("realEstateTitles[y] " + [y])
                    console.log(realEstateTitles[y])
                    console.log(realEstateTitles[y].registeredProprietorField)
                    // if(realEstateTitles[y].registeredProprietorField != null){
                    //     alert("dfgdg")
                        proprietors.push(realEstateTitles.registeredProprietorField)         
                    //     console.log("proprietors")
                    // }                
                    return (
                        <>
                            <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                <div className="inputLabel">
                                    Title UniqueID
                                </div>
                                <div>
                                    <input type="text" id={'TitleUniqueId'+i}  value={this.state.TitleSegmentTitleUniqueIds[i]} readOnly={true} placeholder="Title Unique Id (auto populated)" />
                                </div>
                            </div>
                            
                            <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                <div className="inputLabel">
                                    Title Reference
                                </div>
                                <div>
                                    <input type="text" id={'TitleSegmentTitleReferences'+i}  value={this.state.TitleSegmentTitleReferences[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TitleReferenceUpdated(e,i)}} autoComplete="hello" placeholder="Title Reference" />
                                </div>
                            </div>                        
                            
                            <div>
                                {/* {registeredPropSeg}               */}
                                {this.GenerateProprietor(proprietors)}
                                {/* {
                                proprietors.map((a,b) => { 
                                   
                                    return(
                                        <>
                                        <p>Registered Proprietor #{b+1} </p>
                         <div id={"PartyName"+b}>
                                            <div className="inputBox">
                                                <div className="inputLabel" id={"PartyTypeLabel"+b}>
                                                    Change of Name Required?
                                                </div>
                                                <div className="inputBox">
                                                    <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+b} defaultValue="No" value={this.state.ProprietorSegmentChangeNameFormRequired[b]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentChangeNameFormRequired; vals[b]=e.target.value;this.setState({ProprietorSegmentChangeNameFormRequired:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                    {this.state.ChangeNameYNList}
                                                    </select>
                                                </div>                                           
                                            </div>
                                            <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+b}>
                                                <div className="inputLabel">
                                                    Reason for new name
                                                </div>
                                                <div className="inputBox">
                                                    <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+b} defaultValue="" value={this.state.ProprietorSegmentReasonForDifferentName[b]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentReasonForDifferentName; vals[b]=e.target.value;this.setState({ProprietorSegmentReasonForDifferentName:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                        {this.state.ReasonForDifferentNameList}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="inputBox LPNameBox" id={"PartyNamePerson"+b}>
                                                <div className="inputLabel">
                                                    First Name
                                                </div>
                                                <div className="inputBox">
                                                    <input type="text" id={'PartyNamePersonPreviousName'+b} className={this.state.ProprietorSegmentNames[b]?"successTextBox": ''} value={this.state.ProprietorSegmentNames[b]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RealEstateAssetProprietorSegmentFirstNamesUpdated(e,i)}} autoComplete={false} placeholder="First Name" />
                                                </div>
                                            </div>                                        
    
                                            <div className="inputBox LPNameBox" id={"PartyNamePerson"+b}>
                                                <div className="inputLabel">
                                                    Last Name
                                                </div>
                                                <div className="inputBox">
                                                    <input type="text" id={'PartyNamePersonPreviousName'+b} className={this.state.ProprietorSegmentNames[b]?"successTextBox": ''} value={this.state.ProprietorSegmentNames[b]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RealEstateAssetProprietorSegmentLastNamesUpdated(e,i)}} autoComplete={false} placeholder="Last Name" />
                                                </div>
                                            </div>
    
                                           
    
    
                                            <div className="inputBox LPNameBox" id={"PartyNamePerson"+b}>
                                                <div className="inputLabel">
                                                    Unique ID
                                                </div>
                                                <div className="inputBox">
                                                    <input type="text" id={'PartyNamePersonPreviousName'+b} value={this.state.ProprietorSegmentUniqueIds[b]} readOnly={true}  />                                            
                                                </div>
                                            </div>
                                            </div>
                        </>)
                                })} */}
                            </div>
                        </>
                    );
                });
                
            })


            //titleSeg = this.state.RealEstateAssetTitleSegmentTitles.map(i => {
                
            
            
            // for(let i = 0; i<5; i++){
            //     titleSeg = (<>
            //     <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
            //         <div className="inputLabel">
            //             Title UniqueID
            //         </div>
            //         <div>
            //             <input type="text" id={'TitleUniqueId'+i}  value={this.state.TitleSegmentTitleUniqueIds[i]} readOnly={true} placeholder="Title Unique Id (auto populated)" />
            //         </div>
            //     </div>
                
            //     <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
            //         <div className="inputLabel">
            //             Title Reference
            //         </div>
            //         <div>
            //             <input type="text" id={'TitleSegmentTitleReferences'+i}  value={this.state.TitleSegmentTitleReferences[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TitleReferenceUpdated(e,i)}} autoComplete="hello" placeholder="Title Reference" />
            //         </div>
            //     </div>
                
            //     <div>
            //         Registered Proprietor                   
            //     </div>
            //     <div>
            //         {registeredPropSeg}              
            //     </div>
            //     </>);
            // }
                
            
            
            this.setState({
                ProprietorSegmentChangeNameFormRequired:changeRequired,
                ProprietorSegmentNames:changeNames,
                ProprietorSegmentReasonForDifferentName:changeReasons,
                ProprietorSegmentUniqueIds: uniqueIds,
                RealEstateAssetTitleSegmentTitles: realEstateTitles
            },()=>{
                alert(this.state.RealEstateAssetTitleSegmentTitles.length)
                        for(let i =0; i < this.state.RealEstateAssetSegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Real Estate Asset #{i+1}</h5>
                                    {this.state.RealEstateAssetTitleSegmentTitles.map((x,y)=>{
                                         //this.GenerateTitles(x,y);
                                        var titles = x[0].uniqueIDField;
                                        alert(titles)
                                        this.setState({
                                            TitleSegmentTitleUniqueIds: titles
                                        },()=>{
                                            return(<>
                                                <div>{y}</div>
                                                <div>{x.countyField}</div>
                                                
                                                <div className="inputBox LPNameBox" id={"PartyNamePerson"+y}>
                                                <div className="inputLabel">
                                                    Title UniqueID
                                                </div>
                                                <div>
                                                    <input type="text" id={'TitleUniqueId'+y}  value={this.state.TitleSegmentTitleUniqueIds[y]} readOnly={true} placeholder="Title Unique Id (auto populated)" />
                                                </div>
                                            </div>
                                            
                                            <div className="inputBox LPNameBox" id={"PartyNamePerson"+y}>
                                                <div className="inputLabel">
                                                    Title Reference
                                                </div>
                                                <div>
                                                    <input type="text" id={'TitleSegmentTitleReferences'+y}  value={this.state.TitleSegmentTitleReferences[y]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TitleReferenceUpdated(e,y)}} autoComplete="hello" placeholder="Title Reference" />
                                                </div>
                                            </div>
        
                                                </>)
                                        })
                                       
                                    })}
                                    {/* {titleSeg}                                         */}

                                    {/* <div id={"PartyName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Change of Name Required?
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="No" value={this.state.ProprietorSegmentChangeNameFormRequired[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentChangeNameFormRequired; vals[i]=e.target.value;this.setState({ProprietorSegmentChangeNameFormRequired:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                {this.state.ChangeNameYNList}
                                                </select>
                                            </div>                                           
                                        </div>
                                        <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+i}>
                                            <div className="inputLabel">
                                                Reason for new name
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="" value={this.state.ProprietorSegmentReasonForDifferentName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentReasonForDifferentName; vals[i]=e.target.value;this.setState({ProprietorSegmentReasonForDifferentName:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                    {this.state.ReasonForDifferentNameList}
                                                </select>
                                            </div>
                                        </div> */}

                                        {/* <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Name on Title
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} value={this.state.ProprietorSegmentNames[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i)}} autoComplete={false} placeholder="Name" />
                                            </div>
                                        </div> */}
                                        {/* <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['firstname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['firstname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'firstname')}} autoComplete={false} placeholder="First Name" />
                                            </div>
                                        </div>                                        

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Last Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['lastname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['lastname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'lastname')}} autoComplete={false} placeholder="Last Name" />
                                            </div>
                                        </div>

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Unique ID
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} value={this.state.ProprietorSegmentUniqueIds[i]} readOnly={true}  />                                            
                                            </div>
                                        </div> */}
                                        
                                        {/* <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['firstname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['firstname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'firstname')}} autoComplete={false} placeholder="First Name" />
                                            </div>
                                        </div>

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Middle Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['middlename']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['middlename']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'middlename')}} autoComplete={false} placeholder="Middle Name" />
                                            </div>
                                        </div>

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Last Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.ProprietorSegmentNames[i]['lastname']?"successTextBox": ''} value={this.state.ProprietorSegmentNames[i]['lastname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProprietorSegmentNamesUpdated(e,i,'lastname')}} autoComplete={false} placeholder="Last Name" />
                                            </div>
                                        </div> */}
                                        
                                        
                                    {/* </div> */}

                                    
                                    
                                    
                                </>));
                               
                        }
                        this.setState({RealEstateAssetSegmentEntities:ents});
            });
        }

    }
    

    NonRealEstateSegmentAccountNumbersUpdated(e,i)
    {
        e.preventDefault();
        let acctNumbers =this.state.NonRealEstateSegmentAccountNumbers;
        acctNumbers[i]=e.target.value;
        this.setState({NonRealEstateSegmentAccountNumbers:acctNumbers},()=>{this.UpdateNonRealEstateSegment()});
    }

    NonRealEstateSegmentAccountAmountsUpdated(e,i)
    {
        e.preventDefault();
        let acctAmount =this.state.NonRealEstateSegmentAccountAmounts;
        acctAmount[i]=e.target.value;
        this.setState({NonRealEstateSegmentAccountAmounts:acctAmount},()=>{this.UpdateNonRealEstateSegment()});
    }

    UpdateNonRealEstateSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.NonRealEstateSegmentEntities;
        
        if(ents.length>this.state.NonRealEstateSegmentCount){
            let accounts = this.state.NonRealEstateSegmentAccountNumbers;
            let amounts = this.state.NonRealEstateSegmentAccountAmounts;
            let owners = this.state.NonRealEstateSegmentAccountOwners;
            for(let i = this.state.NonRealEstateSegmentEntities.length; i>this.state.NonRealEstateSegmentCount  && i>this.state.NonRealEstateSegmentMinCount; i--){
                ents.pop();
                accounts.pop();                
                amounts.pop();                
                owners.pop();                
            }
            this.setState({
                NonRealEstateSegmentEntities: ents,
                NonRealEstateSegmentAccountNumbers:accounts,
                NonRealEstateSegmentAccountAmounts:amounts,
                NonRealEstateSegmentAccountOwners:owners,
            });
        }else{
            let accounts = this.state.NonRealEstateSegmentAccountNumbers;
            let amounts = this.state.NonRealEstateSegmentAccountAmounts;
            let owners = this.state.NonRealEstateSegmentAccountOwners;
           
            for(let f = 0; f<this.state.NonRealEstateSegmentCount; f++){
                if(accounts.length<this.state.NonRealEstateSegmentCount){
                    accounts.push('');
                    amounts.push('');
                    owners.push('');
                }
            }
            this.setState({
                NonRealEstateSegmentAccountNumbers:accounts,
                NonRealEstateSegmentAccountAmounts:amounts,
                NonRealEstateSegmentAccountOwners:owners,
            },()=>{
                        for(let i =0; i < this.state.NonRealEstateSegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Term Deposit #{i+1}</h5>
                                    <div id={"PartydfgfdgName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartfdsgsdgyTypeLabel"+1}>
                                                Account Number
                                            </div>
                                            <div className="inputBox">
                                            <input type="text" id={'PartyNamePersonPrgdfgeviousName'+i} className={this.state.NonRealEstateSegmentAccountNumbers[i]?"successTextBox": ''} value={this.state.NonRealEstateSegmentAccountNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.NonRealEstateSegmentAccountNumbersUpdated(e,i)}} autoComplete={false} placeholder="Account Number" />
                                            </div>                                           
                                        </div>

                                        <div className="inputBox LPNameBox" id={"fgdgdfs"+i}>
                                            <div className="inputLabel">
                                                Amount
                                            </div>
                                            <div className="inputBox">
                                            <input type="number" min="1" step="any" id={'PartyNamePdggdfgersonPreviousName'+i} className="dollarTextBox" value={this.state.NonRealEstateSegmentAccountAmounts[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{this.NonRealEstateSegmentAccountAmountsUpdated(e,i)}} autoComplete={false} placeholder="Amount" />
                                            </div>
                                        </div>
                                        {/* <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+i}>
                                            <div className="inputLabel">
                                                Reason for new name
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="Marriage" value={this.state.ProprietorSegmentReasonForDifferentName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ProprietorSegmentReasonForDifferentName; vals[i]=e.target.value;this.setState({ProprietorSegmentReasonForDifferentName:vals},()=>{this.UpdateProprietorSegment()})}}>
                                                    {this.state.ReasonForDifferentNameList}
                                                </select>
                                            </div>
                                        </div> */}
                                        
                                        
                                        
                                        
                                    </div>

                                    
                                    
                                    
                                </>));
                               
                        }
                        this.setState({NonRealEstateSegmentEntities:ents});
            });
        }

    }


    ProtectedTenantFirstNameUpdated(e,i)
    {
        e.preventDefault();
        let firstname =this.state.ProtectedTenantFirstName;
        firstname[i]=e.target.value;
        // if((firstname[i] == "" || firstname[i] == undefined) && this.state.IsThereProtectedTenant[i]==='Yes')
        // {
        //     alert("First Name is required")
        // }
        this.setState({ProtectedTenantFirstName:firstname},()=>{this.UpdateRealEstateSegment()});
    }

    ProtectedTenantMiddleNameUpdated(e,i)
    {
        e.preventDefault();
        let middlename =this.state.ProtectedTenantMiddleName;
        middlename[i]=e.target.value;
        this.setState({ProtectedTenantMiddleName:middlename},()=>{this.UpdateRealEstateSegment()});
    }

    ProtectedTenantLastNameUpdated(e,i)
    {
        e.preventDefault();
        let lastname =this.state.ProtectedTenantLastName;
        lastname[i]=e.target.value;
        // if((lastname[i] == "" || lastname[i] == undefined) && this.state.IsThereProtectedTenant[i]==='Yes')
        // {
        //     alert("First Name is required")
        // }
        this.setState({ProtectedTenantLastName:lastname},()=>{this.UpdateRealEstateSegment()});
    }

    ProtectedTenantXTenantUpdated(e,i)
    {
        e.preventDefault();
        let tenantId =this.state.ProtectedTenantXTenant;
        tenantId[i]=e.target.value;
        // if((tenantId[i] == "" || tenantId[i] == undefined) && this.state.IsThereProtectedTenant[i]==='Yes')
        // {
        //     alert("First Name is required")
        // }
        this.setState({ProtectedTenantXTenant:tenantId},()=>{this.UpdateRealEstateSegment()});
    }

    UpdateRealEstateSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.RealEstateSegmentEntities;
        
        if(ents.length>this.state.RealEstateSegmentCount){
            let protectiveTenant = this.state.IsThereProtectedTenant;
            let relatonshipToBorrower = this.state.RelationshipOfTenantToBorrower;
            let firstname = this.state.ProtectedTenantFirstName;
            let middlename = this.state.ProtectedTenantMiddleName;
            let lastname = this.state.ProtectedTenantLastName;
            let uniqueId = this.state.ProtectedTenantXTenant;

            for(let i = this.state.RealEstateSegmentEntities.length; i>this.state.RealEstateSegmentCount  && i>this.state.RealEstateSegmentMinCount; i--){
                ents.pop();
                protectiveTenant.pop();                
                relatonshipToBorrower.pop();                
                firstname.pop();      
                middlename.pop();                
                lastname.pop();            
                uniqueId.pop();    
            }
            this.setState({
                RealEstateSegmentEntities: ents,
                IsThereProtectedTenant: protectiveTenant,
                RelationshipOfTenantToBorrower: relatonshipToBorrower,
                ProtectedTenantFirstName: firstname,
                ProtectedTenantMiddleName: middlename,
                ProtectedTenantLastName: lastname,
                ProtectedTenantXTenant: uniqueId
            });
        }else{
            let protectiveTenant = this.state.IsThereProtectedTenant;
            let relatonshipToBorrower = this.state.RelationshipOfTenantToBorrower;
            let firstname = this.state.ProtectedTenantFirstName;
            let middlename = this.state.ProtectedTenantMiddleName;
            let lastname = this.state.ProtectedTenantLastName;
            let uniqueId = this.state.ProtectedTenantXTenant;
           
            // for(let f = 0; f<this.state.RealEstateSegmentCount; f++){
            //     if(protectiveTenant.length<this.state.RealEstateSegmentCount){
            //         protectiveTenant.push('Yes');                
            //         relatonshipToBorrower.push('');                
            //         firstname.push('');      
            //         middlename.push('');                
            //         lastname.push(''); 
            //         uniqueId.push('');
            //     }
            // }
            this.setState({
                // IsThereProtectedTenant: protectiveTenant,
                // RelationshipOfTenantToBorrower: relatonshipToBorrower,
                // ProtectedTenantFirstName: firstname,
                // ProtectedTenantMiddleName: middlename,
                // ProtectedTenantLastName: lastname,
                // ProtectedTenantXTenant: uniqueId
            },()=>{
                        for(let i =0; i < this.state.RealEstateSegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Reverse Mortgage #{i+1}</h5>
                                    <div id={"PartydfgfdgName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartfdsgsdgyTypeLabel"+1}>
                                                Is there a Protective Tenant?
                                            </div>
                                            <div className="inputBox">
                                                <div className="multiColsInputBoxContentWidth" id={"ProtectiveTenant"+i} value={this.state.IsThereProtectedTenant[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.IsThereProtectedTenant; vals[i]=e.target.value;this.setState({IsThereProtectedTenant:vals},(e)=>{this.UpdateRealEstateSegment(e,i)})}}>    
                                                    <RadioButton value="Yes" name={"ProtectiveTenant"+i} title="Yes" checked={this.state.IsThereProtectedTenant[i]==='Yes'} />                              
                                                    <RadioButton value="No" name={"ProtectiveTenant"+i} title="No" checked={this.state.IsThereProtectedTenant[i]==='No'} />                                           
                                                </div>
                                            </div>                                           
                                        </div>
                                        {
                                            this.state.IsThereProtectedTenant[i] === 'Yes'?
                                            <>
                                                <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+i}>
                                                    <div className="inputLabel">
                                                        Relationship of the Tenant to the borrower
                                                    </div>
                                                    <div className="inputBox">
                                                        <select className="form-control" id={"RelationshipOfTenantToBorrowerLabel"+i} value={this.state.RelationshipOfTenantToBorrower[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.RelationshipOfTenantToBorrower; vals[i]=e.target.value;this.setState({RelationshipOfTenantToBorrower:vals},()=>{this.UpdateRealEstateSegment()})}}>
                                                            {this.state.KinRelationshipList}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                                    <div className="inputLabel">
                                                        First Name
                                                    </div>
                                                    <div className="inputBox">
                                                        <input type="text" id={'PartyNamePersonGivenName'+i} className={this.state.ProtectedTenantFirstName[i]?"successTextBox": ''} value={this.state.ProtectedTenantFirstName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProtectedTenantFirstNameUpdated(e,i)}} autoComplete={false} placeholder="First Name" required/>
                                                    </div>
                                                </div>
                                                <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                                    <div className="inputLabel">
                                                        Middle Name
                                                    </div>
                                                    <div className="inputBox">
                                                        <input type="text" id={'PartyNamePersonMiddleName'+i} className={this.state.ProtectedTenantMiddleName[i]?"successTextBox": ''} value={this.state.ProtectedTenantMiddleName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProtectedTenantMiddleNameUpdated(e,i)}} autoComplete={false} placeholder="Middle Name" />
                                                    </div>
                                                </div>
                                                <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                                    <div className="inputLabel">
                                                        Surname
                                                    </div>
                                                    <div className="multiColsIinputBoxnputBox">
                                                        <input type="text" id={'PartyNamePersonSurname'+i} className={this.state.ProtectedTenantLastName[i]?"successTextBox": ''} value={this.state.ProtectedTenantLastName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProtectedTenantLastNameUpdated(e,i)}} autoComplete={false} placeholder="Last Name" required />
                                                    </div>
                                                </div>
                                                <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                                    <div className="inputLabel">
                                                        Tenant UniqueId
                                                    </div>
                                                    <div className="multiColsIinputBoxnputBox">
                                                        <input type="text" id={'PartyNamePersonTenant'+i} className={this.state.ProtectedTenantXTenant[i]?"successTextBox": ''} value={this.state.ProtectedTenantXTenant[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.ProtectedTenantXTenantUpdated(e,i)}} autoComplete={false} placeholder="Unique ID" required />
                                                    </div>
                                                </div>
                                            </>

                                            : ''
                                        }                                       
                                       
                                        
                                        
                                        
                                        
                                    </div>

                                    
                                    
                                    
                                </>));
                               
                        }
                        this.setState({RealEstateSegmentEntities:ents});
            });
        }

    }

    TitleReferenceUpdated(e,i){
        e.preventDefault();
        let titleRef =this.state.TitleSegmentTitleReferences;
        titleRef[i]=e.target.value;
        this.setState({TitleSegmentTitleReferences:titleRef},()=>{this.UpdateTitleSegment()});
    }

    UpdateTitleSegment(){
        this.checkIfSessionHasExpired();
        let ents = this.state.TitleSegmentEntities;
        
        if(ents.length>this.state.TitleSegmentCount){
            let titleRefs = this.state.TitleSegmentTitleReferences;
            let uniqueIds = this.state.TitleSegmentTitleUniqueIds;

            for(let i = this.state.TitleSegmentEntities.length; i>this.state.TitleSegmentCount  && i>this.state.TitleSegmentMinCount; i--){
                ents.pop();
                titleRefs.pop();                
                uniqueIds.pop();       
            }
            this.setState({
                TitleSegmentEntities: ents,
                TitleSegmentTitleReferences: titleRefs,
                TitleSegmentTitleUniqueIds: uniqueIds
            });
        }else{
            let titleRefs = this.state.TitleSegmentTitleReferences;
            let uniqueIds = this.state.TitleSegmentTitleUniqueIds;
           
            for(let f = 0; f<this.state.TitleSegmentCount; f++){
                // if(titleRefs.length<this.state.TitleSegmentCount){
                //     titleRefs.push('');                
                //     uniqueIds.push('');
                // }
            }
            this.setState({
                // TitleSegmentTitleReferences: titleRefs,
                // TitleSegmentTitleUniqueIds: uniqueIds
            },()=>{
                        for(let i =0; i < this.state.TitleSegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Title #{i+1}</h5>
                                    <div id={"PartydfgfdgName"+i}>

                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Title UniqueID
                                            </div>
                                            <div>
                                                <input type="text" id={'TitleUniqueId'+i}  value={this.state.TitleSegmentTitleUniqueIds[i]} readOnly={true} placeholder="Title Unique Id (auto populated)" />
                                            </div>
                                        </div>
                                        
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i}>
                                            <div className="inputLabel">
                                                Title Reference
                                            </div>
                                            <div>
                                                <input type="text" id={'TitleSegmentTitleReferences'+i}  value={this.state.TitleSegmentTitleReferences[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TitleReferenceUpdated(e,i)}} autoComplete="hello" placeholder="Title Reference" />
                                            </div>
                                        </div>
                                    </div>

                                    
                                    
                                    
                                </>));
                               
                        }
                        this.setState({TitleSegmentEntities:ents});
            });
        }

    }
    
    IsValidPostcode(value){
        let re = /[^0-9]/;
        return !re.test(value);
    }

    SecurityAddressUpdated(e,i,key){
        e.preventDefault();
        let address = this.state.SecuritySegmentAddreses;
        address[i][key]=e.target.value;
        let titleRefFormat = this.state.TitleRefFormat;
        //alert(e.target.value)
        // alert(e)
        // alert(key)
        // alert(address[i])
        //if(address[i][key]=="NSW" || address[i][key]=="QLD"){
        if(key=="state" && address[i][key]!=""){
            //alert('dsfdsfdsf')
            this.ResetSecurityOtherDetails(i);
            if(e.target.value == "NSW")
            {
                titleRefFormat[i] = "1/SP12345, 7/12/6789, 7456-56"
                // this.setState({
                //     TitleRefFormat: "1/SP12345, 7/12/6789, 7456-56"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "NT")
            {
                titleRefFormat[i] = "12345/12345, CUFT12345/12345"
                // this.setState({
                //     //TitleRefFormat: "CUFT145678"
                //     TitleRefFormat: "12345/12345, CUFT12345/12345"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "QLD")
            {
                titleRefFormat[i] = "14483140"
                // this.setState({
                //     TitleRefFormat: "14483140"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "SA")
            {
                titleRefFormat[i] = "CT 6321/321, CL 5432/43"
                // this.setState({
                //     //TitleRefFormat: "1234/234"
                //     TitleRefFormat: "CT 6321/321, CL 5432/43"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "TAS")
            {
                titleRefFormat[i] = "123456/1"
                // this.setState({
                //     //TitleRefFormat: "155100/1, 155100:S1/1"
                //     TitleRefFormat: "123456/1"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "VIC")
            {
                titleRefFormat[i] = "6237/745, 18378/002, 459/789K"
                // this.setState({
                //     //TitleRefFormat: "12345/789A"
                //     TitleRefFormat: "6237/745, 18378/002, 459/789K"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "WA")
            {
                titleRefFormat[i] = "AB12345-11111A, 2767-913"
                // this.setState({
                //     //TitleRefFormat: "AB12345/11111A"
                //     TitleRefFormat: "AB12345-11111A, 2767-913"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
            else if(e.target.value == "ACT")
            {
                titleRefFormat[i] = "1234:123"
                // this.setState({
                //     TitleRefFormat: "1234:123"
                // })
                //this.setState({TitleRefFormat:titleRefFormat},()=>{this.UpdateSecuritySegment()});
            }
        }
        this.setState({SecuritySegmentAddreses:address
            , TitleRefFormat:titleRefFormat
        },()=>{this.UpdateSecuritySegment()});

         
    }

    


    CheckMatter(event){
        event.preventDefault();
        this.setState({
            isChecking:true,
        });
        setTimeout(()=>{
            this.setState({isChecking:false});
        },300);
        InstructionService.CheckIsExistingMatter(this.state.lenderReference).then(response=>{
            this.setState({
                isExistingLenderReference:response.data.Exists,
                matterNumber:response.data.MatterId,
                matterDescription:response.data.MatterDescription,
            });
        },error=>{
            this.setState({
                isExistingLenderReference:false,
                errorMessage:error.message,
            })
        })
        this.setState({
            hasCheckedReferenceNumber:true,
            isChecking:false,
        },()=>{
            this.setState({
                copy:this.state.isExistingLenderReference? "":"",
            })
        });
        //check matter exists
        
    }

    UpdateLenderReferenceNumber(event){
        event.preventDefault();
        this.setState({
            lenderReference:event.target.value
        },_=>{
            if(this.state.lenderReference===""){
                this.setState({
                    hasCheckedReferenceNumber:false
                })
            }
        })
    }

    DownloadInstructionHtml(event){
        event.preventDefault();
        InstructionService.GetInstructionHtml(this.state.msg).then(
            resp => {
              const file = new Blob([resp.data], {type: resp.request.response.type});
                saveAs(file,this.state.msg+"_Instruction"+".html");  
            },err=>{

            })
    }

    DownloadInstructionXml(event){
        event.preventDefault();
        InstructionService.GetInstructionXml(this.state.msg).then(
            resp => {
              const file = new Blob([resp.data], {type: resp.request.response.type});
                saveAs(file,this.state.msg+"_Instruction"+".xml");  
            },err=>{

            })
    }

    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    } 

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }
    
    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }

    gotoLogin(){
        this.setState({redirectToLogin: true});
    }


    handleAccordionCallback(child){
        //alert(child);
        if(child[0] == "Unallocated"){
            this.setState({
                isUnallocatedExpanded: child[1],
              });
        }
        else{
            this.setState({
                expanded: child[1],
              });
        }       
      
    }

    handleInit() {
   
    }
  
    handleItemUpdate(fileItems) {
      // console.log(fileItems);
      // Set current file objects to this.state
      this.setState({
        files: fileItems.map(fileItem => fileItem.file)
      },()=>{	
       // this.btnUploadFiles(this.state.files)
      });
      // console.log(this.state.files);
      //this.btnUploadFiles(this.state.files)
    }

    btnUploadFiles(fileItem) {
        //alert(fileItem)
        // console.log(this.state.files);
        // console.log(this.state.files[0].name);
        //return;
        if (this.state.files.length != 0) {
          // console.log("Button has been clicked!");
    
          // console.log("To be upload files");
          // console.log(
          //   this.props.matterId,
          //   this.state.files,
          //   this.props.note.OutstandingItemName
          // );
          //var safeFilename = this.props.note.OutstandingItemName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
          var fileName = this.state.files[0].name;
          var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
          

          if(!regex.test(fileName) || fileName.length > 200)
          {
            alert ("Invalid filename. Please check.");
            return;
          }
          else{
            //alert (true);
            //return;
          }
          var safeFilename = fileItem.replace(/[^a-z0-9]/gi, '_').toLowerCase();
          
    
          this.setState({ showModal: true });
          MatterOutstandingService.uploadDocument(
            this.props.matterId,
            this.state.files,
            //safeFilename,
            //this.props.note.OutstandingItemName
            fileItem
          ).then(
            response => {
              if( new SAlert({
                //title: "File Upload",
                //html: "Document transferred: <br />" + response.data.Message,
                // "Document transferred: " + files[0].name + "<br />" + result.Message
                //html: "<h4>A document has been successfully uploaded.</h4> ",//<br /> Document Name: " + response.data.Message,	
                html: "<h5>The document has been uploaded and will be sent to the system shortly.</h5> ",
                //onClose:()=>{this.reloadNotes()}
                didClose:()=>{this.reloadNotes()}
                //didClose:this.reloadNotes()
              })
            //   .then(function(){
                  
            //   }

            //   )
              ){
                this.setState({
                  files: [],
                  showModal:false,	
                  uploadSuccess: true
                });
                //this.props.Reload();
              }else{
                alert("Document has ben transferred.");
                this.setState({
                  files: [],
                  showModal:false
                });
                this.props.Reload();
              }
             
            
              
            },
            error => {
              new SAlert({
                title: "File Upload",
                html: "Document transfer FAILED.",//<br /><br />" + error.message,
                type: "error"
              });
              this.setState({ showModal: false });
    
            }
          );
        } else {
          this.pond.browse();
          this.setState({ showModal: false });
          // SAlert({
          //   title: "File Upload",
          //   html: "Please select file(s)",
          //   type: "error"
          // });
        }
    }

    render() {   
        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }        
        
        if(this.state.leftContent != this.state.pageTitle){
            // alert(this.state.lmwInstructMSASub)
             return( 
                 <DisplayLeftMenu 
                     page={this.state.leftContent} 
                     desktopMenu={this.state.desktopMenu} 
                     lmwInstructMSASub={this.state.lmwInstructMSASub}
                 />
             )
        } 

        // if(this.state.isLoading){
        //     return(<ProgressDisplay></ProgressDisplay>)
        // }

        let divProgress="";
        if (this.state.loading) {
            divProgress=(<div className="content"> <ProgressDisplay /></div>);
            //return (<div>progressDisplay</div>);
          }

        if(this.state.redirectToLogin)
        {
            return <Redirect to="/" />;
        }

        if((this.state.sessionHasExpired)){
            return(
              <div className="popup-box" style={{display: 'block',height:"60%", textAlign:"center"}}>
                        <div className="box card">
                            <div className="tal">                       
                                Your session has expired.  Click the button to login.                            
                            </div>  
                            <div>&nbsp;</div>                       
                            <ButtonGroup>
                                <Button
                                    clickFunction={(e) => this.gotoLogin(e)}
                                >
                                OK
                                </Button>
                            
                                {/* <Button
                                    addCSS="tertiaryBTN" 
                                    clickFunction={(e) => this.cancel(e)}
                                >
                                Cancel
                                </Button> */}
                            </ButtonGroup>
      
                                                      
                        </div>                       
                    </div>
            );
          }

        
        let partySegRenderable = this.state.PartySegmentEntities.length? this.state.PartySegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let addressSectionRenderable = this.state.AddressSectionEntities.length? this.state.AddressSectionEntities.reduce((p,c)=>[p,'',c]):'';
        let guarantorSegRenderable = this.state.GuarantorSegmentEntities.length? this.state.GuarantorSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let proprietorsSegRenderable = this.state.ProprietorSegmentEntities.length? this.state.ProprietorSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let nonRealEstateSegRenderable = this.state.NonRealEstateSegmentEntities.length? this.state.NonRealEstateSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let realEstateSegRenderable = this.state.RealEstateSegmentEntities.length? this.state.RealEstateSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let realEstateAssetSegRenderable = this.state.RealEstateAssetSegmentEntities.length? this.state.RealEstateAssetSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let titleSegRenderable = this.state.TitleSegmentEntities.length? this.state.TitleSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let dischargeOptions=[];
        for(let i=0; i<this.state.DischargeTypes.length;i++){
            dischargeOptions.push(<option>{this.state.DischargeTypes[i]}</option>);
        }
        
        let fileUpload= '';
        fileUpload = (<FilePond	 
            labelIdle="
                  <div class='GUContent'>
                      <div class='GUTextBox'>
                          <div classname='GUText1'>Drag & drop file here</div>
                          <div id='format'>File format: PDF, PBG, JPG</div>
                      </div>
                      <div id='button'> Browse</div>
                  </div>" 
            ref={ref => (this.pond = ref)}	
            files={this.state.files}	
            allowMultiple={false}	
            oninit={() => this.handleInit()}	
            onupdatefiles={fileItems => this.handleItemUpdate(fileItems)}	
            onClick={click => this.handleOnClick(click)}	
            //onaddfile={()=>this.btnUploadFiles("general_upload")}
          />);
        
          let files = [];
          this.state.LenderFiles.forEach((v,i)=>{files.push((<li>{v['FileName']}</li>))});         

        return ( 
             

            
            <LeftMenuContent 
                id="EditInstructionPage"  
                page="editinstructions" 
                desktopMenu={this.state.desktopMenu} 
                callBack = {this.handleLeftMenuCallback} 
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 

                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />
                    
                    {divProgress}

                    <div className="content"> 
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                    
                        <div className="FormsBox">
                            <form id="instruction-form">
                                <div className="spacingBottomLarge">
                                    <TitleSegment>Edit Instruction</TitleSegment> 
                                    <Card>
                                    {this.state.PartyHasOverseasAddress && this.state.isMiniHoldOverseaAddress?                                              
                                        <div className="spacingBottomLarge">
                                                <h5>Overseas Address</h5> 
                                                
                                                <Card>                                                
                                                    {addressSectionRenderable}
                                                    
                                                    {/* <div className="multiColsInputBox">
                                                        <div>
                                                            <ButtonIcon
                                                                id="btnCFPost"
                                                                addCSS="ButtonIconForm"
                                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                                onClick={(e)=>{e.preventDefault();this.setState({AddressSectionCount:this.state.AddressSectionCount+1},()=>{this.UpdateAddressSection()})}}
                                                            >Add Overseas Address</ButtonIcon>
                                                        </div>
                                                        <div className="tar">
                                                            <ButtonIcon
                                                                id="btnCFPost"
                                                                addCSS="ButtonIconForm"
                                                                iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                                display={this.state.AddressSectionCount===this.state.AddressSectionMinCount?'none':'inline-block'}
                                                                onClick={(e)=>{e.preventDefault();if(this.state.AddressSectionCount>=0){this.setState({AddressSectionCount:this.state.AddressSectionCount-1},()=>{this.UpdateAddressSection()})}}}
                                                                // onClick={(e)=>{e.preventDefault();if(this.state.AddressSectionCount>=0){let incVal=this.state.AddressSectionCount-1;this.setState({AddressSectionCount:incVal},()=>{this.UpdateAddressSection()})}}}
                                                            >Remove Overseas Address</ButtonIcon>         
                                                        </div>                              
                                                    </div>     */}
                                                </Card>
                                        </div>
                                    :
                                    ''
                                    }
                                        
                                    {this.state.LendingGuarantee !=null && this.state.isMiniHoldLimitedGuarantee?

                                        <div className="spacingBottomLarge">
                                            <h5>Guarantors</h5> 
                                            
                                            <Card>
                                                {guarantorSegRenderable}
                                                
                                                {/* <div className="multiColsInputBox">
                                                    <div>
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                            onClick={(e)=>{e.preventDefault();this.setState({GuarantorSegmentCount:this.state.GuarantorSegmentCount+1},()=>{this.UpdateLendingGuaranteeSegment()})}}
                                                        >Add Guarantor</ButtonIcon>
                                                    </div>
                                                    <div className="tar">
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                            display={this.state.GuarantorSegmentCount===this.state.GuarantorSegmentMinCount?'none':'inline-block'}
                                                            onClick={(e)=>{e.preventDefault();if(this.state.GuarantorSegmentCount>=0){this.setState({GuarantorSegmentCount:this.state.GuarantorSegmentCount-1},()=>{this.UpdateLendingGuaranteeSegment()})}}}
                                                        >Remove Guarantor</ButtonIcon>         
                                                    </div>                              
                                                </div>     */}
                                            </Card>
                                        </div>
                                    : ''}

                                    {/* {this.state.LoanPartySegment != null ? 
                                        <div className="spacingBottomLarge">
                                            <h5>Registered Proprietors</h5> 
                                            
                                            <Card>
                                                {proprietorsSegRenderable} */}
                                                
                                                {/* <div className="multiColsInputBox">
                                                    <div>
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                            onClick={(e)=>{e.preventDefault();this.setState({ProprietorSegmentCount:this.state.ProprietorSegmentCount+1},()=>{this.UpdateProprietorSegment()})}}
                                                        >Add Proprietor</ButtonIcon>
                                                    </div>
                                                    <div className="tar">
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                            display={this.state.ProprietorSegmentCount===this.state.ProprietorSegmentMinCount?'none':'inline-block'}
                                                            onClick={(e)=>{e.preventDefault();if(this.state.ProprietorSegmentCount>=0){this.setState({ProprietorSegmentCount:this.state.ProprietorSegmentCount-1},()=>{this.UpdateProprietorSegment()})}}}
                                                        >Remove Proprietor</ButtonIcon>         
                                                    </div>                              
                                                </div>     */}
                                            {/* </Card>
                                        </div>
                                    : ''} */}

                                    {/* <div className="spacingBottomLarge">
                                        <h5>Real Estate Asset</h5> 
                                        
                                        <Card> */}
                                            {/* {realEstateAssetSegRenderable} */}
                                            
                                            {/* <div className="multiColsInputBox">
                                                <div>
                                                    <ButtonIcon
                                                        id="btnCFPost"
                                                        addCSS="ButtonIconForm"
                                                        iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                        onClick={(e)=>{e.preventDefault();this.setState({NonRealEstateSegmentCount:this.state.NonRealEstateSegmentCount+1},()=>{this.UpdateNonRealEstateSegment()})}}
                                                    >Add Real Estate Asset</ButtonIcon>
                                                </div>
                                                <div className="tar">
                                                    <ButtonIcon
                                                        id="btnCFPost"
                                                        addCSS="ButtonIconForm"
                                                        iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                        display={this.state.NonRealEstateSegmentCount===this.state.NonRealEstateSegmentMinCount?'none':'inline-block'}
                                                        onClick={(e)=>{e.preventDefault();if(this.state.NonRealEstateSegmentCount>=0){this.setState({NonRealEstateSegmentCount:this.state.NonRealEstateSegmentCount-1},()=>{this.UpdateNonRealEstateSegment()})}}}
                                                    >Remove Real Estate Asset</ButtonIcon>         
                                                </div>                              
                                            </div>     */}
                                        {/* </Card>
                                    </div> */}


                                        {/* <div className="spacingBottomLarge">
                                            <h5>Non Real Estate Asset</h5> 
                                            
                                            <Card>
                                                {nonRealEstateSegRenderable}
                                                
                                                <div className="multiColsInputBox">
                                                    <div>
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                            onClick={(e)=>{e.preventDefault();this.setState({NonRealEstateSegmentCount:this.state.NonRealEstateSegmentCount+1},()=>{this.UpdateNonRealEstateSegment()})}}
                                                        >Add Real Estate Asset</ButtonIcon>
                                                    </div>
                                                    <div className="tar">
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                            display={this.state.NonRealEstateSegmentCount===this.state.NonRealEstateSegmentMinCount?'none':'inline-block'}
                                                            onClick={(e)=>{e.preventDefault();if(this.state.NonRealEstateSegmentCount>=0){this.setState({NonRealEstateSegmentCount:this.state.NonRealEstateSegmentCount-1},()=>{this.UpdateNonRealEstateSegment()})}}}
                                                        >Remove Real Estate Asset</ButtonIcon>         
                                                    </div>                              
                                                </div>    
                                            </Card>
                                        </div> */}

                                        {/* {this.state.TitleSegmentTitleReferences.length > 0 ? 
                                        <div className="spacingBottomLarge">
                                        <h5>Title References</h5> 
                                        
                                        <Card>
                                            {titleSegRenderable} */}
                                            
                                            {/* <div className="multiColsInputBox">
                                                <div>
                                                    <ButtonIcon
                                                        id="btnCFPost"
                                                        addCSS="ButtonIconForm"
                                                        iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                        onClick={(e)=>{e.preventDefault();this.setState({NonRealEstateSegmentCount:this.state.NonRealEstateSegmentCount+1},()=>{this.UpdateNonRealEstateSegment()})}}
                                                    >Add Real Estate Asset</ButtonIcon>
                                                </div>
                                                <div className="tar">
                                                    <ButtonIcon
                                                        id="btnCFPost"
                                                        addCSS="ButtonIconForm"
                                                        iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                        display={this.state.NonRealEstateSegmentCount===this.state.NonRealEstateSegmentMinCount?'none':'inline-block'}
                                                        onClick={(e)=>{e.preventDefault();if(this.state.NonRealEstateSegmentCount>=0){this.setState({NonRealEstateSegmentCount:this.state.NonRealEstateSegmentCount-1},()=>{this.UpdateNonRealEstateSegment()})}}}
                                                    >Remove Real Estate Asset</ButtonIcon>         
                                                </div>                              
                                            </div>     */}
                                        {/* </Card>
                                    </div>
                                        : ''} */}

                                        
                                    {this.state.RealEstateSegmentEntities.length > 0 && this.state.isMiniHoldReverseMortgage?
                                          <div className="spacingBottomLarge">
                                          <h5>Real Estate Asset</h5> 
                                          
                                          <Card>
                                              {realEstateSegRenderable}
                                              
                                              {/* <div className="multiColsInputBox">
                                                  <div>
                                                      <ButtonIcon
                                                          id="btnCFPost"
                                                          addCSS="ButtonIconForm"
                                                          iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                          onClick={(e)=>{e.preventDefault();this.setState({NonRealEstateSegmentCount:this.state.NonRealEstateSegmentCount+1},()=>{this.UpdateNonRealEstateSegment()})}}
                                                      >Add Real Estate Asset</ButtonIcon>
                                                  </div>
                                                  <div className="tar">
                                                      <ButtonIcon
                                                          id="btnCFPost"
                                                          addCSS="ButtonIconForm"
                                                          iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                          display={this.state.NonRealEstateSegmentCount===this.state.NonRealEstateSegmentMinCount?'none':'inline-block'}
                                                          onClick={(e)=>{e.preventDefault();if(this.state.NonRealEstateSegmentCount>=0){this.setState({NonRealEstateSegmentCount:this.state.NonRealEstateSegmentCount-1},()=>{this.UpdateNonRealEstateSegment()})}}}
                                                      >Remove Real Estate Asset</ButtonIcon>         
                                                  </div>                              
                                              </div>     */}
                                          </Card>
                                      </div>
                                    : ''}

                                    {this.state.PartySegmentEntities.length > 0 && (this.state.isMiniHoldIndependentFinancialAdviceRequired || this.state.isMiniHoldIndependentLegalAdviceWaived || this.state.isMiniHoldIntrepreterRequired) ? 
                                        <div className="spacingBottomLarge" id="divPartyDetails">
                                            <h5>Loan Parties</h5> 
                                            <form id="instruction-form-partydetails">
                                                <Card>
                                                    {partySegRenderable}
                                                    
                                                    {/* <div className="multiColsInputBox">
                                                        <div>
                                                            <ButtonIcon
                                                                id="btnCFPost"
                                                                addCSS="ButtonIconForm"
                                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                                onClick={(e)=>{e.preventDefault();this.setState({PartySegmentCount:this.state.PartySegmentCount+1},()=>{this.PartyTypeUpdated(e);this.UpdatePartySegment()})}}
                                                            >Add loan party</ButtonIcon>
                                                        </div>
                                                        <div className="tar">
                                                            <ButtonIcon
                                                                id="btnCFPost"
                                                                addCSS="ButtonIconForm"
                                                                iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                                display={this.state.PartySegmentCount===this.state.PartySegmentMinCount?'none':'inline-block'}
                                                                onClick={(e)=>{e.preventDefault();if(this.state.PartySegmentCount>=0){this.setState({PartySegmentCount:this.state.PartySegmentCount-1},()=>{this.UpdatePartySegment()})}}}
                                                            >Remove loan party</ButtonIcon>         </div>                              
                                                    </div>     */}
                                                </Card>
                                            </form>                                            
                                        </div>
                                    : ''}
                                    
                                      


                                        {/* <div className="spacingBottomLarge" id="divPartyDetails">
                                            <h5>Company Applicant Details</h5> 
                                            <form id="instruction-form-partydetails">
                                            <Card>
                                                {partySegRenderable}
                                                
                                                <div className="multiColsInputBox">
                                                    <div>
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                            onClick={(e)=>{e.preventDefault();this.setState({PartySegmentCount:this.state.PartySegmentCount+1},()=>{this.PartyTypeUpdated(e);this.UpdatePartySegment()})}}
                                                        >Add loan party</ButtonIcon>
                                                    </div>
                                                    <div className="tar">
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                            display={this.state.PartySegmentCount===this.state.PartySegmentMinCount?'none':'inline-block'}
                                                            onClick={(e)=>{e.preventDefault();if(this.state.PartySegmentCount>=0){this.setState({PartySegmentCount:this.state.PartySegmentCount-1},()=>{this.UpdatePartySegment()})}}}
                                                        >Remove loan party</ButtonIcon>         </div>                              
                                                </div>    
                                            </Card>
                                            </form>                                            
                                        </div> */}

                                        {/* <div className="spacingBottomLarge" id="divPartyDetails">
                                            <h5>Trust Applicant Details</h5> 
                                            <form id="instruction-form-partydetails">
                                            <Card>
                                                {trustSegRenderable}
                                                
                                                <div className="multiColsInputBox">
                                                    <div>
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                            onClick={(e)=>{e.preventDefault();this.setState({TrustSegmentCount:this.state.TrustSegmentCount+1},()=>{this.UpdateTrustSegment()})}}
                                                        >Add Trust Applicant</ButtonIcon>
                                                    </div>
                                                    <div className="tar">
                                                        <ButtonIcon
                                                            id="btnCFPost"
                                                            addCSS="ButtonIconForm"
                                                            iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                            display={this.state.TrustSegmentCount===this.state.TrustSegmentMinCount?'none':'inline-block'}
                                                            onClick={(e)=>{e.preventDefault();if(this.state.TrustSegmentCount>=0){this.setState({TrustSegmentCount:this.state.TrustSegmentCount-1},()=>{this.UpdateTrustSegment()})}}}
                                                        >Remove Trust Applicant</ButtonIcon>         </div>                              
                                                </div>    
                                            </Card>
                                            </form>                                            
                                        </div> */}

                                        {/* <div>
                                            <Accordion  callback={this.handleAccordionCallback}
                                                name={"DocumentUpload"}
                                                title={"Document Upload"}
                                                expanded={this.state.isGeneralExpanded}
                                            > 
                                                <div className="GUBox"> 
                                                    <div className="">
                                                        <div className="GUTitle">{fileUpload}</div>                                                     
                                                    </div> 
                                                </div>  
                                            </Accordion>
                                        </div> */}
                                        {/* {!this.state.isLoading && this.state.canUserGenralUpload ?  */}
                                        {!this.state.isLoading && this.state.isMiniHoldLendersDisclosureDocuments ? 
                                        <>
                                            <div>
                                                <h5>Document Upload for Lender Disclosure</h5> 
                                                {/* <div className="inputLabelSub">
                                                    Document Upload for Lender Disclosure
                                                </div> */}
                                                <div>
                                                    {this.state.files != "" ?  
                                                        <>                                                       
                                                            <div className="inputLabel">Uploaded: </div>
                                                            <div className="inputLabel">
                                                                {/* {this.state.files[0].name} */}
                                                                {files}
                                                            </div>
                                                        </>
                                                    : ""
                                                    }
                                                    
                                                </div>
                                                <div>
                                                    <div className="inputUploadBox">
                                                        <div className="iubContent">
                                                            <IconBox
                                                                addCSS="iubIcon"
                                                                id=""
                                                                title=""
                                                                iconURL="../../../../images/icons/OutlineGrey/Upload.svg"
                                                            />
                                                        <Dropzone onDrop={this.handleOnDrop} style={{height:"100px", width:"100%", backgroundColor:"none"}}>                                                
                                                                
                                                            
                                                                <div className="titleBox">Drag & drop file here or click to select file to upload.</div>
                                                                <div className="subText">File format: PDF, PNG, JPEG</div>
                                                            </Dropzone> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/><br/>

                                        </>
                                        : ''
                                        }
                                        
                                        <div>	
                                            {/* <Button addCSS="btnAuto">SUBMIT</Button>	 */}
                                            {this.state.InstructionSubmit?<div><a type="link" className="btn btn-secondary" href="dashboard">                            
                                                <Button
                                                    id=""
                                                    addCSS="btnAuto"
                                                    >
                                                Done</Button> 
                                            </a></div>
                                            :this.state.isLoading?                               
                                                <ProgressDisplay></ProgressDisplay>
                                            :
                                            <div><Button
                                                id=""
                                                addCSS="btnAuto"
                                                clickFunction={(e)=>{this.UpdateInstruction(e)}}
                                            >Update Instruction</Button> </div>
                                            }
                                        </div>  
                    
                                        
                                    </Card>
                                </div>                            

                            </form>    
                        </div>             
                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
EditInstructionPage=AutoLogout(EditInstructionPage);
export default EditInstructionPage
