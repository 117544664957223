import WebApiService                                        from './web-api-service';
import { AuthService }                                      from './auth-service';
class instructionService extends WebApiService{
    constructor(props){
        super(props);
    }

    CheckIsExistingMatter(lenderReference){
        return new Promise((resolve,reject)=>{
            const url = "api/CheckLenderReferenceExists";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'LenderReference':lenderReference,
                }
            };
            super.get(url,options).then((response)=>{
                    resolve(response);
                },error=>{
                    reject(error);
                });
        });
    }
    GetInstructionXml(matterId){
        return new Promise((resolve,reject)=>{
            const url = "api/GetInstructedXml";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':matterId
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);

            },err=>{
                reject(err);
            })
        })
    }
    GetInstructionHtml(matterId){
        return new Promise((resolve,reject)=>{
            const url = "api/GetInstructedHtml";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':matterId
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);

            },err=>{
                reject(err);
            })
        })
    }

    GetNewLoanInstructionXml(matterId){
        return new Promise((resolve,reject)=>{
            const url = "api/GetNewloanInstructedXml";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':matterId
                }
            }
            super.get(url,options).then((response)=>{
                resolve(response);

            },err=>{
                reject(err);
            })
        })
    }

    GetPayoutTrusts(){
        return new Promise((resolve,reject)=>{
            const url = "api/GetTrustAccounts";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            });
        });
    }

    GetExistingMatterDetails(msaReferenceNumber){
        return new Promise((resolve,reject)=>{
            const url = "api/GetExistingDischargeInformation";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':msaReferenceNumber,
                }
            }
            super.get(url,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    GetMatterDetailsForDischarges(lenderRefNo){
        return new Promise((resolve,reject)=>{
            const url = "api/GetMatterDetailsForDischarges";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'LenderRefNo':lenderRefNo,
                }
            }
            super.get(url,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    SendPayoutData(matterId,body){
        return new Promise((resolve,reject)=>{
            const url = "api/SendPayoutData/"+matterId;
            const options={
                'headers':{
                    "Content-Type": "application/json",
                    'Authorization':AuthService.token,
                }
            }
            super.postJSONWithHeaders(url,body,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }
    GetInstructionSchema(){
        return new Promise((resolve,reject)=>{
            const url = "api/InstructionScheme";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url).then((response)=>{
                resolve(response);
            },
            (error)=>{
                reject(error);
            });
        });
    }
    GetInstructionUISchema(){
        return new Promise((resolve,reject)=>{
            const url = "api/InstructionUIScheme";
            
            super.get(url).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    GetInstructionStates(){
        return new Promise((resolve,reject)=>{
            const url ="api/GetInstructionStates";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            });
        })
    }

    GetLenderMortgagees(){
        return new Promise((resolve,reject)=>{
            const url ="api/getLenderMortgagees";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            });
        })
    }


    GetLenderMortgageesViewList() {

        return new Promise((resolve, reject) => {

            const url = "api/getLenderMortgageesViewList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }


    GetDischargeTypes(){
        return new Promise((resolve,reject)=>{
            const url ="api/GetDischargeTypes";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            });
        })
    }

    GetDischargeReasonTypes(){
        return new Promise((resolve,reject)=>{
            const url ="api/GetDischargeReasonTypes";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            });
        })
    }

    SendDischargeInstruction(instruction){
        return new Promise((resolve,reject)=>{
            const url = "api/SendDischargeInstruction";
            super.postJSON(url,instruction).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    SendNewLoansInstruction(instruction){
        return new Promise((resolve,reject)=>{
            const url = "api/SendNewLoansInstruction";
            super.postJSON(url,instruction).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    SendInstructionData(instruction){
        return new Promise((resolve,reject)=>{
            const url = "api/SendInstructionData";
            super.postJSON(url,instruction).then((resp)=>{
                resolve(resp);
            },(err)=>{
                //reject(err);
                if(err.response.data.Message)
                {
                    reject(err.response.data.Message)
                }
                else
                {
                    reject(err);
                }
            })
        })
    }

    SendInstructionDischargeUpload(instruction){
        return new Promise((resolve,reject)=>{
            const url = "api/SendInstructionDischargeUpload";
            super.postJSON(url,instruction).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    SendInstructionConsentUpload(instruction){
        return new Promise((resolve,reject)=>{
            const url = "api/SendInstructionConsentUpload";
            super.postJSON(url,instruction).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    SendInstructionVariationUpload(instruction){
        return new Promise((resolve,reject)=>{
            const url = "api/SendNewLoanVariationUpload";
            super.postJSON(url,instruction).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    SendNewLoansInstruction(matterId,body){
        return new Promise((resolve,reject)=>{
            const url = "api/SendNewLoansInstruction/"+matterId;
            const options={
                'headers':{
                    "Content-Type": "application/json",
                    'Authorization':AuthService.token,
                    //"LenderId": lenderId
                }
            }
            super.postJSONWithHeaders(url,body,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            })
        })
    }

    // UpdateInstruction(matterId,body){
    //     return new Promise((resolve,reject)=>{
    //         const url = "api/UpdateInstruction/"+matterId;
    //         const options={
    //             'headers':{
    //                 "Content-Type": "application/json",
    //                 'Authorization':AuthService.token,
    //                 //"LenderId": lenderId
    //             }
    //         }
    //         super.postJSONWithHeaders(url,body,options).then((resp)=>{
    //             resolve(resp);
    //         },(err)=>{
    //             reject(err);
    //         })
    //     })
    // }

    UpdateInstruction(matterId, body){
        return new Promise((resolve,reject)=>{
            const url = "api/UpdateInstruction/"+matterId;
            super.postJSON(url,body).then((resp)=>{
                resolve(resp);
            },(err)=>{
                //reject(err);
                if(err.response.data.Message)
                {
                    reject(err.response.data.Message)
                }
                else
                {
                    reject(err);
                }
            })
        })
    }
    
}


export const InstructionService = new instructionService(); 